import React from "react";
import { default as withStyles, WithStyles } from "@material-ui/core/styles/withStyles";
import { listItem as styles, PrimaryIcon } from "./styles";
import { DeviceTypeListItem } from "@data";
import ModuleListView from "@components/module-list-view";
import { SortedSearchResultsListActions, SortedSearchResultsListModel } from "@components";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";
import { getPathToDeviceType } from "@modules/deviceTypeDetails/helpers";

export enum DeviceTypeColumn {
  NONE = "",
  NAMESPACE = "Namespace",
  NAME = "Name",
  VERSION = "Version",
  STATE = "State",
}

export const DEFAULT_COLUMNS = [
  DeviceTypeColumn.NAMESPACE,
  DeviceTypeColumn.NAME,
  DeviceTypeColumn.VERSION,
  DeviceTypeColumn.STATE,
];

export interface Model extends SortedSearchResultsListModel<DeviceTypeListItem, DeviceTypeColumn> {
  columns?: DeviceTypeColumn[];
}

export interface Actions extends SortedSearchResultsListActions<DeviceTypeListItem, DeviceTypeColumn> {
  loadMore?: () => void;
  toggleSortOrder?: () => void;
  setSortedColumn?: (column: DeviceTypeColumn) => void;
  showDeviceTypeDetails?: (deviceType: DeviceTypeListItem) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const DeviceTypesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    items = [],
    columns = DEFAULT_COLUMNS,
    toggleSortOrder,
    setSortedColumn,
    showDeviceTypeDetails,
    loadMore,
    ...remainingProps
  } = props;

  const moduleListItems: ListViewItem<DeviceTypeListItem>[] = React.useMemo(() =>
    items.map(device => {
      return {
        item: device,
        pathToDetailsView: getPathToDeviceType(device.getTypeIdentity()),
        icon: PrimaryIcon,
        columnAttributes: createColumns([
          {
            className: "deviceNamespace",
            value: device.getNameSpace(),
            column: DeviceTypeColumn.NAMESPACE,
            firstColumn: true,
          },
          {
            className: "deviceName",
            value: device.getName(),
            column: DeviceTypeColumn.NAME,
          },
          {
            className: "deviceVersion",
            value: device.getVersionAsString(),
            column: DeviceTypeColumn.VERSION,
          },
          {
            className: "deviceState",
            value: device.getState(),
            column: DeviceTypeColumn.STATE,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      { ...remainingProps }
      className="deviceTypesList"
      columns={columns}
      listViewItems={moduleListItems}
      onClickToggleSortOrder={toggleSortOrder}
      onClickColumn={setSortedColumn}
      onClickItem={showDeviceTypeDetails}
      onClickLoadMore={loadMore}
    />
  );
});

export default DeviceTypesList;
