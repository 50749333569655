import React from "react";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { clickHandler, isEmptyString, noop } from "@util";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface EditorTabModel {
  className?: string;
  label?: string;
  selected?: boolean;
}

export interface EditorTabActions {
  openTab?: () => void;
  closeTab?: () => void;
}

type Model = EditorTabModel;
type Actions = EditorTabActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditorTab = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    label = "",
    selected = false,
    openTab = noop,
    closeTab = noop,
    children,
  } = props;

  const onClickOpen = clickHandler(React.useCallback(() => {
    if (!selected) {
      openTab();
    }
  }, [selected, openTab]));

  const onClickClose = clickHandler(React.useCallback(() => {
    closeTab();
  }, [closeTab]));

  if (isEmptyString(label)) {
    return null;
  }

  return (
    <div
      className={classnames("editorTab", className, classes.container, {
        [classes.selected]: selected,
      })}
      onClick={onClickOpen}
    >
      <FileIcon
        className={classnames("icon", classes.icon)}
      />
      <Typography
        className={classnames("label", classes.label)}
      >
        {label}
      </Typography>
      <IconButton
        className={classnames("closeIconButton", classes.closeIconButton)}
        size="small"
        onClick={onClickClose}
      >
        <CloseIcon className={classnames("closeIcon", classes.closeIcon)} />
      </IconButton>
      {children}
    </div>
  );
});

export default EditorTab;