import React from "react";
import { ExecuteWorkloadEvent, TriggerType } from "@data";
import { useApiRequest, UseApiRequestModel, UseApiRequestProps, useWorkloadManagerClient } from "@hooks";
import { ManuallyExecuteWorkloadResponse } from "@network";

type SuccessResponse = ManuallyExecuteWorkloadResponse;

export interface UseExecuteWorkloadProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: number;
  data: ExecuteWorkloadEvent;
}

export interface UseExecuteWorkloadModel extends UseApiRequestModel<SuccessResponse> {
  executionId?: string;
  successMessage: string;
}

export interface UseExecuteWorkloadActions {
  executeWorkload: () => void;
}

type Props = UseExecuteWorkloadProps;
type Model = UseExecuteWorkloadModel;
type Actions = UseExecuteWorkloadActions;
type Result = [Model, Actions];

export const useExecuteWorkload = (props: Props): Result => {

  const {
    name,
    version,
    data = ExecuteWorkloadEvent.EMPTY,
    defaultErrorMessage = "Failed to execute workload",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const json = data.toJS();
  const triggerType = React.useMemo(() => data.getType(), [data]);

  if (triggerType === TriggerType.DATA_LAKE) {
    if (json.triggerStorage.size >= 0) {
     delete json.triggerStorage.size;
    }
  } else {
    delete json.triggerStorage;
    delete json.dataSetAlias;
    delete json.accountId;
  }

  if (triggerType !== TriggerType.SCHEDULE) {
    delete json.scheduleId;
    delete json.eventDatetime;
  }

  if (triggerType !== TriggerType.WORKLOAD_COMPLETE) {
    delete json.precedingWorkloadId;
  }

  if (triggerType !== TriggerType.MANUAL_EXECUTE) {
    delete json.listFile;
  }

  const makeApiRequest = React.useCallback(() =>
      WorkloadManagerClient.manuallyExecuteWorkloadApi(name, version, JSON.stringify({
        ...json,
        eventDatetime: new Date(),
        "@type": triggerType,
        eventType: triggerType,
        type: triggerType,
      })), [WorkloadManagerClient, name, version, json, triggerType]);

  const [{ showSuccessView, successResponse, ...baseModel }, { refresh: executeWorkload }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest,
    });

  const successMessage = React.useMemo(() =>
    showSuccessView ? "Workload Executed" : "", [showSuccessView]);

  const executionId = React.useMemo(() => {
    return successResponse ? successResponse.executionId : "";
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    executionId,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    executionId,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    executeWorkload,
  }), [
    executeWorkload,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useExecuteWorkload;
