import React from "react";
import Fab from "@components/fab";
import classnames from "classnames";
import { clickHandler, noop } from "@util";
import SaveIcon from "@material-ui/icons/Save";
import { Tooltip } from "@components";
import CloseIcon from "@material-ui/icons/Close";
import CheckmarkIcon from "@material-ui/icons/Check";
import ContinueButton from "@components/continue-button";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface SaveButtonProps {
  className?: string;
  buttonClassName?: string;
  success?: boolean;
  loading?: boolean;
  disabled?: boolean;
  color?: "inherit" | "primary" | "secondary" | "default";
  label?: React.ReactNode | string;
  downloadIndicatorSize?: number;
  showCloseIconOnSuccess?: boolean;
  tabIndex?: number;
  save?: () => void;
  onClickCloseIcon?: () => void;
}

type Props = WithStyles<typeof styles> & SaveButtonProps;

export const SaveButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    buttonClassName,
    loading,
    color = "primary",
    success,
    disabled,
    label = "Save",
    downloadIndicatorSize = 48,
    showCloseIconOnSuccess,
    tabIndex,
    save = noop,
    onClickCloseIcon = noop,
  } = props;

  const closeIconClicked = React.useCallback(() => {
    if (showCloseIconOnSuccess) {
      onClickCloseIcon();
    }
  }, [showCloseIconOnSuccess, onClickCloseIcon]);

  const button = React.useMemo(() => {

    if (!loading && !success) {
      return (
        <ContinueButton
          className={classnames("button", classes.button, buttonClassName)}
          color={color}
          variant="contained"
          fullWidth={false}
          disabled={disabled}
          tabIndex={tabIndex}
          onClick={clickHandler(save)}
        >
          {label}
        </ContinueButton>
      );
    }

    return (
      <Fab
        color={color}
        className={classnames("fab", classes.fab, {
          [classes.success]: success,
        })}
        onClick={clickHandler(closeIconClicked)}
      >
        {!success && <SaveIcon />}
        {success && (
          <React.Fragment>
            {!showCloseIconOnSuccess && (
              <CheckmarkIcon className={classnames("checkmarkIcon", classes.checkmarkIcon)} />
            )}
            {showCloseIconOnSuccess && (
              <Tooltip title="Click to Close" >
                <CloseIcon className={classnames("closeIcon", classes.closeIcon)}/>
              </Tooltip>
            )}
          </React.Fragment>
        )}
        {loading && (
          <CircularProgress
            size={downloadIndicatorSize}
            className={classnames("progressIndicator", classes.progressIndicator)}
          />
        )}
      </Fab>
    );

  }, [classes, loading, color, success, disabled, label, save, buttonClassName, downloadIndicatorSize]);

  return (
    <div className={classnames("saveButton", className, classes.container)}>
      {button}
    </div>
  );
});

export default SaveButton;
