import React from "react";
import classnames from "classnames";
import { formatUTCDate, noop } from "@util";
import QualityReport from "@data/QualityReport";
import Typography from "@material-ui/core/Typography";
import RefreshButton from "@components/refresh-button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Licenses from "./Licenses";
import Tests from "./Tests";
import SecuritySection from "./SecuritySection";
import QualitySection from "./QualitySection";
import styles, { PrimaryIcon } from "./styles";

export interface QualityReportDetailsModel {
  className?: string;
  title?: string;
  report?: QualityReport;
  previousVersions?: QualityReport[];
  totalNumItems?: number;
  showLoadingIndicator?: boolean;
  showTests?: boolean;
  showCreatedAt?: boolean;
}

export interface QualityReportDetailsActions {
  refresh?: () => void;
}

type Model = QualityReportDetailsModel;
type Actions = QualityReportDetailsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const QualityReportDetails = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Quality Report Metrics",
    report = QualityReport.EMPTY,
    previousVersions = [],
    showLoadingIndicator,
    showTests,
    showCreatedAt,
    refresh = noop,
    children,
  } = props;

  const codeCoverageReports = React.useMemo(() =>
    [report].concat(previousVersions).slice(0, 5),
  [report, previousVersions]);

  const previousVersion = React.useMemo(() => {
    const unsortedPreviousVersions = previousVersions.slice();
    const sortedPreviousVersions = unsortedPreviousVersions.sort((a, b) => {
      return new Date(a.getCreatedAt()).getTime() - new Date(b.getCreatedAt()).getTime();
    });
    return sortedPreviousVersions.pop() || QualityReport.EMPTY;
  }, [previousVersions]);

  return (
    <div className={classnames("qualityReportDetails", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <PrimaryIcon className={classnames("icon", classes.icon)} />
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
        <RefreshButton
          className={classnames("refreshButton", classes.refreshButton)}
          loading={showLoadingIndicator}
          refresh={refresh}
        />
      </div>
      {showCreatedAt && (
        <Typography className={classnames("createdAt", classes.createdAt)} variant="caption">
          Created On: {formatUTCDate(report.getCreatedAt(), "YYYY-MM-DD")}
        </Typography>
      )}
      <div className={classnames("row", classes.row)}>
        <Licenses
          className={classnames("licenses", classes.column, classes.licenses)}
          report={report}
        />
        {showTests && (
          <Tests
            className={classnames("tests", classes.tests)}
            currentReport={report}
            previousReport={previousVersion}
          />
        )}
        <SecuritySection
          className={classnames("securitySection", classes.securitySection)}
          currentReport={report}
          previousReport={previousVersion}
        />
      </div>
      <QualitySection
        className={classnames("qualitySection", classes.qualitySection)}
        currentReport={report}
        previousReport={previousVersion}
        codeCoverageReports={codeCoverageReports}
      />
      {children}
    </div>
  );
});

export default QualityReportDetails;
