import { useApiRequestAnalytics } from "@hooks";
import { UserIdmLegacyClient } from "@network";

export type LogoutApi = (userId?: string) => Promise<void>;

export interface UserIdmLegacyClientApi {
  logout: LogoutApi;
}

enum ActionTypes {
  LOGOUT_REQUEST = "user_idm_legacy_client/logout_request",
  LOGOUT_SUCCESS = "user_idm_legacy_client/logout_success",
  LOGOUT_FAILED = "user_idm_legacy_client/logout_failed",
}

const useLogoutApi = () =>
  useApiRequestAnalytics(UserIdmLegacyClient.logout, {
    REQUEST_EVENT: ActionTypes.LOGOUT_REQUEST,
    SUCCESS_EVENT: ActionTypes.LOGOUT_SUCCESS,
    ERROR_EVENT: ActionTypes.LOGOUT_FAILED,
  });

export const useUserIdmLegacyClient = (): UserIdmLegacyClientApi => ({
  logout: useLogoutApi(),
});

export { ActionTypes as UserIdmLegacyClientActionTypes };

export default useUserIdmLegacyClient;
