import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AlertDialog, DownloadButton, ErrorView } from "@components";
import { useTrainingCertificateUrl } from "@hooks";
import styles from "./styles";

export interface DownloadTrainingCertificateButtonModel {
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  label?: string;
  trainingCertificateId?: string;
}

type Model = DownloadTrainingCertificateButtonModel;
type Props = WithStyles<typeof styles> & Model & {
  children?: React.ReactNode;
};

export const DownloadTrainingCertificateButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    buttonClassName,
    disabled,
    label = "Download Training Certificate",
    trainingCertificateId = "",
    children,
  } = props;

  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  const [model, actions] = useTrainingCertificateUrl({
    trainingCertificateId,
  });

  const { errorMessage, showErrorView, showLoadingIndicator, statusCode, trainingCertificateUrl } = model;

  const { reset, download } = actions;

  React.useEffect(() => setShowErrorDialog(showErrorView), [setShowErrorDialog, showErrorView]);

  return (
    <React.Fragment>
      <DownloadButton
        className={classnames("downloadTrainingCertificateButton", className, classes.downloadButton)}
        downloadFileClassName="downloadTrainingCertificateFile"
        buttonClassName={buttonClassName}
        label={label}
        href={trainingCertificateUrl}
        loading={showLoadingIndicator}
        disabled={disabled}
        download={download}
      />
      <AlertDialog
        className={classnames("errorDialog", classes.errorDialog)}
        open={showErrorDialog}
        title="Download Failed"
        close={reset}
      >
        <ErrorView
          className={classnames("errorView", classes.errorView)}
          message={errorMessage}
          retry={download}
          retryButtonLabel="Retry"
          retryButtonClassName={classnames("retryButton", classes.retryButton)}
          statusCode={statusCode}
        />
      </AlertDialog>
      {children}
    </React.Fragment>
  );
});

export default DownloadTrainingCertificateButton;
