import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
} from "@hooks";
import useUiPackageClient from "@hooks/uiPackage/use-ui-package-client";

type SuccessResponse = void;

export interface UseDeleteUiPackageProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseDeleteUiPackageModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseDeleteUiPackageActions {
  deleteUiPackage: () => void;
}

type Props = UseDeleteUiPackageProps;
type Model = UseDeleteUiPackageModel;
type Actions = UseDeleteUiPackageActions;
type Result = [Model, Actions];

export const useDeleteUiPackage = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to delete UI package",
    ...otherProps
  } = props;

  const UiPackageClient = useUiPackageClient();

  const makeApiRequest = React.useCallback(() =>
    UiPackageClient.deleteUiPackage(),
    [UiPackageClient]);

  const [{ showSuccessView, ...baseModel }, { refresh: deleteUiPackage }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "Delete Successful" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    deleteUiPackage,
  }), [
    deleteUiPackage,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeleteUiPackage;
