import React from "react";
import { DataSetDetail, DataSetDetailAttributes } from "@data";
import { AuthTokenContext } from "@components";
import { isEmptyString, noop } from "@util";
import { DataCatalogClient, RestClientError } from "@network";

export interface UseDataSetProps {
  dataSetAlias?: string;
  accountId?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseDataSetModel {
  dataSet: DataSetDetail;
  errorMessage: string;
  showNotFound: boolean;
  showErrorView: boolean;
  showAccessDenied: boolean;
  showProgressIndicator: boolean;
  isDataSetAliasValid: boolean;
  isAccountIdValid: boolean;
  statusCode?: number;
}

export interface UseDataSetActions {
  refresh: () => void;
}

type Props = UseDataSetProps;

export const useDataSet = (props: Props): [UseDataSetModel, UseDataSetActions] => {

  const {
    dataSetAlias = "",
    accountId = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [dataSet, setDataSet] = React.useState(DataSetDetail.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState<number>();

  const isDataSetAliasValid = React.useMemo(() => !isEmptyString(dataSetAlias), [dataSetAlias]);
  const isAccountIdValid = React.useMemo(() => !isEmptyString(accountId), [accountId]);

  React.useEffect(() => {
    if (!isDataSetAliasValid || !isAccountIdValid) {
      return () => noop;
    }

    let ignore = false;

    setShowNotFound(false);
    setShowAccessDenied(false);
    setShowProgressIndicator(true);
    trackRequestEvent();
    setErrorMessage("");

    DataCatalogClient.getDataSet(authToken, dataSetAlias, accountId)
      .then((attrs: DataSetDetailAttributes) => {

        if (!ignore) {
          setDataSet(new DataSetDetail(attrs));
          trackSuccessEvent();
          setShowProgressIndicator(false);
        }
      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch data set failed", analytic } = response;
          setErrorMessage(error);
          trackErrorEvent(analytic);
          setShowProgressIndicator(false);
          setStatusCode(response.status);
          if (response.status === 404) {
            setShowNotFound(true);
          }
          if (response.status === 403) {
            setShowAccessDenied(true);
          }
        }
      });

    return () => { ignore = true; };
  } , [
    isDataSetAliasValid,
    isAccountIdValid,
    setShowNotFound,
    setShowAccessDenied,
    setShowProgressIndicator,
    setErrorMessage,
    lastRefresh,
    authToken,
    dataSetAlias,
    accountId,
    setDataSet,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const refresh = React.useCallback(() =>
    setLastRefresh(+new Date) , [setLastRefresh]);

  const model = React.useMemo<UseDataSetModel>( () => ({
    dataSet,
    errorMessage,
    showNotFound,
    showErrorView,
    showAccessDenied,
    showProgressIndicator,
    isDataSetAliasValid,
    isAccountIdValid,
    statusCode,
  }), [
    dataSet,
    errorMessage,
    showNotFound,
    showErrorView,
    showAccessDenied,
    showProgressIndicator,
    isDataSetAliasValid,
    isAccountIdValid,
    statusCode,
  ]);

  const actions = React.useMemo<UseDataSetActions>(() => ({
    refresh,
  }), [refresh]);

  return [ model, actions];

};

export default useDataSet;
