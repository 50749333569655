import React from "react";
import { noop } from "@util";
import { UsersIcon } from "@icons";
import { SearchFilter, User } from "@data";
import UsersListColumn from "./UsersListColumn";
import { ActionMenuItem } from "../actions-menu";
import { UserAction } from "../user-actions-menu";
import {
  SortedSearchResultsListActions,
  SortedSearchResultsListModel,
} from "../sorted-search-results-list";
import { getPathToUser } from "@modules/userDetails/helpers";
import { ModuleListView } from "@components/module-list-view";
import ManageSearchFilters from "@components/user-search-filter";
import ListViewItem from "@components/module-list-view/ListViewItem";
import { createColumns } from "@components/module-list-view/helpers";

export const DEFAULT_USERS_LIST_COLUMNS: UsersListColumn[] = [
  UsersListColumn.NAME,
  UsersListColumn.STATUS
];

// This function is added to address a customer bug IOTSST-2804.
// This should be removed once SecSvcs does not allow users with % sign to be created.
const encodeUserIdIfNeeded = (userId: string): string => {
  if (userId.includes("%")) {
    try {
      return encodeURIComponent(userId);
    } catch (e) {
      console.error("Error encoding user ID:", e);
      return userId;
    }
  }
  return userId;
};

export interface UsersListModel extends SortedSearchResultsListModel<User, UsersListColumn> {
  headerClassName?: string;
  orJoinOperator?: boolean;
  showOrJoinOperator?: boolean;
  showSearchFilters?: boolean;
  searchFilters?: SearchFilter[];
}

export interface UsersListActions extends SortedSearchResultsListActions<User, UsersListColumn> {
  addUserToGroup?: (user: User) => void;
  removeUserFromGroup?: (user: User) => void;
  attachPolicy?: (user: User) => void;
  detachPolicy?: (user: User) => void;
  logoutUser?: (user: User) => void;
  expirePassword?: (user: User) => void;
  changePassword?: (user: User) => void;
  recoverPassword?: (user: User) => void;
  lockUser?: (user: User) => void;
  unlockUser?: (user: User) => void;
  closeUser?: (user: User) => void;
  deleteUser?: (user: User) => void;
  setOrJoinOperator?: (orJoinOperator: boolean) => void;
  setSearchFilters?: (searchFilters: SearchFilter[]) => void;
  setNameFilter?: (nameFilter: string) => void;
}

type Props = UsersListModel & UsersListActions;

export const UsersList = (props: Props) => {

  const {
    items = [],
    className = "users",
    headerClassName,
    listClassName = "usersList",
    searchViewHint = "Filter by email address",
    columns = DEFAULT_USERS_LIST_COLUMNS,
    sortByColumn = UsersListColumn.NAME,
    sortOrderAscending = true,
    showSearchFilters = true,
    noResultsLabel = "No users found",
    summaryViewQuantities = {
      other: "users",
      one: "user",
    },
    nameFilterDelay = 250,
    orJoinOperator,
    showOrJoinOperator,
    searchFilters,
    showSearch,
    addUserToGroup = noop,
    removeUserFromGroup = noop,
    attachPolicy = noop,
    detachPolicy = noop,
    logoutUser = noop,
    expirePassword = noop,
    changePassword = noop,
    recoverPassword = noop,
    lockUser = noop,
    unlockUser = noop,
    closeUser = noop,
    deleteUser = noop,
    onClickAction = noop,
    setOrJoinOperator = noop,
    setSearchFilters = noop,
    setNameFilter = noop,
    ...otherProps
  } = props;

  const { showLoadingIndicator } = otherProps;

  const onActionClicked = React.useCallback((user: User, action: ActionMenuItem) => {
    switch (action.id) {
      case UserAction.ADD_USER_TO_GROUP:
        return addUserToGroup(user);
      case UserAction.REMOVE_USER_FROM_GROUP:
        return removeUserFromGroup(user);
      case UserAction.ATTACH_POLICY:
        return attachPolicy(user);
      case UserAction.DETACH_POLICY:
        return detachPolicy(user);
      case UserAction.LOGOUT_USER:
        return logoutUser(user);
      case UserAction.EXPIRE_PASSWORD:
        return expirePassword(user);
      case UserAction.CHANGE_PASSWORD:
        return changePassword(user);
      case UserAction.RECOVER_PASSWORD:
        return recoverPassword(user);
      case UserAction.LOCK_USER:
        return lockUser(user);
      case UserAction.UNLOCK_USER:
        return unlockUser(user);
      case UserAction.CLOSE_USER:
        return closeUser(user);
      case UserAction.DELETE_USER:
        return deleteUser(user);
      default:
        return onClickAction(user, action);
    }
  }, [
    addUserToGroup,
    removeUserFromGroup,
    attachPolicy,
    detachPolicy,
    logoutUser,
    expirePassword,
    changePassword,
    recoverPassword,
    lockUser,
    unlockUser,
    closeUser,
    deleteUser,
    onClickAction,
  ]);

  const header = React.useMemo(() => !showSearchFilters ? null : (
    <ManageSearchFilters
      className={headerClassName}
      orJoinOperator={orJoinOperator}
      showOrJoinOperator={showOrJoinOperator}
      searchFilters={searchFilters}
      loading={showLoadingIndicator}
      setOrJoinOperator={setOrJoinOperator}
      setSearchFilters={setSearchFilters}
    />
  ), [
    showSearchFilters,
    headerClassName,
    orJoinOperator,
    showOrJoinOperator,
    searchFilters,
    showLoadingIndicator,
    setOrJoinOperator,
    setSearchFilters,
  ]);

  const moduleListItems: ListViewItem<User>[] = React.useMemo(() =>
    items.map(item => {
      return {
        item: item,
        pathToDetailsView: getPathToUser(encodeUserIdIfNeeded(item.getUserId())),
        icon: UsersIcon,
        columnAttributes: createColumns([
          {
            className: "userId",
            value: item.getUserId(),
            column: UsersListColumn.NAME,
            firstColumn: true,
          },
          {
            className: "userStatus",
            value: item.getStatus(),
            column: UsersListColumn.STATUS,
          },
        ])
      };
    }), [items]);

  return (
    <ModuleListView
      {...otherProps}
      className={className}
      listViewItems={moduleListItems}
      listClassName={listClassName}
      searchViewHint={searchViewHint}
      columns={columns}
      nameFilterDelay={nameFilterDelay}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      noResultsLabel={noResultsLabel}
      summaryViewIcon={UsersIcon}
      summaryViewQuantities={summaryViewQuantities}
      onClickAction={onActionClicked}
      header={header}
      showSearch={showSearch}
      customNameSearch={setNameFilter}
    />
  );
};

export default UsersList;
