import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import PdfViewer from "@components/pdf-viewer";
import { useTermsAndConditionsUrl } from "@hooks";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface TermsConditionsPdfViewerModel {
  className?: string;
  termsConditionsId?: string;
  hideDownloadButton?: boolean;
}

export interface TermsConditionsPdfViewerActions {
}

type Model = TermsConditionsPdfViewerModel;
type Actions = TermsConditionsPdfViewerActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const TermsConditionsPdfViewer = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    termsConditionsId,
    hideDownloadButton,
    children,
  } = props;

  const [model, actions] = useTermsAndConditionsUrl({ termsConditionsId });

  const {
    termsConditionsUrl,
    errorMessage,
    showErrorView,
    showAccessDenied,
    showLoadingIndicator,
    statusCode,
  } = model;

  const { download } = actions;

  const fileName = React.useMemo(() =>
      decodeURIComponent((((termsConditionsUrl || "").split("/").pop() || "")
        .split("?")[0] || "")
        .split("-").pop() || "") || "Terms & Conditions",
    [termsConditionsUrl]);

  const startDownload = React.useMemo(() =>
      isEmptyString(termsConditionsUrl) &&
      !showLoadingIndicator && !showErrorView && !showAccessDenied &&
      !isEmptyString(termsConditionsId),
    [
      termsConditionsUrl,
      showLoadingIndicator,
      showErrorView,
      showAccessDenied,
      termsConditionsId,
    ]);

  React.useEffect(() => {
    if (!startDownload) {
      return noop;
    }
    const timer = setTimeout(download, 25);
    return () => clearTimeout(timer);
  }, [startDownload, download]);

  if (isEmptyString(termsConditionsId)) {
    return null;
  }

  return (
    <WaitForApiRequestView
      className={classnames("termsConditionsPdfViewer", className, classes.container)}
      errorMessage={errorMessage}
      showErrorView={showErrorView}
      showAccessDenied={showAccessDenied}
      showLoadingIndicator={showLoadingIndicator}
      loadingMessage="Loading Terms & Conditions..."
      errorTitle="Failed to Load Terms & Conditions"
      retry={download}
      statusCode={statusCode}
    >
      <PdfViewer
        className={classnames("pdfViewer", classes.pdfViewer)}
        file={termsConditionsUrl}
        fileName={fileName}
        hideDownloadButton={hideDownloadButton}
      >
        {children}
      </PdfViewer>
    </WaitForApiRequestView>
  );
});

export default TermsConditionsPdfViewer;
