import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { usePortalSnackbar } from "@components";
import { RestClientError } from "@network";
import {  styles } from "./styles";
import { noop } from "@util/Functions";
import UploadDialog from "@components/upload-dialog";
import useUiPackageClient from "@hooks/uiPackage/use-ui-package-client";

export const UploadUiPackage = withStyles(styles)((props: WithStyles<typeof styles> & {
  open?: boolean,
  cancel?: () => void,
  onSuccess?: () => void,
}) => {

  const {
    classes,
    open,
    cancel = noop,
    onSuccess = noop,
  } = props;

  const UiPackageClient = useUiPackageClient();
  const [uploading, setUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [file, setFile] = React.useState<File>();
  const [responseMessage, setResponseMessage] = React.useState("");
  const [successDialog, setSuccessDialog] = React.useState(false);

  usePortalSnackbar(`upload-ui-package`, {
    errorMessage,
    successMessage,
    onSuccessMessageShown: onSuccess,
    successCallbackDelay: 0,
  });

  const fileLocationUrl = responseMessage.substring(
    responseMessage.indexOf("http"),
    responseMessage.indexOf("Base")
  );
  const baseUrl = responseMessage.substring(responseMessage.lastIndexOf("http"));

  React.useEffect(() => {

    if (!file || !uploading || errorMessage || successMessage) {
      return () => noop;
    }

    let ignore = false;

    UiPackageClient.uploadUiPackage(file)
      .then((response: any) => {
        if (!ignore) {
          // File Location and S3 location updated to responseMessage
          response.text().then((body: any) => setResponseMessage(body));
          setUploading(false);
          setSuccessDialog(true);
          setSuccessMessage("UI Package Upload Successfully");
        }
      })
      .catch((response: RestClientError) => {
        if (!ignore) {
          const { description, error } = response;
          setUploading(false);
          setErrorMessage(description || error || "Upload Failed");
        }
      });

    return () => { ignore = true; };

  }, [
    UiPackageClient,
    file,
    uploading,
    successMessage,
    errorMessage,
    setUploading,
    setSuccessDialog,
    setSuccessMessage,
    setErrorMessage,
  ]);

  return (
    <div className={classnames("uploadUiPackage", classes.container)}>
      <UploadDialog
        open={open}
        title={successDialog ? "Upload Successful" : "Upload UI Package"}
        file={file}
        uploading={uploading}
        successDialog={successDialog}
        showCloseIconOnSuccess={true}
        successMessage={successMessage}
        errorMessage={errorMessage}
        fileLocationUrl={fileLocationUrl}
        baseUrl={baseUrl}
        cancel={cancel}
        setUploading={setUploading}
        setErrorMessage={setErrorMessage}
        setFile={setFile}
      />
    </div>
  );
});

export default UploadUiPackage;
