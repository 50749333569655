import React from "react";
import SwitchPrincipal from "./containers/SwitchPrincipal";
import { useParams } from "react-router-dom";
import { RouteParams } from "@modules/dataWorkloadDetails/constants";
import { isValidInteger } from "@util";

export function SwitchPrincipalView() {

  const { name, version: versionParam } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [ versionParam ]);

  return (
    <SwitchPrincipal
      workloadName={name}
      version={version}
    />
  );
}

export default SwitchPrincipalView;
