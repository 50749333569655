import React from "react";
import { useInviteUser } from "@hooks";
import { isEmptyString, noop } from "@util";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import EditProfileAttributes from "./EditProfileAttributes";
import SelectApplication from "./SelectApplication";
import SelectEmailTemplate from "./SelectEmailTemplate";
import InviteUserState from "../models/InviteUserState";
import ReviewView from "../components/ReviewView";
import { InviteUserRequest, UserProfileAttribute, UserProfileAttributesMap } from "@data";

export enum NewUserWizardStep {
  NONE = "",
  USER_INFO = "user_info",
  APPLICATION = "application",
  EMAIL_TEMPLATE = "email_template",
  REVIEW = "review",
}

export const WIZARD_STEP_LABELS = {
  [NewUserWizardStep.NONE]: null,
  [NewUserWizardStep.USER_INFO]: "Enter User Info",
  [NewUserWizardStep.APPLICATION]: "Select Application",
  [NewUserWizardStep.EMAIL_TEMPLATE]: "Select Email Template (Optional)",
  [NewUserWizardStep.REVIEW]: "Review Invitation",
};

export const DEFAULT_STEPS = [
  NewUserWizardStep.USER_INFO,
  NewUserWizardStep.APPLICATION,
  NewUserWizardStep.EMAIL_TEMPLATE,
  NewUserWizardStep.REVIEW,
];

interface ContainerModel extends SubmitApiRequestViewModel<InviteUserState, NewUserWizardStep> {
  userId?: string;
}

interface ContainerActions extends SubmitApiRequestViewActions<InviteUserState, NewUserWizardStep> {
  showUserDetails?: (userId: string) => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const InviteUserContainer = (props: Props) => {

  const {
    className = "inviteUser",
    snackbarId = "invite-user",
    errorTitle = "Invite User Failed",
    saveButtonLabel = "Invite User",
    defaultState = InviteUserState.EMPTY,
    mapStepToLabel = (step: NewUserWizardStep) => WIZARD_STEP_LABELS[step] || null,
    showUserDetails = noop,
    children,
    ...otherProps
  } = props;

  const [userId, setUserId] = React.useState<string>("");
  const [applicationId, setApplicationId] = React.useState<string>("");
  const [emailTemplateId, setEmailTemplateId] = React.useState<string>("");
  const [sendEmailCopy, setSendEmailCopy] = React.useState<boolean>(false);
  const [profile, setProfile] = React.useState<UserProfileAttributesMap>({});

  const onSuccess = React.useCallback(() =>
    showUserDetails(userId), [showUserDetails, userId]);

  const userProfileAttributesMap = React.useMemo(() =>
    Object.keys(profile).reduce((attrs: UserProfileAttributesMap, key: string) => {
      attrs[key] = new UserProfileAttribute(profile[key]);
      return attrs;
    }, {}), [profile]);

  const userProfileAttributes = React.useMemo(() =>
    Object.keys(userProfileAttributesMap).map(key => userProfileAttributesMap[key]),
    [userProfileAttributesMap]);

  const profileValueUpdated = React.useMemo(() =>
    userProfileAttributes.some(attr => !isEmptyString(attr.value)),
    [userProfileAttributes]);

  const inviteUserRequest = React.useMemo(() =>
    InviteUserRequest.from(userProfileAttributesMap, emailTemplateId),
    [userProfileAttributesMap, emailTemplateId]);

  const updateProfile = React.useCallback((obj: UserProfileAttributesMap) => {
    const profileAttributes = Object.keys(obj)
      .reduce((attributes: UserProfileAttributesMap, key: string) => {
        const attribute = obj[key];
        attributes[attribute.getName()] = attribute;
        return attributes;
      }, {});
    return setProfile(profileAttributes);
  }, [setProfile]);

  const updateApplicationId = React.useCallback((appId: string) => {
    setApplicationId(appId);
    // Reset the email template id whenever the application id changes to ensure that the
    // user must consciously select an email template appropriate for that application; this
    // logic will prevent a previously selected email template for a different application from
    // being used if the application id is changed
    return setEmailTemplateId("");
  }, [setApplicationId, setEmailTemplateId]);

  const [{ showLoadingIndicator, ...otherModel }, { inviteUser, reset, ...otherActions }] =
    useInviteUser({ userId, applicationId, emailTemplateId, sendEmailCopy, inviteUserRequest });

  const currentState = React.useMemo(() => new InviteUserState({
    userId,
    applicationId,
    emailTemplateId,
    profileValueUpdated
  }), [userId, applicationId, emailTemplateId, profileValueUpdated]);

  const editProfileAttributesView = React.useMemo(() => (
    <EditProfileAttributes
      profile={profile}
      userId={userId}
      showLoadingIndicator={showLoadingIndicator}
      setUserId={setUserId}
      setProfile={updateProfile}
    />
  ), [profile, userId, showLoadingIndicator, setUserId, updateProfile]);

  const selectApplicationView = React.useMemo(() => (
    <SelectApplication applicationId={applicationId} setApplicationId={updateApplicationId} />
  ), [applicationId, updateApplicationId]);

  const selectEmailTemplateView = React.useMemo(() => (
    <SelectEmailTemplate
      applicationId={applicationId}
      emailTemplateId={emailTemplateId}
      setSelectedEmailTemplate={setEmailTemplateId}
    />
  ), [applicationId, emailTemplateId, setEmailTemplateId]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      currentState={currentState}
      request={inviteUserRequest}
      sendEmailCopy={sendEmailCopy}
      setSendEmailCopy={setSendEmailCopy}
    />
  ), [currentState, sendEmailCopy, inviteUserRequest, setSendEmailCopy]);

  const mapStepToView = React.useCallback((step: NewUserWizardStep) => {
    switch (step) {
      case NewUserWizardStep.USER_INFO:
        return editProfileAttributesView;
      case NewUserWizardStep.APPLICATION:
        return selectApplicationView;
      case NewUserWizardStep.EMAIL_TEMPLATE:
        return selectEmailTemplateView;
      case NewUserWizardStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [editProfileAttributesView, selectApplicationView, selectEmailTemplateView, reviewView]);

  const validUserId = React.useMemo(() => !isEmptyString(userId), [userId]);
  const validApplicationId = React.useMemo(() => !isEmptyString(applicationId), [applicationId]);
  const validEmailTemplate = React.useMemo(() => !isEmptyString(emailTemplateId), [emailTemplateId]);

  const saveButtonDisabled = React.useMemo(
    () => !(validUserId && validApplicationId),
    [validUserId, validApplicationId]);

  const completedSteps = React.useMemo(() => {
    if (DEFAULT_STEPS.length === 0) {
      return [];
    }
    return DEFAULT_STEPS.filter(step => {
      switch (step) {
        case NewUserWizardStep.USER_INFO:
          return validUserId;
        case NewUserWizardStep.APPLICATION:
          return validApplicationId;
        case NewUserWizardStep.EMAIL_TEMPLATE:
          return validEmailTemplate;
        default:
          return false;
      }
    });
  }, [validUserId, validApplicationId, validEmailTemplate]);

  const disabledSteps = React.useMemo(() => {
    if (!validUserId) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          default:
            return true;
        }
      });
    }
    if (!validApplicationId) {
      return DEFAULT_STEPS.filter(step => {
        switch (step) {
          case NewUserWizardStep.USER_INFO:
            return false;
          case NewUserWizardStep.APPLICATION:
            return false;
          default:
            return true;
        }
      });
    }
    return [];
  }, [validUserId, validApplicationId]);

  return (
    <SubmitApiRequestView
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={className}
      snackbarId={snackbarId}
      errorTitle={errorTitle}
      saveButtonLabel={saveButtonLabel}
      currentState={currentState}
      defaultState={defaultState}
      steps={DEFAULT_STEPS}
      disabledSteps={disabledSteps}
      completedSteps={completedSteps}
      saveButtonDisabled={saveButtonDisabled}
      showLoadingIndicator={showLoadingIndicator}
      mapStepToLabel={mapStepToLabel}
      mapStepToView={mapStepToView}
      onChangeCurrentStep={reset}
      onSuccess={onSuccess}
      save={inviteUser}
      reset={reset}
    >
      {children}
    </SubmitApiRequestView>
  );
};

export default InviteUserContainer;
