import React from "react";
import { isEmptyString, noop } from "@util";
import { AuthTokenContext } from "@components/auth-token-provider";
import { DataSetRequestClient, RestClientError, TermsConditionsUrlResponse } from "@network";

export interface UseTermsAndConditionsUrlProps {
  termsConditionsId?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

export interface UseTermsAndConditionsUrlModel {
  errorMessage: string;
  showErrorView: boolean;
  showAccessDenied: boolean;
  showLoadingIndicator: boolean;
  termsConditionsUrl: string;
  statusCode?: number;
}

export interface UseTermsAndConditionsUrlActions {
  reset: () => void;
  download: () => void;
}

type Props = UseTermsAndConditionsUrlProps;
type Model = UseTermsAndConditionsUrlModel;
type Actions = UseTermsAndConditionsUrlActions;

export const useTermsAndConditionsUrl = (props: Props = {}): [Model, Actions] => {

  const {
    termsConditionsId = "",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [termsConditionsUrl, setTermsConditionsUrl] = React.useState("");
  const [statusCode, setStatusCode] = React.useState<number>();
  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const getTermsAndConditionsUrl = React.useCallback(() => {

    trackRequestEvent();

    return DataSetRequestClient.getTermsAndConditionsUrl(authToken, termsConditionsId)
      .then(response => {
        trackSuccessEvent();
        return response;
      }, (response: RestClientError) => {
        const { analytic } = response;
        trackErrorEvent(analytic);
        return Promise.reject(response);
      });

  }, [authToken, termsConditionsId, trackRequestEvent, trackSuccessEvent, trackErrorEvent]);

  const reset = React.useCallback(() => {
    setShowLoadingIndicator(false);
    setShowAccessDenied(false);
    setErrorMessage("");
    setTermsConditionsUrl("");
  }, [setShowLoadingIndicator, setShowAccessDenied, setErrorMessage, setTermsConditionsUrl]);

  const download = React.useCallback(() => {
    reset();
    setShowLoadingIndicator(true);
  }, [reset, setShowLoadingIndicator]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {
      getTermsAndConditionsUrl()
        .then((response: TermsConditionsUrlResponse) => {
          if (!ignore) {
            const { termsConditionsUrl: url } = response;
            setTermsConditionsUrl(url);
            setShowLoadingIndicator(false);
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { status, error = "Fetch privacy terms & conditions failed" } = response;
            setErrorMessage(error);
            setStatusCode(status);
            if (status === 403) {
              setShowAccessDenied(true);
            }
            setShowLoadingIndicator(false);
          }
        });
    }

    return () => { ignore = true; };

  }, [
    showLoadingIndicator,
    setErrorMessage,
    setShowAccessDenied,
    setShowLoadingIndicator,
    setTermsConditionsUrl,
    setStatusCode,
    getTermsAndConditionsUrl,
  ]);

  const model = React.useMemo<Model>(() => ({
    errorMessage,
    showErrorView,
    showAccessDenied,
    showLoadingIndicator,
    termsConditionsUrl,
    statusCode,
  }), [
    errorMessage,
    showErrorView,
    showAccessDenied,
    showLoadingIndicator,
    termsConditionsUrl,
    statusCode,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    download,
  }), [reset, download]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useTermsAndConditionsUrl;
