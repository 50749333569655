import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      cursor: "pointer",
      padding: "4px 8px",
      minHeight: 30,
      "&:hover": {
        backgroundColor: Color.LIGHT_GREY2,
        "&$selected": {
          backgroundColor: Color.GREY1,
        },
      },
    },
    icon: {
      width: "auto",
      height: 14,
      cursor: "pointer",
    },
    label: {
      flex: "1 0 auto",
      marginLeft: 4,
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: 400,
      cursor: "pointer",
    },
    textField: {
      zIndex: 9999,
      flex: "1 0 auto",
      padding: 0,
      "& > .MuiInput-formControl": {
        marginRight: 16,
        "& > input": {
          padding: "2px 4px",
          lineHeight: "0px",
          fontSize: 11,
          border: `1px solid ${Color.BLACK}`,
          marginLeft: 4,
          backgroundColor: Color.WHITE,
        },
      },
    },
    backdrop: {
      background: "rgba(105, 105, 105, 0.25)",
      zIndex: 9998,
    },
    actionsMenu: {
      flex: "0 0 auto",
    },
    folder: {
    },
    folderIcon: {
    },
    file: {
    },
    fileIcon: {
    },
    collapsed: {
    },
    selected: {
      backgroundColor: Color.GREY1,
    },
    editMode: {
      backgroundColor: Color.TRANSPARENT,
    },
    folderContents: {
      margin: 0,
      padding: "0 0 0 15px",
      display: "flex",
      flexFlow: "column nowrap",
    },
    folderContent: {
    },
  });

export default styles;