import React from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { isEmptyString, noop } from "@util/Functions";
import { AppSchema } from "@main/schemas";
import { getAccountId } from "@main/selectors";
import { useProfileAttributeDefinitions } from "@hooks";
import { EditUserProfileAttributesList, UserProfileAttributesListColumn } from "@components";
import { UserProfileAttribute, UserProfileAttributesMap } from "@data/UserProfileAttribute";
import UserInfo, { Actions as UserActions, Model as UserModel } from "../components/UserInfo";

interface Model extends UserActions {
  accountId?: string;
  profile?: UserProfileAttributesMap;
}

interface Actions extends UserModel {
  setProfile?: (profile: UserProfileAttributesMap) => void;
}

const EditProfileAttributes = (props: Model & Actions) => {

  const {
    userId = "",
    accountId = "",
    profile = {},
    showLoadingIndicator = false,
    setUserId = noop,
    setProfile = noop
  } = props;

  const [model, actions] = useProfileAttributeDefinitions({ accountId });

  const { attributes: definitions } = model;

  const defaultItems: UserProfileAttribute[] = React.useMemo(() => {
    return definitions
      .map(definition => new UserProfileAttribute({
        name: definition.getName(),
        dataType: definition.getDataType(),
        value: definition.getDefaultValue(),
      }))
      .filter(profileAttribute => !profileAttribute.isBinaryDataType());
  }, [definitions]);

  const items: UserProfileAttribute[] = React.useMemo(() => {
    return defaultItems
      .map(defaultItem => {
        const item = profile[defaultItem.getName()] || defaultItem;
        return new UserProfileAttribute({
          ...item.toJS(),
        });
      });
  }, [defaultItems, profile]);

  const defaultProfile: UserProfileAttributesMap = React.useMemo(() => {
    return defaultItems
      .reduce((defaults: UserProfileAttributesMap, attr: UserProfileAttribute) => {
        defaults[attr.getName()] = attr;
        return defaults;
      }, {});
  }, [defaultItems]);

  const updateUserProfileAttributes = React.useCallback((item: UserProfileAttribute) => {

    const profileAttributes: UserProfileAttributesMap = Object.keys(defaultProfile)
      .filter(key => !profile.hasOwnProperty(key))
      .filter(key => !isEmptyString(defaultProfile[key].getValue()))
      .map(key => defaultProfile[key])
      .concat(Object.keys(profile).map(key => profile[key]))
      .reduce((updatedProfile: UserProfileAttributesMap, attribute: UserProfileAttribute) => {
        updatedProfile[attribute.getName()] = attribute;
        return updatedProfile;
      }, {});

    profileAttributes[item.getName()] = item;

    setProfile(profileAttributes);

  }, [setProfile, profile, defaultProfile]);

  return (
    <React.Fragment>
      <UserInfo userId={userId} setUserId={setUserId} showLoadingIndicator={showLoadingIndicator} />
      <Typography variant="h3" style={{ margin: "25px 0 0", fontWeight: 300 }}>
        Provide some information about this user
      </Typography>
      <EditUserProfileAttributesList
        {...model}
        {...actions}
        items={items}
        showSearch={false}
        sortingDisabled={true}
        updateItem={updateUserProfileAttributes}
        sortByColumn={UserProfileAttributesListColumn.NONE}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  accountId: getAccountId(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileAttributes);
