import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import Menu from "@material-ui/core/Menu";
import { Tooltip } from "../tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { clickHandler, getStringValue, noop, stopPropagation } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import NestedActionsMenuItem from "./NestedActionsMenuItem";
import OpenMenuButton from "./OpenMenuButton";
import ActionMenuItem from "./ActionMenuItem";
import styles from "./styles";

export interface ActionsMenuProps {
  className?: string;
  disabled?: boolean;
  buttonClassName?: string;
  buttonVariant?: "button" | "icon";
  buttonLabel?: string;
  buttonIcon?: React.ComponentType<SvgIconProps>;
  actions?: ActionMenuItem[];
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & ActionsMenuProps & {
  children?: React.ReactNode;
};

export const ActionsMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    disabled,
    buttonClassName,
    buttonVariant,
    buttonLabel,
    buttonIcon,
    actions = [],
    onClickAction = noop,
  } = props;

  const anchorRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const visibleActions = React.useMemo(() => actions.filter(({ hidden }) => !hidden), [actions]);

  const openMenu = React.useCallback(() => setOpen(true), [setOpen]);

  const closeMenu = React.useCallback(() => setOpen(false), [setOpen]);

  // It is possible for the hidden attribute to change after this component has already mounted,
  // so we therefore cannot return null if the array of actions contains no visible actions. Instead,
  // we will selectively render the actual actions menu button in this case.
  if (actions.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames("actionsMenu", className, classes.container)}
      onClick={stopPropagation}
    >
      {visibleActions.length > 0 && (
        <OpenMenuButton
          ref={anchorRef}
          className={buttonClassName}
          disabled={disabled}
          variant={buttonVariant}
          label={buttonLabel}
          icon={buttonIcon}
          onClick={clickHandler(openMenu)}
        />
      )}
      <Menu
        className={classnames("menu", "actionsMenuPopover", classes.menu)}
        open={open}
        onClose={closeMenu}
        anchorEl={anchorRef.current}
        disablePortal={true}
      >
        {actions.map((action: ActionMenuItem) => {

          const {
            id,
            name,
            tooltip,
            hidden,
            disabled: childDisabled,
            actions: childActions = [],
          } = action;

          const [subMenuOpen, setSubMenuOpen] = React.useState(false);

          const nestedActions = React.useMemo(() => childActions.length > 0, [childActions]);

          const onChildMenuOpen = React.useCallback(() => setSubMenuOpen(true), [setSubMenuOpen]);

          const onChildMenuClose = React.useCallback(() => setSubMenuOpen(false), [setSubMenuOpen]);

          const onClickMenuItem = React.useCallback(() => {
            closeMenu();
            if (!action.disabled) {
              onClickAction(action);
            }
          }, [action, closeMenu, onClickAction]);

          const onClickNestedMenuItem = React.useCallback(clickedAction => {
            closeMenu();
            if (!clickedAction.disabled) {
              onClickAction(clickedAction);
            }
          }, [closeMenu, onClickAction]);

          // We cannot filter actions based on whether they are hidden because if the collection
          // were to change we would throw a react exception related to a different number of
          // hooks running after re-render; the solution is to therefore return null when rendering
          // a hidden action to ensure the same number of hooks is run after each render.
          if (hidden) {
            return null;
          }

          return (
            <MenuItem
              key={id}
              className={classnames("menuItem", camelCase(id), classes.menuItem, {
                // material-ui styles cause tooltips to not work on disabled actions without this
                [classes.overridePointerEvents]: (tooltip && childDisabled),
                [classes.subMenuOpen]: nestedActions && subMenuOpen,
              })}
              disabled={childDisabled}
              button={true}
              onClick={nestedActions ? undefined : onClickMenuItem}
            >
              {!nestedActions && (
                <Tooltip title={getStringValue(tooltip)}>
                  <label
                    className={classnames("menuItemLabel", classes.menuItemLabel, {
                      [classes.disabled]: childDisabled,
                    })}
                  >
                    {name}
                  </label>
                </Tooltip>
              )}
              {nestedActions && (
                <NestedActionsMenuItem
                  key={id}
                  className={classnames("nestedMenuItem", classes.nestedMenuItem)}
                  label={name}
                  tooltip={tooltip}
                  disabled={childDisabled}
                  actions={childActions}
                  onClickAction={onClickNestedMenuItem}
                  onMenuOpen={onChildMenuOpen}
                  onMenuClose={onChildMenuClose}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
});

export { ActionMenuItem };

export default ActionsMenu;
