import React from "react";
import { noop } from "@util";
import { Workload } from "@data";
import VersionHistoryListItem from "@modules/dataWorkloadDetails/components/VersionHistory/VersionHistoryListItem";
import { ActionMenuItem, WorkloadAction } from "@components";
import { VersionHistoryContainerActions } from "@modules/dataWorkloadDetails/containers/VersionHistory";

export interface VersionHistoryListItemContainerModel {
  workload: Workload;
  version: number;
  isActiveVersion: boolean;
}

export interface VersionHistoryListItemContainerActions extends VersionHistoryContainerActions {
  showWorkloadVersionDetails?: (version: number) => void;
}

type Model = VersionHistoryListItemContainerModel;
type Actions = VersionHistoryListItemContainerActions;
type Props = Model & Actions;

const VersionHistoryListItemContainer = (props: Props) => {

  const {
    workload,
    version,
    isActiveVersion,
    releaseWorkload = noop,
    deprecateWorkload = noop,
    decommissionWorkload = noop,
    deleteWorkloadVersion = noop,
    draftNewWorkloadVersion = noop,
    switchWorkloadPrincipal = noop,
    showWorkloadVersionDetails = noop,
  } = props;

  const onClick = React.useCallback((currWorkload: Workload) => {
      showWorkloadVersionDetails(currWorkload.getVersion());
  }, [showWorkloadVersionDetails]);

  const onClickAction = React.useCallback((currWorkload: Workload, action: ActionMenuItem) => {
    switch (action.id) {
      case WorkloadAction.DRAFT_LATEST_VERSION:
        return draftNewWorkloadVersion(currWorkload, true);
      case WorkloadAction.DRAFT_EXISTING_VERSION:
        return draftNewWorkloadVersion(currWorkload, false);
      case WorkloadAction.RELEASE:
        return releaseWorkload(currWorkload);
      case WorkloadAction.DEPRECATE:
        return deprecateWorkload(currWorkload);
      case WorkloadAction.DECOMMISSION:
        return decommissionWorkload(currWorkload);
      case WorkloadAction.DELETE_VERSION:
        return deleteWorkloadVersion(currWorkload);
      case WorkloadAction.SWITCH_PRINCIPAL:
        return switchWorkloadPrincipal(currWorkload.getVersion());
      default:
        return noop();
    }
  }, [
    releaseWorkload,
    deprecateWorkload,
    decommissionWorkload,
    draftNewWorkloadVersion,
    switchWorkloadPrincipal,
    deleteWorkloadVersion,
  ]);

  return (
    <VersionHistoryListItem
      key={`${workload.getName()}:${version}`}
      workload={workload}
      active={version > 0 && isActiveVersion}
      onClick={onClick}
      onClickAction={onClickAction}
    />
  );
};

export default VersionHistoryListItemContainer;
