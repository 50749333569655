import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface DeviceTypesSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<DeviceTypesSchema>({
  nameFilter: "",
});

type DeviceTypesActionTypes = PortalModuleActionTypes<DeviceTypesSchema>;

export const ACTION_TYPES: DeviceTypesActionTypes =
  createPortalModuleActionTypes<DeviceTypesSchema>(MODULE_ID, DEFAULT_STATE);

export const DeviceTypesReducer = combineReducers<DeviceTypesSchema>(
  createPortalModuleReducers<DeviceTypesSchema>(ACTION_TYPES, DEFAULT_STATE));

export default DeviceTypesReducer;
