import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      padding: 8,
      cursor: "pointer",
      borderRadius: "8px 8px 0 0",
      color: Color.TEXT,
      backgroundColor: "#eee",
      borderBottom: "1px solid rgba(60, 72, 88, 0.35)",
      "&$selected": {
        color: `${Color.TEXT} !iomportant`,
        backgroundColor: "#e5e5e5 !important",
        borderBottom: `2px solid ${Color.GREY5} !important`,
        "& .label": {
          fontWeight: 400,
        },
      },
      "&:hover": {
        color: Color.BLACK,
        backgroundColor: "#D7D7D7",
        borderBottom: "2px solid rgba(60, 72, 88, 0.37)",
      },
    },
    icon: {
      flex: "0 0 auto",
      width: "auto",
      height: 14,
      color: "inherit",
      marginLeft: 4,
    },
    label: {
      flex: "1 0 auto",
      color: "inherit",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "16px",
      marginLeft: 4,
    },
    closeIconButton: {
      flex: "0 0 auto",
      width: "auto",
      height: "auto",
      marginLeft: 4,
      color: Color.GREY4,
      "&:hover": {
        color: Color.BLACK,
      },
    },
    closeIcon: {
      width: "auto",
      height: 14,
      color: "inherit",
    },
    selected: {
    },
  });

export default styles;