import React from "react";
import { User } from "@data/User";
import { useHistory } from "react-router-dom";
import { getCreateUserPath } from "@modules/createUser/helpers";
import { getInviteUserPath } from "@modules/inviteUser/helpers";
import { getPathToUser } from "@modules/userDetails/helpers";
import {
  getAddUserToGroupPath,
  getRemoveUserFromGroupPath,
} from "@modules/groupManagementWizard/helpers";
import {
  getAttachPoliciesToUserPath,
  getDetachPoliciesFromUserPath,
} from "@modules/policyManagement/helpers";
import { getPasswordRecoveryPath } from "@modules/passwordRecovery/helpers";
import Users from "./containers/Users";

// This function is added to address a customer bug IOTSST-2804.
// This should be removed once SecSvcs does not allow users with % sign to be created.
const encodeUserIdIfNeeded = (userId: string): string => {
  if (userId.includes("%")) {
    // Fix for Incomplete Percentage encoding
    if (!/%\d{2}/.test(userId)) {
      userId = userId.replace("%", "%25");
    }
    try {
      return encodeURIComponent(userId);
    } catch (e) {
      console.error("Error encoding user ID:", e);
      return userId;
    }
  }
  return userId;
};

export function UsersView() {

  const history = useHistory();

  const createUser = React.useCallback(() =>
    history.push(getCreateUserPath()), [history]);

  const inviteUser = React.useCallback(() =>
    history.push(getInviteUserPath()), [history]);

  const showUserDetails = React.useCallback((user: User) =>
    history.push(getPathToUser(encodeUserIdIfNeeded(user.getUserId()))), [history]);

  const addUserToGroup = React.useCallback((user: User) =>
    history.push(getAddUserToGroupPath(user.getUserId())), [history]);

  const removeUserFromGroup = React.useCallback((user: User) =>
    history.push(getRemoveUserFromGroupPath(user.getUserId())), [history]);

  const attachPolicy = React.useCallback((user: User) =>
  history.push(getAttachPoliciesToUserPath(user.getUserId())), [history]);

  const detachPolicy = React.useCallback((user: User) =>
    history.push(getDetachPoliciesFromUserPath(user.getUserId())), [history]);

  const recoverPassword = React.useCallback((user: User) =>
    history.push(getPasswordRecoveryPath(user.getUserId()), { goBackOnSuccess: true}),
    [history]);

  return (
    <Users
      createUser={createUser}
      inviteUser={inviteUser}
      showUserDetails={showUserDetails}
      addUserToGroup={addUserToGroup}
      removeUserFromGroup={removeUserFromGroup}
      attachPolicy={attachPolicy}
      detachPolicy={detachPolicy}
      recoverPassword={recoverPassword}
    />
  );
}

export default UsersView;
