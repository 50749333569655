import React from "react";
import { instanceVariables as styles } from "./styles";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { InstanceVariablesListItem, VariablesListColumn } from "@components";
import { WorkloadInstanceVariable, WorkloadInstanceVariableAttributes } from "@data";
import SortedSearchResultsList from "@components/sorted-search-results-list";

interface InstanceVariableListsModel {
  variables?: WorkloadInstanceVariableAttributes[];
}

interface InstanceVariableListsActions {
  refreshDetails?: () => void;
}

type Props = WithStyles<typeof styles> & InstanceVariableListsModel & InstanceVariableListsActions & {
  children?: React.ReactNode;
};

export const InstanceVariablesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    variables = [],
  } = props;

  const variableKeys = React.useMemo<string[]>(() =>
      Object.keys(variables),
    [variables]);

  const workloadInstanceVariables = React.useMemo<WorkloadInstanceVariable[]>(() => variableKeys
      .map((key) => new WorkloadInstanceVariable({name: key, value: variables[key]})),
    [variableKeys, variables]);

  const renderVariable = React.useCallback((item: WorkloadInstanceVariable, column: VariablesListColumn) => (
    <InstanceVariablesListItem
      item={item}
      column={column}
    />
  ), []);

  return (
    <SortedSearchResultsList
      className={classnames("instanceVariablesList", classes.container)}
      columns={[VariablesListColumn.NAME, VariablesListColumn.VALUE]}
      items={workloadInstanceVariables}
      sortingDisabled={true}
      showSearch={false}
      showSummary={false}
      noResultsLabel={"No variables provided"}
      renderItem={renderVariable}
    />
  );
});

export default InstanceVariablesList;
