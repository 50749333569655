import React from "react";
import classnames from "classnames";
import { isEmptyString } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ProjectView, { ProjectViewListItem, ProjectViewListItemType } from "./ProjectView";
import EditorView, { EditorViewItem } from "./EditorView";
import styles from "./styles";

const DEFAULT_FILES: ProjectViewListItem[] = [];

export interface Model {
  className?: string;
  jobId?: string;
  title?: string;
  code?: string;
  workloadName?: string;
  maxNumOpenEditorTabs?: number;
  files?: ProjectViewListItem[];
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const WorkloadCodeEditor = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    maxNumOpenEditorTabs = 5,
    files: initialFiles = DEFAULT_FILES,
    workloadName,
    children,
  } = props;

  const [files, setFiles] = React.useState<ProjectViewListItem[]>(initialFiles);

  const flattenedFiles = React.useMemo<ProjectViewListItem[]>(() => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] => {
      return filesToIterate.reduce((flattened, file) => {
        const { type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return flattened.concat(iterate(fileContents));
        } else if (type === ProjectViewListItemType.FILE) {
          return flattened.concat(file);
        } else {
          return flattened;
        }
      }, [] as ProjectViewListItem[]);
    };
    return iterate(files);
  }, [files]);

  const editorViewItems = React.useMemo<EditorViewItem[]>(() =>
    flattenedFiles
      .filter(({ open, selected }) => open || selected)
      .map(({ id, name: label, code: itemCode, selected }) => new EditorViewItem({
        id,
        label,
        code: itemCode,
        selected,
      })), [flattenedFiles]);

  const collapseFolder = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, collapsed: true };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const expandFolder = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, collapsed: false };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const openFile = React.useCallback(id => {
    const openFiles = editorViewItems.find(item => id === item.getId()) != null
      ? editorViewItems.slice() : editorViewItems.slice(0, maxNumOpenEditorTabs - 1);
    const openFileIds = openFiles.map(item => item.getId());
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          const selected = fileId === id;
          return { ...file, selected, open: selected || openFileIds.indexOf(fileId) >= 0 };
        }
      });
    setFiles(iterate(files));
  }, [maxNumOpenEditorTabs, editorViewItems, files, setFiles]);

  const setSelectedFile = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, open, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          const selected = fileId === id;
          return {
            ...file,
            selected,
            open: open || selected,
          };
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const closeTab = React.useCallback(id => {
    const wasSelected = (editorViewItems.find(item => id === item.getId()) || EditorViewItem.EMPTY).isSelected();
    const newlySelectedEditorViewItem = !wasSelected
      ? EditorViewItem.EMPTY
      : editorViewItems.filter(item => id !== item.getId()).pop() || EditorViewItem.EMPTY;
    const newlySelectedFileId = newlySelectedEditorViewItem.getId();
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else if (fileId === id) {
          return { ...file, open: false, selected: false };
        } else {
          return {
            ...file,
            ...(!wasSelected ? ({}) : ({
              selected: fileId === newlySelectedFileId,
            })),
          };
        }
      });
    setFiles(iterate(files));
  }, [editorViewItems, files, setFiles, setSelectedFile]);

  const updateCode = React.useCallback((id, updatedCode) => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, code: updatedCode };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const renameFile = React.useCallback((id, updatedName) => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (fileId === id) {
          return { ...file, name: updatedName };
        } else if (type === ProjectViewListItemType.FOLDER) {
          return {
            ...file,
            children: iterate(fileContents),
          };
        } else {
          return file;
        }
      });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const deleteFile = React.useCallback(id => {
    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate
        .filter(({ id: fileId }) => fileId !== id)
        .map(file => {
          const { type, children: fileContents = [] } = file;
          if (type === ProjectViewListItemType.FOLDER) {
            return {
              ...file,
              children: iterate(fileContents),
            };
          } else {
            return file;
          }
        });
    setFiles(iterate(files));
  }, [files, setFiles]);

  const addFile = React.useCallback((id: string, name: string = "", parentId: string = "") => {

    const newFile = {
      id,
      name,
      type: ProjectViewListItemType.FILE,
      code: "",
    };

    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          if (fileId === parentId) {
            return {
              ...file,
              children: fileContents.concat(newFile),
            };
          }
          return {
            ...file,
            children: iterate(fileContents),
          };
        }
        return file;
      });

    if (isEmptyString(parentId)) {
      setFiles(files.concat(newFile));
    } else {
      setFiles(iterate(files));
    }

  }, [files, setFiles]);

  const addFolder = React.useCallback((id: string, name: string = "", parentId: string = "") => {

    const newFolder = {
      id,
      name,
      type: ProjectViewListItemType.FOLDER,
      code: "",
    };

    const iterate = (filesToIterate: ProjectViewListItem[]): ProjectViewListItem[] =>
      filesToIterate.map(file => {
        const { id: fileId, type, children: fileContents = [] } = file;
        if (type === ProjectViewListItemType.FOLDER) {
          if (fileId === parentId) {
            return {
              ...file,
              children: fileContents.concat(newFolder),
            };
          }
          return {
            ...file,
            children: iterate(fileContents),
          };
        }
        return file;
      });

    if (isEmptyString(parentId)) {
      setFiles(files.concat(newFolder));
    } else {
      setFiles(iterate(files));
    }

  }, [files, setFiles]);

  return (
    <div className={classnames("workloadCodeEditor", className, classes.container)}>
      <div className={classnames("ide", classes.ide)}>
        <ProjectView
          className={classnames("projectView", classes.projectView)}
          files={files}
          workloadName={workloadName}
          collapseFolder={collapseFolder}
          expandFolder={expandFolder}
          setSelectedFile={openFile}
          renameFile={renameFile}
          deleteFile={deleteFile}
          addFile={addFile}
          addFolder={addFolder}
          setFiles={setFiles}
        />
        <EditorView
          className={classnames("editor", classes.editor)}
          items={editorViewItems}
          closeTab={closeTab}
          setSelectedTab={setSelectedFile}
          updateCode={updateCode}
        />
      </div>
      {children}
    </div>
  );
});

export default WorkloadCodeEditor;