import { useApiRequestAnalytics } from "@hooks";
import { GetUiPackageFilesResponse, UiManagerClient, UiManagerLegacyClient } from "@network";

export type DeleteUiPackageApi = () => Promise<void>;
export type UploadUiPackageApi = (file: File) => Promise<any>;
export type GetUiPackagesApi = () => Promise<GetUiPackageFilesResponse>;

export interface UiPackageApi {
  getUiPackages: GetUiPackagesApi;
  uploadUiPackage: UploadUiPackageApi;
  deleteUiPackage: DeleteUiPackageApi;
}

enum ActionTypes {
  GET_UI_PACKAGES_REQUEST = "ui_package_client/get_ui_packages_request",
  GET_UI_PACKAGES_SUCCESS = "ui_package_client/get_ui_packages_success",
  GET_UI_PACKAGES_FAILED = "ui_package_client/get_ui_packages_failed",

  UPLOAD_UI_PACKAGE_REQUEST = "ui_package_client/upload_ui_package_request",
  UPLOAD_UI_PACKAGE_SUCCESS = "ui_package_client/upload_ui_package_success",
  UPLOAD_UI_PACKAGE_FAILED = "ui_package_client/upload_ui_package_failed",

  DELETE_UI_PACKAGE_REQUEST = "ui_package_client/delete_ui_package_request",
  DELETE_UI_PACKAGE_SUCCESS = "ui_package_client/delete_ui_package_success",
  DELETE_UI_PACKAGE_FAILED = "ui_package_client/delete_ui_package_failed",
}

export const useGetUiPackagesApi = () =>
  useApiRequestAnalytics(UiManagerClient.getUiPackageFiles, {
    REQUEST_EVENT: ActionTypes.GET_UI_PACKAGES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_UI_PACKAGES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_UI_PACKAGES_FAILED,
  });

export const useUploadUiPackageApi = () =>
  useApiRequestAnalytics(UiManagerLegacyClient.uploadUIPackage, {
    REQUEST_EVENT: ActionTypes.UPLOAD_UI_PACKAGE_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPLOAD_UI_PACKAGE_SUCCESS,
    ERROR_EVENT: ActionTypes.UPLOAD_UI_PACKAGE_FAILED,
  });

export const useDeleteUiPackageApi = () =>
  useApiRequestAnalytics(UiManagerLegacyClient.deleteUiPackage, {
    REQUEST_EVENT: ActionTypes.DELETE_UI_PACKAGE_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_UI_PACKAGE_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_UI_PACKAGE_FAILED,
  });

export const useUiPackage = (): UiPackageApi => ({
  getUiPackages: useGetUiPackagesApi(),
  uploadUiPackage: useUploadUiPackageApi(),
  deleteUiPackage: useDeleteUiPackageApi(),
});

export { ActionTypes as UiPackageClientActionType };

export default useUiPackage;
