import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      position: "relative",
    },
    editor: {
      flex: "1 0 auto",
      border: `1px solid ${Color.LIGHT_GREY}`,
    },
  });

export default styles;