import React from "react";
import { styles } from "./styles";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UsersList, UsersListModel, UsersListActions } from "@components/users-list";
import { User } from "@data";
import UsersListColumn from "@components/users-list/UsersListColumn";
import { noop } from "@util";

export interface SwitchPrincipalViewModel extends UsersListModel {
  users: User[];
  selectedPrincipal: User[];
}

export interface SwitchPrincipalViewActions extends UsersListActions {
  close?: () => void;
  setSelectedPrincipal: (users: User[]) => void;
}

type Props = WithStyles<typeof styles> & SwitchPrincipalViewModel & SwitchPrincipalViewActions;

export const SwitchPrincipalView = withStyles(styles)((props: Props) => {

  const {
    classes,
    users = [],
    selectedPrincipal = [],
    setSelectedPrincipal = noop,
    ...otherProps
  } = props;

  const [nameFilter, setNameFilter] = React.useState("");

  return (
      <div className={classnames("switchPrincipal", classes.container)}>
        <h1 className={classnames("usersTitle", classes.title)}>
          Select the new release principal
        </h1>
        <UsersList
          {...otherProps}
          columns={[UsersListColumn.NAME]}
          items={users}
          showSearch={true}
          showSearchFilters={true}
          selectable={true}
          selectAllDisabled={true}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          selectedItems={selectedPrincipal}
          setSelectedItems={setSelectedPrincipal}
        />
      </div>
  );
});

export default SwitchPrincipalView;
