import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import useUserIdmLegacyClient from "../use-user-idm-legacy-client";

type SuccessResponse = void;

export interface UseUserLogoutProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  userId?: string;
}

export interface UseUserLogoutModel extends UseApiRequestModel<SuccessResponse> {
}

export interface UseUserLogoutActions extends UseApiRequestActions<SuccessResponse> {
  logout: () => void;
}

type Props = UseUserLogoutProps;
type Model = UseUserLogoutModel;
type Actions = UseUserLogoutActions;
type Result = [Model, Actions];

export const useUserLogout = (props: Props = {}): Result => {

  const {
    userId,
    defaultErrorMessage = "Failed to logout user",
    deferRequest = true,
    ...otherProps
  } = props;

  const UserIdmLegacyClient = useUserIdmLegacyClient();

  const makeApiRequest = React.useCallback(() =>
      UserIdmLegacyClient.logout(userId),
    [UserIdmLegacyClient, userId]);

  const [model, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest,
      makeApiRequest,
    });

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    logout: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useUserLogout;
