import { ActionType, equalsIgnoreCase, getStringValue, isEmptyString, ReduxAction } from "@util";
import { LoginActionType } from "@modules/login/reducers";
import { ACTION_TYPES as CREATE_POLICY_ACTION_TYPES } from "@modules/createPolicy/reducers";
import { DeletePolicyActionType } from "@modules/deletePolicy/reducers";
import { CreateNamespaceActionType } from "@modules/createNamespace/reducers";
import { DeviceTypeManagerActionType } from "@modules/deviceTypeManager/reducers";
import { SchemaStateManagerActionType } from "@modules/schemaStateManager/reducers";
import {
  ACTION_TYPES as SCHEMA_WIZARD_ACTION_TYPES,
} from "@modules/schemaWizard/reducers/schemaWizard";
import { ACTION_TYPES as CREATE_USER_ACTION_TYPES } from "@modules/createUser/reducers";
import { ACTION_TYPES as LOGOUT_USER_ACTION_TYPES } from "@modules/logoutUser/reducers";
import { ACTION_TYPES as DELETE_USER_ACTION_TYPES } from "@modules/deleteUser/reducers";
import { ACTION_TYPES as CLOSE_USER_ACTION_TYPES } from "@modules/closeUser/reducers";
import { ACTION_TYPES as LOCK_USER_ACTION_TYPES } from "@modules/lockUser/reducers";
import { ACTION_TYPES as PASSWORD_RECOVERY_ACTION_TYPES } from "@modules/passwordRecovery/reducers";
import { ACTION_TYPES as CHANGE_PASSWORD_ACTION_TYPES } from "@modules/changePassword/reducers";
import { ACTION_TYPES as USER_REPORT_ACTION_TYPES } from "@modules/userReport/reducers";
import { PropertyEditorActionType } from "@modules/schemaWizard/reducers/propertyEditor";
import {
  ACTION_TYPES as FEDERATION_PROVIDER_WIZARD_ACTION_TYPES,
} from "@modules/federationProviderWizard/reducers";
import {
  ACTION_TYPES as DELETE_FEDERATION_PROVIDER_ACTION_TYPES,
} from "@modules/deleteFederationProvider/reducers";
import {
  ACTION_TYPES as DEVICE_TYPE_WIZARD_ACTION_TYPES,
} from "@modules/deviceTypeWizard/reducers/deviceTypeWizard";
import {
  ACTION_TYPES as GROUP_MANAGEMENT_WIZARD_ACTION_TYPES,
} from "../modules/groupManagementWizard/reducers";
import {
  EditModeActionType as EditModeSchemaActionType,
} from "../modules/schemaWizard/reducers/editMode";
import {
  ManageSoftwareVersionsActionType,
} from "@modules/deviceTypeDetails/reducers/manageSoftwareVersions";
import { ACTION_TYPES as USER_ACTION_TYPES } from "@modules/users/reducers";
import { ACTION_TYPES as USER_DETAILS_ACTION_TYPES } from "@modules/userDetails/reducers";
import { ACTION_TYPES as AUDIT_EVENTS_ACTION_TYPES } from "@modules/auditEvents/reducers";
import {
  ACTION_TYPES as DATA_SET_REQUEST_WIZARD_ACTION_TYPES,
} from "@modules/dataSetRequestWizard/reducers";
import { ACTION_TYPES as DATA_CATALOG_ACTION_TYPES } from "@modules/dataCatalog/reducers";
import {
  ACTION_TYPES as DELETE_DATA_ACCESS_REQUEST_ACTION_TYPES,
} from "@modules/deleteDataAccessRequest/reducers";
import {
  ACTION_TYPES as DELETE_DATA_SET_REQUEST_ACTION_TYPES,
} from "@modules/deleteDataSetRequest/reducers";
import {
  ACTION_TYPES as DATA_ACCESS_REQUEST_DETAILS_ACTION_TYPES,
} from "@modules/dataAccessRequestDetails/reducers";
import {
  ACTION_TYPES as DATA_APPROVAL_REQUEST_DETAILS_ACTION_TYPES,
} from "@modules/dataApprovalRequestDetails/reducers";
import { ACTION_TYPES as APPROVE_REQUEST_ACTION_TYPES } from "@modules/approveRequest/reducers";
import {
  ACTION_TYPES as DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES,
} from "@modules/deviceEnrollmentWizard/reducers";
import { ACTION_TYPES as DISENROLL_DEVICE_ACTION_TYPES } from "@modules/disenrollDevice/reducers";
import { BackButtonActionType } from "@components";
import {
  ApplicationManagerRegionalClientActionType,
  DataAccessRequestClientActionType,
  DataCatalogClientActionType,
  DataLakeFileTransferClientActionType,
  DataLakeIndexClientActionType,
  DataSetRequestClientActionType,
  DeviceTypeClientActionType,
  DeviceRegistryClientActionType,
  EmailTemplateClientActionTypes,
  ManagedPolicyClientActionType,
  NamespaceClientActionType,
  UserIdmClientActionType,
  OrchestrationServiceClientActionType,
  PolicyManagementClientActionType,
  ProfileAttributeClientActionType,
  QueryDatabasesClientActionType,
  SecurityGroupsClientActionType,
  SecurityManagerClientActionType,
  SecurityServiceRegionalClientActionType,
  WorkloadManagerClientActionType,
  DigitalShadowClientActionType,
  FederationProviderClientActionType,
  UiPackageClientActionType,
  SchemaClientActionTypes,
} from "@hooks";

export enum EventTypes {
  SET_VERSION = "set_version",
  SET_VERSION_CODE = "set_version_code",
  SET_ACCOUNT_ID = "set_account_id",
  SET_IDENTITY_TYPE = "set_identity_type",
  AUTHENTICATE_SERVICE = "authenticate_service",
  SECURITY_MANAGER_LOGIN = "security_manager_login",
  START_FEDERATED_LOGIN = "start_federated_login",
  REQUEST_FEDERATED_LOGIN_TOKENS = "request_federated_login_tokens",
  FINISH_FEDERATED_LOGIN = "finish_federated_login",
  USER_LOGGED_IN = "user_logged_in",
  USER_LOGGED_OUT = "user_logged_out",
  ERROR = "error",
  CLICK_BACK_BUTTON = "click_back_button",
  CREATE_NAMESPACE = "create_namespace",
  UPDATE_NAMESPACE_LIFECYCLE = "update_namespace_lifecycle",
  CREATE_POLICY = "create_policy",
  DELETE_POLICY = "delete_policy",
  GET_SECURITY_GROUP = "get_security_group",
  GET_SECURITY_GROUP_SERVICES = "get_security_group_services",
  GET_SECURITY_GROUPS = "get_security_groups",
  CREATE_SECURITY_GROUP = "create_security_group",
  DELETE_SECURITY_GROUP = "delete_security_group",
  GET_SCHEMA = "get_schema",
  GET_SCHEMA_METADATA = "get_schema_metadata",
  CREATE_SCHEMA = "create_schema",
  EDIT_SCHEMA = "edit_schema",
  DRAFT_SCHEMA = "draft_schema",
  DELETE_SCHEMA = "delete_schema",
  PROMOTE_SCHEMA = "promote_schema",
  DEPRECATE_SCHEMA = "deprecate_schema",
  DECOMMISSION_SCHEMA = "decommission_schema",
  SCHEMA_DETAILS_VALIDATE_SCHEMA_DATA = "schema_details_validate_schema_data",
  DEVICE_ENROLLMENT_WIZARD_VALIDATE_SCHEMA_DATA = "device_enrollment_wizard_validate_schema_data",
  GET_DEVICE_TYPES = "get_device_types",
  CREATE_DEVICE_TYPE = "create_device_type",
  EDIT_DEVICE_TYPE = "edit_device_type",
  DRAFT_DEVICE_TYPE = "draft_device_type",
  PROMOTE_DEVICE_TYPE = "promote_device_type",
  DEPRECATE_DEVICE_TYPE = "deprecate_device_type",
  DECOMMISSION_DEVICE_TYPE = "decommission_device_type",
  DELETE_DEVICE_TYPE = "delete_device_type",
  ENROLL_DEVICE = "enroll_device",
  BATCH_ENROLL_DEVICE = "batch_enroll_device",
  BATCH_ENROLL_DEVICE_PRE_SIGNED_URL = "batch_enroll_device_pre_signed_url",
  VALIDATE_DEVICE = "validate_device",
  DISENROLL_DEVICE = "disenroll_device",
  VALIDATE_SCHEMA_PROPERTY = "validate_schema_property",
  ADD_SOFTWARE_VERSION = "add_software_version",
  REMOVE_SOFTWARE_VERSION = "remove_software_version",
  ATTACH_POLICY_TO_GROUP = "attach_policy_to_group",
  DETACH_POLICY_FROM_GROUP = "detach_policy_from_group",
  ATTACH_MANAGED_POLICY_TO_GROUP = "attach_managed_policy_to_group",
  DETACH_MANAGED_POLICY_FROM_GROUP = "detach_managed_policy_from_group",
  ADD_USER_TO_GROUP = "add_user_to_group",
  REMOVE_USER_FROM_GROUP = "remove_user_from_group",
  ADD_SERVICE_TO_GROUP = "add_service_to_group",
  REMOVE_SERVICE_FROM_GROUP = "remove_service_from_group",
  ENABLE_SERVICE = "enable_service",
  DISABLE_SERVICE = "disable_service",
  FETCH_DATA_SETS = "fetch_data_sets",
  CREATE_USER = "create_user",
  CREATE_PORTAL_USER = "create_portal_user",
  CREATE_FEDERATED_USER = "create_federated_user",
  INVITE_USER = "invite_user",
  LOGOUT_USER = "logout_user",
  DELETE_USER = "delete_user",
  CLOSE_USER = "close_user",
  CLOSE_USER_CREATE_ADMIN_NOTE = "close_user_create_admin_note",
  LOCK_USER = "lock_user",
  LOCK_USER_CREATE_ADMIN_NOTE = "lock_user_create_admin_note",
  UNLOCK_USER = "lock_user",
  UNLOCK_USER_CREATE_ADMIN_NOTE = "unlock_user_create_admin_note",
  CHANGE_PASSWORD = "change_password",
  PASSWORD_RECOVERY = "password_recovery",
  GENERATE_REPORT = "generate_report",
  FETCH_REPORT = "fetch_report",
  ADD_NOTE = "add_note",
  GET_USER_BINARY_DATA = "get_user_binary_data",
  UPDATE_USER_BINARY_DATA = "update_user_binary_data",
  DELETE_USER_BINARY_DATA = "delete_user_binary_data",
  GET_EMAIL_TEMPLATE = "get_email_template",
  GET_EMAIL_TEMPLATES = "get_email_templates",
  GET_EMAIL_TEMPLATE_REQS = "get_email_template_reqs",
  GET_EMAIL_TEMPLATE_LANG = "get_email_template_lang",
  VALIDATE_EMAIL_TEMPLATE = "validate_email_template",
  CREATE_EMAIL_TEMPLATE = "create_email_template",
  EDIT_EMAIL_TEMPLATE = "edit_email_template",
  EMAIL_TEMPLATE_APPLICATION_SELECTED = "email_template_application_selected",
  CREATED_APPLICATION_EMAIL_TEMPLATE = "created_application_email_template",
  DELETE_EMAIL_TEMPLATE = "delete_email_template",
  GET_UI_PACKAGES = "get_ui_packages",
  UPLOAD_UI_PACKAGE = "upload_ui_package",
  DELETE_UI_PACKAGE = "delete_ui_package",
  EDIT_PROFILE_ATTRIBUTE_DEFINITIONS = "edit_profile_attribute_definitions",
  CREATE_SERVICE_REGIONAL = "create_service_regional",
  DELETE_SERVICE_REGIONAL = "delete_service_regional",
  GET_SERVICE_DATA_SETS = "get_service_data_sets",
  GENERATE_SERVICE_SECRET = "generate_service_secret",
  SET_SERVICE_SECRET = "set_service_secret",
  DELETE_SERVICE_SECRET = "delete_service_secret",
  DELETE_APPLICATION_REGIONAL = "delete_application_regional",
  CREATE_APPLICATION_REGIONAL = "create_application_regional",
  EDIT_APPLICATION_REGIONAL = "edit_application_regional",
  CREATE_SERVICE_MULTIFACTOR_AUTHENTICATOR = "create_service_multifactor_authenticator",
  ACTIVATE_SERVICE_MULTIFACTOR_AUTHENTICATOR = "activate_service_multifactor_authenticator",
  DELETE_SERVICE_MULTIFACTOR_AUTHENTICATOR = "delete_service_multifactor_authenticator",
  ENABLE_APPLICATION = "enable_application",
  DISABLE_APPLICATION = "disable_application",
  GENERATE_APPLICATION_SECRET = "generate_application_secret",
  DELETE_APPLICATION_SECRET = "delete_application_secret",
  SET_APPLICATION_SECRET= "set_application_secret",
  AUTHENTICATE_APPLICATION= "authenticate_application",
  UPDATE_APPLICATION_USER_MANAGEMENT= "update_application_user_management",
  EDIT_FEDERATION_PROVIDER = "edit_federation_provider",
  GET_FEDERATION_PROVIDER = "get_federation_provider",
  GET_FEDERATION_PROVIDERS = "get_federation_providers",
  CREATE_FEDERATION_PROVIDER = "create_federation_provider",
  DELETE_FEDERATION_PROVIDER = "delete_federation_provider",
  GET_POLICY = "get_policy",
  ATTACH_POLICY_TO_USER = "attach_policy_to_user",
  DETACH_POLICY_FROM_USER = "detach_policy_from_user",
  ATTACH_MANAGED_POLICY_TO_USER = "attach_managed_policy_to_user",
  DETACH_MANAGED_POLICY_FROM_USER = "detach_managed_policy_from_user",
  ATTACH_POLICY_TO_SERVICE = "attach_policy_to_service",
  DETACH_POLICY_FROM_SERVICE = "detach_policy_from_service",
  ATTACH_MANAGED_POLICY_TO_SERVICE = "attach_managed_policy_to_service",
  DETACH_MANAGED_POLICY_FROM_SERVICE = "detach_managed_policy_from_service",
  FETCH_USERS = "fetch_users",
  FETCH_AUDIT_EVENTS = "fetch_audit_events",
  FETCH_AUDIT_EVENT_DETAILS = "fetch_audit_event_details",
  FETCH_USER_AUDIT_EVENTS = "fetch_user_audit_events",
  GET_DATA_SET_REQUEST_DETAILS = "get_data_set_request",
  CREATE_DATA_SET_REQUEST = "create_data_set_request",
  EDIT_DATA_SET_REQUEST = "edit_data_set_request",
  ADD_TERMS_CONDITIONS_FILE = "add_terms_conditions_file",
  REMOVE_TERMS_CONDITIONS_FILE = "remove_terms_conditions_file",
  UPLOAD_TERMS_AND_CONDITIONS = "upload_terms_and_conditions",
  GET_TERMS_AND_CONDITIONS_URL = "get_terms_and_conditions_url",
  GET_DATA_SET_REQUEST_EVENTS = "data_set_request_client/get_data_set_request_events",
  GET_DATA_SET_REQUEST_COMMENTS = "data_set_request_client/get_data_set_request_comments",
  CREATE_DATA_SET_REQUEST_COMMENT = "create_data_set_request_comment",
  DELETE_DATA_SET_REQUEST = "delete_data_set_request",
  CREATE_DATA_APPROVAL_REQUEST_COMMENT = "create_data_approval_request_comment",
  APPROVE_REQUEST = "approve_request",
  REJECT_REQUEST = "reject_request",
  REVOKE_DATA_SET_ACCESS_BY_OWNER = "revoke_data_set_access",
  GET_DEVICE_CONFIGURATION = "get_device_configuration",
  GET_ACTUAL_DEVICE_CONFIGURATION = "get_actual_device_configuration",
  GET_DESIRED_DEVICE_CONFIGURATION = "get_desired_device_configuration",
  DELETE_ACTUAL_DEVICE_CONFIGURATION = "delete_actual_device_configuration",
  DELETE_DESIRED_DEVICE_CONFIGURATION = "delete_desired_device_configuration",
  EDIT_ACTUAL_DEVICE_DATA = "edit_actual_device_data",
  EDIT_DESIRED_DEVICE_DATA = "edit_desired_device_data",
  GET_DEVICE_METADATA = "get_device_metadata",
  DELETE_DEVICE_METADATA = "delete_device_metadata",
  EDIT_DEVICE_METADATA = "edit_device_metadata",
  GET_REGION_DEVICE_CONFIGURATION = "get_region_device_configuration",
  EDIT_REGION_DEVICE_CONFIGURATION = "edit_region_device_configuration",
  DELETE_REGION_DEVICE_CONFIGURATION = "delete_region_device_configuration",
  GET_TYPE_DEVICE_CONFIGURATION = "get_type_device_configuration",
  EDIT_TYPE_DEVICE_CONFIGURATION = "edit_type_device_configuration",
  DELETE_TYPE_DEVICE_CONFIGURATION = "delete_type_device_configuration",

  UPDATE_MFA_REQUIREMENT = "update_mfa_requirement",
  DELETE_AUTHENTICATOR = "delete_authenticator",

  GET_DATA_ACCESS_REQUESTS = "get_data_access_requests",
  GET_ACCOUNT_DATA_ACCESS_REQUESTS = "get_account_data_access_requests",
  CREATE_DATA_ACCESS_REQUEST = "create_data_access_request",
  GET_DATA_ACCESS_REQUEST = "get_data_access_request",
  GET_DATA_ACCESS_REQUEST_COMMENTS = "get_data_access_request_comments",
  CREATE_DATA_ACCESS_REQUEST_COMMENT = "create_data_access_request_comment",
  DELETE_DATA_ACCESS_REQUEST = "delete_data_access_request",
  UPLOAD_TRAINING_CERT = "upload_training_cert",
  GET_TRAINING_STATUS = "get_training_status",
  GET_TRAINING_CERT_URL = "get_training_cert_url",

  GET_DATA_LAKE_INDEX_QUERY_RESULTS = "get_data_lake_index_query_results",

  GET_ACQUIRED_FILES_DOWNLOAD_URL = "get_acquired_files_download_url",
  GET_ACQUIRED_STREAM_DOWNLOAD_URL = "get_acquired_stream_download_url",
  GET_BLOB_FILES_DOWNLOAD_URL = "get_blob_files_download_url",
  GET_ACQUIRED_FILES_CREDENTIALS = "get_acquired_files_credentials",
  GET_ACQUIRED_STREAM_CREDENTIALS = "get_acquired_stream_credentials",
  GET_BLOB_FILES_CREDENTIALS = "get_blob_files_credentials",
  GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL = "get_blob_files_single_file_upload_url",
  CANCEL_MULTIPART_BLOB_FILE_UPLOAD = "cancel_multipart_blob_file_upload",
  COMPLETE_MULTIPART_BLOB_FILE_UPLOAD = "complete_multipart_blob_file_upload",
  GET_MULTIPART_BLOB_UPLOAD_URL = "get_multipart_blob_upload_url",
  GET_DATA_LAKE_FILE_METADATA = "get_data_lake_file_metadata",

  GET_WORKLOAD = "get_workload",
  GET_WORKLOADS = "get_workloads",
  GET_ALL_VERSIONS_OF_WORKLOAD = "get_all_versions_of_workload",
  GET_WORKLOAD_INSTANCES = "get_workload_instances",
  GET_WORKLOAD_INSTANCE_LOGS = "get_workload_instance_logs",
  DELETE_WORKLOAD = "delete_workload",
  CREATE_WORKLOAD = "create_workload",
  UPLOAD_WORKLOAD_CODE = "upload_workload_code",
  COPY_WORKLOAD_CODE = "copy_workload_code",
  GET_WORKLOAD_CODE = "get_workload_code",
  GET_WORKLOAD_CODE_HEAD = "get_workload_code_head",
  GET_WORKLOAD_TRIGGERS = "get_workload_triggers",
  UPDATE_WORKLOAD_TRIGGER = "update_workload_trigger",
  SET_WORKLOAD_SERVICE_SECRET = "set_workload_service_secret",
  GET_WORKLOAD_CONFIGURATION = "get_workload_configuration",
  UPDATE_WORKLOAD_CONFIGURATION = "update_workload_configuration",
  UPDATE_WORKLOAD_CONFIGURATION_BY_KEY = "update_workload_configuration_by_key",
  EDIT_WORKLOAD_DESCRIPTION = "edit_workload_description",
  EDIT_WORKLOAD_TAGS = "edit_workload_tags",
  EDIT_WORKLOAD_POLICY = "edit_workload_policy",
  DELETE_WORKLOAD_CONFIGURATION_KEYS = "delete_workload_configuration_keys",
  DELETE_WORKLOAD_CONFIGURATION_BY_KEY = "delete_workload_configuration_by_key",
  ADD_WORKLOAD_TRIGGER = "add_workload_trigger",
  MANUALLY_EXECUTE_WORKLOAD = "manually_execute_workload",
  DRAFT_NEW_WORKLOAD_VERSION = "draft_new_workload_version",
  RELEASE_WORKLOAD = "release_workload",
  DECOMMISSION_WORKLOAD = "decommission_workload",

  GET_EVENT_SOURCES = "get_event_sources",
  GET_EVENT_SOURCE = "get_event_source",
  GET_AVAILABLE_EVENT_SOURCES = "get_available_event_sources",
  DELETE_EVENT_SOURCE = "delete_event_source",
  ENABLE_EVENT_SOURCE = "enable_event_source",
  DISABLE_EVENT_SOURCE = "disable_event_source",

  GET_QUERY_DATABASE = "get_query_database",
  GET_QUERY_DATABASE_TABLES = "get_query_database_tables",

  CREATE_DS_TYPE = "create_ds_type",
  UPDATE_DS_TYPE = "update_ds_type",
  DELETE_DS_TYPE = "delete_ds_type",
  CREATE_DS_LAYOUT = "create_ds_layout",
  UPDATE_DS_LAYOUT = "update_ds_layout",
  DELETE_DS_LAYOUT = "delete_ds_layout"
}

export enum EventCategories {
  USER_ACTION = "user_action",
  ERROR_ACTION = "error_action",
}

export interface AnalyticsReduxAction<T, V = any> extends ReduxAction<T, V> {
  accountId?: string;
}

type Action<T = any, V = any> = AnalyticsReduxAction<T, V>;

const mapActionToLabel = (action: Action) => {

  const { value, accountId = "" } = action;

  // If the value is missing - or not a string - use the accountId as the label
  if (isEmptyString(value)) {
    return accountId;
  }

  // We can only append the accountId if the user is logged in
  if (isEmptyString(accountId)) {
    return value;
  }

  // Make sure that the accountId was not manually added to the action value
  const isAccountIdPresent = value.split(":").some((s: string) => equalsIgnoreCase(accountId, s));

  if (isAccountIdPresent) {
    return value;
  }

  return `${accountId}:${value}`;
};

export class AnalyticsEvent {

  public static userAction(analyticAction: EventTypes,
                           reduxAction: Action,
                           labelRequired?: boolean): AnalyticsEvent | null {

    return AnalyticsEvent.create(
      EventCategories.USER_ACTION, analyticAction, reduxAction, labelRequired);
  }

  public static errorAction(analyticAction: EventTypes,
                            reduxAction: Action,
                            labelRequired?: boolean): AnalyticsEvent | null {

    const { value } = reduxAction;

    if (value && value instanceof Error) {

      const error = value as Error;

      const errorMessage = getStringValue(error.message);

      return AnalyticsEvent.errorAction(analyticAction, {
        ...reduxAction,
        value: errorMessage || "UNCAUGHT ERROR ENCOUNTERED",
      });
    }

    return AnalyticsEvent.create(
      EventCategories.ERROR_ACTION, analyticAction, reduxAction, labelRequired);
  }

  private static create(analyticCategory: EventCategories,
                        analyticAction: EventTypes,
                        reduxAction: Action,
                        labelRequired?: boolean): AnalyticsEvent | null {

    const label = mapActionToLabel(reduxAction);

    if (labelRequired && isEmptyString(label)) {
      return null;
    }

    return new AnalyticsEvent(
      analyticAction, analyticCategory, label);
  }

  constructor(
    public readonly action: string,
    public readonly category: string,
    public readonly label: string = "") {
  }
}

type ActionHandler = (action: Action) => AnalyticsEvent | null;

type ActionHandlers = { [key: string]: ActionHandler };

const userActionHandler = (eventType: EventTypes, labelRequired?: boolean) =>
  (action: Action) => AnalyticsEvent.userAction(eventType, action, labelRequired);

const errorActionHandler = (eventType: EventTypes, labelRequired?: boolean) =>
  (action: Action) => AnalyticsEvent.errorAction(eventType, action, labelRequired);

const commonActionHandlers: ActionHandlers = {
  [ActionType.ERROR]: errorActionHandler(EventTypes.ERROR),
  [ActionType.UPDATE_CURRENT_USER]: userActionHandler(EventTypes.USER_LOGGED_IN),
  [ActionType.RESET]: userActionHandler(EventTypes.USER_LOGGED_OUT),
  [ActionType.SET_VERSION]: userActionHandler(EventTypes.SET_VERSION),
  [ActionType.SET_VERSION_CODE]: userActionHandler(EventTypes.SET_VERSION_CODE),
  [ActionType.SET_ACCOUNT_ID]: userActionHandler(EventTypes.SET_ACCOUNT_ID, true),
  [ActionType.SET_IDENTITY_TYPE]: userActionHandler(EventTypes.SET_IDENTITY_TYPE),
  [BackButtonActionType.CLICK_BACK_BUTTON]: userActionHandler(EventTypes.CLICK_BACK_BUTTON),
};

const loginActionHandlers: ActionHandlers = {
  [LoginActionType.SECURITY_MANAGER_LOGIN_SUCCESS]:
    userActionHandler(EventTypes.SECURITY_MANAGER_LOGIN),
  [LoginActionType.SECURITY_MANAGER_LOGIN_FAILED]:
    errorActionHandler(EventTypes.SECURITY_MANAGER_LOGIN),
  [LoginActionType.REDIRECT_TO_FEDERATED_LOGIN_URL]:
    userActionHandler(EventTypes.START_FEDERATED_LOGIN),
  [LoginActionType.FEDERATED_LOGIN_FAILED]:
    errorActionHandler(EventTypes.START_FEDERATED_LOGIN),
  [LoginActionType.FETCH_OIDC_TOKENS_REQUEST]:
    userActionHandler(EventTypes.REQUEST_FEDERATED_LOGIN_TOKENS),
  [LoginActionType.FETCH_OIDC_TOKENS_SUCCESS]:
    userActionHandler(EventTypes.FINISH_FEDERATED_LOGIN),
  [LoginActionType.FETCH_OIDC_TOKENS_FAILED]:
    errorActionHandler(EventTypes.FINISH_FEDERATED_LOGIN),
};

const namespaceActionHandlers: ActionHandlers = {
  [CreateNamespaceActionType.CREATE_NAMESPACE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.CREATE_NAMESPACE),
  [CreateNamespaceActionType.CREATE_NAMESPACE_REQUEST_FAILURE]:
    errorActionHandler(EventTypes.CREATE_NAMESPACE),
  [NamespaceClientActionType.UPDATE_NAMESPACE_LIFECYCLE_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_NAMESPACE_LIFECYCLE),
  [NamespaceClientActionType.UPDATE_NAMESPACE_LIFECYCLE_FAILED]:
    errorActionHandler(EventTypes.UPDATE_NAMESPACE_LIFECYCLE),
};

const applicationsRegionalActionHandlers: ActionHandlers = {
  [ApplicationManagerRegionalClientActionType.DELETE_APPLICATION_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.DELETE_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.DELETE_APPLICATION_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.DELETE_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.ENABLE_APPLICATION_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.ENABLE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.ENABLE_APPLICATION_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.ENABLE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.DISABLE_APPLICATION_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.DISABLE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.DISABLE_APPLICATION_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.DISABLE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.GENERATE_APPLICATION_SECRET_SUCCESS]:
    userActionHandler(EventTypes.GENERATE_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.GENERATE_APPLICATION_SECRET_FAILED]:
    errorActionHandler(EventTypes.GENERATE_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.DELETE_APPLICATION_SECRET_SUCCESS]:
    userActionHandler(EventTypes.DELETE_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.DELETE_APPLICATION_SECRET_FAILED]:
    errorActionHandler(EventTypes.DELETE_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.SET_APPLICATION_SECRET_SUCCESS]:
    userActionHandler(EventTypes.SET_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.SET_APPLICATION_SECRET_FAILED]:
    errorActionHandler(EventTypes.SET_APPLICATION_SECRET),
  [ApplicationManagerRegionalClientActionType.AUTHENTICATE_APPLICATION_SUCCESS]:
    userActionHandler(EventTypes.AUTHENTICATE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.AUTHENTICATE_APPLICATION_FAILED]:
    errorActionHandler(EventTypes.AUTHENTICATE_APPLICATION),
  [ApplicationManagerRegionalClientActionType.CREATE_APPLICATION_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.CREATE_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.CREATE_APPLICATION_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.CREATE_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.EDIT_APPLICATION_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.EDIT_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.EDIT_APPLICATION_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.EDIT_APPLICATION_REGIONAL),
  [ApplicationManagerRegionalClientActionType.UPDATE_APPLICATION_USER_MANAGEMENT_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_APPLICATION_USER_MANAGEMENT),
  [ApplicationManagerRegionalClientActionType.UPDATE_APPLICATION_USER_MANAGEMENT_FAILED]:
    errorActionHandler(EventTypes.UPDATE_APPLICATION_USER_MANAGEMENT),
};

const securityServicesRegionalActionHandlers: ActionHandlers = {
  [SecurityServiceRegionalClientActionType.AUTHENTICATE_SERVICE_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.AUTHENTICATE_SERVICE),
  [SecurityServiceRegionalClientActionType.AUTHENTICATE_SERVICE_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.AUTHENTICATE_SERVICE),
  [SecurityServiceRegionalClientActionType.GENERATE_SERVICE_SECRET_SUCCESS]:
    userActionHandler(EventTypes.GENERATE_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.GENERATE_SERVICE_SECRET_FAILED]:
    errorActionHandler(EventTypes.GENERATE_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.SET_SERVICE_SECRET_SUCCESS]:
    userActionHandler(EventTypes.SET_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.SET_SERVICE_SECRET_FAILED]:
    errorActionHandler(EventTypes.SET_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.DELETE_SERVICE_SECRET_SUCCESS]:
    userActionHandler(EventTypes.DELETE_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.DELETE_SERVICE_SECRET_FAILED]:
    errorActionHandler(EventTypes.DELETE_SERVICE_SECRET),
  [SecurityServiceRegionalClientActionType.DELETE_SERVICE_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.DELETE_SERVICE_REGIONAL),
  [SecurityServiceRegionalClientActionType.DELETE_SERVICE_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.DELETE_SERVICE_REGIONAL),
  [SecurityServiceRegionalClientActionType.GET_SERVICE_DATA_SETS_SUCCESS]:
    userActionHandler(EventTypes.GET_SERVICE_DATA_SETS),
  [SecurityServiceRegionalClientActionType.GET_SERVICE_DATA_SETS_FAILED]:
    errorActionHandler(EventTypes.GET_SERVICE_DATA_SETS),
  [SecurityServiceRegionalClientActionType.CREATE_SERVICE_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.CREATE_SERVICE_REGIONAL),
  [SecurityServiceRegionalClientActionType.CREATE_SERVICE_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.CREATE_SERVICE_REGIONAL),
  [SecurityServiceRegionalClientActionType.ENABLE_SERVICE_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.ENABLE_SERVICE),
  [SecurityServiceRegionalClientActionType.ENABLE_SERVICE_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.ENABLE_SERVICE),
  [SecurityServiceRegionalClientActionType.DISABLE_SERVICE_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.DISABLE_SERVICE),
  [SecurityServiceRegionalClientActionType.DISABLE_SERVICE_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.DISABLE_SERVICE),
  [SecurityServiceRegionalClientActionType.CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.CREATE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
  [SecurityServiceRegionalClientActionType.CREATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.CREATE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
  [SecurityServiceRegionalClientActionType.ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.ACTIVATE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
  [SecurityServiceRegionalClientActionType.ACTIVATE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.ACTIVATE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
  [SecurityServiceRegionalClientActionType.DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_SUCCESS]:
    userActionHandler(EventTypes.DELETE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
  [SecurityServiceRegionalClientActionType.DELETE_MULTIFACTOR_AUTHENTICATOR_REGIONAL_FAILED]:
    errorActionHandler(EventTypes.DELETE_SERVICE_MULTIFACTOR_AUTHENTICATOR),
};

const policyActionHandlers: ActionHandlers = {
  [CREATE_POLICY_ACTION_TYPES.CREATE_POLICY_SUCCESS]:
    userActionHandler(EventTypes.CREATE_POLICY),
  [CREATE_POLICY_ACTION_TYPES.CREATE_POLICY_FAILED]:
    errorActionHandler(EventTypes.CREATE_POLICY),
  [DeletePolicyActionType.DELETE_POLICY_SUCCESS]:
    userActionHandler(EventTypes.DELETE_POLICY),
  [DeletePolicyActionType.DELETE_POLICY_FAILED]:
    errorActionHandler(EventTypes.DELETE_POLICY),

  [PolicyManagementClientActionType.GET_POLICY_SUCCESS]:
    userActionHandler(EventTypes.GET_POLICY),
  [PolicyManagementClientActionType.GET_POLICY_FAILED]:
    errorActionHandler(EventTypes.GET_POLICY),

  [PolicyManagementClientActionType.ATTACH_POLICY_TO_USER_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_POLICY_TO_USER),
  [PolicyManagementClientActionType.ATTACH_POLICY_TO_USER_FAILED]:
    errorActionHandler(EventTypes.ATTACH_POLICY_TO_USER),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_USER_SUCCESS]:
    userActionHandler(EventTypes.DETACH_POLICY_FROM_USER),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_USER_FAILED]:
    errorActionHandler(EventTypes.DETACH_POLICY_FROM_USER),

  [PolicyManagementClientActionType.ATTACH_POLICY_TO_SERVICE_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_POLICY_TO_SERVICE),
  [PolicyManagementClientActionType.ATTACH_POLICY_TO_SERVICE_FAILED]:
    errorActionHandler(EventTypes.ATTACH_POLICY_TO_SERVICE),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_SERVICE_SUCCESS]:
    userActionHandler(EventTypes.DETACH_POLICY_FROM_SERVICE),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_SERVICE_FAILED]:
    errorActionHandler(EventTypes.DETACH_POLICY_FROM_SERVICE),

  [PolicyManagementClientActionType.ATTACH_POLICY_TO_GROUP_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_POLICY_TO_GROUP),
  [PolicyManagementClientActionType.ATTACH_POLICY_TO_GROUP_FAILED]:
    errorActionHandler(EventTypes.ATTACH_POLICY_TO_GROUP),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_GROUP_SUCCESS]:
    userActionHandler(EventTypes.DETACH_POLICY_FROM_GROUP),
  [PolicyManagementClientActionType.DETACH_POLICY_FROM_GROUP_FAILED]:
    errorActionHandler(EventTypes.DETACH_POLICY_FROM_GROUP),

  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_USER_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_USER),
  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_USER_FAILED]:
    errorActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_USER),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_USER_SUCCESS]:
    userActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_USER),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_USER_FAILED]:
    errorActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_USER),

  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_SERVICE_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_SERVICE),
  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_SERVICE_FAILED]:
    errorActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_SERVICE),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_SERVICE_SUCCESS]:
    userActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_SERVICE),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_SERVICE_FAILED]:
    errorActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_SERVICE),

  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_GROUP_SUCCESS]:
    userActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_GROUP),
  [ManagedPolicyClientActionType.ATTACH_MANAGED_POLICY_TO_GROUP_FAILED]:
    errorActionHandler(EventTypes.ATTACH_MANAGED_POLICY_TO_GROUP),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_GROUP_SUCCESS]:
    userActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_GROUP),
  [ManagedPolicyClientActionType.DETACH_MANAGED_POLICY_FROM_GROUP_FAILED]:
    errorActionHandler(EventTypes.DETACH_MANAGED_POLICY_FROM_GROUP),
};

const securityGroupActionHandlers: ActionHandlers = {
  [SecurityGroupsClientActionType.GET_SECURITY_GROUP_SUCCESS]:
    userActionHandler(EventTypes.GET_SECURITY_GROUP),
  [SecurityGroupsClientActionType.GET_SECURITY_GROUP_FAILED]:
    errorActionHandler(EventTypes.GET_SECURITY_GROUP),
  [SecurityGroupsClientActionType.GET_SECURITY_GROUPS_SUCCESS]:
    userActionHandler(EventTypes.GET_SECURITY_GROUPS),
  [SecurityGroupsClientActionType.GET_SECURITY_GROUPS_FAILED]:
    errorActionHandler(EventTypes.GET_SECURITY_GROUPS),
  [SecurityGroupsClientActionType.CREATE_SECURITY_GROUP_SUCCESS]:
    userActionHandler(EventTypes.CREATE_SECURITY_GROUP),
  [SecurityGroupsClientActionType.CREATE_SECURITY_GROUP_FAILED]:
    errorActionHandler(EventTypes.CREATE_SECURITY_GROUP),
  [SecurityGroupsClientActionType.DELETE_SECURITY_GROUP_SUCCESS]:
    userActionHandler(EventTypes.DELETE_SECURITY_GROUP),
  [SecurityGroupsClientActionType.DELETE_SECURITY_GROUP_FAILED]:
    errorActionHandler(EventTypes.DELETE_SECURITY_GROUP),
  [SecurityGroupsClientActionType.GET_SECURITY_GROUP_SERVICES_SUCCESS]:
    userActionHandler(EventTypes.GET_SECURITY_GROUP_SERVICES),
  [SecurityGroupsClientActionType.GET_SECURITY_GROUP_SERVICES_FAILED]:
    errorActionHandler(EventTypes.GET_SECURITY_GROUP_SERVICES),
};

const schemaActionHandlers: ActionHandlers = {
  [SCHEMA_WIZARD_ACTION_TYPES.SAVE_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.CREATE_SCHEMA),
  [SCHEMA_WIZARD_ACTION_TYPES.SAVE_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.CREATE_SCHEMA),
  [EditModeSchemaActionType.EDIT_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_SCHEMA),
  [EditModeSchemaActionType.EDIT_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_SCHEMA),
  [SchemaStateManagerActionType.DRAFT_NEW_SCHEMA_VERSION_SUCCESS]:
    userActionHandler(EventTypes.DRAFT_SCHEMA),
  [SchemaStateManagerActionType.DRAFT_NEW_SCHEMA_VERSION_FAILED]:
    errorActionHandler(EventTypes.DRAFT_SCHEMA),
  [SchemaStateManagerActionType.DELETE_SCHEMA_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DELETE_SCHEMA),
  [SchemaStateManagerActionType.DELETE_SCHEMA_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DELETE_SCHEMA),
  [SchemaStateManagerActionType.PROMOTE_SCHEMA_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.PROMOTE_SCHEMA),
  [SchemaStateManagerActionType.PROMOTE_SCHEMA_REQUEST_FAILED]:
    errorActionHandler(EventTypes.PROMOTE_SCHEMA),
  [SchemaStateManagerActionType.DEPRECATE_SCHEMA_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DEPRECATE_SCHEMA),
  [SchemaStateManagerActionType.DEPRECATE_SCHEMA_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DEPRECATE_SCHEMA),
  [SchemaStateManagerActionType.DECOMMISSION_SCHEMA_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DECOMMISSION_SCHEMA),
  [SchemaStateManagerActionType.DECOMMISSION_SCHEMA_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DECOMMISSION_SCHEMA),
  [PropertyEditorActionType.VALIDATE_PROPERTY_SUCCESS]:
    userActionHandler(EventTypes.VALIDATE_SCHEMA_PROPERTY),
  [PropertyEditorActionType.VALIDATE_PROPERTY_FAILED]:
    errorActionHandler(EventTypes.VALIDATE_SCHEMA_PROPERTY),
  [SchemaClientActionTypes.GET_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_SCHEMA),
  [SchemaClientActionTypes.GET_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_SCHEMA),
  [SchemaClientActionTypes.GET_SCHEMA_METADATA_SUCCESS]:
    userActionHandler(EventTypes.GET_SCHEMA_METADATA),
  [SchemaClientActionTypes.GET_SCHEMA_METADATA_FAILED]:
    errorActionHandler(EventTypes.GET_SCHEMA_METADATA),
};

const deviceTypeActionHandlers: ActionHandlers = {
  [DeviceTypeClientActionType.GET_DEVICE_TYPES_SUCCESS]:
    userActionHandler(EventTypes.GET_DEVICE_TYPES),
  [DeviceTypeClientActionType.GET_DEVICE_TYPES_FAILED]:
    errorActionHandler(EventTypes.GET_DEVICE_TYPES),
  [DEVICE_TYPE_WIZARD_ACTION_TYPES.CREATE_DEVICE_TYPE_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DEVICE_TYPE),
  [DEVICE_TYPE_WIZARD_ACTION_TYPES.CREATE_DEVICE_TYPE_FAILED]:
    errorActionHandler(EventTypes.CREATE_DEVICE_TYPE),
  [DEVICE_TYPE_WIZARD_ACTION_TYPES.EDIT_DEVICE_TYPE_SUCCESS]:
    userActionHandler(EventTypes.EDIT_DEVICE_TYPE),
  [DEVICE_TYPE_WIZARD_ACTION_TYPES.EDIT_DEVICE_TYPE_FAILED]:
    errorActionHandler(EventTypes.EDIT_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DRAFT_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DRAFT_DEVICE_TYPE),
  [DeviceTypeManagerActionType.PROMOTE_DEVICE_TYPE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.PROMOTE_DEVICE_TYPE),
  [DeviceTypeManagerActionType.PROMOTE_DEVICE_TYPE_REQUEST_FAILED]:
    errorActionHandler(EventTypes.PROMOTE_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DECOMMISSION_DEVICE_TYPE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DECOMMISSION_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DECOMMISSION_DEVICE_TYPE_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DECOMMISSION_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DEPRECATE_DEVICE_TYPE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DEPRECATE_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DEPRECATE_DEVICE_TYPE_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DEPRECATE_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DELETE_DEVICE_TYPE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DEVICE_TYPE),
  [DeviceTypeManagerActionType.DELETE_DEVICE_TYPE_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DELETE_DEVICE_TYPE),
  [ManageSoftwareVersionsActionType.ADD_SOFTWARE_VERSION_SUCCESS]:
    userActionHandler(EventTypes.ADD_SOFTWARE_VERSION),
  [ManageSoftwareVersionsActionType.ADD_SOFTWARE_VERSION_FAILED]:
    errorActionHandler(EventTypes.ADD_SOFTWARE_VERSION),
  [ManageSoftwareVersionsActionType.REMOVE_SOFTWARE_VERSION_SUCCESS]:
    userActionHandler(EventTypes.REMOVE_SOFTWARE_VERSION),
  [ManageSoftwareVersionsActionType.REMOVE_SOFTWARE_VERSION_FAILED]:
    errorActionHandler(EventTypes.REMOVE_SOFTWARE_VERSION),
};

const groupActionHandlers: ActionHandlers = {
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.ADD_USER_TO_GROUPS_SUCCESS]:
    userActionHandler(EventTypes.ADD_USER_TO_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.ADD_USER_TO_GROUPS_FAILED]:
    errorActionHandler(EventTypes.ADD_USER_TO_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.REMOVE_USER_FROM_GROUPS_SUCCESS]:
    userActionHandler(EventTypes.REMOVE_USER_FROM_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.REMOVE_USER_FROM_GROUPS_FAILED]:
    errorActionHandler(EventTypes.REMOVE_USER_FROM_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.ADD_SERVICE_TO_GROUPS_SUCCESS]:
    userActionHandler(EventTypes.ADD_SERVICE_TO_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.ADD_SERVICE_TO_GROUPS_FAILED]:
    errorActionHandler(EventTypes.ADD_SERVICE_TO_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.REMOVE_SERVICE_FROM_GROUPS_SUCCESS]:
    userActionHandler(EventTypes.REMOVE_SERVICE_FROM_GROUP),
  [GROUP_MANAGEMENT_WIZARD_ACTION_TYPES.REMOVE_SERVICE_FROM_GROUPS_FAILED]:
    errorActionHandler(EventTypes.REMOVE_SERVICE_FROM_GROUP),
};

const userActionHandlers: ActionHandlers = {
  [CREATE_USER_ACTION_TYPES.CREATE_USER_SUCCESS]:
    userActionHandler(EventTypes.CREATE_USER),
  [CREATE_USER_ACTION_TYPES.CREATE_USER_FAILED]:
    errorActionHandler(EventTypes.CREATE_USER),
  [CREATE_USER_ACTION_TYPES.CREATE_PORTAL_USER_SUCCESS]:
    userActionHandler(EventTypes.CREATE_PORTAL_USER),
  [CREATE_USER_ACTION_TYPES.CREATE_PORTAL_USER_FAILED]:
    errorActionHandler(EventTypes.CREATE_PORTAL_USER),
  [CREATE_USER_ACTION_TYPES.CREATE_FEDERATED_USER_SUCCESS]:
    userActionHandler(EventTypes.CREATE_FEDERATED_USER),
  [CREATE_USER_ACTION_TYPES.CREATE_FEDERATED_USER_FAILED]:
    errorActionHandler(EventTypes.CREATE_FEDERATED_USER),
  [UserIdmClientActionType.INVITE_USER_SUCCESS]:
    userActionHandler(EventTypes.INVITE_USER),
  [UserIdmClientActionType.INVITE_USER_FAILED]:
    errorActionHandler(EventTypes.INVITE_USER),
  [LOGOUT_USER_ACTION_TYPES.LOGOUT_USER_SUCCESS]:
    userActionHandler(EventTypes.LOGOUT_USER),
  [LOGOUT_USER_ACTION_TYPES.LOGOUT_USER_FAILED]:
    errorActionHandler(EventTypes.LOGOUT_USER),
  [DELETE_USER_ACTION_TYPES.DELETE_USER_SUCCESS]:
    userActionHandler(EventTypes.DELETE_USER),
  [DELETE_USER_ACTION_TYPES.DELETE_USER_FAILED]:
    errorActionHandler(EventTypes.DELETE_USER),
  [CLOSE_USER_ACTION_TYPES.CLOSE_USER_SUCCESS]:
    userActionHandler(EventTypes.CLOSE_USER),
  [CLOSE_USER_ACTION_TYPES.CLOSE_USER_FAILED]:
    errorActionHandler(EventTypes.CLOSE_USER),
  [CLOSE_USER_ACTION_TYPES.CLOSE_USER_CREATE_ADMIN_NOTE_SUCCESS]:
    userActionHandler(EventTypes.CLOSE_USER_CREATE_ADMIN_NOTE),
  [CLOSE_USER_ACTION_TYPES.CLOSE_USER_CREATE_ADMIN_NOTE_FAILED]:
    errorActionHandler(EventTypes.CLOSE_USER_CREATE_ADMIN_NOTE),
  [LOCK_USER_ACTION_TYPES.LOCK_USER_SUCCESS]:
    userActionHandler(EventTypes.LOCK_USER),
  [LOCK_USER_ACTION_TYPES.LOCK_USER_FAILED]:
    errorActionHandler(EventTypes.LOCK_USER),
  [LOCK_USER_ACTION_TYPES.LOCK_USER_CREATE_ADMIN_NOTE_SUCCESS]:
    userActionHandler(EventTypes.LOCK_USER_CREATE_ADMIN_NOTE),
  [LOCK_USER_ACTION_TYPES.LOCK_USER_CREATE_ADMIN_NOTE_FAILED]:
    errorActionHandler(EventTypes.LOCK_USER_CREATE_ADMIN_NOTE),
  [LOCK_USER_ACTION_TYPES.UNLOCK_USER_SUCCESS]:
    userActionHandler(EventTypes.UNLOCK_USER),
  [LOCK_USER_ACTION_TYPES.UNLOCK_USER_FAILED]:
    errorActionHandler(EventTypes.UNLOCK_USER),
  [LOCK_USER_ACTION_TYPES.UNLOCK_USER_CREATE_ADMIN_NOTE_SUCCESS]:
    userActionHandler(EventTypes.UNLOCK_USER_CREATE_ADMIN_NOTE),
  [LOCK_USER_ACTION_TYPES.UNLOCK_USER_CREATE_ADMIN_NOTE_FAILED]:
    errorActionHandler(EventTypes.UNLOCK_USER_CREATE_ADMIN_NOTE),
  [CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS]:
    userActionHandler(EventTypes.CHANGE_PASSWORD),
  [CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_FAILED]:
    errorActionHandler(EventTypes.CHANGE_PASSWORD),
  [PASSWORD_RECOVERY_ACTION_TYPES.PASSWORD_RECOVERY_SUCCESS]:
    userActionHandler(EventTypes.PASSWORD_RECOVERY),
  [PASSWORD_RECOVERY_ACTION_TYPES.PASSWORD_RECOVERY_FAILED]:
    errorActionHandler(EventTypes.PASSWORD_RECOVERY),
  [USER_ACTION_TYPES.FETCH_USERS_SUCCESS]:
    userActionHandler(EventTypes.FETCH_USERS),
  [USER_ACTION_TYPES.FETCH_USERS_FAILED]:
    errorActionHandler(EventTypes.FETCH_USERS),
  [USER_DETAILS_ACTION_TYPES.CREATE_ADMIN_NOTE_SUCCESS]:
    userActionHandler(EventTypes.ADD_NOTE),
  [USER_DETAILS_ACTION_TYPES.CREATE_ADMIN_NOTE_FAILED]:
    errorActionHandler(EventTypes.ADD_NOTE),
  [USER_DETAILS_ACTION_TYPES.FETCH_USER_AUDIT_EVENTS_SUCCESS]:
    userActionHandler(EventTypes.FETCH_USER_AUDIT_EVENTS),
  [USER_DETAILS_ACTION_TYPES.FETCH_USER_AUDIT_EVENTS_FAILED]:
    errorActionHandler(EventTypes.FETCH_USER_AUDIT_EVENTS),
  [USER_REPORT_ACTION_TYPES.GENERATE_USER_REPORT_SUCCESS]:
    userActionHandler(EventTypes.GENERATE_REPORT),
  [USER_REPORT_ACTION_TYPES.GENERATE_USER_REPORT_FAILED]:
    errorActionHandler(EventTypes.GENERATE_REPORT),
  [USER_REPORT_ACTION_TYPES.FETCH_USER_REPORT_SUCCESS]:
    userActionHandler(EventTypes.FETCH_REPORT),
  [USER_REPORT_ACTION_TYPES.FETCH_USER_REPORT_FAILED]:
    errorActionHandler(EventTypes.FETCH_REPORT),
  [SecurityManagerClientActionType.GET_USER_BINARY_DATA_SUCCESS]:
    userActionHandler(EventTypes.GET_USER_BINARY_DATA),
  [SecurityManagerClientActionType.GET_USER_BINARY_DATA_FAILED]:
    errorActionHandler(EventTypes.GET_USER_BINARY_DATA),
  [SecurityManagerClientActionType.UPDATE_USER_BINARY_DATA_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_USER_BINARY_DATA),
  [SecurityManagerClientActionType.UPDATE_USER_BINARY_DATA_FAILED]:
    errorActionHandler(EventTypes.UPDATE_USER_BINARY_DATA),
  [SecurityManagerClientActionType.DELETE_USER_BINARY_DATA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_USER_BINARY_DATA),
  [SecurityManagerClientActionType.DELETE_USER_BINARY_DATA_FAILED]:
    errorActionHandler(EventTypes.DELETE_USER_BINARY_DATA),
};

const emailTemplateActionHandlers: ActionHandlers = {
  [EmailTemplateClientActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS]:
    userActionHandler(EventTypes.DELETE_EMAIL_TEMPLATE),
  [EmailTemplateClientActionTypes.DELETE_EMAIL_TEMPLATE_FAILED]:
    errorActionHandler(EventTypes.DELETE_EMAIL_TEMPLATE),

  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_SUCCESS]:
    userActionHandler(EventTypes.GET_EMAIL_TEMPLATE),
  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_FAILED]:
    errorActionHandler(EventTypes.GET_EMAIL_TEMPLATE),

  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATES_SUCCESS]:
    userActionHandler(EventTypes.GET_EMAIL_TEMPLATES),
  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATES_FAILED]:
    errorActionHandler(EventTypes.GET_EMAIL_TEMPLATES),

  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_REQS_SUCCESS]:
    userActionHandler(EventTypes.GET_EMAIL_TEMPLATE_REQS),
  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_REQS_FAILED]:
    errorActionHandler(EventTypes.GET_EMAIL_TEMPLATE_REQS),

  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_LANG_SUCCESS]:
    userActionHandler(EventTypes.GET_EMAIL_TEMPLATE_LANG),
  [EmailTemplateClientActionTypes.GET_EMAIL_TEMPLATE_LANG_FAILED]:
    errorActionHandler(EventTypes.GET_EMAIL_TEMPLATE_LANG),

  [EmailTemplateClientActionTypes.VALIDATE_EMAIL_TEMPLATE_SUCCESS]:
    userActionHandler(EventTypes.VALIDATE_EMAIL_TEMPLATE),
  [EmailTemplateClientActionTypes.VALIDATE_EMAIL_TEMPLATE_FAILED]:
    errorActionHandler(EventTypes.VALIDATE_EMAIL_TEMPLATE),

  [EmailTemplateClientActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS]:
    userActionHandler(EventTypes.CREATE_EMAIL_TEMPLATE),
  [EmailTemplateClientActionTypes.CREATE_EMAIL_TEMPLATE_FAILED]:
    errorActionHandler(EventTypes.CREATE_EMAIL_TEMPLATE),

  [EmailTemplateClientActionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS]:
    userActionHandler(EventTypes.EDIT_EMAIL_TEMPLATE),
  [EmailTemplateClientActionTypes.EDIT_EMAIL_TEMPLATE_FAILED]:
    errorActionHandler(EventTypes.EDIT_EMAIL_TEMPLATE),
};

const profileAttributeDefinitionActionHandlers: ActionHandlers = {
  [ProfileAttributeClientActionType.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_SUCCESS]:
    userActionHandler(EventTypes.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS),
  [ProfileAttributeClientActionType.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS_FAILED]:
    errorActionHandler(EventTypes.EDIT_PROFILE_ATTRIBUTE_DEFINITIONS),
};

const federationProviderActionHandlers: ActionHandlers = {
  [FEDERATION_PROVIDER_WIZARD_ACTION_TYPES.CREATE_FEDERATION_PROVIDER_SUCCESS]:
    userActionHandler(EventTypes.CREATE_FEDERATION_PROVIDER),
  [FEDERATION_PROVIDER_WIZARD_ACTION_TYPES.CREATE_FEDERATION_PROVIDER_FAILED]:
    errorActionHandler(EventTypes.CREATE_FEDERATION_PROVIDER),
  [FEDERATION_PROVIDER_WIZARD_ACTION_TYPES.EDIT_FEDERATION_PROVIDER_SUCCESS]:
    userActionHandler(EventTypes.EDIT_FEDERATION_PROVIDER),
  [FEDERATION_PROVIDER_WIZARD_ACTION_TYPES.EDIT_FEDERATION_PROVIDER_FAILED]:
    errorActionHandler(EventTypes.EDIT_FEDERATION_PROVIDER),
  [DELETE_FEDERATION_PROVIDER_ACTION_TYPES.DELETE_FEDERATION_PROVIDER_SUCCESS]:
  userActionHandler(EventTypes.DELETE_FEDERATION_PROVIDER),
  [DELETE_FEDERATION_PROVIDER_ACTION_TYPES.DELETE_FEDERATION_PROVIDER_FAILED]:
    errorActionHandler(EventTypes.DELETE_FEDERATION_PROVIDER),
  [FederationProviderClientActionType.GET_FEDERATION_PROVIDER_SUCCESS]:
    userActionHandler(EventTypes.GET_FEDERATION_PROVIDER),
  [FederationProviderClientActionType.GET_FEDERATION_PROVIDER_FAILED]:
    errorActionHandler(EventTypes.GET_FEDERATION_PROVIDER),
  [FederationProviderClientActionType.GET_FEDERATION_PROVIDERS_SUCCESS]:
    userActionHandler(EventTypes.GET_FEDERATION_PROVIDERS),
  [FederationProviderClientActionType.GET_FEDERATION_PROVIDERS_FAILED]:
    errorActionHandler(EventTypes.GET_FEDERATION_PROVIDERS),
};

const auditEventActionHandlers: ActionHandlers = {
  [AUDIT_EVENTS_ACTION_TYPES.FIND_EVENTS_SUCCESS]:
    userActionHandler(EventTypes.FETCH_AUDIT_EVENTS),
  [AUDIT_EVENTS_ACTION_TYPES.FIND_EVENTS_FAILURE]:
    errorActionHandler(EventTypes.FETCH_AUDIT_EVENTS),
};

const uiPackageActionHandlers: ActionHandlers = {
  [UiPackageClientActionType.GET_UI_PACKAGES_SUCCESS]:
    userActionHandler(EventTypes.GET_UI_PACKAGES),
  [UiPackageClientActionType.GET_UI_PACKAGES_FAILED]:
    errorActionHandler(EventTypes.GET_UI_PACKAGES),
  [UiPackageClientActionType.UPLOAD_UI_PACKAGE_SUCCESS]:
    userActionHandler(EventTypes.UPLOAD_UI_PACKAGE),
  [UiPackageClientActionType.UPLOAD_UI_PACKAGE_FAILED]:
    errorActionHandler(EventTypes.UPLOAD_UI_PACKAGE),
  [UiPackageClientActionType.DELETE_UI_PACKAGE_SUCCESS]:
    userActionHandler(EventTypes.DELETE_UI_PACKAGE),
  [UiPackageClientActionType.DELETE_UI_PACKAGE_FAILED]:
    errorActionHandler(EventTypes.DELETE_UI_PACKAGE),
};

const dataCatalogActionHandlers: ActionHandlers = {
  [DataCatalogClientActionType.GET_DATA_SETS_FAILED]:
    errorActionHandler(EventTypes.FETCH_DATA_SETS),

  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_SET_REQUEST_DETAILS),
  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_SET_REQUEST_DETAILS),

  [DataSetRequestClientActionType.GET_TERMS_AND_CONDITIONS_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_TERMS_AND_CONDITIONS_URL),
  [DataSetRequestClientActionType.GET_TERMS_AND_CONDITIONS_URL_FAILED]:
    errorActionHandler(EventTypes.GET_TERMS_AND_CONDITIONS_URL),

  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_EVENTS_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_SET_REQUEST_EVENTS),
  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_EVENTS_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_SET_REQUEST_EVENTS),

  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_COMMENTS_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_SET_REQUEST_COMMENTS),
  [DataSetRequestClientActionType.GET_DATA_SET_REQUEST_COMMENTS_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_SET_REQUEST_COMMENTS),

  [DataSetRequestClientActionType.CREATE_DATA_SET_REQUEST_COMMENT_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_SET_REQUEST_COMMENT),
  [DataSetRequestClientActionType.CREATE_DATA_SET_REQUEST_COMMENT_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_SET_REQUEST_COMMENT),

  [DataSetRequestClientActionType.UPLOAD_TERMS_AND_CONDITIONS_SUCCESS]:
    userActionHandler(EventTypes.UPLOAD_TERMS_AND_CONDITIONS),
  [DataSetRequestClientActionType.UPLOAD_TERMS_AND_CONDITIONS_FAILED]:
    errorActionHandler(EventTypes.UPLOAD_TERMS_AND_CONDITIONS),

  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.CREATE_DATA_SET_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_SET_REQUEST),
  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.CREATE_DATA_SET_REQUEST_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_SET_REQUEST),

  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.EDIT_DATA_SET_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.EDIT_DATA_SET_REQUEST),
  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.EDIT_DATA_SET_REQUEST_FAILED]:
    errorActionHandler(EventTypes.EDIT_DATA_SET_REQUEST),

  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.ADD_TERMS_CONDITIONS_FILE]:
    userActionHandler(EventTypes.ADD_TERMS_CONDITIONS_FILE),

  [DATA_SET_REQUEST_WIZARD_ACTION_TYPES.REMOVE_TERMS_CONDITIONS_FILE]:
    userActionHandler(EventTypes.REMOVE_TERMS_CONDITIONS_FILE),

  [DATA_CATALOG_ACTION_TYPES.UPLOAD_TERMS_AND_CONDITIONS_SUCCESS]:
    userActionHandler(EventTypes.UPLOAD_TERMS_AND_CONDITIONS),
  [DATA_CATALOG_ACTION_TYPES.UPLOAD_TERMS_AND_CONDITIONS_FAILED]:
    errorActionHandler(EventTypes.UPLOAD_TERMS_AND_CONDITIONS),

  [DELETE_DATA_ACCESS_REQUEST_ACTION_TYPES.DELETE_DATA_ACCESS_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DATA_ACCESS_REQUEST),
  [DELETE_DATA_ACCESS_REQUEST_ACTION_TYPES.DELETE_DATA_ACCESS_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DELETE_DATA_ACCESS_REQUEST),

  [DELETE_DATA_SET_REQUEST_ACTION_TYPES.DELETE_DATA_SET_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DATA_SET_REQUEST),
  [DELETE_DATA_SET_REQUEST_ACTION_TYPES.DELETE_DATA_SET_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DELETE_DATA_SET_REQUEST),

  [DATA_ACCESS_REQUEST_DETAILS_ACTION_TYPES.CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST_COMMENT),
  [DATA_ACCESS_REQUEST_DETAILS_ACTION_TYPES.CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST_COMMENT),

  [DATA_APPROVAL_REQUEST_DETAILS_ACTION_TYPES.CREATE_DATA_APPROVAL_REQUEST_COMMENT_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_APPROVAL_REQUEST_COMMENT),
  [DATA_APPROVAL_REQUEST_DETAILS_ACTION_TYPES.CREATE_DATA_APPROVAL_REQUEST_COMMENT_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_APPROVAL_REQUEST_COMMENT),

  [APPROVE_REQUEST_ACTION_TYPES.APPROVE_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.APPROVE_REQUEST),
  [APPROVE_REQUEST_ACTION_TYPES.APPROVE_REQUEST_FAILED]:
    errorActionHandler(EventTypes.APPROVE_REQUEST),
  [APPROVE_REQUEST_ACTION_TYPES.REJECT_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.REJECT_REQUEST),
  [APPROVE_REQUEST_ACTION_TYPES.REJECT_REQUEST_FAILED]:
    errorActionHandler(EventTypes.REJECT_REQUEST),

  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUESTS_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_ACCESS_REQUESTS),
  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUESTS_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_ACCESS_REQUESTS),

  [DataAccessRequestClientActionType.GET_ACCOUNT_DATA_ACCESS_REQUESTS_SUCCESS]:
    userActionHandler(EventTypes.GET_ACCOUNT_DATA_ACCESS_REQUESTS),
  [DataAccessRequestClientActionType.GET_ACCOUNT_DATA_ACCESS_REQUESTS_FAILED]:
    errorActionHandler(EventTypes.GET_ACCOUNT_DATA_ACCESS_REQUESTS),

  [DataAccessRequestClientActionType.CREATE_DATA_ACCESS_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST),
  [DataAccessRequestClientActionType.CREATE_DATA_ACCESS_REQUEST_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST),

  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_ACCESS_REQUEST),
  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUEST_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_ACCESS_REQUEST),

  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUEST_COMMENTS_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_ACCESS_REQUEST_COMMENTS),
  [DataAccessRequestClientActionType.GET_DATA_ACCESS_REQUEST_COMMENTS_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_ACCESS_REQUEST_COMMENTS),

  [DataAccessRequestClientActionType.CREATE_DATA_ACCESS_REQUEST_COMMENT_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST_COMMENT),
  [DataAccessRequestClientActionType.CREATE_DATA_ACCESS_REQUEST_COMMENT_FAILED]:
    errorActionHandler(EventTypes.CREATE_DATA_ACCESS_REQUEST_COMMENT),

  [DataAccessRequestClientActionType.DELETE_DATA_ACCESS_REQUEST_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DATA_ACCESS_REQUEST),
  [DataAccessRequestClientActionType.DELETE_DATA_ACCESS_REQUEST_FAILED]:
    errorActionHandler(EventTypes.DELETE_DATA_ACCESS_REQUEST),

  [DataAccessRequestClientActionType.REVOKE_DATA_ACCESS_BY_OWNER_SUCCESS]:
    userActionHandler(EventTypes.REVOKE_DATA_SET_ACCESS_BY_OWNER),
  [DataAccessRequestClientActionType.REVOKE_DATA_ACCESS_BY_OWNER_FAILED]:
    errorActionHandler(EventTypes.REVOKE_DATA_SET_ACCESS_BY_OWNER),

  [DataAccessRequestClientActionType.UPLOAD_TRAINING_CERT_SUCCESS]:
    userActionHandler(EventTypes.UPLOAD_TRAINING_CERT),
  [DataAccessRequestClientActionType.UPLOAD_TRAINING_CERT_FAILED]:
    errorActionHandler(EventTypes.UPLOAD_TRAINING_CERT),

  [DataAccessRequestClientActionType.GET_TRAINING_STATUS_SUCCESS]:
    userActionHandler(EventTypes.GET_TRAINING_STATUS),
  [DataAccessRequestClientActionType.GET_TRAINING_STATUS_FAILED]:
    errorActionHandler(EventTypes.GET_TRAINING_STATUS),

  [DataAccessRequestClientActionType.GET_TRAINING_CERT_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_TRAINING_CERT_URL),
  [DataAccessRequestClientActionType.GET_TRAINING_CERT_URL_FAILED]:
    errorActionHandler(EventTypes.GET_TRAINING_CERT_URL),
};

const deviceEnrollmentActionHandlers: ActionHandlers = {
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.DEVICE_ENROLLMENT_SUCCESS]:
  userActionHandler(EventTypes.ENROLL_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.DEVICE_ENROLLMENT_FAILED]:
  errorActionHandler(EventTypes.ENROLL_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.VALIDATE_DEVICE_ENROLLMENT_SUCCESS]:
    userActionHandler(EventTypes.VALIDATE_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.VALIDATE_DEVICE_ENROLLMENT_FAILED]:
    errorActionHandler(EventTypes.VALIDATE_DEVICE),
  [DISENROLL_DEVICE_ACTION_TYPES.DISENROLL_DEVICE_SUCCESS]:
    userActionHandler(EventTypes.DISENROLL_DEVICE),
  [DISENROLL_DEVICE_ACTION_TYPES.DISENROLL_DEVICE_FAILED]:
    errorActionHandler(EventTypes.DISENROLL_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.PRE_SIGNED_URL_SUCCESS]:
    userActionHandler(EventTypes.BATCH_ENROLL_DEVICE_PRE_SIGNED_URL),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.PRE_SIGNED_URL_FAILED]:
    errorActionHandler(EventTypes.BATCH_ENROLL_DEVICE_PRE_SIGNED_URL),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.BATCH_DEVICE_ENROLLMENT_SUCCESS]:
    userActionHandler(EventTypes.BATCH_ENROLL_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.BATCH_DEVICE_ENROLLMENT_FAILED]:
    errorActionHandler(EventTypes.BATCH_ENROLL_DEVICE),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.VALIDATE_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DEVICE_ENROLLMENT_WIZARD_VALIDATE_SCHEMA_DATA),
  [DEVICE_ENROLLMENT_WIZARD_ACTION_TYPES.VALIDATE_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DEVICE_ENROLLMENT_WIZARD_VALIDATE_SCHEMA_DATA),
};

const deviceConfigurationActionHandlers: ActionHandlers = {
  [DeviceRegistryClientActionType.GET_DEVICE_CONFIGURATION_SUCCESS]:
    userActionHandler(EventTypes.GET_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_DEVICE_CONFIGURATION_FAILED]:
    errorActionHandler(EventTypes.GET_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_ACTUAL_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_ACTUAL_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_DESIRED_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_DESIRED_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_ACTUAL_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_ACTUAL_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DELETE_ACTUAL_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DESIRED_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_DESIRED_DEVICE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DELETE_DESIRED_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_ACTUAL_DEVICE_DATA),
  [DeviceRegistryClientActionType.EDIT_ACTUAL_DEVICE_DATA_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_ACTUAL_DEVICE_DATA),
  [DeviceRegistryClientActionType.EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_DESIRED_DEVICE_DATA),
  [DeviceRegistryClientActionType.EDIT_DESIRED_DEVICE_DATA_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_DESIRED_DEVICE_DATA),
  [DeviceRegistryClientActionType.GET_DEVICE_METADATA_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_DEVICE_METADATA),
  [DeviceRegistryClientActionType.GET_DEVICE_METADATA_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_DEVICE_METADATA),
  [DeviceRegistryClientActionType.DELETE_DEVICE_METADATA_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DEVICE_METADATA),
  [DeviceRegistryClientActionType.DELETE_DEVICE_METADATA_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DELETE_DEVICE_METADATA),
  [DeviceRegistryClientActionType.EDIT_DEVICE_METADATA_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_DEVICE_METADATA),
  [DeviceRegistryClientActionType.EDIT_DEVICE_METADATA_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_DEVICE_METADATA),
  [DeviceRegistryClientActionType.GET_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_REGION_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.GET_TYPE_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.GET_TYPE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.GET_TYPE_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.EDIT_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.EDIT_REGION_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.EDIT_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.EDIT_TYPE_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.EDIT_TYPE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.EDIT_TYPE_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_REGION_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_REGION_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DELETE_REGION_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_TYPE_CONFIGURATION_BY_SCHEMA_SUCCESS]:
    userActionHandler(EventTypes.DELETE_TYPE_DEVICE_CONFIGURATION),
  [DeviceRegistryClientActionType.DELETE_TYPE_CONFIGURATION_BY_SCHEMA_FAILED]:
    errorActionHandler(EventTypes.DELETE_TYPE_DEVICE_CONFIGURATION),
};

const adminUsersActionHandlers: ActionHandlers = {
  [OrchestrationServiceClientActionType.UPDATE_USER_MFA_REQUIREMENT_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_MFA_REQUIREMENT),
  [OrchestrationServiceClientActionType.UPDATE_USER_MFA_REQUIREMENT_FAILED]:
    errorActionHandler(EventTypes.UPDATE_MFA_REQUIREMENT),

  [OrchestrationServiceClientActionType.DELETE_AUTHENTICATOR_SUCCESS]:
    userActionHandler(EventTypes.DELETE_AUTHENTICATOR),
  [OrchestrationServiceClientActionType.DELETE_AUTHENTICATOR_FAILED]:
    errorActionHandler(EventTypes.DELETE_AUTHENTICATOR),
};

const dataLakeActionHandlers: ActionHandlers = {
  [DataLakeIndexClientActionType.GET_DATA_LAKE_INDEX_QUERY_RESULTS_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_LAKE_INDEX_QUERY_RESULTS),
  [DataLakeIndexClientActionType.GET_DATA_LAKE_INDEX_QUERY_RESULTS_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_LAKE_INDEX_QUERY_RESULTS),

  [DataLakeFileTransferClientActionType.GET_ACQUIRED_FILES_DOWNLOAD_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_ACQUIRED_FILES_DOWNLOAD_URL),
  [DataLakeFileTransferClientActionType.GET_ACQUIRED_FILES_DOWNLOAD_URL_FAILED]:
    errorActionHandler(EventTypes.GET_ACQUIRED_FILES_DOWNLOAD_URL),

  [DataLakeFileTransferClientActionType.GET_ACQUIRED_STREAM_DOWNLOAD_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_ACQUIRED_STREAM_DOWNLOAD_URL),
  [DataLakeFileTransferClientActionType.GET_ACQUIRED_STREAM_DOWNLOAD_URL_FAILED]:
    errorActionHandler(EventTypes.GET_ACQUIRED_STREAM_DOWNLOAD_URL),

  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_DOWNLOAD_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_BLOB_FILES_DOWNLOAD_URL),
  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_DOWNLOAD_URL_FAILED]:
    errorActionHandler(EventTypes.GET_BLOB_FILES_DOWNLOAD_URL),

  [DataLakeFileTransferClientActionType.GET_ACQUIRED_FILES_CREDENTIALS_SUCCESS]:
    userActionHandler(EventTypes.GET_ACQUIRED_FILES_CREDENTIALS),
  [DataLakeFileTransferClientActionType.GET_ACQUIRED_FILES_CREDENTIALS_FAILED]:
    errorActionHandler(EventTypes.GET_ACQUIRED_FILES_CREDENTIALS),

  [DataLakeFileTransferClientActionType.GET_ACQUIRED_STREAM_CREDENTIALS_SUCCESS]:
    userActionHandler(EventTypes.GET_ACQUIRED_STREAM_CREDENTIALS),
  [DataLakeFileTransferClientActionType.GET_ACQUIRED_STREAM_CREDENTIALS_FAILED]:
    errorActionHandler(EventTypes.GET_ACQUIRED_STREAM_CREDENTIALS),

  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_CREDENTIALS_SUCCESS]:
    userActionHandler(EventTypes.GET_BLOB_FILES_CREDENTIALS),
  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_CREDENTIALS_FAILED]:
    errorActionHandler(EventTypes.GET_BLOB_FILES_CREDENTIALS),

  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL),
  [DataLakeFileTransferClientActionType.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL_FAILED]:
    errorActionHandler(EventTypes.GET_BLOB_FILES_SINGLE_FILE_UPLOAD_URL),

  [DataLakeFileTransferClientActionType.CANCEL_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS]:
    userActionHandler(EventTypes.CANCEL_MULTIPART_BLOB_FILE_UPLOAD),
  [DataLakeFileTransferClientActionType.CANCEL_MULTIPART_BLOB_FILE_UPLOAD_FAILED]:
    errorActionHandler(EventTypes.CANCEL_MULTIPART_BLOB_FILE_UPLOAD),

  [DataLakeFileTransferClientActionType.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_SUCCESS]:
    userActionHandler(EventTypes.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD),
  [DataLakeFileTransferClientActionType.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD_FAILED]:
    errorActionHandler(EventTypes.COMPLETE_MULTIPART_BLOB_FILE_UPLOAD),

  [DataLakeFileTransferClientActionType.GET_MULTIPART_BLOB_UPLOAD_URL_SUCCESS]:
    userActionHandler(EventTypes.GET_MULTIPART_BLOB_UPLOAD_URL),
  [DataLakeFileTransferClientActionType.GET_MULTIPART_BLOB_UPLOAD_URL_FAILED]:
    errorActionHandler(EventTypes.GET_MULTIPART_BLOB_UPLOAD_URL),

  [DataLakeFileTransferClientActionType.GET_DATA_LAKE_FILE_METADATA_SUCCESS]:
    userActionHandler(EventTypes.GET_DATA_LAKE_FILE_METADATA),
  [DataLakeFileTransferClientActionType.GET_DATA_LAKE_FILE_METADATA_FAILED]:
    errorActionHandler(EventTypes.GET_DATA_LAKE_FILE_METADATA),
};

const dataProcessingActionHandlers: ActionHandlers = {
  [WorkloadManagerClientActionType.GET_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD),
  [WorkloadManagerClientActionType.GET_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD),
  [WorkloadManagerClientActionType.GET_WORKLOADS_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOADS),
  [WorkloadManagerClientActionType.GET_WORKLOADS_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOADS),
  [WorkloadManagerClientActionType.GET_ALL_VERSIONS_OF_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.GET_ALL_VERSIONS_OF_WORKLOAD),
  [WorkloadManagerClientActionType.GET_ALL_VERSIONS_OF_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.GET_ALL_VERSIONS_OF_WORKLOAD),
  [WorkloadManagerClientActionType.GET_WORKLOAD_INSTANCES_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_INSTANCES),
  [WorkloadManagerClientActionType.GET_WORKLOAD_INSTANCES_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_INSTANCES),
  [WorkloadManagerClientActionType.GET_WORKLOAD_INSTANCE_LOGS_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_INSTANCE_LOGS),
  [WorkloadManagerClientActionType.GET_WORKLOAD_INSTANCE_LOGS_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_INSTANCE_LOGS),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.DELETE_WORKLOAD),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.DELETE_WORKLOAD),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_DESCRIPTION_SUCCESS]:
    userActionHandler(EventTypes.EDIT_WORKLOAD_DESCRIPTION),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_DESCRIPTION_FAILED]:
    errorActionHandler(EventTypes.EDIT_WORKLOAD_DESCRIPTION),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_TAGS_SUCCESS]:
    userActionHandler(EventTypes.EDIT_WORKLOAD_TAGS),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_TAGS_FAILED]:
    errorActionHandler(EventTypes.EDIT_WORKLOAD_TAGS),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_POLICY_SUCCESS]:
    userActionHandler(EventTypes.EDIT_WORKLOAD_POLICY),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_POLICY_FAILED]:
    errorActionHandler(EventTypes.EDIT_WORKLOAD_POLICY),
  [WorkloadManagerClientActionType.CREATE_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.CREATE_WORKLOAD),
  [WorkloadManagerClientActionType.CREATE_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.CREATE_WORKLOAD),
  [WorkloadManagerClientActionType.UPLOAD_WORKLOAD_CODE_SUCCESS]:
    userActionHandler(EventTypes.UPLOAD_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.UPLOAD_WORKLOAD_CODE_FAILED]:
    errorActionHandler(EventTypes.UPLOAD_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.COPY_WORKLOAD_CODE_SUCCESS]:
    userActionHandler(EventTypes.COPY_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.COPY_WORKLOAD_CODE_FAILED]:
    errorActionHandler(EventTypes.COPY_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CODE_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CODE_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_CODE),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CODE_HEAD_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_CODE_HEAD),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CODE_HEAD_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_CODE_HEAD),
  [WorkloadManagerClientActionType.SET_WORKLOAD_SERVICE_SECRET_SUCCESS]:
    userActionHandler(EventTypes.SET_WORKLOAD_SERVICE_SECRET),
  [WorkloadManagerClientActionType.SET_WORKLOAD_SERVICE_SECRET_FAILED]:
    errorActionHandler(EventTypes.SET_WORKLOAD_SERVICE_SECRET),
  [WorkloadManagerClientActionType.MANUALLY_EXECUTE_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.MANUALLY_EXECUTE_WORKLOAD),
  [WorkloadManagerClientActionType.MANUALLY_EXECUTE_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.MANUALLY_EXECUTE_WORKLOAD),
  [WorkloadManagerClientActionType.DRAFT_NEW_WORKLOAD_VERSION_SUCCESS]:
    userActionHandler(EventTypes.DRAFT_NEW_WORKLOAD_VERSION),
  [WorkloadManagerClientActionType.DRAFT_NEW_WORKLOAD_VERSION_FAILED]:
    errorActionHandler(EventTypes.DRAFT_NEW_WORKLOAD_VERSION),
  [WorkloadManagerClientActionType.RELEASE_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.RELEASE_WORKLOAD),
  [WorkloadManagerClientActionType.RELEASE_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.RELEASE_WORKLOAD),
  [WorkloadManagerClientActionType.DECOMMISSION_WORKLOAD_SUCCESS]:
    userActionHandler(EventTypes.DECOMMISSION_WORKLOAD),
  [WorkloadManagerClientActionType.DECOMMISSION_WORKLOAD_FAILED]:
    errorActionHandler(EventTypes.DECOMMISSION_WORKLOAD),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_CONFIGURATION_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_WORKLOAD_CONFIGURATION),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_CONFIGURATION_FAILED]:
    errorActionHandler(EventTypes.UPDATE_WORKLOAD_CONFIGURATION),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED]:
    errorActionHandler(EventTypes.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CONFIGURATION_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_CONFIGURATION),
  [WorkloadManagerClientActionType.GET_WORKLOAD_CONFIGURATION_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_CONFIGURATION),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_DESCRIPTION_SUCCESS]:
    userActionHandler(EventTypes.EDIT_WORKLOAD_DESCRIPTION),
  [WorkloadManagerClientActionType.EDIT_WORKLOAD_DESCRIPTION_FAILED]:
    errorActionHandler(EventTypes.EDIT_WORKLOAD_DESCRIPTION),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_CONFIGURATION_KEYS_SUCCESS]:
    userActionHandler(EventTypes.DELETE_WORKLOAD_CONFIGURATION_KEYS),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_CONFIGURATION_KEYS_FAILED]:
    errorActionHandler(EventTypes.DELETE_WORKLOAD_CONFIGURATION_KEYS),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS]:
    userActionHandler(EventTypes.DELETE_WORKLOAD_CONFIGURATION_BY_KEY),
  [WorkloadManagerClientActionType.DELETE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED]:
    errorActionHandler(EventTypes.DELETE_WORKLOAD_CONFIGURATION_BY_KEY),
  [WorkloadManagerClientActionType.ADD_WORKLOAD_TRIGGER_SUCCESS]:
    userActionHandler(EventTypes.ADD_WORKLOAD_TRIGGER),
  [WorkloadManagerClientActionType.ADD_WORKLOAD_TRIGGER_FAILED]:
    errorActionHandler(EventTypes.ADD_WORKLOAD_TRIGGER),
  [WorkloadManagerClientActionType.GET_WORKLOAD_TRIGGERS_SUCCESS]:
    userActionHandler(EventTypes.GET_WORKLOAD_TRIGGERS),
  [WorkloadManagerClientActionType.GET_WORKLOAD_TRIGGERS_FAILED]:
    errorActionHandler(EventTypes.GET_WORKLOAD_TRIGGERS),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_TRIGGER_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_WORKLOAD_TRIGGER),
  [WorkloadManagerClientActionType.UPDATE_WORKLOAD_TRIGGER_FAILED]:
    errorActionHandler(EventTypes.UPDATE_WORKLOAD_TRIGGER),
};

const queryDatabaseActionHandlers: ActionHandlers = {
  [QueryDatabasesClientActionType.GET_QUERY_DATABASE_SUCCESS]:
    userActionHandler(EventTypes.GET_QUERY_DATABASE),
  [QueryDatabasesClientActionType.GET_QUERY_DATABASE_FAILED]:
    errorActionHandler(EventTypes.GET_QUERY_DATABASE),
  [QueryDatabasesClientActionType.GET_QUERY_DATABASE_TABLES_SUCCESS]:
    userActionHandler(EventTypes.GET_QUERY_DATABASE_TABLES),
  [QueryDatabasesClientActionType.GET_QUERY_DATABASE_TABLES_FAILED]:
    errorActionHandler(EventTypes.GET_QUERY_DATABASE_TABLES),
};

const digitalShadowActionHandlers: ActionHandlers = {
  [DigitalShadowClientActionType.CREATE_DS_TYPE_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DS_TYPE),
  [DigitalShadowClientActionType.CREATE_DS_TYPE_FAILED]:
    errorActionHandler(EventTypes.CREATE_DS_TYPE),
  [DigitalShadowClientActionType.UPDATE_DS_TYPE_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_DS_TYPE),
  [DigitalShadowClientActionType.UPDATE_DS_TYPE_FAILED]:
    errorActionHandler(EventTypes.UPDATE_DS_TYPE),
  [DigitalShadowClientActionType.DELETE_DS_TYPE_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DS_TYPE),
  [DigitalShadowClientActionType.DELETE_DS_TYPE_FAILED]:
    errorActionHandler(EventTypes.DELETE_DS_TYPE),
  [DigitalShadowClientActionType.CREATE_DS_LAYOUT_SUCCESS]:
    userActionHandler(EventTypes.CREATE_DS_LAYOUT),
  [DigitalShadowClientActionType.CREATE_DS_LAYOUT_FAILED]:
    errorActionHandler(EventTypes.CREATE_DS_LAYOUT),
  [DigitalShadowClientActionType.UPDATE_DS_LAYOUT_SUCCESS]:
    userActionHandler(EventTypes.UPDATE_DS_LAYOUT),
  [DigitalShadowClientActionType.UPDATE_DS_LAYOUT_FAILED]:
    errorActionHandler(EventTypes.UPDATE_DS_LAYOUT),
  [DigitalShadowClientActionType.DELETE_DS_LAYOUT_SUCCESS]:
    userActionHandler(EventTypes.DELETE_DS_LAYOUT),
  [DigitalShadowClientActionType.DELETE_DS_LAYOUT_FAILED]:
    errorActionHandler(EventTypes.DELETE_DS_LAYOUT),
};

const handlers: ActionHandlers = {
  ...commonActionHandlers,
  ...loginActionHandlers,
  ...namespaceActionHandlers,
  ...policyActionHandlers,
  ...securityGroupActionHandlers,
  ...schemaActionHandlers,
  ...deviceTypeActionHandlers,
  ...deviceEnrollmentActionHandlers,
  ...deviceConfigurationActionHandlers,
  ...groupActionHandlers,
  ...userActionHandlers,
  ...securityServicesRegionalActionHandlers,
  ...emailTemplateActionHandlers,
  ...profileAttributeDefinitionActionHandlers,
  ...applicationsRegionalActionHandlers,
  ...federationProviderActionHandlers,
  ...auditEventActionHandlers,
  ...dataCatalogActionHandlers,
  ...uiPackageActionHandlers,
  ...dataLakeActionHandlers,
  ...adminUsersActionHandlers,
  ...dataProcessingActionHandlers,
  ...queryDatabaseActionHandlers,
  ...digitalShadowActionHandlers,
};

export function AnalyticsReducer(action: Action): AnalyticsEvent | null {

  const { type = "" } = action || {};

  if (isEmptyString(type)) {
    return null;
  }

  const handler = handlers[type] || (() => null);

  const event = handler(action);

  if (event === null) {
    return null;
  }

  const analyticEvent = event as AnalyticsEvent;

  if (isEmptyString(analyticEvent.category) || isEmptyString(analyticEvent.action)) {
    return null;
  }

  return analyticEvent;
}
