import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { resultsView as styles } from "./styles";
import { Results } from "./Results";
import { Header } from "./Header";
import { ErrorView } from "@components";

export interface Model {
  className?: string;
  message?: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
  showLoadingIndicator?: boolean;
  statusCode?: number;
}

type Props = WithStyles<typeof styles> & Model;

export const ResultsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    message,
    showErrorMessage,
    showSuccessMessage,
    showLoadingIndicator,
    statusCode,
  } = props;

  return (
    <div className={classnames("resultsView", className, classes.container)}>
      <Header
        className={classes.header}
        title="Validation Results"
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showLoadingIndicator={showLoadingIndicator}
      />
      <ErrorView statusCode={statusCode} message={showErrorMessage ? message : ""} />
      {showSuccessMessage && (
        <Results
          className={classes.results}
          message={message}
        />
      )}
    </div>
  );
});

export default ResultsView;
