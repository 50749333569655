import React from "react";
import classnames from "classnames";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";

export interface CustomMuiTooltipProps extends MuiTooltipProps {}

export const Tooltip = ((props: CustomMuiTooltipProps) => {

    const {
      className,
      children,
      ...otherProps
    } = props;

    return (
      <MuiTooltip
        {...otherProps}
        className={classnames("tooltip", className)}
      >
        {children}
      </MuiTooltip>
    );
});

export default Tooltip;
