import React from "react";
import Vector from "@data/Vector";
import Fab from "@components/fab";
import classnames from "classnames";
import InfoIcon from "@material-ui/icons/Help";
import { Tooltip } from "@components/tooltip/Tooltip";
import { tooltip, tooltipIcon } from "./styles";
import { IconButton } from "@components/icon-button";
import { PopperProps } from "@material-ui/core/Popper";
import { isEmptyString, noopClickHandler } from "@util";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const IconButtonTooltip = withStyles(tooltip)(Tooltip);

export const TooltipIcon = withStyles(tooltipIcon)((props: WithStyles<typeof tooltipIcon> & {
  className?: string,
  tooltipText?: string,
  disableTouchRipple?: boolean,
  disabled?: boolean,
  icon?: React.ComponentType<SvgIconProps>,
  style?: React.CSSProperties,
  PopperProps?: Partial<PopperProps>,
  color?: string,
  size?: Vector,
  onClick?: () => void,
  useFab?: boolean,
}) => {

  const {
    classes,
    className,
    tooltipText = "",
    useFab = false,
    disableTouchRipple = true,
    disabled = false,
    icon: Icon = InfoIcon,
    style = {},
    color,
    size = Vector.square(15),
    onClick = noopClickHandler,
    PopperProps: popperProps = { className: "" },
    ...otherProps
  } = props;

  if (isEmptyString(tooltipText)) {
    return null;
  }

  const iconStyle = {
    width: size.x,
    height: size.y,
  };

  const iconButtonStyle = {
    ...iconStyle,
    ...style,
  };

  const { className: popperClassName } = popperProps;

  const Button = useFab ? Fab : IconButton;

  return (
    <IconButtonTooltip
      className={classnames("tooltip", className)}
      disableFocusListener={true}
      title={tooltipText}
      PopperProps={{
        ...popperProps,
        className: classnames("tooltipIconPopper", popperClassName, classes.tooltipIconPopper)
      }}
    >
      <Button
        {...otherProps}
        className={classnames("tooltipIconButton", className, classes.tooltipIconButton)}
        style={iconButtonStyle}
        tabIndex={-1}
        disabled={disabled}
        disableTouchRipple={disableTouchRipple}
        onClick={onClick}
      >
        <Icon
          className={classnames("tooltipIcon", classes.tooltipIcon)}
          htmlColor={color}
          style={iconStyle}
        />
      </Button>
    </IconButtonTooltip>
  );
});

export default TooltipIcon;
