export enum VariablesListColumn {
  NONE = "",
  NAME = "Name",
  DESCRIPTION = "Description",
  REQUIRED = "Required",
  TYPE = "Type",
  ACTIONS = "Actions",
  VALUE = "Value",
}

export default VariablesListColumn;
