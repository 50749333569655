import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    editorTabs: {
      flex: "0 0 auto",
    },
    codeEditor: {
      flex: "1 0 auto",
    },
    emptyView: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      padding: "24px 48px",
      border: `1px solid ${Color.LIGHT_GREY}`,
    },
    emptyViewTitle: {
      marginTop: 100,
      color: Color.TEXT,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "22px",
    },
    emptyViewSubtitle: {
      marginTop: 16,
      color: Color.GREY3,
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "18px",
    },
  });

export default styles;