import React from "react";
import { RestClientError } from "@network";
import { isEmptyString, noop } from "@util";
import { AuthTokenContext } from "@components";

export interface UseApiRequestProps<SuccessResponse = any, ErrorResponse extends RestClientError = RestClientError> {
  deferRequest?: boolean;
  makeApiRequest: (accessToken: string) => Promise<SuccessResponse>;
  defaultErrorMessage?: string;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  onSuccess?: (response: SuccessResponse) => void;
}

export interface UseApiRequestModel<SuccessResponse = any, ErrorResponse extends RestClientError = RestClientError> {
  errorMessage: string;
  errors?: string[];
  showErrorView: boolean;
  showAccessDenied: boolean;
  showNotFound: boolean;
  showLoadingIndicator: boolean;
  showSuccessView: boolean;
  statusCode?: number;
  successResponse?: SuccessResponse;
  errorResponse?: ErrorResponse;
}

export interface UseApiRequestActions<SuccessResponse = any, ErrorResponse extends RestClientError = RestClientError> {
  refresh: () => void;
  reset: () => void;
}

type Props<S, E extends RestClientError> = UseApiRequestProps<S, E>;
type Model<S, E extends RestClientError> = UseApiRequestModel<S, E>;
type Actions<S, E extends RestClientError> = UseApiRequestActions<S, E>;
type Result<S, E extends RestClientError> = [Model<S, E>, Actions<S, E>];

export const useApiRequest = <S, E extends RestClientError = RestClientError>(props: Props<S, E>): Result<S, E> => {

  const {
    deferRequest,
    makeApiRequest,
    defaultErrorMessage = "Oops, something went wrong. Please refresh the page and try again.",
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    onSuccess,
  } = props;

  const authToken = React.useContext(AuthTokenContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errors, setErrors] = React.useState<string[]>([]);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(!deferRequest);
  const [showSuccessView, setShowSuccessView] = React.useState(false);
  const [successResponse, setSuccessResponse] = React.useState<S>();
  const [errorResponse, setErrorResponse] = React.useState<E>();

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const reset = React.useCallback(() => {
    setErrorMessage("");
    setErrors([]);
    setShowAccessDenied(false);
    setShowNotFound(false);
    setShowLoadingIndicator(false);
    setShowSuccessView(false);
  }, [
    setShowSuccessView,
    setShowLoadingIndicator,
    setShowAccessDenied,
    setShowNotFound,
    setErrors,
    setErrorMessage,
  ]);

  const refresh = React.useCallback(() => {
    reset();
    setShowLoadingIndicator(true);
  }, [reset, setShowLoadingIndicator]);

  const apiRequest = React.useCallback(() => {
    trackRequestEvent();
    return makeApiRequest(authToken)
      .then((s: S) => {
        trackSuccessEvent();
        return Promise.resolve(s);
      }, (e: E) => {
        const { analytic } = e;
        trackErrorEvent(analytic);
        return Promise.reject(e);
      });
  }, [
    trackRequestEvent,
    makeApiRequest,
    authToken,
    trackSuccessEvent,
    trackErrorEvent,
  ]);

  React.useEffect(() => {

    let ignore = false;

    if (showLoadingIndicator) {

      apiRequest()
        .then((response: S) => {
          if (!ignore) {
            setShowLoadingIndicator(false);
            setSuccessResponse(response);
            setShowSuccessView(true);
            if (onSuccess) {
              onSuccess(response);
            }
          }
        }, (response: E) => {
          if (!ignore) {
            const { status, error = defaultErrorMessage, errors: responseErrors = [] } = response;
            setShowLoadingIndicator(false);
            setErrorResponse(response);
            if (status === 403) {
              setShowAccessDenied(true);
            } else if (status === 404) {
              setShowNotFound(true);
            }
            setErrors(responseErrors);
            setErrorMessage(error || defaultErrorMessage || "Request Failed");
          }
        });
    }

    return () => { ignore = true; };

  }, [
    showLoadingIndicator,
  ]);

  const statusCode = React.useMemo(() =>
    showErrorView ? (errorResponse ? errorResponse.status : undefined) : undefined, [showErrorView, errorResponse]);

  const model = React.useMemo<Model<S, E>>(() => ({
    errorMessage,
    errors,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showLoadingIndicator,
    showSuccessView,
    ...(showLoadingIndicator ? ({}) : ({
      ...(!showSuccessView ? ({}) : ({ successResponse })),
      ...(!showErrorView ? ({}) : ({ errorResponse })),
    })),
    statusCode,
  }), [
    errorMessage,
    errors,
    showErrorView,
    showAccessDenied,
    showNotFound,
    showLoadingIndicator,
    showSuccessView,
    successResponse,
    errorResponse,
    statusCode,
  ]);

  const actions = React.useMemo<Actions<S, E>>(() => ({
    refresh,
    reset,
  }), [
    refresh,
    reset,
  ]);

  return React.useMemo<Result<S, E>>(() => [model, actions], [model, actions]);
};

export default useApiRequest;
