import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { getCurrentAccountId } from "@main/selectors";
import { SchemasList, SchemasListColumn } from "@components/schemas-list";
import { useSchemaDefinition } from "@hooks";
import {
  DeviceEnrollmentWizardSelectors
} from "../../selectors";
import { JsonSchemaDefinition, JsonSchemaMetadata } from "@data";
import DeviceDataView, { Actions, Model } from "../../components/addDeviceWizard/DeviceDataView";
import DataValidationDialog from "../dataValidation/DataValidationDialog";
import {
  AddDeviceWizardActions,
  DeviceEnrollmentWizardActions,
} from "../../actions";
import { noop } from "@util";

export const DEFAULT_COLUMNS: SchemasListColumn[] = [
  SchemasListColumn.NAMESPACE,
  SchemasListColumn.NAME,
  SchemasListColumn.VERSION,
  SchemasListColumn.STATE,
];

interface ContainerModel extends Model {
  hidden?: boolean;
  accountId?: string;
  items?: JsonSchemaMetadata[];
  selectedSchemaIds?: string[];
  deviceDataVisible?: boolean;
  isLoading?: boolean;
}

interface ContainerActions extends Actions {
  updateSchemaList?: (schemas: JsonSchemaMetadata[]) => void;
  setSchema?: (schema: JsonSchemaDefinition) => void;
  updateSchemaIdentity?: (identity: string) => void;
  setErrorMessage?: (message: string) => void;
  setDeviceDataDialog?: (value: boolean) => void;
}

const DeviceDataViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    accountId = "",
    selectedSchemaIds = [],
    items = [],
    isLoading = false,
    updateSchemaList = noop,
    updateSchemaIdentity = noop,
    setSchema = noop,
    setErrorMessage = noop,
    setDeviceDataDialog = noop,
    deviceDataVisible,
    ...otherProps
  } = props;

  const [schemaIdentity, setSchemaIdentity] = React.useState("");

  const namespace = schemaIdentity.split(":")[0] || "";
  const name = schemaIdentity.split(":")[1] || "";
  const version = schemaIdentity.split(":")[2] || "";

  const onSuccess = React.useCallback((schemaDefinition: JsonSchemaDefinition) => {
    setSchema(schemaDefinition);
    setDeviceDataDialog(true);
  }, [setSchema, setDeviceDataDialog]);

  const onFailure = React.useCallback((error: string) => {
   setErrorMessage(error);
  }, [setErrorMessage]);

  const [model, actions] = useSchemaDefinition({
    namespace,
    name,
    version,
    onSuccess,
    onFailure,
  });

  const {
    showProgressIndicator: loading,
    statusCode,
  } = model;

  const {
    refresh,
  } = actions;

  const selectedItems = React.useMemo(() =>
      items.filter(selectedSchema => selectedSchemaIds.indexOf(selectedSchema.getId()) >= 0),
    [items, selectedSchemaIds]);

  const showNoResultsView = React.useMemo(() =>
    items.length === 0,
    [items]);

  const setSelectedSchema = React.useCallback((schemas: JsonSchemaMetadata[]) => {
    updateSchemaList(schemas);
    const schemaItem = schemas.pop();
    if (schemaItem !== undefined) {
      setSchemaIdentity(schemaItem.getId());
      updateSchemaIdentity(schemaItem.getId());
      setSchema();
      setErrorMessage("");
      refresh();
    }
  }, [updateSchemaList, setSchemaIdentity, updateSchemaIdentity, setSchema, setErrorMessage, refresh]);

  const showBackDrop = React.useMemo(() =>
    isLoading || loading, [isLoading, loading]);

  if (hidden) {
    return null;
  }

  return (
    <DeviceDataView
      showBackDrop={showBackDrop}
      {...otherProps}
    >
      <SchemasList
        schemas={items}
        columns={DEFAULT_COLUMNS}
        hideSummary={true}
        hideSearch={true}
        showSearchFilters={false}
        selectable={true}
        selectedItems={selectedItems}
        selectAllDisabled={true}
        setSelectedItems={setSelectedSchema}
        showNoResultsView={showNoResultsView}
        noResultsLabel="No schemas found for the selected Device type"
        statusCode={statusCode}
      />
      {deviceDataVisible && <DataValidationDialog/>}
    </DeviceDataView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !DeviceEnrollmentWizardSelectors.isDataViewSelected(state),
  title: DeviceEnrollmentWizardSelectors.getDataViewTitle(state),
  accountId: getCurrentAccountId(state),
  items: DeviceEnrollmentWizardSelectors.getAvailableSchema(state),
  selectedSchemaIds: DeviceEnrollmentWizardSelectors.getSelectedSchemaIds(state),
  populateAllSchema: DeviceEnrollmentWizardSelectors.getPopulateAllSchemaValue(state),
  information: DeviceEnrollmentWizardSelectors.getDeviceDataInfo(state),
  actualViewSelected: DeviceEnrollmentWizardSelectors.isActualDeviceDataModeActive(state),
  desiredViewSelected: DeviceEnrollmentWizardSelectors.isDesiredDeviceDataModeActive(state),
  deviceDataVisible: DeviceEnrollmentWizardSelectors.isDeviceDataDialogVisible(state),
  isLoading: DeviceEnrollmentWizardSelectors.isLoadingIndicatorVisible(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  updateSchemaList: (schemas: JsonSchemaMetadata[]) => dispatch(
    AddDeviceWizardActions.updateSchemaList((schemas.map((schema: JsonSchemaMetadata) =>
      `${schema.namespace}:${schema.name}:${schema.version}`)))),
  updateSchemaIdentity: (identity: string) => dispatch(DeviceEnrollmentWizardActions.setSchemaIdentity(identity)),
  setSchema: (schema: JsonSchemaDefinition) => dispatch(DeviceEnrollmentWizardActions.setSchema(schema)),
  setDeviceDataDialog: (value: boolean) => dispatch(DeviceEnrollmentWizardActions.setDeviceDataDialog(value)),
  setErrorMessage: (message: string) => dispatch(DeviceEnrollmentWizardActions.setErrorMessage(message)),
  setActualDataView: () => dispatch(AddDeviceWizardActions.showActualData()),
  setDesiredDataView: () => dispatch(AddDeviceWizardActions.showDesiredData()),
  setPopulateAllSchemaValue: (value: boolean) => dispatch(AddDeviceWizardActions.updatePopulateAllSchema(value)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceDataViewContainer);
