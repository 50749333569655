import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { isEmptyString, noop } from "@util";
import { ManagedPolicyInfo, SummaryViewData } from "@data";
import { SummaryJsonDetailsView } from "@components";
import { useManagedPolicy } from "@hooks";
import {
  getAccountId,
  getJson,
  getPolicyName,
  getSummaryViewItems,
} from "../selectors";
import {
  fetchManagedPolicyDetailsFailed,
  fetchManagedPolicyDetailsRequest,
  fetchManagedPolicyDetailsSuccess,
  setManagedPolicy,
} from "../actions";
import StatementsView from "./StatementsView";
import ModuleIcon from "@material-ui/icons/AssignmentTurnedIn";

interface ContainerModel {
  policyName?: string;
  accountId?: string;
  json?: string;
  items?: SummaryViewData[];
  showProgressIndicator?: boolean;
  showErrorView?: boolean;
  errorMessage?: string;
}

interface ContainerActions {
  setPolicy?: (policy: ManagedPolicyInfo) => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
}

type Props = ContainerModel & ContainerActions;

const ManagedPolicyDetailsContainer = (props: Props) => {

  const {
    policyName,
    accountId,
    json,
    setPolicy = noop,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
    items,
  } = props;

  const onSuccess = React.useCallback((policy: ManagedPolicyInfo) =>
    setPolicy(policy), [setPolicy]);

  const [model, { refresh }] = useManagedPolicy({
    policyName,
    accountId,
    onSuccess,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
  });

  const { showProgressIndicator, showNotFound, showAccessDenied, errorMessage, statusCode } = model;

  const policyJson = React.useMemo(() =>
    !showProgressIndicator ? json : JSON.stringify(ManagedPolicyInfo.EMPTY.toJS(), null, "  "),
    [showProgressIndicator, json]);

  const StatementView = React.useMemo(() => (
    <StatementsView/>
  ), []);

  return (
    <SummaryJsonDetailsView
      title={policyName}
      icon={ModuleIcon}
      refresh={refresh}
      showLoadingIndicator={showProgressIndicator}
      summaryViewItems={items}
      additionalViews={StatementView}
      json={policyJson}
      fileName={`${policyName}.json`}
      showJsonHeader={!isEmptyString(policyName)}
      jsonViewClassName={"managedPolicyJsonView"}
      errorMessage={errorMessage}
      showNotFound={showNotFound}
      showAccessDenied={showAccessDenied}
      statusCode={statusCode}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  policyName: getPolicyName(state),
  accountId: getAccountId(state),
  json: getJson(state),
  items: getSummaryViewItems(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setPolicy: (policy: ManagedPolicyInfo) => dispatch(setManagedPolicy(policy)),
  trackRequestEvent: () => dispatch(fetchManagedPolicyDetailsRequest()),
  trackSuccessEvent: () => dispatch(fetchManagedPolicyDetailsSuccess()),
  trackErrorEvent: analytic => dispatch(fetchManagedPolicyDetailsFailed(analytic)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ManagedPolicyDetailsContainer);
