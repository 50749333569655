import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { noop } from "@util";
import { useGetSchema } from "@hooks";
import { PrimaryIcon } from "../styles";
import { SchemaAction } from "@modules/schemaStateManager/reducers";
import { JsonSchemaMetadata, JsonSchemaState, SummaryViewData } from "@data";
import SchemaValidationView from "./SchemaValidationView";
import SchemaStateManagerActions from "@modules/schemaStateManager/actions";
import { isSuccessMessageVisible } from "@modules/schemaStateManager/selectors";
import {
  ActionMenuItem,
  DetailsViewRoute,
  SummaryJsonDetailsView,
  SchemaProperties as SummaryViewTable
} from "@components";

// Each item starts off disabled and will switch depending on state of active schema
export const DEFAULT_ACTION_MENU_ITEMS: ActionMenuItem[] = [
  {
    id: SchemaAction.EDIT,
    name: "Edit Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DRAFT,
    name: "Draft New Schema Version",
    disabled: true,
  },
  {
    id: SchemaAction.PROMOTE,
    name: "Promote Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DEPRECATE,
    name: "Deprecate Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DECOMMISSION,
    name: "Decommission Schema",
    disabled: true,
  },
  {
    id: SchemaAction.DELETE,
    name: "Delete Schema",
    disabled: true,
  },
];

interface ContainerModel {
  name: string;
  version: string;
  namespace: string;
  showStateChangeSuccessView?: boolean;
}

interface ContainerActions {
  editSchema?: (schema: JsonSchemaMetadata) => void;
  draftNewSchemaVersion?: (schema: JsonSchemaMetadata) => void;
  promoteSchema?: (schema: JsonSchemaMetadata) => void;
  deprecateSchema?: (schema: JsonSchemaMetadata) => void;
  decommissionSchema?: (schema: JsonSchemaMetadata) => void;
  deleteSchema?: (schema: JsonSchemaMetadata) => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const SchemaDetailsContainer = (props: Props) => {

  const {
    name,
    version,
    namespace,
    showStateChangeSuccessView = false,
    draftNewSchemaVersion: draft = noop,
    promoteSchema: promote = noop,
    deprecateSchema: deprecate = noop,
    decommissionSchema: decommission = noop,
    deleteSchema: deleteSchemaAction = noop,
    editSchema = noop,
    onClickAction = noop,
  } = props;

  const [{
    schemaMetadata: schema = JsonSchemaMetadata.EMPTY,
    json,
    isLatest,
    showLoadingIndicator,
    errorMessage,
    showNotFound,
    ...otherModel
  }, { refresh }] = useGetSchema({ name, version, namespace });

  const schemaState = React.useMemo(() => schema.state, [schema]);

  const isDraftSchema = React.useMemo(() => schemaState === JsonSchemaState.DRAFT, [schemaState]);
  const isReleasedSchema = React.useMemo(() => schemaState === JsonSchemaState.RELEASED, [schemaState]);
  const isDeprecatedSchema = React.useMemo(() => schemaState === JsonSchemaState.DEPRECATED, [schemaState]);
  const isDecommissionedSchema = React.useMemo(() => schemaState === JsonSchemaState.DECOMMISSIONED, [schemaState]);

  const isDraftButtonEnabled = React.useMemo(() => isLatest && isReleasedSchema,
    [isLatest, isReleasedSchema]);

  const isPromoteButtonEnabled = React.useMemo(() => isDraftSchema || isDeprecatedSchema,
    [isDraftSchema, isDeprecatedSchema]);

  const isDeprecateButtonEnabled = React.useMemo(() => isReleasedSchema || isDecommissionedSchema,
    [isReleasedSchema, isDecommissionedSchema]);

  const isDecommissionButtonEnabled = React.useMemo(() => isDeprecatedSchema,
    [isDeprecatedSchema]);

  const isDeleteButtonEnabled = React.useMemo(() => isDraftSchema || isDecommissionedSchema,
    [isDraftSchema, isDecommissionedSchema]);

  const actions = React.useMemo(() => {
    return DEFAULT_ACTION_MENU_ITEMS.map((action: ActionMenuItem) => {
      switch (action.id) {
        case SchemaAction.EDIT:
          return {
            ...action,
            disabled: !isDraftSchema,
          };
        case SchemaAction.DRAFT:
          return {
            ...action,
            disabled: !isDraftButtonEnabled,
          };
        case SchemaAction.PROMOTE:
          return {
            ...action,
            disabled: !isPromoteButtonEnabled,
          };
        case SchemaAction.DEPRECATE:
          return {
            ...action,
            disabled: !isDeprecateButtonEnabled,
          };
        case SchemaAction.DECOMMISSION:
          return {
            ...action,
            disabled: !isDecommissionButtonEnabled,
          };
        case SchemaAction.DELETE:
          return {
            ...action,
            disabled: !isDeleteButtonEnabled,
          };
        default:
          return action;
      }
    });
  }, [
    isDraftSchema,
    isDraftButtonEnabled,
    isPromoteButtonEnabled,
    isDeprecateButtonEnabled,
    isDecommissionButtonEnabled,
    isDeleteButtonEnabled
  ]);

  const onClickSchemaAction = React.useCallback((schemaAction: ActionMenuItem) => {
    switch (schemaAction.id) {
      case SchemaAction.EDIT:
        return editSchema(schema);
      case SchemaAction.DRAFT:
        return draft(schema);
      case SchemaAction.PROMOTE:
        return promote(schema);
      case SchemaAction.DEPRECATE:
        return deprecate(schema);
      case SchemaAction.DECOMMISSION:
        return decommission(schema);
      case SchemaAction.DELETE:
        return deleteSchemaAction(schema);
      default:
        onClickAction(schemaAction);
    }
  }, [
    schema,
    editSchema,
    draft,
    promote,
    deprecate,
    decommission,
    deleteSchemaAction,
    onClickAction,
  ]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "namespace",
      name: "Namespace",
      value: schema.getNamespace(),
    }),
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: schema.getName(),
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: schema.getVersionAsString(),
    }),
    new SummaryViewData({
      className: "state",
      name: "State",
      value: schema.getState(),
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: schema.getDescription(),
    }),
    new SummaryViewData({
      className: "createdAt",
      name: "Created At",
      value: schema.getCreatedAt(),
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: schema.getCreatedBy(),
    }),
    new SummaryViewData({
      className: "updatedAt",
      name: "Updated At",
      value: schema.getUpdatedAt(),
    }),
    new SummaryViewData({
      className: "updatedBy",
      name: "Updated By",
      value: schema.getUpdatedBy(),
    }),
    new SummaryViewData({
      className: "location",
      name: "Location",
      value: schema.getLocation(),
    }),
    new SummaryViewData({
      className: "etag",
      name: "ETag",
      value: schema.getEtag(),
    }),
  ], [schema]);

  const tableView = React.useMemo<React.ReactElement>(() => {
    return (
      <SummaryViewTable
        className={"schemaProperties"}
        title={"Properties"}
        refreshEnabled={false}
        location={schema.location}
        noResultsLabel={showLoadingIndicator ? "Loading..." : "No Properties Defined"}
      />
    );
  }, [schema, showLoadingIndicator]);

  const validationView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SchemaValidationView
        name={name}
        schema={json}
        version={version}
        namespace={namespace}
        showLoadingIndicator={showLoadingIndicator}
      />
    );
  }, [name, version, namespace, json, showLoadingIndicator]);

  const ADDITIONAL_ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "validationView",
      name: "Validate",
      path: "/validate",
      view: validationView,
    },
  ], [validationView]);

  React.useEffect(() => {
    if (showStateChangeSuccessView) {
      refresh();
    }
  }, [showStateChangeSuccessView, refresh]);

  return (
    <SummaryJsonDetailsView
      {...otherModel}
      title={name}
      icon={PrimaryIcon}
      refresh={refresh}
      showLoadingIndicator={showLoadingIndicator}
      errorMessage={errorMessage}
      actions={actions}
      onClickAction={onClickSchemaAction}
      summaryViewItems={summaryViewItems}
      additionalViews={tableView}
      json={json}
      fileName={`${name}.json`}
      additionalRoutes={ADDITIONAL_ROUTES}
      showNotFound={showNotFound}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  showStateChangeSuccessView: isSuccessMessageVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  draftNewSchemaVersion:
    (schema) => dispatch(SchemaStateManagerActions.openDraftNewSchemaVersionDialog(schema)),
  promoteSchema:
    (schema) => dispatch(SchemaStateManagerActions.openPromoteSchemaDialog(schema)),
  deprecateSchema:
    (schema) => dispatch(SchemaStateManagerActions.openDeprecateSchemaDialog(schema)),
  decommissionSchema:
    (schema) => dispatch(SchemaStateManagerActions.openDecommissionSchemaDialog(schema)),
  deleteSchema:
    (schema) => dispatch(SchemaStateManagerActions.openDeleteSchemaDialog(schema)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaDetailsContainer);
