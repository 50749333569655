import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./PolicyDetailsView";

type RouteParams = { id?: string };

export const policyDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: true,
  name: "Policy Details",
  title: "Policy Details",
  className: "policyDetailsModule",
  primaryColor,
  icon,
  view,
  initialize,
  onMount,
});

export default policyDetailsModule;
