import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import Paper from "@material-ui/core/Paper";
import ActionMenuItem from "./ActionMenuItem";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "../tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { swallow, getStringValue, noop } from "@util";
import { nestedActionsMenuItem as styles } from "./styles";
import ExpandIcon from "@material-ui/icons/KeyboardArrowRight";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export const INITIAL_OPEN_NESTED_MENU_DELAY_MS = 250;

export interface NestedActionsMenuItemProps {
  className?: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  actions?: ActionMenuItem[];
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = WithStyles<typeof styles> & NestedActionsMenuItemProps & {
  children?: React.ReactNode;
};

export const NestedActionsMenuItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    label,
    tooltip,
    disabled,
    actions = [],
    onClickAction = noop,
    onMenuOpen = noop,
    onMenuClose = noop,
    children,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [initialized, setInitialized] = React.useState(false);

  const open = React.useMemo(() => Boolean(anchorEl) && initialized, [anchorEl, initialized]);

  const visibleActions = React.useMemo(() =>
    actions.filter(action => !action.hidden), [actions]);

  const onMouseEnter = React.useCallback(event => {
    if (!disabled) {
      if (initialized) {
        onMenuOpen();
      }
      if (anchorEl === null && event) {
        setAnchorEl(event.currentTarget || event.relatedTarget || event.target);
      }
    }
  }, [initialized, disabled, anchorEl, setAnchorEl, onMenuOpen]);

  const onMouseLeave = React.useCallback(() => {
    onMenuClose();
    setAnchorEl(null);
  }, [setAnchorEl, onMenuClose]);

  const closeMenu = React.useCallback(() => {
    onMouseLeave();
  }, [onMouseLeave]);

  // Wait 250ms before opening a nested menu so that it does not open before the root menu is opened
  // in the event that the user's mouse triggers an onMouseEnter event before the initial transition
  // animation is complete.
  React.useEffect(() => {

    if (initialized) {
      return noop;
    }

    const timer = setTimeout(() => {
      setInitialized(true);
    }, INITIAL_OPEN_NESTED_MENU_DELAY_MS);

    return () => clearTimeout(timer);

  }, [initialized, setInitialized]);

  React.useEffect(() => {
    if (open && !disabled) {
      onMenuOpen();
    } else {
      onMenuClose();
    }
  }, [open, disabled, onMenuOpen, onMenuClose]);

  return (
    <div
      className={classnames("nestedActionsMenuItem", className, classes.container)}
      onClick={event => {
        swallow(event);
        if (open) {
          onMouseLeave();
        } else {
          onMouseEnter(event);
        }
      }}
      onFocus={onMouseEnter}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Tooltip
        title={getStringValue(tooltip)}
        onOpen={onMouseEnter}
      >
        <label
          className={classnames("label", classes.label, {
            [classes.disabled]: disabled,
          })}
        >
          {label}
        </label>
      </Tooltip>
      <ExpandIcon
        className={classnames("icon", classes.icon, {
          [classes.disabled]: disabled,
        })}
      />
      <Popper
        className={classnames("nestedActionsMenuItemPopover", "popover", classes.popover)}
        anchorEl={anchorEl}
        open={open}
        placement={"right-start"}
        onMouseEnter={onMouseEnter}
        role={"menu"}
      >
        <Paper
          className={classnames("nestedActionsMenuItemPaper", "paper", classes.paper)}
          onMouseEnter={onMouseEnter}
          elevation={8}
        >
          <MenuList
            className={classnames("nestedActionsMenuItemMenuList", "menuList", "menu", classes.menuList)}
            variant="menu"
            onMouseLeave={onMouseLeave}
            autoFocus={true}
            autoFocusItem={true}
          >
            {visibleActions.map(action => {

              const {
                id: childId,
                name,
                tooltip: childTooltip,
                disabled: childDisabled,
                actions: childActions = [],
              } = action;

              const [subMenuOpen, setSubMenuOpen] = React.useState(false);

              const nestedActions = React.useMemo(() => childActions.length > 0, [childActions]);

              const onChildMenuOpen = React.useCallback(() => setSubMenuOpen(true), [setSubMenuOpen]);

              const onChildMenuClose = React.useCallback(() => setSubMenuOpen(false), [setSubMenuOpen]);

              const onClickMenuItem = React.useCallback(() => {
                closeMenu();
                onClickAction(action);
              }, [action, closeMenu, onClickAction]);

              const onClickNestedActionsMenuItem = React.useCallback(clickedAction => {
                closeMenu();
                onClickAction(clickedAction);
              }, [closeMenu, onClickAction]);

              return (
                <MenuItem
                  key={childId}
                  className={classnames("menuItem", camelCase(childId), classes.menuItem, {
                    // material-ui styles cause tooltips to not work on disabled actions without this
                    [classes.overridePointerEvents]: (childTooltip && childDisabled),
                    [classes.subMenuOpen]: nestedActions && subMenuOpen,
                  })}
                  disabled={childDisabled}
                  button={true}
                  onClick={nestedActions ? undefined : onClickMenuItem}
                >
                  {!nestedActions && (
                    <Tooltip title={getStringValue(childTooltip)}>
                      <label
                        className={classnames("menuItemLabel", classes.menuItemLabel, {
                          [classes.disabled]: childDisabled,
                        })}
                      >
                        {name}
                      </label>
                    </Tooltip>
                  )}
                  {nestedActions && (
                    <NestedActionsMenuItem
                      key={childId}
                      className={classnames("nestedActionsMenuItem", classes.nestedActionsMenuItem)}
                      label={name}
                      tooltip={childTooltip}
                      disabled={childDisabled}
                      actions={childActions}
                      onClickAction={onClickNestedActionsMenuItem}
                      onMenuOpen={onChildMenuOpen}
                      onMenuClose={onChildMenuClose}
                    />
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </Popper>
      {children}
    </div>
  );
});

export default NestedActionsMenuItem;
