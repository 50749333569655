// *************************************************************************************************
// LOCAL STORAGE KEYS
// *************************************************************************************************

export const KEY_NAMESPACE = "com.signify.sst.portal.store.";

// Legacy key used in v27 and below
export const KEY_APP_STATE = KEY_NAMESPACE + "APP_STATE";

export const KEY_VERSION_CODE = KEY_NAMESPACE + "VERSION_CODE";

export const KEY_SAVED_USERNAME = KEY_NAMESPACE + "SAVED_USERNAME";
export const KEY_SAVED_ACCOUNT = KEY_NAMESPACE + "SAVED_ACCOUNT";

export const KEY_SAVED_SERVICE_ID = KEY_NAMESPACE + "SAVED_SERVICE_ID";
export const KEY_SAVED_SERVICE_ACCOUNT_ID = KEY_NAMESPACE + "SAVED_SERVICE_ACCOUNT_ID";

export const KEY_LAST_MOUSE_MOVE = KEY_NAMESPACE + "LAST_MOUSE_MOVE";
export const KEY_ACCOUNT_ID = KEY_NAMESPACE + "ACCOUNT_ID";
export const KEY_PRINCIPAL_ID = KEY_NAMESPACE + "PRINCIPAL_ID";
export const KEY_IDENTITY_TYPE = KEY_NAMESPACE + "IDENTITY_TYPE";
export const KEY_ACCESS_TOKEN_EXPIRY_TIME = KEY_NAMESPACE + "ACCESS_TOKEN_EXPIRY_TIME";

export const KEY_FAVORITES = KEY_NAMESPACE + "FAVORITES";

export const KEY_CLOSED_MAINTENANCE_REMINDERS = KEY_NAMESPACE + "CLOSED_MAINTENANCE_REMINDERS";

// *************************************************************************************************
// SESSION STORAGE KEYS
// *************************************************************************************************

export const KEY_ACCESS_TOKEN = KEY_NAMESPACE + "ACCESS_TOKEN";
export const KEY_REFRESH_TOKEN = KEY_NAMESPACE + "REFRESH_TOKEN";
export const KEY_NONCE = KEY_NAMESPACE + "NONCE";
export const KEY_LAST_IDENTITY_TYPE = KEY_NAMESPACE + "LAST_IDENTITY_TYPE";

// *************************************************************************************************
// BROADCAST CHANNELS
// *************************************************************************************************

export const BROADCAST_CHANNEL = "PORTAL";
export const BROADCAST_CHANNEL_SERVICE = `${BROADCAST_CHANNEL}:SERVICE`;
export const BROADCAST_CHANNEL_SERVICE_RESTORE_SESSION = `${BROADCAST_CHANNEL_SERVICE}:RESTORE_SESSION`;
export const BROADCAST_CHANNEL_END_SESSION = `${BROADCAST_CHANNEL}:END_SESSION`;

// *************************************************************************************************
// BROADCAST ACTIONS
// *************************************************************************************************

export const BROADCAST_ACTION_PING = "PING";
export const BROADCAST_ACTION_LOGOUT = "LOGOUT";
