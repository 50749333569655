import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { TriggerType, Trigger } from "@data";
import ErrorView from "@components/error-view";
import { isEmptyString, isValidInteger } from "@util";
import { getAddTriggerPath, getEditTriggerPath } from "@modules/dataWorkloadTriggerWizard/helpers";
import { CreateWorkloadRequest } from "@data/CreateWorkloadRequest";
import useGetWorkload from "@hooks/workloadsManager/use-get-workload";
import { getCloneWorkloadPath } from "@modules/dataWorkloadWizard/helpers";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import DataWorkloadDetailsLayout from "./containers/DataWorkloadDetailsLayout";
import { getPathToSwitchPrincipal, getPathToWorkload, getPathToWorkloadExecutionHistory } from "./helpers";
import { MODULE_PATH as WORKLOADS_LIST_PATH } from "@modules/dataWorkloads/constants";
import { getPathToWorkloadInstance } from "@modules/dataWorkloadInstanceDetails/helpers";
import { LATEST_VERSION_MODULE_PATH, LEGACY_MODULE_PATH, RouteParams } from "./constants";
import DataSetsListItemData from "@components/data-sets-list/models/DataSetsListItemData";

function DetailsView() {

  const history = useHistory();

  const { name, version: versionParam } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [ versionParam ]);

  const showDataSetDetails = React.useCallback((item: DataSetsListItemData) =>
    history.push(item.getHref()), [ history ]);

  const showDataSetDetailsInNewTab = React.useCallback((item: DataSetsListItemData) =>
    item.getHref(), []);

  const showWorkloadExecutionDetails = React.useCallback((workloadName: string,
                                                          workloadVersion: number,
                                                          executionId: string) =>
      history.push(getPathToWorkloadInstance(workloadName, workloadVersion, executionId)),
    [ history ]);

  const showExecutionHistory = React.useCallback((workloadName: string,
                                                  workloadVersion: number) =>
      history.push(getPathToWorkloadExecutionHistory(workloadName, workloadVersion)),
    [history]);

  const addTrigger = React.useCallback((type: TriggerType) =>
      history.push(getAddTriggerPath(name, version, type)),
    [ name, version, history ]);

  const editTrigger = React.useCallback((trigger: Trigger) =>
      history.push(getEditTriggerPath(name, versionParam, trigger.getType()), { trigger }),
    [ name, versionParam, history ]);

  const openCloneWorkloadWizard = React.useCallback((workload: CreateWorkloadRequest) => {
    history.push(getCloneWorkloadPath(name, version), {
      data: workload.toJS(),
    });
  }, [ name, version, history ]);

  const showWorkloadVersionDetails = React.useCallback((selectedVersion: number) => {
    history.push(getPathToWorkload(name, selectedVersion));
  }, [ name, history ]);

  const showLatestWorkloadVersion = React.useCallback(() => {
    history.push(getPathToWorkload(name, "latest"));
  }, [ name, history ]);

  const switchPrincipal = React.useCallback((workloadVersion: number) => {
    history.push(getPathToSwitchPrincipal(name, workloadVersion));
  }, [ name, history ]);

  const onDeleteWorkloadSuccess = React.useCallback(() =>
      history.push(WORKLOADS_LIST_PATH),
    [ name, version, history ]);

  if (isEmptyString(name)) {
    return (
      <ErrorView
        title="Invalid Workload Name"
        message="Workload name is invalid"
        size="large"
      />
    );
  }

  if (!isValidInteger(version) || version <= 0) {
    return (
      <ErrorView
        title="Invalid Workload Version"
        message="Workload version is invalid"
        size="large"
      />
    );
  }

  return (
    <DataWorkloadDetailsLayout
      name={name}
      version={version}
      showWorkloadVersionDetails={showWorkloadVersionDetails}
      showDataSetDetails={showDataSetDetails}
      showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
      showWorkloadExecutionDetails={showWorkloadExecutionDetails}
      showExecutionHistory={showExecutionHistory}
      openCloneWorkloadWizard={openCloneWorkloadWizard}
      addTrigger={addTrigger}
      editTrigger={editTrigger}
      showLatestVersion={showLatestWorkloadVersion}
      onDeleteWorkloadSuccess={onDeleteWorkloadSuccess}
      switchPrincipal={switchPrincipal}
    />
  );
}

function LoadDetailsView() {

  const { name, version: versionParam } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [ versionParam ]);

  const [ { showSuccessView, showErrorView, showNotFound, ...model }, actions ] = useGetWorkload({
    name,
    version,
    deferRequest: true,
  });

  const { refresh: retry } = actions;

  React.useEffect(() => {
    retry();
  }, []);

  // TODO: Below comment also affects showNotFound, which can become true before the
  //       showErrorView prop becomes true. Without the additional check for showErrorView,
  //       we would get a react state error if redirecting before showErrorView becomes true.
  //       It would be ideal to fix this in the same PR for the below IOTSST-1920 ticket.
  if (showErrorView && showNotFound) {
    return (
      <Redirect to={getPathToWorkload(name)}/>
    );
  }

  // TODO: There seems to be a bug with WaitForApiRequestView where the children are rendered
  //       before the useApiRequest hook actually fully completes, which could be a result of
  //       the order in which the success/error handlers disable the loading indicator before
  //       setting the success/error from the API response. At a quick glance, it would seem
  //       that `showSuccessView` could be used as a condition for rendering children, but this
  //       is a sweeping change to perform in the base component, so we will need to tackle this
  //       in a separate ticket to ensure all usages are providing the showSuccessView prop,
  //       which will be done in IOTSST-1920.
  return (
    <WaitForApiRequestView
      loadingMessage={`Loading Workload Version ${version}...`}
      errorTitle={`Failed to load workload version ${version}`}
      showErrorView={showErrorView}
      showSuccessView={showSuccessView}
      retry={retry}
      {...model}
      {...actions}
    >
      {showSuccessView && (<DetailsView/>)}
    </WaitForApiRequestView>
  );
}

function WaitForLatestVersion() {

  const { url } = useRouteMatch() || {};

  const { name } = useParams<RouteParams>();

  const { pathname, search } = useLocation();

  const [ { workload, ...model }, actions ] = useGetWorkload({ name });

  const { showSuccessView } = model;

  const { refresh: retry } = actions;

  const childPath = React.useMemo(() =>
    pathname.split(url).pop() + search, [ pathname, url, search ]);

  const redirect = React.useMemo(() => !showSuccessView ? null : (
    <Redirect to={getPathToWorkload(name, workload.getVersion()) + childPath}/>
  ), [ showSuccessView, name, workload, childPath ]);

  return (
    <WaitForApiRequestView
      loadingMessage="Loading Workload..."
      errorTitle="Failed to load latest version of workload"
      retry={retry}
      {...model}
      {...actions}
    >
      {redirect}
    </WaitForApiRequestView>
  );
}

export function DataWorkloadDetailsView() {

  const { name } = useParams<RouteParams>();

  return (
    <Switch>
      <Route path={LEGACY_MODULE_PATH} exact={true}>
        <Redirect to={getPathToWorkload(name)}/>
      </Route>
      <Route path={LATEST_VERSION_MODULE_PATH}>
        <WaitForLatestVersion/>
      </Route>
      <Route>
        <LoadDetailsView/>
      </Route>
    </Switch>
  );
}

export default DataWorkloadDetailsView;
