import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { SecurityGroup } from "@data";
import { DeviceTypeWizardActions } from "../actions";
import { getCurrentAccountId } from "@main/selectors";
import { DeviceTypeWizardSelectors } from "../selectors";
import { equalsIgnoreCase, isEmptyString, noop } from "@util";
import { useAccountSecurityGroups } from "@components/security-groups-list";
import GroupsView, { Actions, Model } from "../components/GroupsView";

interface ContainerModel extends Model {
  hidden?: boolean;
  accountId?: string;
  selectedGroupNames?: string[];
}

interface ContainerActions extends Actions {
  setSelectedItems?: (securityGroups: SecurityGroup[]) => void;
  showSecurityGroupDetails?: (securityGroup: SecurityGroup) => void;
}

const GroupViewContainer = (props: ContainerModel & ContainerActions) => {

  const {
    hidden,
    accountId = "",
    selectedGroupNames = [],
    setSelectedItems = noop,
    showSecurityGroupDetails,
  } = props;

  const [ model, actions ] = useAccountSecurityGroups();

  const { securityGroups, nameFilter, errorStatus, ...remainingModel } = model;

  const missingItems = React.useMemo(() =>
      selectedGroupNames
        .filter(name => !securityGroups.some(group => equalsIgnoreCase(name, group.getName())))
        .map(name => new SecurityGroup({ name })),
    [selectedGroupNames, securityGroups]);

  const items = React.useMemo(() =>
      missingItems
        .concat(securityGroups.slice())
        .filter(group => group.hasName()),
    [missingItems, securityGroups]);

  const selectedItems = React.useMemo(() =>
      items.filter(group => selectedGroupNames.indexOf(group.getName()) >= 0),
    [items, selectedGroupNames]);

  const noResultsLabel = React.useMemo(() => {
    if (isEmptyString(nameFilter)) {
      return `No groups found in account ${accountId}`;
    } else {
      return "No groups found";
    }
  }, [nameFilter, accountId]);

  const statusCode = React.useMemo(() => parseInt(errorStatus.toString(), 10), [errorStatus]);

  if (hidden) {
    return null;
  }

  return (
    <GroupsView
      {...remainingModel}
      {...actions}
      items={items}
      selectable={true}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      noResultsLabel={noResultsLabel}
      nameFilter={nameFilter}
      statusCode={statusCode}
      showMoreInfoLabel="Group Details"
      onClickShowMoreInfo={showSecurityGroupDetails}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  hidden: !DeviceTypeWizardSelectors.isGroupViewSelected(state),
  accountId: getCurrentAccountId(state),
  selectedGroupNames: DeviceTypeWizardSelectors.getGroupNames(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  setSelectedItems: (securityGroups: SecurityGroup[]) =>
    dispatch(DeviceTypeWizardActions.updateGroups(
      securityGroups.map((securityGroup) => securityGroup.name))),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(GroupViewContainer);
