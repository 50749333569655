import React from "react";
import { noop } from "@util";
import { useDeleteUiPackage } from "@hooks";
import {
  DeleteUiPackageDialog,
  DeleteUiPackageDialogActions as Actions,
  DeleteUiPackageDialogModel as Model,
} from "./DeleteUiPackageDialog";

export interface DeleteUiPackageModel extends Model {
}

export interface DeleteUiPackageActions extends Actions {
}

type Props = DeleteUiPackageModel & DeleteUiPackageActions;

export const DeleteUiPackage = (props: Props) => {

  const {
    cancel = noop,
    onSuccessMessageShown = noop,
    ...otherProps
  } = props;

  const [{
    errorMessage,
    successMessage,
    showLoadingIndicator
  }, { deleteUiPackage }] = useDeleteUiPackage();

  return (
    <DeleteUiPackageDialog
      {...otherProps}
      open={true}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={showLoadingIndicator}
      cancel={cancel}
      confirm={deleteUiPackage}
      onSuccessMessageShown={onSuccessMessageShown}
    />
  );
};

export default DeleteUiPackage;
