import React from "react";
import { isEmptyString, noop } from "@util";
import { DeviceTypeModelV3 } from "@data";
import { AuthTokenContext } from "@components";
import { DeviceTypeClient, RestClientError } from "@network";

export interface UseDeviceTypeModel {
  deviceType: DeviceTypeModelV3;
  errorMessage: string;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  isValidDeviceTypeIdentity: boolean;
  statusCode?: number;
}

export interface UseDeviceTypeActions {
  refresh: () => void;
}

export const useDeviceType = (namespace: string,
                              name: string,
                              version: string): [UseDeviceTypeModel, UseDeviceTypeActions] => {

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [deviceType, setDeviceType] = React.useState(DeviceTypeModelV3.EMPTY);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState<number>();

  const isValidDeviceTypeIdentity = React.useMemo(() =>
    !isEmptyString(namespace) &&
    !isEmptyString(name) &&
    !isEmptyString(version),
    [namespace, name, version]);

  React.useEffect(() => {

    if (!isValidDeviceTypeIdentity) {
      return () => noop;
    }

    let ignore = false;

    setShowProgressIndicator(true);
    setErrorMessage("");

    DeviceTypeClient.getDeviceTypeV3(authToken, namespace, name, version)
      .then((responseV3) => {

        if (!ignore) {
          setDeviceType(new DeviceTypeModelV3(responseV3));
          setShowProgressIndicator(false);
        }

      }, (response: RestClientError) => {

        if (!ignore) {
          const { error = "Fetch deviceType failed" } = response;
          setErrorMessage(error);
          setShowProgressIndicator(false);
          setStatusCode(response.status);
        }
      });

    return () => { ignore = true; };

  }, [
    setShowProgressIndicator,
    setErrorMessage,
    setDeviceType,
    setStatusCode,
    isValidDeviceTypeIdentity,
    authToken,
    lastRefresh,
    namespace,
    name,
    version,
  ]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const refresh = React.useCallback(() =>
    setLastRefresh(+new Date) , [setLastRefresh]);

  const deviceTypeModel = React.useMemo<UseDeviceTypeModel>(() => ({
    deviceType,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidDeviceTypeIdentity,
    statusCode,
  }), [
    deviceType,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    isValidDeviceTypeIdentity,
    statusCode,
  ]);

  const deviceTypeAction = React.useMemo<UseDeviceTypeActions>(() => ({
    refresh,
  }), [refresh]);

  return [deviceTypeModel, deviceTypeAction];
};

export default useDeviceType;
