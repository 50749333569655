import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks";
import { GetUiPackageFilesResponse } from "@network";
import useUiPackageClient from "@hooks/uiPackage/use-ui-package-client";

type SuccessResponse = GetUiPackageFilesResponse;

export interface UseGetUiPackagesProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetUiPackagesModel extends UseApiRequestModel<SuccessResponse> {
  files?: string[];
}

export interface UseGetUiPackagesActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetUiPackagesProps;
type Model = UseGetUiPackagesModel;
type Actions = UseGetUiPackagesActions;
type Result = [Model, Actions];

export const useGetUiPackages = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get UI packages",
    ...otherProps
  } = props;

  const UiPackageClient = useUiPackageClient();

  const makeApiRequest = React.useCallback(
    () => UiPackageClient.getUiPackages(),
    [UiPackageClient]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const files = React.useMemo(() => {
    return successResponse?.package?.files || [];
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    files,
  }), [
    baseModel,
    files,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetUiPackages;
