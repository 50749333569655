import React from "react";
import { useWorkloadManagerClient } from "@hooks";
import {
  useApiRequest,
  UseApiRequestModel,
  UseApiRequestProps,
  UseApiRequestActions,
} from "@hooks";
import { ValidateWorkloadNameResponse } from "@network";

type SuccessResponse = ValidateWorkloadNameResponse;

export interface UseValidateWorkloadNameProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
}

export interface UseValidateWorkloadNameModel extends UseApiRequestModel<SuccessResponse> {
  isValid: boolean;
  message: string;
}

export interface UseValidateWorkloadNameActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseValidateWorkloadNameProps;
type Model = UseValidateWorkloadNameModel;
type Actions = UseValidateWorkloadNameActions;
type Result = [Model, Actions];

export const useValidateWorkloadName = (props: Props): Result => {

  const {
    name,
    defaultErrorMessage = "Failed to validate workload name",
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const makeApiRequest = React.useCallback(() => WorkloadManagerClient.validateWorkloadName(name),
    [WorkloadManagerClient, name]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const isValid = React.useMemo(() => successResponse ? successResponse.isValid : false, [successResponse]);
  const message = React.useMemo(() => successResponse ? successResponse.message : "", [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    isValid,
    message,
  }), [
    baseModel,
    isValid,
    message,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useValidateWorkloadName;
