import { useApiRequestAnalytics } from "@hooks";
import { SchemaRegistryClient } from "@network";
import { JsonSchemaDefinition, JsonSchemaMetadataAttributes } from "@data";

export type GetSchemaApi = (
  namespace: string, name: string, version?: string
) => Promise<JsonSchemaDefinition>;

export type GetSchemaMetadataApi = (
  namespace: string, name: string, version: string
) => Promise<JsonSchemaMetadataAttributes>;

export interface SchemaClientApi {
  getSchema: GetSchemaApi;
  getSchemaMetadata: GetSchemaMetadataApi;
}

enum ActionTypes {
  GET_SCHEMA_REQUEST = "schema_client/get_schema_request",
  GET_SCHEMA_SUCCESS = "schema_client/get_schema_success",
  GET_SCHEMA_FAILED = "schema_client/get_schema_failed",

  GET_SCHEMA_METADATA_REQUEST = "schema_client/get_schema_metadata_request",
  GET_SCHEMA_METADATA_SUCCESS = "schema_client/get_schema_metadata_success",
  GET_SCHEMA_METADATA_FAILED = "schema_client/get_schema_metadata_failed",
}

export const useGetSchemaApi = () =>
  useApiRequestAnalytics(SchemaRegistryClient.getSchema, {
    REQUEST_EVENT: ActionTypes.GET_SCHEMA_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SCHEMA_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SCHEMA_FAILED,
  });

export const useGetSchemaMetadataApi = () =>
  useApiRequestAnalytics(SchemaRegistryClient.getSchemaMetadata, {
    REQUEST_EVENT: ActionTypes.GET_SCHEMA_METADATA_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_SCHEMA_METADATA_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_SCHEMA_METADATA_FAILED,
  });

export const useSchemaClient = (): SchemaClientApi => ({
  getSchema: useGetSchemaApi(),
  getSchemaMetadata: useGetSchemaMetadataApi(),
});

export { ActionTypes as SchemaClientActionTypes };

export default useSchemaClient;
