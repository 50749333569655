import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { logout, updateAuthToken } from "../actions";
import { getAuthToken, getCurrentUserId, getIdentityType, getRefreshToken } from "../selectors";
import SplashScreen, { Model, Actions } from "../components/SplashScreen";

const mapStateToProps = (state: AppSchema): Model => ({
  userId: getCurrentUserId(state),
  accessToken: getAuthToken(state),
  refreshToken: getRefreshToken(state),
  identityType: getIdentityType(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
  logout: () => dispatch(logout()),
  updateAuthToken: (authToken: string, expiryTime: string) =>
    dispatch(updateAuthToken(authToken, expiryTime)),
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(SplashScreen);
