import React from "react";
import { isEmptyString } from "@util";
import { useCreateDataSetRequestComment } from "@hooks";
import CreateCommentView from "@components/request-comments/CreateCommentView";

interface Model {
  dataSetRequestId: string;
}

interface Actions {
  onSuccess: () => void;
}

const CreateCommentViewContainer = (props: Model & Actions) => {

  const { dataSetRequestId = "", onSuccess } = props;

  const [message, setMessage] = React.useState<string>("");
  const [showCreateCommentBox, setShowCreateCommentBox] = React.useState<boolean>(false);

  const [model, actions] = useCreateDataSetRequestComment({
    dataSetRequestId,
    json: JSON.stringify({ message })
  });

  const { createComment, reset } = actions;
  const { successMessage, showLoadingIndicator, errorMessage, } = model;

  const cancel = React.useCallback(() => {
    reset();
    setMessage("");
    setShowCreateCommentBox(false);
  }, [reset, setMessage, setShowCreateCommentBox]);

  const addComment = React.useCallback(() => {
    reset();
    setMessage("");
    setShowCreateCommentBox(true);
  }, [reset, setMessage, setShowCreateCommentBox]);

  const setComment = React.useCallback((comment) => setMessage(comment), [setMessage]);

  const finished = !!(successMessage && successMessage.length > 0);
  const showProgressIndicator = showLoadingIndicator;
  const buttonsDisabled = showProgressIndicator || finished;

  const showCancelButton = !finished && !showProgressIndicator;
  const showSaveButton = !finished && !isEmptyString(message);

  return (
    <CreateCommentView
      cancel={cancel}
      save={createComment}
      setComment={setComment}
      addComment={addComment}
      onSuccess={onSuccess}
      comment={message}
      finished={finished}
      errorMessage={errorMessage}
      successMessage={successMessage}
      showSaveButton={showSaveButton}
      buttonsDisabled={buttonsDisabled}
      showCancelButton={showCancelButton}
      createCommentBoxVisible={showCreateCommentBox}
      showProgressIndicator={showProgressIndicator}
    />
  );
};

export default CreateCommentViewContainer;
