import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
    },
    actionsIconButton: {
      width: "auto",
      height: "auto",
      margin: "0 1px",
      color: Color.GREY3,
      "&:hover": {
        color: Color.BLACK,
      },
    },
    actionsIcon: {
      width: "auto",
      height: 14,
      color: "inherit",
    },
    popper: {
      zIndex: 9999,
    },
    paper: {
    },
    list: {
      display: "flex",
      flexFlow: "column nowrap",
      border: `1px solid ${Color.LIGHT_GREY}`,
    },
    listItem: {
      fontSize: 13,
      fontWeight: 300,
      lineHeight: "16px",
      color: Color.TEXT,
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
    },
    rename: {
    },
    delete: {
      color: Color.DARK_RED,
    },
    addFile: {
    },
    addFolder: {
    },
  });

export default styles;