import React from "react";
import { isArray } from "lodash";
import Module from "@data/Module";
import { Breadcrumb } from "@data";
import classnames from "classnames";
import { clickHandler, isEmptyString } from "@util";
import { Breadcrumbs, HelpIcon, IconButton, Tooltip } from "@components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ToolbarUserMenu from "./ToolbarUserMenu";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { layoutHeader as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Model {
  className?: string;
  principalId?: string;
  showMyProfileButton?: boolean;
  profilePath?: string;
  settingsPath?: string;
  routes?: Module[];
  showMaintenanceReminder?: boolean;
}

export interface Actions {
  onClickMyProfile?: () => void;
  onClickSettings?: () => void;
  onClickLogout?: () => void;
}

export interface LayoutHeaderProps extends WithStyles<typeof styles>, Model, Actions {
  children?: React.ReactNode;
}

export const LayoutHeader = withStyles(styles)((props: LayoutHeaderProps) => {

  const {
    classes,
    className,
    routes = [],
    principalId = "",
    showMyProfileButton,
    profilePath,
    settingsPath,
    showMaintenanceReminder = false,
    onClickSettings,
    onClickMyProfile,
    onClickLogout,
    children,
  } = props;

  const getBreadcrumbs = React.useCallback(() => {
    const crumbs: Breadcrumb[] = [];
    routes.forEach(({ path: modulePath = "", breadcrumbTitle = "" }) => {
      const match = useRouteMatch({path: modulePath});
      if (match) {
        const {path: matchPath = "", url = ""} = match;
        const moduleMatch = routes.find(({path}) =>
          isArray(path) ? path.includes(matchPath) : matchPath === path);
        if (moduleMatch && !crumbs.some((crumb) => crumb.url === url)) {
          const {title, name} = moduleMatch;
          const hasTitle = typeof title === "string" && !isEmptyString(title);
          crumbs.push(new Breadcrumb({
            title: isEmptyString(breadcrumbTitle) ? (hasTitle ? title : name) : breadcrumbTitle, url}));
        }
      }
    });
    return crumbs.sort((a, b) => a.url.length - b.url.length);
  }, [routes]);

  const currentBreadcrumbs = getBreadcrumbs();

  return (
    <React.Fragment>
      <AppBar
        className={classnames("appBar", className, classes.appBar)}
        color="primary"
        position="sticky"
        elevation={0}
      >
        <Toolbar
          className={classnames("toolbar", classes.toolbar, {
            [classes.maintenanceReminder]: showMaintenanceReminder,
          })}
        >
          <Switch>
            {routes.map((module: Module) => !module.backButton ? null : (
              <Route
                key={module.id}
                path={module.path}
                exact={module.exactPath}
                sensitive={module.sensitivePath}
                strict={module.strictPath}
              >
                {module.backButton}
              </Route>
            ))}
          </Switch>
          <ToolbarUserMenu
            className={classnames("toolbarUserMenu", classes.toolbarUserMenu)}
            label={principalId}
            showMyProfileButton={showMyProfileButton}
            profilePath={profilePath}
            settingsPath={settingsPath}
            onClickSettings={clickHandler(onClickSettings)}
            onClickMyProfile={clickHandler(onClickMyProfile)}
            onClickLogout={clickHandler(onClickLogout)}
          />
        </Toolbar>
      </AppBar>
      <Switch>
        {routes.map((module: Module) => !module.title ? null : (
          <Route
            key={module.id}
            path={module.path}
            exact={module.exactPath}
            sensitive={module.sensitivePath}
            strict={module.strictPath}
            render={({ match, location }) => {

              const {
                showBreadcrumbs,
                customBreadcrumbs = currentBreadcrumbs,
                customBreadcrumbsFactory = () => customBreadcrumbs,
              } = module;

              const breadcrumbs = !showBreadcrumbs ? [] : customBreadcrumbsFactory(match, location);

              const hasBreadcrumbs = breadcrumbs.length >= 2;

              return (
                <AppBar
                  className={classnames("title", classes.title, {[classes.breadcrumbTitle]: hasBreadcrumbs})}
                  color="primary"
                  position="static"
                  elevation={0}
                >
                  <div className={classnames("titleName", classes.titleName)}>
                    {module.title}
                    {!isEmptyString(module.documentationLink) && (
                      <Tooltip title="Documentation" placement="right">
                        <a href={module.documentationLink} target="_blank" rel="noopener noreferrer">
                          <IconButton className={classnames("documentation", classes.documentationButton)}>
                            <HelpIcon/>
                          </IconButton>
                        </a>
                      </Tooltip>
                    )}
                  </div>
                  {hasBreadcrumbs && (
                    <Breadcrumbs items={breadcrumbs} className={classnames("breadcrumbs", classes.breadcrumbs)}/>
                  )}
                </AppBar>
              );
            }}
          />
        ))}
      </Switch>
      <Switch>
        {routes.map((module: Module) => !module.subtitle ? null : (
          <Route
            key={module.id}
            path={module.path}
            exact={module.exactPath}
            sensitive={module.sensitivePath}
            strict={module.strictPath}
          >
            <AppBar
              className={classnames("subtitle", classes.subtitle)}
              color="primary"
              position="static"
              elevation={0}
            >
              {module.subtitle}
            </AppBar>
          </Route>
        ))}
      </Switch>
      <Switch>
        {routes.map((module: Module) => !module.tabs ? null : (
          <Route
            key={module.id}
            path={module.path}
            exact={module.exactPath}
            sensitive={module.sensitivePath}
            strict={module.strictPath}
          >
            <AppBar
              className={classnames("tabsContainer", classes.tabsContainer)}
              color="primary"
              position="static"
              elevation={0}
            >
              {module.tabs}
            </AppBar>
          </Route>
        ))}
      </Switch>
      {children}
    </React.Fragment>
  );
});

export default LayoutHeader;
