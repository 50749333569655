import createStyles from "@material-ui/core/styles/createStyles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    tab: {
    },
  });

export default styles;