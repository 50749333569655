import React from "react";
import classnames from "classnames";
import HelpIcon from "@material-ui/icons/Help";
import { IconButton, Tooltip } from "@components";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { accountId as styles, textFieldTooltip } from "./styles";
import { isEmptyString, noop } from "@util";
import LoginTextField from "./LoginTextField";

const TextFieldTooltip = withStyles(textFieldTooltip)(Tooltip);

export const DEFAULT_ACCOUNT_ID_TOOLTIP =
  "Specify the IoT Account for the business/product you are accessing.";

export const AccountIdTextField = withStyles(styles)((props: WithStyles<typeof styles> & {
  className?: string,
  name?: string,
  label?: string,
  tooltip?: string;
  accountId?: string;
  accountIdErrorMessage?: string;
  disabled?: boolean;
  setAccountId?: (account: string) => void;
  login?: () => void;
}) => {

  const {
    classes,
    className,
    name = "iot_account_id",
    label = "Account ID",
    tooltip = DEFAULT_ACCOUNT_ID_TOOLTIP,
    accountId,
    accountIdErrorMessage,
    disabled,
    setAccountId = noop,
    login = noop,
  } = props;

  return (
    <LoginTextField
      className={className}
      name={name}
      label={label}
      value={accountId}
      helperText={accountIdErrorMessage}
      error={!isEmptyString(accountIdErrorMessage)}
      disabled={disabled}
      setValue={setAccountId}
      login={login}
      endAdornment={(
        <InputAdornment position="end">
          <TextFieldTooltip className={classnames("tooltip", classes.tooltip)} title={tooltip}>
            <IconButton className={classnames("iconButton", classes.iconButton)} tabIndex={-1}>
              <HelpIcon className={classnames("textFieldIcon", classes.textFieldIcon)} />
            </IconButton>
          </TextFieldTooltip>
        </InputAdornment>
      )}
    />
  );
});

export default AccountIdTextField;
