import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Color.SIGNIFY_GREEN,
    },
    loading: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    expired: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 48,
      fontWeight: 400,
      lineHeight: "48px",
      margin: "16px 8px",
    },
    button: {
      color: Color.GREY5,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "21px",
      textTransform: "none",
    },
    underlined: {
      marginLeft: 4,
      marginRight: 4,
      textDecoration: "underline",
    },
    errorIcon: {
      color: Color.WHITE,
      width: 128,
      height: 128,
    },
  });

export default styles;
