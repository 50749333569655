import React from "react";
import { Store } from "redux";
import { AppSchema } from "@schemas";
import { isEmptyString } from "@util";
import Application from "./Application";
import SessionStorageClient from "./util/SessionStorageClient";
import { refreshSuperset } from "@modules/portlet/actions";
import { setQueryParams, setVersion, setVersionCode, updateLastMouseMove } from "@main/actions";
import {
  fetchDashboardsSuccess,
  updateDataSources,
  updateReaders,
} from "@modules/dashboards/actions";
import clearPortalLocalStorage from "@store/clearPortalLocalStorage";

const VERSION = process.env.REACT_APP_VERSION || "";
const VERSION_CODE = process.env.REACT_APP_VERSION_CODE || "";

const getQueryParams = () => window.location.hash.substring(1).split("&")
  .concat(window.location.search.substring(1).split("&"))
  .filter((entry: string) => !isEmptyString(entry))
  .map((entry: string, index: number) => index === 0 ? `?${entry}` : entry)
  .join("&");

export const Main = (props: {
  store: Store<AppSchema>,
}) => {

  const { store } = props;

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {

    const eventListener = (event: { origin?: string, data?: any }) => {
      if (event.origin === window.location.origin) {

        // Reset everything and reload the website if a resetApp command is received
        if (event.data === "resetApp") {
          clearPortalLocalStorage();
          SessionStorageClient.clear();
          window.location.reload();
          return;
        }

        // Notify the user when an update is available
        if (event.data === "updateAvailable") {
          // TODO: Show a snackbar or dialog when a new version is available
          console.info("Update Available");
          return;
        }

        // Reload if a service worker is uninstalled to ensure
        // the user is always running the latest version of the app.
        if (event.data === "serviceWorkerRemoved") {
          window.location.reload();
          return;
        }

      }

      if (event.data) {
        const message = event.data.value;
        // console.debug("Signify portal received an event:", event.data);
        if (event.data.source === "Superset:Dashboards") {
          // By default, the React Redux dispatch hook does not contain any types that take middlewares into account.
          // @ts-ignore
          store.dispatch(fetchDashboardsSuccess(message.result));
        }
        if (event.data.source === "Superset:Dashboard_Readers") {
          // @ts-ignore
          store.dispatch(updateReaders(message));
        }
        if (event.data.source === "Superset:Dashboard_Datasource") {
          // @ts-ignore
          store.dispatch(updateDataSources(message));
        }
        if (event.data.source === "Superset:Message") {
          const key = "force_refresh:";
          if (message.indexOf(key) === 0) {
            // @ts-ignore
            store.dispatch(refreshSuperset(message.slice(key.length)));
          }
        }
        if (event.data.source === "Superset:MouseMove") {
          // console.debug("received mouse moved event");
          // @ts-ignore
          store.dispatch(updateLastMouseMove(message));
        }
      }
    };

    window.addEventListener("message", eventListener, false);

    if (!initialized) {
      setInitialized(true);
      store.dispatch(setVersion(VERSION));
      store.dispatch(setVersionCode(VERSION_CODE));
      store.dispatch(setQueryParams(getQueryParams()));
    }

    return () => {
      window.removeEventListener("message", eventListener, false);
    };

  }, [store, initialized, setInitialized]);

  if (!initialized) {
    return null;
  }

  return (
    <Application store={store} />
  );
};

export default Main;
