import { KEY_NAMESPACE } from "@store/constants";
import LocalStorage from "@util/LocalStorage";

export const clearPortalLocalStorage = (client: Storage = LocalStorage) => {

  const keysToRemove = Object.keys(client)
    .filter(key => key.indexOf(KEY_NAMESPACE) === 0);

  keysToRemove.forEach(key => client.removeItem(key));

  return keysToRemove;
};

export default clearPortalLocalStorage;
