import { Record } from "immutable";
import { getStringValue } from "@util";
import IdentityType from "./IdentityType";

export interface PortalSessionInfoAttributes {
  accessToken: string;
  expiration: string;
  principalType: IdentityType;
  accountId: string;
  principalId: string;
  refreshToken: string;
  idToken: string;
  userId: string;
  serviceId: string;
}

export class PortalSessionInfo extends Record({
  accessToken: "",
  expiration: "",
  principalType: IdentityType.NONE,
  accountId: "",
  principalId: "",
  refreshToken: "",
  idToken: "",
  userId: "",
  serviceId: "",
}) implements PortalSessionInfoAttributes {

  public static EMPTY: PortalSessionInfo = new PortalSessionInfo();

  public readonly accessToken: string;
  public readonly expiration: string;
  public readonly principalType: IdentityType;
  public readonly accountId: string;
  public readonly principalId: string;
  public readonly refreshToken: string;
  public readonly idToken: string;
  public readonly userId: string;
  public readonly serviceId: string;

  public getAccessToken(): string {
    return getStringValue(this.accessToken);
  }

  public getExpiration(): string {
    return getStringValue(this.expiration);
  }

  public getPrincipalType(): IdentityType {
    return this.principalType;
  }

  public isUserPrincipal(): boolean {
    return this.getPrincipalType() === IdentityType.USER;
  }

  public isServicePrincipal(): boolean {
    return this.getPrincipalType() === IdentityType.SERVICE;
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getPrincipalId(): string {
    return getStringValue(this.principalId);
  }

  public getRefreshToken(): string {
    return getStringValue(this.refreshToken);
  }

  public getIdToken(): string {
    return getStringValue(this.idToken);
  }

  public getUserId(): string {
    return getStringValue(this.userId);
  }

  public getServiceId(): string {
    return getStringValue(this.serviceId);
  }
}

export default PortalSessionInfo;
