import React from "react";
import { useParams, useHistory } from "react-router-dom";
import PolicyDetails from "./containers/PolicyDetails";
import { getEditPolicyPath } from "@modules/editPolicy/helpers";

export function PolicyDetailsView() {

  const history = useHistory();

  const { id: policyName } = useParams<{ id: string }>();

  const editPolicy = React.useCallback(() =>
      history.push(getEditPolicyPath(policyName), { goBackOnSuccess: true}),
    [history, policyName]);

  return (
    <PolicyDetails
      key={policyName}
      name={policyName}
      editPolicy={editPolicy}
    />
  );
}

export default PolicyDetailsView;
