import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { useGetPolicy } from "@hooks";
import { Policy, SummaryViewData } from "@data";
import { isEmptyString, noop } from "@util";
import ModuleIcon from "@material-ui/icons/Assignment";
import SummaryJsonDetailsView from "@components/summary-json-details-view";
import { open as openDeletePolicyDialog } from "@modules/deletePolicy/actions";
import { ActionMenuItem, POLICY_ACTION_MENU_ITEMS, PolicyAction } from "@components";
import Statement from "./StatementsView";

export interface ContainerModel {
  name?: string;
}

export interface ContainerActions {
  deletePolicy?: (name: string) => void;
  editPolicy?: () => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const PolicyDetailsContainer = (props: Props) => {

  const {
    name,
    editPolicy = noop,
    deletePolicy = noop,
    onClickAction = noop,
  } = props;

  const [{
    policy,
    errorMessage,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
  }, { refresh }] = useGetPolicy({ name });

  const path = React.useMemo(() => policy.getPath(), [policy]);
  const version = React.useMemo(() => policy.getVersion(), [policy]);
  const description = React.useMemo(() => policy.getDescription(), [policy]);
  const statements = React.useMemo(() => policy.getStatements(), [policy]);

  const json = React.useMemo(() => {
    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = showLoadingIndicator ? Policy.EMPTY.toJS() : policy.toJS();

    return JSON.stringify(data, null, "  ");
  }, [policy, showLoadingIndicator]);

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "name",
      name: "Name",
      value: name,
    }),
    new SummaryViewData({
      className: "version",
      name: "Version",
      value: version,
    }),
    new SummaryViewData({
      className: "path",
      name: "Path",
      value: path,
    }),
    new SummaryViewData({
      className: "description",
      name: "Description",
      value: description,
    }),
  ], [
    name,
    path,
    version,
    description,
  ]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case PolicyAction.DELETE:
        return deletePolicy(name);
      case PolicyAction.EDIT:
        return editPolicy();
      default:
        return onClickAction(action);
    }
  }, [name, deletePolicy, editPolicy, onClickAction]);

  const StatementView = React.useMemo(() => (
    <Statement items={statements} />
  ), [statements]);

  return (
    <SummaryJsonDetailsView
      title={name}
      icon={ModuleIcon}
      refresh={refresh}
      showLoadingIndicator={showLoadingIndicator}
      actions={POLICY_ACTION_MENU_ITEMS}
      onClickAction={actionClicked}
      summaryViewItems={summaryViewItems}
      additionalViews={StatementView}
      json={json}
      fileName={`${name}.json`}
      showJsonHeader={!isEmptyString(name)}
      jsonViewClassName={"policyJsonView"}
      errorMessage={errorMessage}
      showNotFound={showNotFound}
      showAccessDenied={showAccessDenied}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  deletePolicy: (name) => dispatch(openDeletePolicyDialog(name)),
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyDetailsContainer);
