import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { UsersList, UsersListModel, UsersListActions, ThemeProvider } from "@components";
import { usersListTheme as theme } from "./themes";
import { usersView as styles } from "./styles";
import { noop } from "@util";

export interface Model extends UsersListModel {
  title?: string;
  maxNumSelectedUsers?: number;
}

export interface Actions extends UsersListActions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const UsersView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title,
    maxNumSelectedUsers = 0,
    ...otherProps
  } = props;

  const { items = [], selectedItems = [], nameFilter = "", setNameFilter = noop } = otherProps;

  // If a maximum number of selected users was specified - and has been reached -
  // then we should only render the selected users
  const showOnlySelectedUsers = React.useMemo(() => {
    if (maxNumSelectedUsers <= 0) {
      return false;
    } else {
      return selectedItems.length >= maxNumSelectedUsers;
    }
  }, [maxNumSelectedUsers, selectedItems]);

  const visibleItems = React.useMemo(() =>
    showOnlySelectedUsers ? selectedItems : items,
    [showOnlySelectedUsers, items, selectedItems]);

  // Reset name filter when showing specific selected users
  React.useEffect(() =>
    showOnlySelectedUsers ? setNameFilter("") : undefined,
    [showOnlySelectedUsers]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classnames("usersView", className, classes.container)}>
        <label className={classnames("title", classes.title)}>
          {title}
        </label>
        <UsersList
          {...otherProps}
          items={visibleItems}
          selectedItems={selectedItems}
          selectable={true}
          selectAllDisabled={true}
          showSearch={!showOnlySelectedUsers}
          showSearchFilters={!showOnlySelectedUsers}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
        />
      </div>
    </ThemeProvider>
  );
});

export default UsersView;
