import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutComplete } from "@main/actions";
import isPortalWebserverEnabled from "@util/isPortalWebserverEnabled";
import { getCurrentUserId, isLogoutTriggered } from "@main/selectors";
import LogoutListener, {
  LogoutListenerModel as Model,
  LogoutListenerActions as Actions,
} from "@components/logout-listener";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
  reload?: () => void;
}

type Props = ContainerModel & ContainerActions;

const LogoutListenerContainer = (props: Props) => {

  const {
    portalWebserverEnabled = isPortalWebserverEnabled(),
    reload = noop,
    onLogoutComplete: logoutCompleted = noop,
    ...otherProps
  } = props;

  const history = useHistory();

  const [reloadTriggered, setReloadTriggered] = React.useState(false);

  const onLogoutComplete = React.useCallback(() => {
    logoutCompleted();
    setReloadTriggered(true);
  }, [logoutCompleted, setReloadTriggered]);

  React.useEffect(() => {
    if (reloadTriggered) {
      if (portalWebserverEnabled) {
        reload();
      } else {
        history.push("/");
      }
    }
  }, [reloadTriggered, portalWebserverEnabled, reload, history]);

  return (
    <LogoutListener
      {...otherProps}
      portalWebserverEnabled={portalWebserverEnabled}
      onLogoutComplete={onLogoutComplete}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  userId: getCurrentUserId(state),
  logoutTriggered: isLogoutTriggered(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  onLogoutComplete: () => dispatch(logoutComplete()),
  reload: () => {
    window.location.reload();
  },
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutListenerContainer);
