import React from "react";
import classnames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { clickHandler, noop, swallow } from "@util";
import IconButton from "@material-ui/core/IconButton";
import ActionsIcon from "@material-ui/icons/MoreHoriz";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface ProjectViewListItemActionsMenuModel {
  className?: string;
  showFolderActions?: boolean;
}

export interface ProjectViewListItemActionsMenuActions {
  renameFile?: () => void;
  deleteFile?: () => void;
  addFile?: () => void;
  addFolder?: () => void;
}

type Model = ProjectViewListItemActionsMenuModel;
type Actions = ProjectViewListItemActionsMenuActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProjectViewListItemActionsMenu = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    showFolderActions,
    renameFile = noop,
    deleteFile = noop,
    addFile = noop,
    addFolder = noop,
    children,
  } = props;

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const openMenu = clickHandler(React.useCallback(() => setOpen(true), [setOpen]));

  const handleClose = React.useCallback(event => {
    swallow(event);
    const currentTarget = anchorRef && anchorRef.current !== null ? anchorRef.current : null;
    if (currentTarget !== null && currentTarget.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, [anchorRef, setOpen]);

  const onClickRename = React.useCallback(event => {
    swallow(event);
    renameFile();
    handleClose(event);
  }, [renameFile, handleClose]);

  const onClickDelete = React.useCallback(event => {
    swallow(event);
    deleteFile();
    handleClose(event);
  }, [deleteFile, handleClose]);

  const onClickAddFile = React.useCallback(event => {
    swallow(event);
    addFile();
    handleClose(event);
  }, [addFile, handleClose]);

  const onClickAddFolder = React.useCallback(event => {
    swallow(event);
    addFolder();
    handleClose(event);
  }, [addFolder, handleClose]);

  return (
    <div className={classnames("projectViewListItemActionsMenu", className, classes.container)}>
      <IconButton
        ref={anchorRef}
        className={classnames("actionsIconButton", classes.actionsIconButton)}
        size="small"
        onClick={openMenu}
      >
        <ActionsIcon className={classnames("actionsIcon", classes.actionsIcon)} />
      </IconButton>
      <Popper
        className={classnames("popper", classes.popper)}
        open={open}
        transition={true}
        disablePortal={true}
        placement="right-start"
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper
              className={classnames("paper", classes.paper)}
            >
              <ClickAwayListener
                onClickAway={handleClose}
              >
                <MenuList
                  className={classnames("list", classes.list)}
                  autoFocusItem={open}
                >
                  <MenuItem
                    className={classnames("listItem", "rename", classes.listItem, classes.rename)}
                    onClick={onClickRename}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem
                    className={classnames("listItem", "delete", classes.listItem, classes.delete)}
                    onClick={onClickDelete}
                  >
                    Delete
                  </MenuItem>
                  {showFolderActions && (
                    <Divider
                      className={classnames("divider", classes.divider)}
                    />
                  )}
                  {showFolderActions && (
                    <MenuItem
                      className={classnames("listItem", "addFile", classes.listItem, classes.addFile)}
                      onClick={onClickAddFile}
                    >
                      Add File
                    </MenuItem>
                  )}
                  {showFolderActions && (
                    <MenuItem
                      className={classnames("listItem", "addFolder", classes.listItem, classes.addFolder)}
                      onClick={onClickAddFolder}
                    >
                      Add Folder
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {children}
    </div>
  );
});

export default ProjectViewListItemActionsMenu;