import { Record } from "immutable";

export enum PyConfigFile {
  NONE = "",
  SETUP = "setup.py",
  PYPROJECT = "pyproject.toml",
}

export enum PyDependencies {
  NONE = "",
  BOTO3 = "boto3",
  REQUESTS = "requests",
  PYTEST = "pytest",
  PANADAS = "pandas",
}

export enum CodeView {
  NONE = "",
  EDITOR = "EDITOR",
  EDITOR_WITH_UPLOAD = "EDITOR_WITH_UPLOAD",
  UPLOAD = "UPLOAD",
}

export interface WorkloadPythonCodePropertyAttributes {
  codeView?: CodeView;
  pyConfigFile?: PyConfigFile;
  requirementTxt?: boolean;
  pyDependencies?: [PyDependencies];
}

export class WorkloadPythonCodeProperty extends Record({
  codeView: CodeView.NONE,
  pyConfigFile: PyConfigFile.NONE,
  requirementTxt: false,
  pyDependencies: [PyDependencies.NONE],
}) implements WorkloadPythonCodePropertyAttributes {

  public static EMPTY = new WorkloadPythonCodeProperty();

  public readonly pyConfigFile: PyConfigFile;
  public readonly codeView: CodeView;
  public readonly requirementTxt: boolean;
  public readonly pyDependencies: [PyDependencies];

}