import React from "react";
import { User, UserStatus } from "@data";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import { useAccountUsers, useCurrentAccountId, useSwitchWorkloadPrincipal } from "@hooks";
import SwitchPrincipalInfo from "../components/SwitchPrincipalInfo";

interface ContainerModel extends SubmitApiRequestViewModel<User> {
  workloadName: string;
  version: number;
}

interface ContainerActions extends SubmitApiRequestViewActions<User> {
}

type Props = ContainerModel & ContainerActions;

const SwitchPrincipalContainer = (props: Props) => {

  const {
    workloadName,
    version,
    ...otherProps
  } = props;

  const [selectedPrincipal, setSelectedPrincipal] = React.useState<User[]>([]);
  const [error, setError] = React.useState("");

  const updateSelectedPrincipal = React.useCallback((newUsers: User[]) => {
    setSelectedPrincipal(newUsers);
    setError("");
  }, [setSelectedPrincipal, setError]);

  const accountId = useCurrentAccountId();

  const [{ errorMessage, ...otherModel }, { switchPrincipal }] =
    useSwitchWorkloadPrincipal({
      name: workloadName,
      version,
      accountId,
      email: selectedPrincipal[0] ? selectedPrincipal[0].getUserId() : ""
    });

  const [{users, ...usersModel}, {...usersActions }] = useAccountUsers({ searchEnabled: true });

  const availableUsers = React.useMemo(() => selectedPrincipal.length > 0 ? selectedPrincipal :
    users.filter(user => user.getStatus() === UserStatus.CONFIRMED), [users, selectedPrincipal]);

  React.useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  return (
    <SubmitApiRequestView
      {...otherModel}
      {...otherProps}
      snackbarId="switch-principal"
      errorTitle="Switch Principal Failed"
      errorMessage={error}
      saveButtonLabel="Switch Workload Principal"
      currentState={selectedPrincipal[0]}
      defaultState={undefined}
      save={switchPrincipal}
      saveButtonDisabled={selectedPrincipal.length === 0}
    >
      <SwitchPrincipalInfo
        {...usersModel}
        {...usersActions}
        users={availableUsers}
        selectedPrincipal={selectedPrincipal}
        setSelectedPrincipal={updateSelectedPrincipal}
      />
    </SubmitApiRequestView>
  );
};

export default SwitchPrincipalContainer;
