import React from "react";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  useSchemaClient,
} from "@hooks";
import { JsonSchemaDefinition, JsonSchemaMetadata, JsonSchemaMetadataAttributes } from "@data";

type SuccessResponse = [JsonSchemaMetadataAttributes, JsonSchemaDefinition, JsonSchemaDefinition];

export interface UseGetSchemaProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name: string;
  version: string;
  namespace: string;
}

export interface UseGetSchemaModel extends UseApiRequestModel<SuccessResponse> {
  json?: string;
  isLatest?: boolean;
  schema?: JsonSchemaDefinition;
  schemaMetadata?: JsonSchemaMetadata;
}

export interface UseGetSchemaActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetSchemaProps;
type Model = UseGetSchemaModel;
type Actions = UseGetSchemaActions;
type Result = [Model, Actions];

export const useGetSchema = (props: Props): Result => {

  const {
    name = "",
    version = "",
    namespace = "",
    defaultErrorMessage = "Failed to get schema details",
    ...otherProps
  } = props;

  const { getSchema, getSchemaMetadata } = useSchemaClient();

  const makeApiRequest = React.useCallback(() =>
    new Promise<SuccessResponse>(async (resolve, reject) => {

      const metaData = await getSchemaMetadata(namespace, name, version).catch((error) => reject(error));

      const schemaData = await getSchema(namespace, name, version).catch((error) => reject(error));

      const latestData = await getSchema(namespace, name).catch((error) => reject(error));

      (metaData && schemaData && latestData) ? resolve([metaData, schemaData, latestData]) : reject();
    }), [namespace, name, version]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const [metadata, schema, latestSchema] = successResponse || [];

  const json = React.useMemo(() => JSON.stringify(schema, null, "  "), [schema]);

  const latest = React.useMemo(() => JSON.stringify(latestSchema, null, "  "), [latestSchema]);

  const isLatest = React.useMemo(() => json === latest, [json, latest]);

  const schemaMetadata = React.useMemo(() => new JsonSchemaMetadata(metadata || {}), [metadata]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    json,
    schema,
    isLatest,
    schemaMetadata,
  }), [
    baseModel,
    json,
    schema,
    latest,
    schemaMetadata,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetSchema;
