import React from "react";
import { noop } from "@util";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { ErrorView, DetailsView, DetailsViewRoute, ActionMenuItem } from "@components";
import { DeviceTypeDetailsActions } from "../actions";
import { DeviceTypeDetailsSelectors } from "../selectors";
import { DeviceTypeListItem, DeviceTypeModelV3 as DeviceType, DeviceTypeModelV3, JsonSchemaMetadata } from "@data";
import SoftwareVersionsView from "./SoftwareVersionsView";
import SummaryView from "./SummaryView";
import JsonView from "./JsonView";
import { PrimaryIcon } from "../components/styles";
import { DeviceTypeAction } from "@modules/deviceTypeManager/reducers";

export interface ContainerModel {
  deviceType?: DeviceType;
  showDeletedView?: boolean;
  loading?: boolean;
  errorMessage?: string;
  title?: string;
  actionsLabel?: string;
  actions?: ActionMenuItem[];
  actionsButtonDisabled?: boolean;
  children?: React.ReactNode;
  showNotFound?: boolean;
  showAccessDenied?: boolean;
  showModelVersionNotSupportedError?: boolean;
}

export interface ContainerActions {
  refresh?: () => void;
  editDeviceType?: (deviceType: DeviceType) => void;
  cloneDeviceType?: (deviceType: DeviceType) => void;
  openDeviceEnrollment?: (deviceType: DeviceType) => void;
  enrollDevice?: (deviceType: DeviceType) => void;
  showDeviceTypeManager?: (action: DeviceTypeAction, deviceType: DeviceType) => void;
  showDeviceTypeDetails?: (deviceType: DeviceTypeListItem) => void;
  showSchemaDetails?: (schema: JsonSchemaMetadata) => void;
  showSchemaDetailsInNewTab?: (schema: JsonSchemaMetadata) => void;
}

type Props = ContainerModel & ContainerActions & {
  children?: React.ReactNode;
};

const DeviceTypeDetailsContainer = (props: Props) => {

  const {
    title: deviceTypeId = "",
    showNotFound,
    showAccessDenied,
    showModelVersionNotSupportedError,
    showDeletedView,
    deviceType = DeviceType.EMPTY,
    showSchemaDetails = noop,
    showSchemaDetailsInNewTab = noop,
    showDeviceTypeDetails = noop,
    cloneDeviceType = noop,
    editDeviceType = noop,
    showDeviceTypeManager = noop,
    openDeviceEnrollment = noop,
    enrollDevice = noop,
    loading,
  } = props;

  if (showModelVersionNotSupportedError) {
    return (
      <ErrorView
        className="apiVersionNotSupported"
        size="large"
        title="Device Type Not Supported"
        message={"Oops, this device type model version is not supported by the IoT Portal. " +
          "Please contact your IoT account owner for help if you believe this error is a mistake."}
        errors={[`Device Type Identity Model Version [v2] Not Supported: ${deviceTypeId}`]}
      />
    );
  }

  if (showDeletedView) {
    return (
      <div className={"deviceTypeDetails"}>
        <ErrorView
          title="Oops, this device type has been deleted"
          message="The device type you selected has been deleted"
        />
      </div>
    );
  }

  const summaryView = React.useMemo(() => () =>
    (
      <SummaryView
        showSchemaDetails={showSchemaDetails}
        showSchemaDetailsInNewTab={showSchemaDetailsInNewTab}
      />
    )
  , [showSchemaDetails, showSchemaDetailsInNewTab]);

  const softwareVersionView = React.useMemo(() => () =>
    (
      <SoftwareVersionsView
        showDeviceTypeDetails={showDeviceTypeDetails}
      />
    )
  , [showDeviceTypeDetails]);

  const ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: summaryView
    },
    {
      id: "json",
      name: "{} JSON",
      view: JsonView,
      path: "/json"
    },
    {
      id: "softwareVersions",
      name: "Software Versions",
      view: softwareVersionView,
      path: "/software-versions",
    }
  ], [summaryView, softwareVersionView]);

  const deviceEnrollment = React.useCallback((deviceTypeModelV3: DeviceType) => {
    enrollDevice();
    openDeviceEnrollment(deviceTypeModelV3);
  } , [enrollDevice, openDeviceEnrollment]);

  const onClickDeviceTypeAction = React.useCallback((deviceTypeAction: ActionMenuItem) => {
    const action = deviceTypeAction.id as DeviceTypeAction;

    if (action === DeviceTypeAction.CLONE) {
      cloneDeviceType(deviceType);
    } else if (action === DeviceTypeAction.EDIT) {
      editDeviceType(deviceType);
    } else if (action === DeviceTypeAction.ENROLL_DEVICE) {
      deviceEnrollment(deviceType);
    } else {
      showDeviceTypeManager(action, deviceType);
    }
  }, [
    deviceType,
    cloneDeviceType,
    editDeviceType,
    deviceEnrollment,
    showDeviceTypeManager,
  ]);

  return (
    <React.Fragment>
      <DetailsView
        {...props}
        className={"deviceTypeDetailsView"}
        icon={PrimaryIcon}
        title={deviceTypeId}
        routes={ROUTES}
        showLoadingIndicator={loading}
        tabsMarginTop={false}
        onClickAction={onClickDeviceTypeAction}
        showAccessDenied={showAccessDenied}
        showNotFound={showNotFound}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deviceType: DeviceTypeDetailsSelectors.getDeviceTypeV3(state),
  title: DeviceTypeDetailsSelectors.getDeviceTypeId(state),
  showDeletedView: DeviceTypeDetailsSelectors.isDeletedDeviceType(state),
  loading: DeviceTypeDetailsSelectors.isLoadingIndicatorVisible(state),
  errorMessage: DeviceTypeDetailsSelectors.getErrorMessage(state),
  actions: DeviceTypeDetailsSelectors.getActionMenuItems(state),
  actionsButtonDisabled: DeviceTypeDetailsSelectors.isActionsButtonDisabled(state),
  showModelVersionNotSupportedError: DeviceTypeDetailsSelectors.isModelVersionNotSupportedErrorVisible(state),
  showNotFound: DeviceTypeDetailsSelectors.isNotFoundVisible(state),
  showAccessDenied: DeviceTypeDetailsSelectors.isAccessDeniedVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  refresh: () => dispatch(DeviceTypeDetailsActions.refresh()),
  showDeviceTypeManager: action => dispatch(DeviceTypeDetailsActions.showDeviceTypeManager(action)),
  openDeviceEnrollment: (deviceType: DeviceTypeModelV3) =>
    dispatch(DeviceTypeDetailsActions.openDeviceEnrollment(deviceType)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypeDetailsContainer);
