import { Record } from "immutable";
import { getStringValue } from "@util";

export interface EditorViewItemAttributes {
  id: string;
  label: string;
  code: string;
  selected: boolean;
}

export class EditorViewItem extends Record({
  id: "",
  label: "",
  code: "",
  selected: false,
}) implements EditorViewItemAttributes {

  public static EMPTY: EditorViewItem = new EditorViewItem();

  public readonly id: string;
  public readonly label: string;
  public readonly code: string;
  public readonly selected: boolean;

  public getId(): string {
    return getStringValue(this.id);
  }

  public getLabel(): string {
    return getStringValue(this.label);
  }

  public getCode(): string {
    return this.code || "";
  }

  public isSelected(): boolean {
    return this.selected;
  }
}

export default EditorViewItem;