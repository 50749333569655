import React from "react";
import Color from "@Color";
import Fab from "@components/fab";
import classnames from "classnames";
import { clickHandler } from "@util";
import AddIcon from "@material-ui/icons/Add";
import { Tooltip } from "../tooltip";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

export interface AddIconButtonModel {
  className?: string;
  iconClassName?: string;
  color?: string;
  tooltipText?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export interface AddIconButtonActions {
  add?: () => void;
}

type Model = AddIconButtonModel;
type Actions = AddIconButtonActions;
type Props = WithStyles<typeof styles> & Model & Actions;

export const AddIconButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    iconClassName,
    color = Color.SIGNIFY_GREEN,
    tooltipText = "Create",
    disabled = false,
    add,
    style = {},
  } = props;

  return (
    <Fab
      className={classnames("addIconButton", className, classes.container)}
      disabled={disabled}
      style={{ color, ...style }}
      onClick={clickHandler(add)}
    >
      <Tooltip title={tooltipText}>
        <AddIcon className={classnames("icon", iconClassName, classes.icon)} color="inherit" />
      </Tooltip>
    </Fab>
  );
});

export default AddIconButton;
