import React from "react";
import classnames from "classnames";
import { Tooltip } from "@components";
import { QualityReportPlatformDomainId } from "@data";
import { Link, LinkProps } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { isEmptyString, isQualityReportsEnabled, stopPropagation } from "@util";
import { PrimaryIcon as QualityReportsIcon } from "@modules/qualityReports/components/styles";
import {
  COMMON_SERVICES_QUALITY_REPORTS_PATH,
  DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH,
  DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH,
  SECURITY_SERVICES_QUALITY_REPORTS_PATH,
  SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH,
} from "@modules/qualityReports/constants";
import styles from "./styles";

const IconButton = React.forwardRef((props: IconButtonProps<Link, LinkProps>, ref: React.Ref<any>) => {
  return (
    <MuiIconButton
      {...props}
      component={Link}
      ref={ref}
    />
  );
});

const mapPlatformDomainToPath = (platformDomain: QualityReportPlatformDomainId): string => {
  switch (platformDomain) {
    case QualityReportPlatformDomainId.SECURITY_SERVICES:
      return SECURITY_SERVICES_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.DEVICE_MANAGEMENT:
      return DEVICE_MANAGEMENT_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.DATA_AND_ANALYTICS:
      return DATA_AND_ANALYTICS_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.COMMON_SERVICES:
      return COMMON_SERVICES_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.SELF_SERVICE_TOOLS:
      return SELF_SERVICE_TOOLS_QUALITY_REPORTS_PATH;
    case QualityReportPlatformDomainId.NONE:
    default:
      return "";
  }
};

export interface QualityReportsIconButtonProps {
  className?: string;
  tooltip?: string;
  size?: "small" | "medium";
  platformDomain?: QualityReportPlatformDomainId;
  qualityReportsEnabled?: boolean;
}

type Props = WithStyles<typeof styles> & QualityReportsIconButtonProps;

export const QualityReportsIconButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    tooltip = "Quality Reports",
    size = "small",
    platformDomain = QualityReportPlatformDomainId.NONE,
    qualityReportsEnabled = isQualityReportsEnabled(),
    ...otherProps
  } = props;

  const path = React.useMemo(() => mapPlatformDomainToPath(platformDomain), [platformDomain]);

  if (!qualityReportsEnabled || isEmptyString(path)) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="right">
      <IconButton
        {...otherProps}
        className={classnames("qualityReportsIconButton", className, classes.container)}
        size={size}
        disableRipple={true}
        color="inherit"
        to={path}
        onClick={stopPropagation}
      >
        <QualityReportsIcon className={classnames("icon", classes.icon)}/>
      </IconButton>
    </Tooltip>
  );
});

export default QualityReportsIconButton;
