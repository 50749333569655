import React from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import DownloadFile from "@components/download-file";
import DownloadIcon from "@material-ui/icons/GetApp";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import JSZip, { JSZipObject, loadAsync } from "jszip";
import Typography from "@material-ui/core/Typography";
import { clickHandler, isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ProjectViewListItemView, {
  ProjectViewListItem,
  ProjectViewListItemType,
} from "./ProjectViewListItemView";
import ProjectViewActionsMenu from "./ProjectViewActionsMenu";
import styles from "./styles";

const readZipFile = (zipFile: JSZipObject): Promise<string> =>
  zipFile.async("string").catch(() => "");

const mapZipFileToProjectViewListItem = async (zipFile: JSZipObject,
                                               zip: JSZip | null = null): Promise<ProjectViewListItem | null> => {

  if (zip === null) {
    return Promise.resolve(null);
  }

  const id = zipFile.name;

  const name = id.split("/").filter(it => !isEmptyString(it)).pop() || "";

  if (isEmptyString(name)) {
    return Promise.resolve(null);
  }

  if (!zipFile.dir) {
    return Promise.resolve<ProjectViewListItem>({
      id,
      name,
      type: ProjectViewListItemType.FILE,
      code: await readZipFile(zipFile),
    });
  }

  return Promise.resolve<ProjectViewListItem>({
    id,
    name,
    type: ProjectViewListItemType.FOLDER,
    collapsed: true,
    children: await Promise.all(zip.filter((relativePath, file) => {
      if (file.name === id || relativePath.indexOf(id) !== 0 || file.name.indexOf(".DS_Store") >= 0) {
        return false;
      }
      return (relativePath.split(id).pop() || "").split("/").filter(it => !isEmptyString(it)).length === 1;
    }).map(async it => await mapZipFileToProjectViewListItem(it, zip))
      .filter(async promise => {
        const result = await promise;
        return result !== null;
      })).then(result => Array.prototype.concat.apply([], result)),
  });
};

const mapZipToProjectViewListItems = async (zip: JSZip | null): Promise<ProjectViewListItem[]> => {
  if (zip === null) {
    return Promise.resolve([] as ProjectViewListItem[]);
  }
  const fileRegistry = zip.files;
  return Object.keys(fileRegistry)
    .filter(key => key.indexOf(".DS_Store") === -1)
    .reduce(async (combined: Promise<ProjectViewListItem[]>, id: string) => {
      if (id.split("/").filter(it => !isEmptyString(it)).length > 1) {
        return combined;
      }
      const zipFile = fileRegistry[id];
      const listItem = await mapZipFileToProjectViewListItem(zipFile, zip);
      if (listItem === null) {
        return combined;
      }
      const data = await combined;
      return Promise.resolve(data.concat([listItem]));
    }, Promise.resolve([] as ProjectViewListItem[]));
};

export interface ProjectViewModel {
  className?: string;
  title?: string;
  workloadName?: string;
  files?: ProjectViewListItem[];
  emptyViewTitle?: string;
  emptyViewSubtitle?: string;
}

export interface ProjectViewActions {
  collapseFolder?: (id: string) => void;
  expandFolder?: (id: string) => void;
  setSelectedFile?: (id: string) => void;
  renameFile?: (id: string, updatedName: string) => void;
  deleteFile?: (id: string) => void;
  addFile?: (id: string, name: string, parentId?: string) => void;
  addFolder?: (id: string, name: string, parentId?: string) => void;
  setFiles?: (files: ProjectViewListItem[]) => void;
}

type Model = ProjectViewModel;
type Actions = ProjectViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProjectView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    title = "Files",
    workloadName = "workload",
    emptyViewTitle = "No Files Found",
    emptyViewSubtitle = "Click here to add file/folder",
    files = [],
    collapseFolder,
    expandFolder,
    setSelectedFile,
    renameFile,
    deleteFile,
    addFile = noop,
    addFolder = noop,
    setFiles = noop,
    children,
  } = props;

  const [zipFile, setZipFile] = React.useState<any>(null);

  const [baseCodeAdded, setBaseCodeAdded] = React.useState(false);

  const [downloadLink, setDownloadLink] = React.useState("");

  const [unzipping, setUnzipping] = React.useState(false);

  const showEmptyView = React.useMemo(() => files.length === 0, [files]);

  const downloadFiles = clickHandler(React.useCallback(() => {
    const zip = new JSZip();
    const iterate = (items: ProjectViewListItem[], relativePath = "") => {
      items.forEach(item => {
        const { name, type, code = "", children: fileContents = [] } = item;
        if (type === ProjectViewListItemType.FILE) {
          zip.file(`${relativePath}${name}`, code);
        } else if (type === ProjectViewListItemType.FOLDER) {
          iterate(fileContents, `${relativePath}/${name}/`);
        }
      });
    };
    iterate(files);
    zip.generateAsync({ type: "base64" }).then(base64 => {
      setDownloadLink(`data:application/zip;base64,${base64}`);
    });
  }, [files, setDownloadLink]));

  const clearAll = clickHandler(React.useCallback(() => {
    setFiles([]);
  }, [setFiles]));

  const addBaseCode = React.useCallback(() => {
      const folderId = uuid();
      const fileId = uuid();
      addFolder(folderId, workloadName);
      addFile(fileId, "main.py", folderId);
      setFiles((prevFiles: any) => [
        ...prevFiles,
        {
          id: folderId,
          name: workloadName,
          type: ProjectViewListItemType.FOLDER,
          collapsed: false,
          children: [
            {
              id: fileId,
              name: "main.py",
              type: ProjectViewListItemType.FILE,
              code:
  `import json

    def handler(event, context):
        # TODO implement
        return {
            'statusCode': 200,
            'body': json.dumps('Hello World!')
        }`,
            },
          ],
        },
      ]);
  }, [
    addFolder,
    addFile,
    renameFile,
    setFiles,
    workloadName,
  ]);

  const onDrop = React.useCallback(([selectedFile] = []) =>
    setZipFile(selectedFile), [setZipFile]);

  const onSelectFile = React.useCallback(event => {
    if (showEmptyView) {
      onDrop(Array.prototype.slice.call(
        event && event.target && event.target.files ? event.target.files : []));
    } else {
      event.stopPropagation();
    }}, [onDrop, showEmptyView]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  React.useEffect(() => {

    let ignore = false;

    if (zipFile) {
      loadAsync(zipFile)
        .then(zip => {
          if (!ignore) {
            setUnzipping(true);
            mapZipToProjectViewListItems(zip)
              .then(items => {
                if (!ignore) {
                  setFiles(items);
                  setUnzipping(false);
                }
              }, err => {
                if (!ignore) {
                  console.error("Failed to decompress zip error: " + err.message, err);
                  setUnzipping(false);
                }
              });
          }
        }, error => {
          if (!ignore) {
            console.error("Load Zip File Error: " + error.message, error);
          }
        });
    }

    return () => {
      ignore = true;
    };

  }, [zipFile, setUnzipping, setFiles]);

  React.useEffect(() => {
    if (!baseCodeAdded) {
      addBaseCode();
      setBaseCodeAdded(true);
    }
  }, [baseCodeAdded, addBaseCode]);

  return (
    <div className={classnames("projectView", className, classes.container)}>
      <div className={classnames("header", classes.header)}>
        <Typography className={classnames("title", classes.title)}>
          {title}
        </Typography>
        {!showEmptyView && (
          <React.Fragment>
            <IconButton
              className={classnames("clearAllButton", classes.iconButton)}
              size="small"
              onClick={clearAll}
            >
              <ClearIcon className={classnames("clearIcon", classes.icon)} />
            </IconButton>
            <IconButton
              className={classnames("downloadIconButton", classes.iconButton)}
              size="small"
              onClick={downloadFiles}
            >
              <DownloadIcon className={classnames("downloadIcon", classes.icon)} />
            </IconButton>
          </React.Fragment>
        )}
        <ProjectViewActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          addFile={() => addFile(uuid(), "")}
          addFolder={() => addFolder(uuid(), "")}
        />
      </div>
      <form
        {...getRootProps()}
        className={classnames("dragAndDropFile", classes.dragAndDropFile, {
          [classes.dragging]: isDragActive,
          "empty": !zipFile,
        })}
      >
        {unzipping && (
          <Typography className={classnames("unzippingTitle", classes.unzippingTitle)}>
            Loading Project Files...
          </Typography>
        )}
        {showEmptyView && (
          <div className={classnames("emptyView", classes.emptyView)}>
            <Typography className={classnames("emptyViewTitle", classes.emptyViewTitle)}>
              {emptyViewTitle}
            </Typography>
            <Typography className={classnames("emptyViewSubtitle", classes.emptyViewSubtitle)}>
              {emptyViewSubtitle}
            </Typography>
          </div>
        )}
        {!showEmptyView && (
          <ul className={classnames("list", classes.list)}>
            {files.map(({ id, name, ...file }) => (
              <ProjectViewListItemView
                key={id}
                className={classnames("listItem", classes.listItem)}
                file={{ id, name, ...file }}
                editMode={isEmptyString(name)}
                collapseFolder={collapseFolder}
                expandFolder={expandFolder}
                setSelectedFile={setSelectedFile}
                renameFile={renameFile}
                deleteFile={deleteFile}
                addFile={addFile}
                addFolder={addFolder}
              />
            ))}
          </ul>
        )}
        <input
          {...getInputProps()}
          type="file"
          onChange={onSelectFile}
        />
      </form>
      {downloadLink && (
        <DownloadFile
          href={downloadLink}
          fileName="project.zip"
          downloadFinished={() => setDownloadLink("")}
        />
      )}
      {children}
    </div>
  );
});

export default ProjectView;