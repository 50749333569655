import React from "react";
import classnames from "classnames";
import { SummaryViewData } from "@data";
import { summaryView as styles } from "./styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  SummaryViewModel,
  SummaryView as WorkloadInstanceSummaryView,
} from "@components";
import { isEmptyString } from "@util";

export interface Model extends SummaryViewModel {
  summaryViewItems?: SummaryViewData[];
  triggerInfoItems?: SummaryViewData[];
  children?: React.ReactNode;
}

export interface Actions {
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems,
    triggerInfoItems = [],
    children,
  } = props;

  return (
    <div className={classnames("summaryView", classes.container)}>
      <WorkloadInstanceSummaryView
        items={summaryViewItems}
      />
      {triggerInfoItems.some(x => !isEmptyString(x.value)) && (
        <WorkloadInstanceSummaryView
          className={classnames("triggerInfo", classes.triggerInfo)}
          title="Trigger Information"
          items={triggerInfoItems}
        />
      )}
      {children}
    </div>
  );
});

export default SummaryView;
