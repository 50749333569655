import React from "react";
import { noop } from "@util";
import styles from "./styles";
import classnames from "classnames";
import { CreateWorkloadRequestV3 } from "@data/CreateWorkloadRequestV3";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useCreateWorkload } from "@hooks/workloadsManager/use-create-workload";
import {
  CreateWorkloadStep,
  DEFAULT_STEP_LABELS,
  DEFAULT_STEPS,
} from "../containers/DataWorkloadWizard";
import SubmitApiRequestView, {
  SubmitApiRequestViewActions,
  SubmitApiRequestViewModel,
} from "@components/submit-api-request-view";
import { CodeInfo } from "../CreateWorkloadWizardView";
import { useCopyWorkloadCode } from "@hooks";
import { DATA_PROCESSING_DEVELOPER_GUIDE } from "@modules/dataWorkloads/constants";
import { WorkloadPythonCodeProperty } from "@data";

export interface Model extends CodeInfo, SubmitApiRequestViewModel<CreateWorkloadRequestV3, CreateWorkloadStep> {
  data?: CreateWorkloadRequestV3;
  codeProperties?: WorkloadPythonCodeProperty;
  stepLabels?: { [key: string]: string };
}

export interface Actions extends SubmitApiRequestViewActions<CreateWorkloadRequestV3, CreateWorkloadStep> {
  showWorkloadDetails?: (name: string, version?: number | "latest") => void;
  setCodeProperties?: (props: Partial<WorkloadPythonCodeProperty>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataWorkloadWizard = withStyles(styles)((props: Props) => {

  const {
    classes,
    sourceName = "",
    sourceVersion = 1,
    disabledSteps = [],
    containsCode = false,
    steps = DEFAULT_STEPS,
    stepLabels = DEFAULT_STEP_LABELS,
    data = CreateWorkloadRequestV3.EMPTY,
    mapStepToLabel = React.useCallback(step => stepLabels[step] || null, [ stepLabels ]),
    showWorkloadDetails = noop,
    children,
    ...otherProps
  } = props;

  const [{ name, version, ...model }, { createWorkload, reset, ...actions }] =
    useCreateWorkload({ data });

  const [{ }, { copyCode }] = useCopyWorkloadCode({ name, version, sourceName, sourceVersion });

  const onSuccess = React.useCallback(() => {
    if (containsCode) {
      copyCode();
    }
    showWorkloadDetails(name, version);
  }, [name, version, sourceName, sourceVersion, containsCode, copyCode, showWorkloadDetails]);

  const filteredSteps = React.useMemo(() => {
    return data.isQueryType() ? steps : steps.filter(step =>
      step !== CreateWorkloadStep.QUERY_EDITOR);
  }, [data, steps]);

  return (
    <SubmitApiRequestView
      {...model}
      {...actions}
      {...otherProps}
      className={classnames("createWorkload", classes.container)}
      snackbarId="create-workload"
      errorTitle="Create Workload Failed"
      saveButtonLabel="Create Workload"
      steps={filteredSteps}
      mapStepToLabel={mapStepToLabel}
      currentState={data}
      defaultState={CreateWorkloadRequestV3.EMPTY}
      disabledSteps={disabledSteps}
      saveButtonDisabled={disabledSteps.length > 0}
      reset={reset}
      save={createWorkload}
      onSuccess={onSuccess}
      onChangeCurrentStep={reset}
      documentationLink={DATA_PROCESSING_DEVELOPER_GUIDE}
    >
      {children}
    </SubmitApiRequestView>
  );
});

export default DataWorkloadWizard;
