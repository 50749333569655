import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import { useDropzone } from "react-dropzone";
import { codeInfoView as styles } from "./styles";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  AlertSeverity,
  DownloadButton,
  ErrorView,
  MissingAlertView,
  SaveButton,
  SummaryViewActionItems,
  SummaryViewModel,
  SummaryViewWithActions as WorkloadCodeSummaryView,
} from "@components";
// TODO: Why are we using the loading indicator from user-profile-attributes-list?
import LoadingIndicator from "@components/user-profile-attributes-list/LoadingIndicator";
import { WorkloadRuntimeOption } from "@data";
import WorkloadCodeEditor from "../components/WorkloadCodeEditor/WorkloadCodeEditor";

export interface Model extends SummaryViewModel {
  summaryViewItems?: SummaryViewActionItems[];
  codeUploaded?: boolean;
  fileDownloadLoading?: boolean;
  href?: string;
  workloadName?: string;
  file?: File;
  fileUploadSuccess?: boolean;
  fileUploadErrorMessage?: string;
  fileName?: string;
  fileType?: string;
  fileUploadLoading?: boolean;
  fileSelected?: boolean;
  codeUploadEnabled?: boolean;
  statusCode?: number;
  codeRuntime?: WorkloadRuntimeOption;
  children?: React.ReactNode;
}

export interface Actions {
  uploadCode?: () => void;
  downloadCode?: () => void;
  onSelectFile?: (event: WindowEventHandlers) => void;
  onDrop?: (files: File[]) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const CodeInfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    fileUploadSuccess = false,
    fileUploadLoading = false,
    codeUploaded,
    fileDownloadLoading,
    fileSelected = false,
    href,
    workloadName,
    fileName,
    fileType = "jar",
    summaryViewItems,
    fileUploadErrorMessage,
    codeUploadEnabled,
    statusCode,
    uploadCode = noop,
    downloadCode = noop,
    onSelectFile = noop,
    onDrop = noop,
    codeRuntime = WorkloadRuntimeOption.JAVA8,
    children,
  } = props;

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  // Verify this validation once backend is finalized.
  const isPythonWorkload = React.useMemo(() =>
    codeRuntime === WorkloadRuntimeOption.PYTHON312, [codeRuntime]);

  const downloadLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <DownloadIcon className={classnames("downloadButtonIcon", classes.buttonIcon)}/>
       Download Code
    </React.Fragment>
  ), []);

  const uploadLabelEl = React.useMemo<React.ReactNode>(() => (
    <React.Fragment>
      <UploadIcon className={classnames("uploadButtonIcon", classes.buttonIcon)}/>
      Upload Code
    </React.Fragment>
  ), []);

  const uploadButtonDisabled = React.useMemo(() =>
    !fileSelected || fileUploadSuccess || !isEmptyString(fileUploadErrorMessage) || codeUploadEnabled,
    [fileSelected, fileUploadSuccess, fileUploadErrorMessage, codeUploadEnabled]);

  const downloadButton = React.useMemo(() => (
    <DownloadButton
      className={classnames("download")}
      buttonClassName={classnames("downloadCodeButton", classes.downloadCodeButton)}
      downloadFileClassName="jarFile"
      fileName={`${workloadName}.${fileType}`}
      loading={fileDownloadLoading}
      disabled={!codeUploaded}
      color="primary"
      label={downloadLabelEl}
      downloadIndicatorSize={50}
      downloadFinishedDelay={0}
      href={href}
      download={downloadCode}
    />
  ), [
    classes,
    workloadName,
    fileType,
    fileDownloadLoading,
    codeUploaded,
    downloadLabelEl,
    href,
    downloadCode,
  ]);

  return (
    <div className={classnames("codeInfoView", classes.container)}>
      {!codeUploaded && (
        <MissingAlertView
          className={classnames("codeNotUploadedAlert", classes.missingAlert)}
          severity={AlertSeverity.WARNING}
          message={(
            <React.Fragment>
              <Typography variant="h6">
                You have to upload code to view code related information and release/test this workload
              </Typography>
              <Typography className={classes.missingAlertBody} variant="body1">
                To upload code, click below or drag and drop your file (.{fileType} file only)
              </Typography>
            </React.Fragment>
          )}
        />
      )}
      {!isPythonWorkload && (
        <React.Fragment>
          <WorkloadCodeSummaryView
            items={summaryViewItems}
          />
          <div className={classnames("controls", classes.controls)}>
            <SaveButton
              className={classnames("uploadCodeButton", classes.uploadCodeButton)}
              color={"primary"}
              label={uploadLabelEl}
              loading={fileUploadLoading}
              downloadIndicatorSize={50}
              disabled={uploadButtonDisabled}
              save={uploadCode}
            />
            {downloadButton}
          </div>
          <form
            {...getRootProps()}
            className={classnames("uploadContent", classes.content, {
              [classes.contentDragging]: !fileUploadSuccess && isDragActive,
              [classes.contentSelected]: fileUploadSuccess || (fileSelected && !isDragActive),
              [classes.contentDisabled]: codeUploadEnabled,
            })}
          >
            {codeUploadEnabled && (
              <div className={classes.disabledLabel}>
                Code can only be uploaded when workload is in DRAFT state
              </div>
            )}
            {fileUploadSuccess && !codeUploadEnabled && (
              <div className={classes.label}>
                Code uploaded successfully!
                <div>Click here to upload a different file</div>
              </div>
            )}
            {!fileUploadSuccess && !codeUploadEnabled && (
              <React.Fragment>
                {!fileSelected && isEmptyString(fileUploadErrorMessage) && (
                  <div className={classnames(classes.label, classes.uploadButton)}>
                    {codeUploaded && (
                      <div className={classnames(classes.label, classes.uploadButton)}>
                        Code already uploaded for this workload!
                        <div className={classnames(classes.subLabel)}>Click here to upload a different file</div>
                      </div>
                    )}
                    {!codeUploaded && (
                      <div className={classnames(classes.label, classes.uploadButton)}>
                        Click here to select a file to upload
                      </div>
                    )}
                  </div>
                )}
                {fileSelected && isEmptyString(fileUploadErrorMessage) && (
                  <div className={classnames(classes.label, classes.uploadButton)}>
                    File Name: {fileName}.
                    <div className={classnames(classes.subLabel)}>Click Upload Code button to continue.</div>
                    <div className={classnames(classes.subLabel)}>Click here to select a different file</div>
                  </div>
                )}
                {!isEmptyString(fileUploadErrorMessage) && (
                  <div>
                    <ErrorView title={"Error uploading file"} message={fileUploadErrorMessage} statusCode={statusCode}/>
                    <div className={classnames(classes.subLabel)}>Click here to try again or select a different file
                    </div>
                  </div>
                )}
                {fileSelected && fileUploadLoading && (
                  <LoadingIndicator
                    className={"loadingIndicator"}
                    label="Uploading..."
                    labelClassName={classnames("uploading", classes.dialogLabel)}
                  />
                )}
              </React.Fragment>
            )}
            <input {...getInputProps()} type="file" onChange={onSelectFile} disabled={codeUploadEnabled}/>
          </form>
        </React.Fragment>
      )}
      {isPythonWorkload && (
        <WorkloadCodeEditor
          workloadName={workloadName}
        />
      )}
      {children}
    </div>
  );
});

export default CodeInfoView;
