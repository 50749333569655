import React from "react";
import { noop } from "@util";
import { Radio, RadioGroup, TagEditor } from "@components";
import classnames from "classnames";
import DnsNameIcon from "@material-ui/icons/CheckCircle";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { internetConfig as styles } from "./styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CreateWorkloadRequestAttributesV3, CreateWorkloadRequestV3 } from "@data";

export interface Model {
  data?: CreateWorkloadRequestV3;
}

export interface Actions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributesV3>) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const InternetConfigView = withStyles(styles)((props: Props) => {

  const {
    classes,
    data = CreateWorkloadRequestV3.EMPTY,
    setWorkloadData = noop,
  } = props;

  const [internetAccess, setInternetAccess] = React.useState(false);

  const setInternetConfig = React.useCallback((value: boolean) => {
    setInternetAccess(value);
    setWorkloadData({
      workloadInternetConfig: {
        accessibleDns: value ? data.getWhitelistedDNSNames() : [],
      },
    });
  }, [setInternetAccess, setWorkloadData]);

  return (
    <div className={classnames("internetConfig", classes.container)}>
      <label className={classes.title}>Configure Internet Access</label>
      <label className="accessLabel">Does this workload require access to the internet?</label>
      <RadioGroup
        name="internetAccess"
        value={internetAccess}
        onChange={(event) => setInternetConfig(event.target.value === "true")}
      >
        <FormControlLabel control={<Radio/>} label="Yes" value={true}/>
        <FormControlLabel control={<Radio/>} label="No" value={false}/>
      </RadioGroup>
      {internetAccess && (
        <TagEditor
          className={classnames("dnsEditor", classes.dnsEditor)}
          tags={data.getWhitelistedDNSNames()}
          label="Add Whitelisted DNS Names"
          emptyValueLabel="No DNS Names Defined"
          icon={<DnsNameIcon className={classnames("nameIcon", classes.nameIcon)} />}
          helperText="DNS names to allow access to the internet"
          setTags={(names) => setWorkloadData({
            workloadInternetConfig: {
              accessibleDns: names,
            },
          })}
        />
      )}
    </div>
  );
});

export default InternetConfigView;
