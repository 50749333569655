import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      border: `1px solid ${Color.LIGHT_GREY}`,
      maxWidth: "40%",
      overflow: "auto"
    },
    header: {
      flex: "0 0 auto",
      backgroundColor: Color.BLACK5,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    title: {
      flex: "1 0 auto",
      color: Color.WHITE,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16px",
      margin: "10px 8px",
      padding: 0,
      textTransform: "uppercase",
    },
    iconButton: {
      width: "auto",
      height: "auto",
      margin: "0 5px",
      color: Color.WHITE,
    },
    icon: {
      width: "auto",
      height: 20,
      color: "inherit",
    },
    actionsMenu: {
      flex: "0 0 auto",
    },
    list: {
      flex: "1 0 auto",
      padding: 0,
      margin: 0,
      display: "flex",
      flexFlow: "column nowrap",
    },
    listItem: {
      flex: "0 0 auto",
    },
    listItemActionsIconButton: {
      width: "auto",
      height: "auto",
      marginLeft: 5,
    },
    listItemActionsIcon: {
      width: 10,
      height: 10,
    },
    listItemIcon: {
    },
    folder: {
    },
    folderIcon: {
    },
    file: {
    },
    fileIcon: {
    },
    selected: {
    },
    emptyView: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      cursor: "pointer"
    },
    unzippingTitle: {
      marginTop: 90,
      color: Color.GREY3,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "center",
    },
    emptyViewTitle: {
      marginTop: 90,
      color: Color.TEXT,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px",
    },
    emptyViewSubtitle: {
      marginTop: 20,
      color: Color.GREY3,
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "16px",
    },
    dragAndDropFile: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    dragging: {
      backgroundColor: Color.LIGHT_GREY2,
    },
  });

export default styles;