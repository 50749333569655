import React from "react";
import classnames from "classnames";
import camelCase from "lodash/camelCase";
import Typography from "@material-ui/core/Typography";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { VariablesListColumn } from "@components";
import { styles } from "./styles";
import { WorkloadInstanceVariable } from "@data";

export interface InstanceVariablesListItemModel {
  className?: string;
  column?: VariablesListColumn;
  item?: WorkloadInstanceVariable;
}

export interface InstanceVariablesListItemActions {
}

type Model = InstanceVariablesListItemModel;
type Actions = InstanceVariablesListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const InstanceVariablesListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    column = VariablesListColumn.NONE,
    item = WorkloadInstanceVariable.EMPTY,
    children,
  } = props;

  const content = React.useMemo(() => {
    if (column === VariablesListColumn.NAME) {
      return (
        <Typography className={classnames("nameLabel", classes.label)}>
          {item.getName()}
        </Typography>
      );
    } else if (column === VariablesListColumn.VALUE) {
      return (
        <Typography className={classnames("descriptionLabel", classes.label)}>
          {item.getValue()}
        </Typography>
      );
    }
    return null;
  }, [classes, column, item]);

  if (WorkloadInstanceVariable.EMPTY.equals(item) || column === VariablesListColumn.NONE) {
    return null;
  }

  return (
    <div className={classnames("variablesListItem", className, camelCase(`${column}Column`), classes.container)}>
      {content}
      {children}
    </div>
  );
});

export default InstanceVariablesListItem;
