import React from "react";
import { isEmptyString } from "@util";
import SchemaValidationView, {
  SchemaValidationActions,
  SchemaValidationModel,
} from "@components/schema-validation-view";
import { useSchemaValidation } from "@hooks";

interface ContainerModel extends SchemaValidationModel {
  namespace: string;
  name: string;
  version: string;
  showLoadingIndicator?: boolean;
}

interface ContainerActions extends SchemaValidationActions {
}

type Props = ContainerModel & ContainerActions;

const SchemaValidationViewContainer = (props: Props) => {

  const {
    namespace,
    name ,
    version,
    schema,
    showLoadingIndicator,
    ...otherProps
  } = props;

  const showEmptyView = React.useMemo(() =>
      isEmptyString(schema) && showLoadingIndicator,
    [schema, showLoadingIndicator]);

  const [model, actions] = useSchemaValidation({
    namespace,
    name,
    version,
  });

  return (
    <SchemaValidationView
      {...otherProps}
      {...model}
      {...actions}
      schema={schema}
      showEmptyView={showEmptyView}
    />
  );
};

export default SchemaValidationViewContainer;
