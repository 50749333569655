import { useApiRequestAnalytics } from "@hooks";
import { FederationProviderAttributes } from "@data";
import { GetFederationProvidersResponse, FederationProviderClient } from "@network";

export type GetFederationProvidersApi = () => Promise<GetFederationProvidersResponse>;
export type GetFederationProviderApi = (providerId: string) => Promise<FederationProviderAttributes>;

export interface FederationProvidersClientApi {
  getFederationProvider: GetFederationProviderApi;
  getFederationProviders: GetFederationProvidersApi;
}

enum ActionTypes {
  GET_FEDERATION_PROVIDER_REQUEST = "federation_providers_client/get_federation_provider_request",
  GET_FEDERATION_PROVIDER_SUCCESS = "federation_providers_client/get_federation_provider_success",
  GET_FEDERATION_PROVIDER_FAILED = "federation_providers_client/get_federation_provider_failed",

  GET_FEDERATION_PROVIDERS_REQUEST = "federation_providers_client/get_federation_providers_request",
  GET_FEDERATION_PROVIDERS_SUCCESS = "federation_providers_client/get_federation_providers_success",
  GET_FEDERATION_PROVIDERS_FAILED = "federation_providers_client/get_federation_providers_failed",
}

export const useGetFederationProviderApi = () =>
  useApiRequestAnalytics(FederationProviderClient.getFederationProvider, {
    REQUEST_EVENT: ActionTypes.GET_FEDERATION_PROVIDER_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_FEDERATION_PROVIDER_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_FEDERATION_PROVIDER_FAILED,
  });

export const useGetFederationProvidersApi = () =>
  useApiRequestAnalytics(FederationProviderClient.getFederationProviders, {
    REQUEST_EVENT: ActionTypes.GET_FEDERATION_PROVIDERS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_FEDERATION_PROVIDERS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_FEDERATION_PROVIDERS_FAILED,
  });

export const useFederationProvidersClient = (): FederationProvidersClientApi => ({
  getFederationProvider: useGetFederationProviderApi(),
  getFederationProviders: useGetFederationProvidersApi(),
});

export { ActionTypes as FederationProviderClientActionType };

export default useFederationProvidersClient;
