import React from "react";
import styles from "./styles";
import classnames from "classnames";
import { Tooltip } from "../tooltip";
import { Link, LinkProps } from "react-router-dom";
import { MODULE_PATH } from "@modules/costReports/constants";
import { isCostReportsEnabled, stopPropagation } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { PrimaryIcon as CostReportsIcon } from "@modules/costReports/components/styles";

const IconButton = React.forwardRef((props: IconButtonProps<Link, LinkProps>, ref: React.Ref<any>) => {
  return (
    <MuiIconButton
      {...props}
      component={Link}
      ref={ref}
    />
  );
});

export interface CostReportsIconButtonProps {
  className?: string;
  tooltip?: string;
  size?: "small" | "medium";
  costReportsEnabled?: boolean;
}

type Props = WithStyles<typeof styles> & CostReportsIconButtonProps;

export const CostReportsIconButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    size = "small",
    tooltip = "Cost Reports",
    costReportsEnabled = isCostReportsEnabled(),
    ...otherProps
  } = props;

  if (!costReportsEnabled) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="right">
      <IconButton
        {...otherProps}
        className={classnames("costReportsIconButton", className, classes.container)}
        size={size}
        disableRipple={true}
        color="inherit"
        to={MODULE_PATH}
        onClick={stopPropagation}
      >
        <CostReportsIcon className={classnames("icon", classes.icon)} />
      </IconButton>
    </Tooltip>
  );
});

export default CostReportsIconButton;
