import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import PortalSessionInfo, { PortalSessionInfoAttributes } from "@data/PortalSessionInfo";
import usePortalSessionManagementClient from "../use-portal-session-management-client";

type SuccessResponse = PortalSessionInfoAttributes;

export interface UseRefreshPortalSessionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  accessToken: string;
}

export interface UseRefreshPortalSessionModel extends UseApiRequestModel<SuccessResponse> {
  sessionInfo: PortalSessionInfo;
}

export interface UseRefreshPortalSessionActions extends UseApiRequestActions<SuccessResponse> {
  refreshPortalSession: () => void;
}

type Props = UseRefreshPortalSessionProps;
type Model = UseRefreshPortalSessionModel;
type Actions = UseRefreshPortalSessionActions;
type Result = [Model, Actions];

export const useRefreshPortalSession = (props: Props): Result => {

  const {
    accessToken,
    defaultErrorMessage = "Failed to refresh portal session",
    deferRequest = true,
    ...otherProps
  } = props;

  const PortalSessionManagementClient = usePortalSessionManagementClient();

  const makeApiRequest = React.useCallback(() =>
      PortalSessionManagementClient.refreshPortalSession(accessToken),
    [PortalSessionManagementClient, accessToken]);

  const [{ successResponse, ...baseModel }, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest,
      makeApiRequest,
    });

  const sessionInfo = React.useMemo<PortalSessionInfo>(() => {
    return new PortalSessionInfo(successResponse || {});
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    sessionInfo,
  }), [
    baseModel,
    sessionInfo,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    refreshPortalSession: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useRefreshPortalSession;
