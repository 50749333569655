import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import {
  ActionMenuItem,
  DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS,
  FederationProviderAction,
  SummaryJsonDetailsView,
  ChipView,
} from "@components";
import FederationProviderIcon from "@material-ui/icons/Domain";
import { noop } from "@util";
import ScopeIcon from "@material-ui/icons/Label";
import { useGetFederationProvider } from "@hooks";
import { FederationProvider, SummaryViewData } from "@data";
import { open as deleteFederationProvider } from "@modules/deleteFederationProvider/actions";

interface ContainerModel {
  providerId: string;
}

interface ContainerActions {
  editFederationProvider?: () => void;
  deleteFederationProvider?: (providerId: string) => void;
  onClickAction?: (action: ActionMenuItem) => void;
}

type Props = ContainerModel & ContainerActions;

const FederationProviderContainer = (props: Props) => {

  const {
    providerId,
    editFederationProvider = noop,
    deleteFederationProvider: deleteProvider = noop,
    onClickAction = noop,
  } = props;

  const [{
    provider,
    errorMessage,
    showNotFound,
    showAccessDenied,
    showLoadingIndicator,
    statusCode,
  }, { refresh }] = useGetFederationProvider({ providerId });

  const title = React.useMemo(() => provider.getDomain(), [provider]);

  const scopes = React.useMemo(() => provider.scope.values, [provider]);

  const json = React.useMemo(() => {
    // If the page is loading, only show the bare minimum to avoid showing potentially stale data
    const data = showLoadingIndicator ? Object.freeze({ ...FederationProvider.EMPTY.toJS() }) : provider.toJS();

    return JSON.stringify(data, null, "  ");
  }, [showLoadingIndicator, provider]);

  const actionClicked = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case FederationProviderAction.DELETE_FEDERATION_PROVIDER:
        return deleteProvider(providerId);
      case FederationProviderAction.EDIT_FEDERATION_PROVIDER:
        return editFederationProvider();
      default:
        return onClickAction(action);
    }
  }, [providerId, deleteProvider, editFederationProvider, onClickAction]);

  const ScopeView = React.useMemo(() => (
    <ChipView
      title={"Scope"}
      items={scopes}
      className={"scope"}
      icon={ScopeIcon}
    />
  ), [scopes]);

  const items = React.useMemo(() =>
    [
      new SummaryViewData({
        className: "accountId",
        name: "Account ID",
        value: provider.accountId,
      }),
      new SummaryViewData({
        className: "providerId",
        name: "Provider ID",
        value: providerId,
      }),
      new SummaryViewData({
        className: "domain",
        name: "Domain",
        value: provider.domain,
      }),
      new SummaryViewData({
        className: "clientId",
        name: "Client ID",
        value: provider.clientId,
      }),
      new SummaryViewData({
        className: "clientSecret",
        name: "Client Secret",
        value: provider.clientSecret,
      }),
      new SummaryViewData({
        className: "discoveryDocument",
        name: "Discovery Document",
        value: provider.discoveryDocument,
      }),
      new SummaryViewData({
        className: "authorizationEndpoint",
        name: "Authorization Endpoint",
        value: provider.authorizationEndpoint,
      }),
      new SummaryViewData({
        className: "tokenEndpoint",
        name: "Token Endpoint",
        value: provider.tokenEndpoint,
      }),
      new SummaryViewData({
        className: "userEndpoint",
        name: "User Endpoint",
        value: provider.userEndpoint,
      }),
      new SummaryViewData({
        className: "jwksUri",
        name: "Jwks Uri",
        value: provider.jwksUri,
      }),
      new SummaryViewData({
        className: "issuer",
        name: "Issuer",
        value: provider.issuer,
      }),
      new SummaryViewData({
        className: "federationClosed",
        name: "Federation Closed",
        value: provider.federationClosed + "",
      }),
      new SummaryViewData({
        className: "attributes",
        name: "Attributes",
        value: JSON.stringify(provider.attributes, null, "  "),
      }),
    ], [provider, providerId]);

  return (
    <SummaryJsonDetailsView
      title={title}
      icon={FederationProviderIcon}
      summaryViewItems={items}
      json={json}
      fileName={`${title}.json`}
      actions={DEFAULT_FEDERATION_PROVIDER_ACTION_MENU_ITEMS}
      onClickAction={actionClicked}
      additionalViews={ScopeView}
      refresh={refresh}
      showLoadingIndicator={showLoadingIndicator}
      errorMessage={errorMessage}
      showNotFound={showNotFound}
      showAccessDenied={showAccessDenied}
      statusCode={statusCode}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  deleteFederationProvider: (providerId: string) => dispatch(deleteFederationProvider(providerId)),
  ...ownProps
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(FederationProviderContainer);
