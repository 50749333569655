import React from "react";
import { DeviceTypeListItem } from "@data";
import { GetDeviceTypesResponse } from "@network";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";
import {
  useDeviceTypeClient,
  usePaginatedApiRequest,
  UsePaginatedApiRequestActions,
  UsePaginatedApiRequestModel,
  UsePaginatedApiRequestProps,
} from "@hooks";

type SuccessResponse = GetDeviceTypesResponse;

export interface UseDeviceTypesProps extends Partial<UsePaginatedApiRequestProps<SuccessResponse>> {
  searchQuery?: string;
  initialDeviceTypes?: DeviceTypeListItem[];
}

export interface UseDeviceTypesModel extends UsePaginatedApiRequestModel<SuccessResponse> {
  deviceTypes: DeviceTypeListItem[];
}

export interface UseDeviceTypesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
  loadMore: () => void;
  refresh: () => void;
  reset: () => void;
}

export interface DeviceTypesActions extends UsePaginatedApiRequestActions<SuccessResponse> {
}

type Props = UseDeviceTypesProps;
type Model = UseDeviceTypesModel;
type Actions = UseDeviceTypesActions;
type Result = [Model, Actions];

export const useDeviceTypes = (props: Props): Result => {

  const {
    searchQuery = "",
    initialDeviceTypes = [],
    defaultErrorMessage = "Failed to fetch device types",
    ...otherProps
  } = props;

  const DeviceTypeClient = useDeviceTypeClient();

  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypeListItem[]>(initialDeviceTypes);

  const makeApiRequest = React.useCallback((accessToken, next) =>
    DeviceTypeClient.getDeviceTypes(next, searchQuery),
    [DeviceTypeClient, searchQuery]);

  const [
    { successResponse, ...baseModel },
    { reset: baseReset, refresh: baseRefresh, ...baseActions },
  ] = usePaginatedApiRequest<SuccessResponse>({
    ...otherProps,
    defaultErrorMessage,
    makeApiRequest,
  });

  const updatedDeviceTypes = React.useMemo(() => {
    const { items = [] } = successResponse || {};
    return items.map(attrs => new DeviceTypeListItem(attrs));
  }, [successResponse]);

  const validatedDeviceTypes = React.useMemo(() => {
    return isDeviceTypeV2APIEnabled() ? deviceTypes :
      deviceTypes.filter(deviceType => deviceType.isRegionalModelVersion());
  }, [deviceTypes, isDeviceTypeV2APIEnabled]);

  const reset = React.useCallback(() => {
    setDeviceTypes([]);
    baseReset();
  }, [setDeviceTypes, baseReset]);

  const refresh = React.useCallback(() => {
    reset();
    baseRefresh();
  }, [reset, baseRefresh]);

  // Refresh whenever a search filter is changed
  React.useEffect(refresh, [refresh, searchQuery]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successResponse,
    deviceTypes: validatedDeviceTypes,
  }), [
    baseModel,
    successResponse,
    validatedDeviceTypes,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    reset,
    refresh,
  }), [
    baseActions,
    reset,
    refresh,
  ]);

  React.useEffect(() => {
    setDeviceTypes(currentDeviceTypes => currentDeviceTypes.concat(updatedDeviceTypes));
  }, [updatedDeviceTypes, setDeviceTypes]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useDeviceTypes;
