import React from "react";
import { JsonSchemaMetadata } from "@data";
import { useParams, useHistory } from "react-router-dom";
import { getEditSchemaPath } from "@modules/schemaWizard/helpers";
import SchemaDetails from "./containers/SchemaDetails";

export function SchemaDetailsView() {

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const editSchema = React.useCallback(() =>
      history.push(getEditSchemaPath(id), { goBackOnSuccess: true}),
    [history, id]);

  const { name, version, namespace } = React.useMemo(() =>
    JsonSchemaMetadata.fromNameAndVersion(id).toJS(), [id]);

  return (
    <SchemaDetails
      key={id}
      name={name}
      namespace={namespace}
      version={`${version}`}
      editSchema={editSchema}
    />
  );
}

export default SchemaDetailsView;
