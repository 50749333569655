import { AppSchema } from "@schemas";
import { createActions } from "@base";
import { getAuthToken } from "@main/selectors";
import { getStringValue, isValidNumber } from "@util";
import { DeviceTypeAction } from "@modules/deviceTypeManager/reducers";
import { open as openDeviceTypeManager } from "@modules/deviceTypeManager/actions";
import { DeviceTypeClient, GetSoftwareVersionsResponse, RestClientError } from "@network";
import {
  DeviceTypeModelVersion,
  DeviceTypeListItem,
  DeviceTypeModelV2,
  DeviceTypeModelV2Attributes,
  DeviceTypeModelV3,
  DeviceTypeModelV3Attributes,
  DeviceTypeState,
} from "@data";
import {
  ACTION_TYPES,
  DEFAULT_STATE,
  DeviceTypeSchemaViewMode
} from "../reducers";
import { ManageSoftwareVersionsActions, SoftwareVersionDetailsActions } from "../actions";
import { DeviceTypeDetailsSelectors } from "../selectors";
import { openDeviceEnrollmentFromDeviceType as openDeviceEnrollmentWizard } from "@modules/deviceEnrollmentWizard/actions/deviceEnrollmentWizard";
import isDeviceTypeV2APIEnabled from "@util/isDeviceTypeV2APIEnabled";

export const {
  deviceTypeV3: setDeviceTypeV3Attributes,
  deviceTypeV2: setDeviceTypeV2Attributes,
  softwareVersions: setSoftwareVersions,
  selectedSoftwareVersion: setSelectedSoftwareVersion,
  jsonV3: setJsonV3,
  jsonV2: setJsonV2,
  showHistoricalData: setShowHistoricalDataView,
  etag: setEtag,
  modelVersion: setModelVersion,
  isLatest: setIsLatest,
  schemaViewMode: setSchemaViewMode,
  setErrorMessage,
  showLoadingIndicator,
  hideLoadingIndicator,
  showEmptyView,
  hideEmptyView,
  showAccessDenied,
  hideAccessDenied,
  showNotFound,
  hideNotFound,
  LOAD_DEVICE_TYPE_REQUEST: loadDeviceTypeRequest,
  LOAD_DEVICE_TYPE_SUCCESS: loadDeviceTypeSuccess,
  LOAD_DEVICE_TYPE_FAILED: loadDeviceTypeFailed,
  ...privateActions
} = createActions(ACTION_TYPES, DEFAULT_STATE);

const { baseReset } = privateActions;

export const reset = () => (dispatch: any) => {
  dispatch(setDeviceTypeV3Attributes());
  dispatch(setDeviceTypeV2Attributes());
  dispatch(setSoftwareVersions());
  dispatch(setSelectedSoftwareVersion());
  dispatch(setJsonV3());
  dispatch(setJsonV2());
  dispatch(setShowHistoricalDataView());
  dispatch(setEtag());
  dispatch(setModelVersion());
  dispatch(setIsLatest());
  dispatch(setSchemaViewMode());
  dispatch(ManageSoftwareVersionsActions.reset());
  dispatch(SoftwareVersionDetailsActions.reset());
  return dispatch(baseReset());
};

export const showConfigurationView = () => setSchemaViewMode(DeviceTypeSchemaViewMode.CONFIGURATION);
export const showMetadataView = () => setSchemaViewMode(DeviceTypeSchemaViewMode.METADATA);

export const updateSoftwareVersions = (softwareVersions: string[] = DEFAULT_STATE.softwareVersions) =>
  (dispatch: any, getState: () => AppSchema) => {

    dispatch(setSoftwareVersions(softwareVersions));

    if (!DeviceTypeDetailsSelectors.isSoftwareVersionSelected(getState())) {
      dispatch(setSelectedSoftwareVersion(softwareVersions[0]));
    }

    return Promise.resolve();
  };

export const showDeviceTypeManager = (action: DeviceTypeAction) =>
  (dispatch: any, getState: () => AppSchema) => dispatch(openDeviceTypeManager(
    DeviceTypeListItem.from(DeviceTypeDetailsSelectors.getDeviceTypeV3(getState())), action));

export const fetchDeviceType = () => (dispatch: any, getState: () => AppSchema) => {

  const state = getState();
  const authToken = getAuthToken(state);
  const namespace = DeviceTypeDetailsSelectors.getDeviceTypeNamespace(state);
  const name = DeviceTypeDetailsSelectors.getDeviceTypeName(state);
  const version = DeviceTypeDetailsSelectors.getDeviceTypeVersion(state);

  const getDeviceTypeSoftwareVersions: () => Promise<string[]> = () =>
    DeviceTypeClient.getDeviceTypeSoftwareVersions(authToken, namespace, name, version)
      .then((response: GetSoftwareVersionsResponse) => {

        const { softwareVersions = [] } = response;

        return Array.from(new Set(softwareVersions
          .map((softwareVersion: string) => getStringValue(softwareVersion))
          .filter((softwareVersion: string) => softwareVersion.length > 0)));
      });

  const getDeviceTypeV2 = () => {
    if (!isDeviceTypeV2APIEnabled()) {
      return Promise.resolve(DeviceTypeModelV2.EMPTY.toJS());
    } else {
      return DeviceTypeClient.getDeviceTypeV2(authToken, namespace, name, version);
    }
  };

  const getDeviceTypeDetails = () => Promise.all([
    DeviceTypeClient.getDeviceTypeV3(authToken, namespace, name, version),
    getDeviceTypeV2(),
    DeviceTypeClient.getDeviceTypeV3(authToken, namespace, name),
  ]).then(([responseV3, responseV2, { etag: latestVersionEtag }]) => {

    const deviceTypeV3 = new DeviceTypeModelV3(responseV3);
    const deviceTypeV3Attrs = deviceTypeV3.toJS();
    const jsonV3 = JSON.stringify(responseV3, null, "  ");

    const deviceTypeV2 = new DeviceTypeModelV2(responseV2);
    const deviceTypeV2Attrs = deviceTypeV2.toJS();
    const jsonV2 = JSON.stringify(responseV2, null, "  ");

    const etag = deviceTypeV3.getEtag();
    const isLatest = etag === latestVersionEtag;
    const modelVersion = deviceTypeV3.getModelVersion();

    return {
      deviceTypeV3Attrs,
      jsonV3,
      deviceTypeV2Attrs,
      jsonV2,
      etag,
      isLatest,
      modelVersion,
      softwareVersions: [],
    };
  }).then(response => {

    const { deviceTypeV3Attrs, isLatest } = response;

    const deviceTypeV3 = new DeviceTypeModelV3(deviceTypeV3Attrs);

    // We only need to double check the `isLatest` flag if the active device type is considered the
    // latest version and that version was created w/ DTS v1
    if (!isLatest || !isValidNumber(deviceTypeV3.getVersion()) || Number(deviceTypeV3.getVersion()) >= 1) {
      return Promise.resolve(response);
    }

    // Verify a deleted device type does not exist for this name & version; otherwise promoting
    // will fail w/ error: Cannot promote from version 0 to 1 because version 1 already exists
    // as a tombstone entry in the database
    return DeviceTypeClient.getLatestDeviceTypeByState(authToken, namespace, name, DeviceTypeState.DELETED)
      .then(() => {

        // If a deleted device type exists, we cannot consider this device type as the latest
        return Promise.resolve({
          ...response,
          isLatest: false,
        });
      })
      .catch(() => {
        // Otherwise, we can stick with our original assumptions
        return Promise.resolve(response);
      });
  }).then(response => {

    return getDeviceTypeSoftwareVersions()
      .then(softwareVersions => ({
        ...response,
        softwareVersions,
      }))
      .catch(() => {
        // Skip software versions and just return the original response if this API call fails
        return Promise.resolve(response);
      });
  });

  dispatch(showLoadingIndicator());
  dispatch(setErrorMessage());
  dispatch(hideNotFound());
  dispatch(hideAccessDenied());
  dispatch(setIsLatest(false));
  dispatch(loadDeviceTypeRequest());

  return getDeviceTypeDetails()
    .then((response: {
      deviceTypeV3Attrs: DeviceTypeModelV3Attributes,
      jsonV3: string,
      deviceTypeV2Attrs: DeviceTypeModelV2Attributes,
      jsonV2: string,
      etag: string,
      isLatest: boolean,
      modelVersion: DeviceTypeModelVersion,
      softwareVersions: string[],
    }) => {

      const {
        deviceTypeV3Attrs,
        jsonV3,
        deviceTypeV2Attrs,
        jsonV2,
        etag,
        isLatest,
        modelVersion,
        softwareVersions,
      } = response;

      dispatch(loadDeviceTypeSuccess());
      dispatch(setDeviceTypeV3Attributes(deviceTypeV3Attrs));
      dispatch(setJsonV3(jsonV3));
      dispatch(setDeviceTypeV2Attributes(deviceTypeV2Attrs));
      dispatch(setJsonV2(jsonV2));
      dispatch(setEtag(etag));
      dispatch(setIsLatest(isLatest));
      dispatch(setModelVersion(modelVersion));
      dispatch(updateSoftwareVersions(softwareVersions));
      dispatch(hideLoadingIndicator());
      dispatch(hideEmptyView());

    }, (response: RestClientError) => {

      const { analytic, status, error = "Fetch device type details failed" } = response;

      dispatch(loadDeviceTypeFailed(analytic));
      dispatch(setErrorMessage(error));
      dispatch(hideLoadingIndicator());

      if (status === 403) {
        dispatch(showAccessDenied());
      }

      if (status === 404) {
        dispatch(showNotFound());
      }

      return dispatch(hideEmptyView());
    });
};

export const refresh = () => (dispatch: any) => {

  return dispatch(fetchDeviceType());
};

export const openDeviceEnrollment = (deviceType: DeviceTypeModelV3) => (dispatch: any) => {
  return dispatch (openDeviceEnrollmentWizard(deviceType));
};

export const initialize = (typeIdentity: string) => (dispatch: any) => {

  dispatch(reset());
  dispatch(setDeviceTypeV3Attributes(new DeviceTypeModelV3({ typeIdentity }).toJS()));
  return dispatch(fetchDeviceType());
};
