import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { useDeviceTypes } from "@hooks";
import { DeviceTypeListItem } from "@data";
import { getNameFilter } from "../selectors";
import { PrimaryIcon as icon } from "../components/styles";
import DeviceTypesList from "../components/DeviceTypesList";
import { setNameFilter as updateNameFilter } from "../actions";

export interface ContainerModel {
  nameFilter?: string;
}

export interface ContainerActions {
  createDeviceType?: () => void;
  setNameFilter?: (name: string) => void;
  showDeviceTypeDetails?: (deviceType: DeviceTypeListItem) => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceTypesContainer = (props: Props) => {

  const {
    nameFilter = "",
    setNameFilter,
    createDeviceType,
    showDeviceTypeDetails,
    ...otherProps
  } = props;

  const [
    { deviceTypes, ...otherModel },
    { ...otherActions }
  ] = useDeviceTypes({ searchQuery: nameFilter });

  return (
    <DeviceTypesList
      {...otherProps}
      {...otherModel}
      {...otherActions}
      summaryViewIcon={icon}
      summaryViewLabel="Device Types"
      createButtonLabel="Create Device Type"
      createButtonClassName="createDeviceTypeButton"
      showSummary={true}
      showSearch={true}
      showCreateButton={true}
      items={deviceTypes}
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      onClickCreateButton={createDeviceType}
      showDeviceTypeDetails={showDeviceTypeDetails}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  nameFilter: getNameFilter(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  setNameFilter: value => dispatch(updateNameFilter(value)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceTypesContainer);
