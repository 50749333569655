import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 400,
      fontSize: 24,
    },
    list: {
      marginTop: 0,
      "& th.issue, & td.issue": {
        width: "12%",
      },
      "& th.description, & td.description": {
        width: "40%",
      },
      "& th.affectedService, & td.affectedService": {
        width: "12%",
      },
      "& th.compatibility, & td.compatibility": {
        width: "12%",
      },
      "& th.otherLink, & td.otherLink": {
        width: "12%",
      },
      "& td.tableBodyColumn .description.value": {
        display: "block",
        margin: "8px 8px 8px 0",
        whiteSpace: "pre-line",
      },
      "& label.placeholder": {
        fontSize: 12,
      },
    },
  });

export default styles;
