import React from "react";
import classnames from "classnames";
import { getStringValue } from "@util";
import Typography from "@material-ui/core/Typography";
import ErrorView from "@components/error-view";
import PaginatedList from "@components/paginated-list";
import DataLakeFileMetadataItem from "./DataLakeFileMetadataItem";
import CircularProgress from "@components/circular-progress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { useDataLakeFileMetadata } from "@hooks";
import styles from "./styles";

export enum DataLakeFileMetadataColumn {
  NONE = "",
  KEY = "Key",
  VALUE = "Value",
}

export interface DataLakeFileMetadataModel {
  className?: string;
  dataSetAlias?: string;
  file?: string;
  accountId?: string;
  enabled?: boolean;
}

export interface DataLakeFileMetadataActions {
}

type Model = DataLakeFileMetadataModel;
type Actions = DataLakeFileMetadataActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const DataLakeFileMetadata = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    dataSetAlias,
    file,
    accountId,
    children,
  } = props;

  const [model, actions] = useDataLakeFileMetadata({
    dataSetAlias,
    file,
    accountId,
  });

  const {
    metadata,
    showLoadingIndicator,
    showErrorView,
    showAccessDenied,
    errorMessage,
    errors,
    statusCode,
  } = model;

  const { refresh } = actions;

  const items = React.useMemo<DataLakeFileMetadataItem[]>(() =>
      Object.keys(metadata)
        .map(key => new DataLakeFileMetadataItem({
          key,
          value: getStringValue(metadata[key]),
        })),
    [metadata]);

  const title = React.useMemo(() =>
      showLoadingIndicator ? "Loading Metadata..." : `Metadata (${items.length})`,
    [showLoadingIndicator, metadata]);

  return (
    <div className={classnames("dataLakeFileMetadata", className, classes.container)}>
      <Typography className={classnames("title", classes.title)} variant="h3">
        {title}
      </Typography>
      {showLoadingIndicator && (
        <CircularProgress
          className={classnames("loadingIndicator", classes.loadingIndicator)}
          size={32}
        />
      )}
      {!showLoadingIndicator && (
        <React.Fragment>
          {showErrorView && (
            <ErrorView
              className={classnames("errorView", classes.errorView, {
                [classnames("accessDenied", classes.accessDenied)]: showAccessDenied,
              })}
              title={showAccessDenied ? "Access Denied" : "Failed to load metadata"}
              message={errorMessage}
              errors={errors}
              retry={refresh}
              statusCode={statusCode}
            />
          )}
          {!showErrorView && (
            <PaginatedList
              className={classnames("table", classes.table)}
              columns={[DataLakeFileMetadataColumn.KEY, DataLakeFileMetadataColumn.VALUE]}
              items={items}
              renderItem={(item: DataLakeFileMetadataItem, column: DataLakeFileMetadataColumn) => {
                switch (column) {
                  case DataLakeFileMetadataColumn.KEY:
                    return (
                      <label className={classnames("key", classes.label, classes.key)}>
                        {item.getKey()}
                      </label>
                    );
                  case DataLakeFileMetadataColumn.VALUE:
                    return (
                      <label className={classnames("value", classes.label, classes.value)}>
                        {item.getValue()}
                      </label>
                    );
                  default:
                    return null;
                }
              }}
            />
          )}
        </React.Fragment>
      )}
      {children}
    </div>
  );
});

export default DataLakeFileMetadata;
