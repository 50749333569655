import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import usePortalSessionManagementClient from "../use-portal-session-management-client";

type SuccessResponse = void;

export interface UseEndPortalSessionProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseEndPortalSessionModel extends UseApiRequestModel<SuccessResponse> {
}

export interface UseEndPortalSessionActions extends UseApiRequestActions<SuccessResponse> {
  endPortalSession: () => void;
}

type Props = UseEndPortalSessionProps;
type Model = UseEndPortalSessionModel;
type Actions = UseEndPortalSessionActions;
type Result = [Model, Actions];

export const useEndPortalSession = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to end portal session",
    deferRequest = true,
    ...otherProps
  } = props;

  const PortalSessionManagementClient = usePortalSessionManagementClient();

  const makeApiRequest = React.useCallback(() =>
      PortalSessionManagementClient.endPortalSession(),
    [PortalSessionManagementClient]);

  const [model, { refresh, ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest,
      makeApiRequest,
    });

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
    refresh,
    endPortalSession: refresh,
  }), [
    baseActions,
    refresh,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useEndPortalSession;
