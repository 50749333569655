import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { updateDeviceType } from "../actions/deviceTypeWizard";
import { getDeviceTypeRequestV3, isCredentialsViewSelected } from "../selectors";
import CredentialsView, { Actions, Model } from "../components/CredentialsView";
import { useCertificateAuthorities } from "@hooks";
import { isEmptyString } from "@util";
import { CertificateAuthority } from "@data/CertificateAuthority";

interface ContainerModel extends Model {
  hidden?: boolean;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const CredentialsViewContainer = (props: Props) => {

  const { hidden, ...otherProps } = props;

  const [ model ] = useCertificateAuthorities();

  const { showLoadingIndicator: loadingAuthorityIds, certificateAuthorities, errorMessage, statusCode } = model ;

  const cmsAuthorityIds = React.useMemo(() => {
    if (!isEmptyString(errorMessage)) {
      return [];
    } else {
      return certificateAuthorities.map((authority: CertificateAuthority) =>
        authority.getId()
      );
    }
  } , [errorMessage, certificateAuthorities]);

  const cmsAuthorityNames = React.useMemo(() =>
      certificateAuthorities.reduce((data, authority) => {
        const id = authority.getId();
        const name = authority.getCommonName();
        if (!isEmptyString(id) && !isEmptyString(name)) {
          data[id] = name;
        }
        return data;
      }, {}),
    [certificateAuthorities]);

  if (hidden) {
    return null;
  }

  return (
    <CredentialsView
      {...otherProps}
      cmsAuthorityIds={cmsAuthorityIds}
      cmsAuthorityNames={cmsAuthorityNames}
      loadingAuthorityIds={loadingAuthorityIds}
      errorMessage={errorMessage}
      statusCode={statusCode}
    />
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  hidden: !isCredentialsViewSelected(state),
  deviceType: getDeviceTypeRequestV3(state),
  ...ownProps
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  updateDeviceType: deviceType => dispatch(updateDeviceType(deviceType)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(CredentialsViewContainer);
