import { combineReducers } from "redux";
import { MODULE_ID } from "./constants";
import {
  BasePortalModuleSchema,
  createPortalModuleActionTypes,
  createPortalModuleDefaultState,
  createPortalModuleReducers,
  PortalModuleActionTypes,
} from "@base";

export interface PolicyDetailsSchema extends BasePortalModuleSchema {
  nameFilter: string;
}

export const DEFAULT_STATE = createPortalModuleDefaultState<PolicyDetailsSchema>({
  nameFilter: "",
});

type PolicyDetailsActionTypes = PortalModuleActionTypes<PolicyDetailsSchema>;

export const ACTION_TYPES: PolicyDetailsActionTypes =
  createPortalModuleActionTypes<PolicyDetailsSchema>(MODULE_ID, DEFAULT_STATE);

export const PolicyDetailsReducer = combineReducers<PolicyDetailsSchema>(
  createPortalModuleReducers<PolicyDetailsSchema>(ACTION_TYPES, DEFAULT_STATE));

export default PolicyDetailsReducer;
