const PORTAL_WEBSERVER_ENABLED =
  process.env.REACT_APP_PORTAL_WEBSERVER_ENABLED || "";

export const isPortalWebserverEnabled = () =>
  PORTAL_WEBSERVER_ENABLED.trim().toLowerCase() === "true" &&
  // We do not have support yet for running portal-client locally using the local dev server
  // in conjunction with running portal-webserver locally because the session management cookies
  // can only be used if both the DNS and port are identical (eg: localhost:3000 vs localhost:5000)
  process.env.NODE_ENV === "production";

export default isPortalWebserverEnabled;
