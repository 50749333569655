import isEqual from "lodash/isEqual";

export function applyInProd(inProd: () => any, otherwise?: () => any) {
  if (process.env.NODE_ENV === "production") {
    return inProd();
  } else if (otherwise) {
    return otherwise();
  }
}

export function memoize<K, O>(fn: (arg: K) => O) {
  let prevArgs: K;
  let prevAns: O;
  return (arg: K) => {
    if (prevArgs && arg && isEqual(prevArgs, arg)) {
      return prevAns;
    }
    prevArgs = arg;
    prevAns = fn(arg);
    return prevAns;
  };
}

// noinspection JSUnusedLocalSymbols
export const noop = (...args: any) => { /* noop */ };

export const isValidJson = (json: string): boolean => {
  try {
    return Boolean(JSON.parse(json));
  } catch (e) {
    return false;
  }
};

export const isValidRegex = (regex: string): boolean => {
  try {
    return Boolean(new RegExp(regex));
  } catch (e) {
    return false;
  }
};

/**
 * Helper that can determine if the provided value is either a number or a string
 * that can be converted into a number.
 */
export const isValidNumber = (value: string | number | undefined | null): boolean => {

  if (value === null) {
    return false;
  }

  if (typeof value === "undefined") {
    return false;
  }

  if (typeof value === "string") {

    if (value.trim().length === 0) {
      return false;
    }

    return !isNaN(Number(value + ""));
  }

  return !isNaN(value as number);
};

export const isString = (value: any): boolean => {
  return typeof value === "string";
};

export const getStringValueNoTrim = (value: any): string => {
  return `${isString(value) ? value : ""}`;
};

export const getStringValue = (value: any): string => {
  return `${isString(value) ? value : ""}`.trim();
};

export const isEmptyString = (value: any): boolean => {
  return getStringValue(value).length === 0;
};

/**
 * Helper that can determine if the provided value is either a number or a string
 * that can be converted into an integer (whole numbers only).
 */
export const isValidInteger = (value: string | number | undefined | null): boolean => {
  return isValidNumber(value) && Number(value) % 1 === 0;
};

export const parseBoolean = (value: string): boolean | string => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const equalsIgnoreCase = (value?: string, otherValue?: string): boolean => {
  if (typeof value !== "string" || typeof otherValue !== "string") {
    return false;
  } else {
    return getStringValue(value).toLowerCase() === getStringValue(otherValue).toLowerCase();
  }
};

export const getUrlParameter = (urlQueryParams: string, paramName: string): string => {
  const name = paramName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(urlQueryParams);
  return getStringValue(results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " ")));
};

export const removeUrlParameter = (url: string = "", paramName: string): string => {

  const start = url.indexOf("?");

  if (start >= 0) {
    return url.substring(0, start + 1) + removeUrlParameter(url.substring(start + 1), paramName);
  }

  return url.split("&")
    .filter(s => paramName !== s.split("=").slice(0, 1).join(""))
    .join("&");
};

export const getCloneName = (name: string = ""): string => {

  const textName = name.replace(/ *\([^)]*\) */g, "");
  const numberSuffix: number = (Number(name.replace(/[^0-9]/g, ""))) + 1;

  return `${textName}(${numberSuffix})`;
};

export function getMenuItems<T> (obj: T, func: any, elm: any) {
  const res = Object.values(obj).filter(value => !isEmptyString(value));
  return res.map(value => elm(value, func));
}

// Remove empty string values from an object
export function cleanEmptyString<T> (obj: T) {
  return Object.entries(obj)
    .filter(([_, v]) => isString(v) ? !isEmptyString(v) : true)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export function cleanEmptyArrays<T> (obj: T) {
  return Object.entries(obj)
    .filter(([_, v]) => Array.isArray(v) ? (v.length > 0) : true)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export function cleanEmptyStringsAndArrays<T> (obj: T) {
  let result = cleanEmptyString(obj);
  return cleanEmptyArrays(result);
}

export function checkIfValidUUID (value: string) {

  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(value);
}

const USER_REGEX = new RegExp(/lrn.*:principal:user:(\S+)/);
export function getUserPrincipal (value: string): string {
    const match = value.match(USER_REGEX);
    return (!Array.isArray(match) || match.length === 0) ? "" : getStringValue(match.pop());
}

/**
 * Determines whether the string contains anything other
 * than just letters, numbers, hypens, periods or underscores
 *
 * @param value - string to test
 * @returns boolean value
 */
export function hasSpecialChars(value: string): boolean {
  return !(new RegExp("^[a-zA-Z0-9_.-]*$").test(value));
}
