import React from "react";
import { Policy, PolicyAttributes } from "@data";
import {
  useApiRequest,
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
  usePolicyManagementClient,
} from "@hooks";

type SuccessResponse = PolicyAttributes;

export interface UseGetPolicyProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  name?: string;
}

export interface UseGetPolicyModel extends UseApiRequestModel<SuccessResponse> {
  policy: Policy;
}

export interface UseGetPolicyActions extends UseApiRequestActions<SuccessResponse> {
  refresh: () => void;
}

type Props = UseGetPolicyProps;
type Model = UseGetPolicyModel;
type Actions = UseGetPolicyActions;
type Result = [Model, Actions];

export const useGetPolicy = (props: Props): Result => {

  const {
    name = "",
    defaultErrorMessage = "Failed to get policy",
    ...otherProps
  } = props;

  const PolicyManagementClient = usePolicyManagementClient();

  const makeApiRequest = React.useCallback(() =>
    PolicyManagementClient.getPolicy(name),
    [PolicyManagementClient, name]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const policy = React.useMemo(() => {
    const attrs = successResponse || {};
    return new Policy(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    policy,
  }), [
    baseModel,
    policy,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetPolicy;
