import React from "react";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import FolderIcon from "@material-ui/icons/Folder";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import OpenFolderIcon from "@material-ui/icons/FolderOpen";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ProjectViewListItemActionsMenu from "./ProjectViewListItemActionsMenu";
import { clickHandler, enterKeyHandler, formEventHandler, isEmptyString, noop } from "@util";
import styles from "./styles";

export enum ProjectViewListItemType {
  NONE = "",
  FILE = "file",
  FOLDER = "folder",
}

export interface ProjectViewListItem {
  id: string;
  name: string;
  type: ProjectViewListItemType;
  code?: string;
  collapsed?: boolean;
  open?: boolean;
  selected?: boolean;
  children?: ProjectViewListItem[];
}

export interface ProjectViewListItemModel {
  className?: string;
  file?: ProjectViewListItem;
  editMode?: boolean;
}

export interface ProjectViewListItemActions {
  collapseFolder?: (id: string) => void;
  expandFolder?: (id: string) => void;
  setSelectedFile?: (id: string) => void;
  renameFile?: (id: string, updatedName: string) => void;
  deleteFile?: (id: string) => void;
  addFile?: (id: string, name: string, parentId?: string) => void;
  addFolder?: (id: string, name: string, parentId?: string) => void;
}

type Model = ProjectViewListItemModel;
type Actions = ProjectViewListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ProjectViewListItemView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    file: {
      id: fileId,
      name,
      type,
      collapsed,
      selected,
      children: folderContents = [],
    } = {
      name: "",
      type: ProjectViewListItemType.NONE,
    },
    editMode: initialShowEditNameMode = false,
    collapseFolder = noop,
    expandFolder = noop,
    setSelectedFile = noop,
    renameFile = noop,
    deleteFile = noop,
    addFile = noop,
    addFolder = noop,
    children,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [showActions, setShowActions] = React.useState(false);

  const [showEditNameMode, setShowEditNameMode] = React.useState(initialShowEditNameMode);

  const isFolder = React.useMemo(() => type === ProjectViewListItemType.FOLDER, [type]);

  const showFolderContents = React.useMemo(() =>
      isFolder && !collapsed && folderContents.length > 0,
    [isFolder, collapsed, folderContents]);

  const Icon = React.useMemo(() =>
      !isFolder ? FileIcon : (collapsed ? FolderIcon : OpenFolderIcon),
    [isFolder, collapsed]);

  const onClickFolder = React.useCallback(() => {
    if (!showEditNameMode) {
      if (collapsed) {
        expandFolder(fileId);
      } else {
        collapseFolder(fileId);
      }
    }
  }, [showEditNameMode, fileId, collapsed, collapseFolder, expandFolder]);

  const onClickFile = React.useCallback(() => {
    if (!showEditNameMode) {
      if (!selected) {
        setSelectedFile(fileId);
      }
    }
  }, [showEditNameMode, fileId, selected, setSelectedFile]);

  const onClickListItem = clickHandler(React.useCallback(() => {
    if (isFolder) {
      onClickFolder();
    } else {
      onClickFile();
    }
  }, [isFolder, onClickFolder, onClickFile]));

  const rename = React.useCallback(() => {
    setShowEditNameMode(true);
  }, [setShowEditNameMode]);

  const renameSelectedFile = React.useCallback(updatedName => {
    renameFile(fileId, updatedName);
  }, [fileId, renameFile]);

  const deleteSelectedFile = React.useCallback(() => {
    deleteFile(fileId);
  }, [fileId, deleteFile]);

  if (ProjectViewListItemType.NONE === type) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className={classnames("projectViewListItemView", className, classes.container, {
    [classes.folder]: isFolder,
      [classes.file]: !isFolder,
      [classes.collapsed]: collapsed,
      [classes.selected]: selected,
      [classes.editMode]: showEditNameMode,
  })}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        onClick={onClickListItem}
      >
  <Icon
    className={classnames("icon", classes.icon, {
    [classes.folderIcon]: isFolder,
      [classes.fileIcon]: !isFolder,
  })}
  />
  {!showEditNameMode && (
    <Typography
      className={classnames("label", classes.label)}
    >
    {name}
    </Typography>
  )}
  {showEditNameMode && (
    <React.Fragment>
      <TextField
        inputRef={inputRef}
        className={classnames("textField", classes.textField)}
        value={name}
        autoFocus={true}
        onChange={formEventHandler(renameSelectedFile)}
        onKeyDown={enterKeyHandler(() => {
        if (!isEmptyString(name)) {
          setShowEditNameMode(false);
        }
  })}
        onBlur={() => {
        if (isEmptyString(name) && inputRef && inputRef.current) {
          inputRef.current.focus();
        }
  }}
      />
    <Backdrop
      className={classnames("backdrop", classes.backdrop)}
      open={showEditNameMode}
      onClick={clickHandler(() => {
      if (!isEmptyString(name)) {
        setShowEditNameMode(false);
      } else if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
      })}
    />
    </React.Fragment>
  )}
      {showActions && !showEditNameMode && (
        <ProjectViewListItemActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          showFolderActions={isFolder}
          renameFile={rename}
          deleteFile={deleteSelectedFile}
          addFile={() => addFile(uuid(), "", fileId)}
          addFolder={() => addFolder(uuid(), "", fileId)}
        />
      )}
      </div>
  {showFolderContents && (
    <ul className={classnames("folderContents", classes.folderContents)}>
    {folderContents.map(({ id, name: fileName, ...file }) => (
      <ProjectViewListItemView
        key={id}
        className={classnames("folderContent", classes.folderContent)}
        file={{ id, name: fileName, ...file }}
        editMode={isEmptyString(fileName)}
        collapseFolder={collapseFolder}
        expandFolder={expandFolder}
        setSelectedFile={setSelectedFile}
        renameFile={renameFile}
        deleteFile={deleteFile}
        addFile={addFile}
        addFolder={addFolder}
      />
    ))}
    </ul>
  )}
  {children}
  </React.Fragment>
);
});

export default ProjectViewListItemView;