import { BASE_URL } from "./constants";

export const getPathToWorkload = (name: string, version: number | "latest" = "latest") =>
  `${BASE_URL}/${name}/version/${version}`;

export const getPathToWorkloadTriggers = (name: string, version?: number | "latest") =>
  `${getPathToWorkload(name, version)}/triggers`;

export const getPathToWorkloadExecutionHistory = (name: string, version?: number | "latest") =>
  `${getPathToWorkload(name, version)}/executions`;

export const getPathToWorkloadCode = (name: string, version?: number | "latest") =>
  `${getPathToWorkload(name, version)}/code`;

export const getPathToSwitchPrincipal = (name: string, version?: number | "latest") =>
  `${getPathToWorkload(name, version)}/switch-principal`;
