import React from "react";
import { logout } from "../actions";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { isEmptyString, noop } from "@util";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
import LayoutContent, { Actions, Model } from "../components/Layout/LayoutContent";
import {
  getLayoutContentModules,
  getPrincipalId,
  getProfilePath,
  getSettingsPath,
  isMaintenanceReminderVisible,
  isInformationBannerVisible,
} from "../selectors";

interface ContainerModel extends Model {
  showFooter?: boolean;
  contactInfo?: string[];
}

interface ContainerActions extends Actions {
  logout?: () => void;
}

type Props = ContainerModel & ContainerActions;

const LayoutContentContainer = (props: Props) => {

  const {
    showFooter,
    contactInfo,
    profilePath = "",
    settingsPath = "",
    logout: performLogout = noop,
    ...otherProps
  } = props;

  const history = useHistory();

  const showMyProfileButton = React.useMemo(() => !isEmptyString(profilePath), [profilePath]);

  const onClickMyProfile = React.useCallback(() => {
    if (showMyProfileButton && !isEmptyString(profilePath)) {
      history.push(profilePath);
    }
  }, [showMyProfileButton, profilePath, history]);

  const showSettingsButton = React.useMemo(() => !isEmptyString(settingsPath), [settingsPath]);

  const onClickSettings = React.useCallback(() => {
    if (showSettingsButton && !isEmptyString(settingsPath)) {
      history.push(settingsPath);
    }
  }, [showSettingsButton, settingsPath, history]);

  const onClickLogout = React.useCallback(() => {
    performLogout();
  }, [performLogout]);

  return (
    <LayoutContent
      {...otherProps}
      showMyProfileButton={showMyProfileButton}
      profilePath={profilePath}
      settingsPath={settingsPath}
      onClickSettings={onClickSettings}
      onClickMyProfile={onClickMyProfile}
      onClickLogout={onClickLogout}
    >
      {showFooter && <Footer contactInfo={contactInfo} />}
    </LayoutContent>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  routes: getLayoutContentModules(state),
  principalId: getPrincipalId(state),
  profilePath: getProfilePath(state),
  settingsPath: getSettingsPath(state),
  showMaintenanceReminder: isMaintenanceReminderVisible(state),
  informationBannerVisible: isInformationBannerVisible(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  logout: () => dispatch(logout()),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutContentContainer);
