import React from "react";
import { isEmptyString } from "@util";
import { InviteUserRequest } from "@data";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useUserIdmClient,
} from "@hooks";

type SuccessResponse = void;

export interface UseInviteUserProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  userId: string;
  applicationId: string;
  emailTemplateId: string;
  sendEmailCopy: boolean;
  inviteUserRequest: InviteUserRequest;
}

export interface UseInviteUserModel extends UseApiRequestModel<SuccessResponse> {
  successMessage: string;
}

export interface UseInviteUserActions {
  inviteUser: () => void;
  reset: () => void;
}

type Props = UseInviteUserProps;
type Model = UseInviteUserModel;
type Actions = UseInviteUserActions;
type Result = [Model, Actions];

export const useInviteUser = (props: Props): Result => {

  const {
    userId = "",
    applicationId = "",
    emailTemplateId = "",
    sendEmailCopy = false,
    inviteUserRequest = InviteUserRequest.EMPTY,
    defaultErrorMessage = "Failed to invite user",
    ...otherProps
  } = props;

  const UserIdmClient = useUserIdmClient();

  const makeApiRequest = React.useCallback(() =>
    UserIdmClient.inviteUser(applicationId, JSON.stringify({
      userId,
      copyEmailToAdmin: sendEmailCopy,
      profile: inviteUserRequest.profile,
      ...!isEmptyString(emailTemplateId) ? { emailTemplateId } : {}
    })), [UserIdmClient, applicationId, userId, sendEmailCopy, inviteUserRequest, emailTemplateId]);

  const [{ showSuccessView, ...baseModel }, { reset, refresh: inviteUser }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
      deferRequest: true,
    });

  const successMessage = React.useMemo(() => showSuccessView ?
    "User Invited" : "", [showSuccessView]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    reset,
    inviteUser,
  }), [
    reset,
    inviteUser,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useInviteUser;
