import { createStyles } from "@material-ui/core/styles";
import Color from "@Color";

export const styles = () =>
  createStyles({
    container: {
    },
    addIconButton: {
      width: "auto",
      height: "auto",
      margin: "0 5px",
      color: Color.WHITE,
    },
    addIcon: {
      width: "auto",
      height: 20,
      color: "inherit",
    },
    popper: {
      zIndex: 9999,
    },
    paper: {
    },
    list: {
      display: "flex",
      flexFlow: "column nowrap",
      border: `1px solid ${Color.LIGHT_GREY}`,
    },
    listItem: {
      fontSize: 13,
      fontWeight: 300,
      lineHeight: "16px",
      color: Color.TEXT,
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
    },
    addFile: {
    },
    addFolder: {
    },
  });

export default styles;