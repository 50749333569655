import { useApiRequestAnalytics } from "@hooks";
import { DeviceTypeClient, GetDeviceTypesResponse } from "@network";

export type GetDeviceTypesApi =
  (next?: string, nameFilter?: string, limit?: number) => Promise<GetDeviceTypesResponse>;

export interface DeviceTypeClientApi {
  getDeviceTypes: GetDeviceTypesApi;
}

enum ActionType {
  GET_DEVICE_TYPES_REQUEST = "device_type_client/get_device_types_request",
  GET_DEVICE_TYPES_SUCCESS = "device_type_client/get_device_types_success",
  GET_DEVICE_TYPES_FAILED = "device_type_client/get_device_types_failed",
}

export const useGetDeviceTypesApi = (): GetDeviceTypesApi =>
  useApiRequestAnalytics(DeviceTypeClient.getDeviceTypes, {
    REQUEST_EVENT: ActionType.GET_DEVICE_TYPES_REQUEST,
    SUCCESS_EVENT: ActionType.GET_DEVICE_TYPES_SUCCESS,
    ERROR_EVENT: ActionType.GET_DEVICE_TYPES_FAILED,
  });

export const useDeviceTypeClient = (): DeviceTypeClientApi => ({
  getDeviceTypes: useGetDeviceTypesApi(),
});

export { ActionType as DeviceTypeClientActionType };

export default useDeviceTypeClient;
