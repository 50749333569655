import React from "react";
import {
  UseApiRequestModel,
  UseApiRequestProps,
  useApiRequest,
  useWorkloadManagerClient
} from "@hooks";
import { WorkloadOperations } from "@components";

export interface UseWorkloadActionManagementProps extends Partial<UseApiRequestProps<void>> {
  name: string;
  version?: number;
  operation: WorkloadOperations;
}

export interface UseWorkloadActionManagementModel extends UseApiRequestModel<void> {
  successMessage: string;
}

export interface UseWorkloadActionManagementActions {
  performOperation: () => void;
}

type Props = UseWorkloadActionManagementProps;
type Model = UseWorkloadActionManagementModel;
type Actions = UseWorkloadActionManagementActions;
type Result = [Model, Actions];

export const useWorkloadActionManagement = (props: Props): Result => {

  const {
    name,
    version = -1,
    operation = WorkloadOperations.NONE,
    ...otherProps
  } = props;

  const WorkloadManagerClient = useWorkloadManagerClient();

  const defaultErrorMessage = React.useMemo(() =>
    `Failed to ${operation} workload`, [operation]);

  const mapApiToOperation = React.useCallback(() => {
    switch (operation) {
    case WorkloadOperations.RELEASE:
      return WorkloadManagerClient.releaseWorkload(name, version);
    case WorkloadOperations.DEPRECATE:
      return WorkloadManagerClient.deprecateWorkload(name, version);
    case WorkloadOperations.DECOMMISSION:
      return WorkloadManagerClient.decommissionWorkload(name, version);
    default:
      return Promise.resolve();
    }
  }, [operation, name]);

  const isReleaseOperation = React.useMemo(() =>
    operation === WorkloadOperations.RELEASE, [operation]);

  const [{ showSuccessView, ...baseModel }, { refresh: performOperation }] =
    useApiRequest<void>({
      ...otherProps,
      defaultErrorMessage,
      deferRequest: true,
      makeApiRequest: () => mapApiToOperation(),
    });

  const successMessage = React.useMemo(() =>
      showSuccessView ? isReleaseOperation ?
        "Release request submitted successfully " :
        `${operation} Successful` :
        "" ,
    [showSuccessView, operation, isReleaseOperation]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    successMessage,
    showSuccessView,
  }), [
    baseModel,
    successMessage,
    showSuccessView,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    performOperation
  }), [performOperation]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useWorkloadActionManagement;
