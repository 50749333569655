import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { userManagementView as styles } from "./styles";
import { SignInPreference, SignOutPreference, Vector } from "@data";
import { Alert, AlertTitle, CancelButton, ErrorView, SaveButton, TooltipIcon } from "@components";
import { formEventHandler, isApplicationSignOutPreferenceEnabled, isEmptyString, noop } from "@util";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const signInTextValue = (value: SignInPreference) => {
  switch (value) {
    case SignInPreference.ALLOW_SSO:
      return "Allow Single Sign-On";
    case SignInPreference.ALWAYS_AUTHENTICATE:
      return "Always Authenticate";
    default:
      return "";
  }
};

const signOutTextValue = (value: SignOutPreference) => {
  switch (value) {
    case SignOutPreference.SIGN_OUT_FROM_APP:
      return "Sign Out from Application";
    case SignOutPreference.SIGN_OUT_GLOBALLY:
      return "Sign Out Globally";
    default:
      return "";
  }
};

const signInMenuItems = Object.values(SignInPreference).map(value => {
  return (
    <MenuItem key={value} value={value}>{signInTextValue(value)}</MenuItem>
  );
});

const signOutMenuItems = Object.values(SignOutPreference).map(value => {
  return (
    <MenuItem key={value} value={value}>{signOutTextValue(value)}</MenuItem>
  );
});

export interface Model {
  loading?: boolean;
  configStatusCode?: number;
  updateStatusCode?: number;
  errorTitle?: string;
  errorMessage?: string;
  updateErrorMessage?: string;
  showSuccessView?: boolean;
  signInPreference?: SignInPreference;
  signOutPreferenceEnabled?: boolean;
  signOutPreference?: SignOutPreference;
}

export interface Actions {
  updateSignInPreference?: (value: SignInPreference) => void;
  updateSignOutPreference?: (value: SignOutPreference) => void;
  save?: () => void;
  reset?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ApplicationUserManagementView = withStyles(styles)((props: Props) => {

  const {
    classes,
    loading,
    configStatusCode,
    updateStatusCode,
    errorTitle = "",
    errorMessage = "",
    updateErrorMessage = "",
    showSuccessView = false,
    signInPreference = SignInPreference.ALWAYS_AUTHENTICATE,
    signOutPreferenceEnabled = isApplicationSignOutPreferenceEnabled(),
    signOutPreference = SignOutPreference.SIGN_OUT_GLOBALLY,
    updateSignInPreference = noop,
    updateSignOutPreference = noop,
    save = noop,
    reset = noop,
  } = props;

  const [edit, setEdit] = React.useState(false);

  const errorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const header = (
    <Alert severity="info" className={classnames("userManagementInfo", classes.alert)}>
      <AlertTitle className={classnames("alertTitle", classes.alertTitle)}>
        <strong>
          Application User Management Settings
        </strong>
      </AlertTitle>
      <ul className={classnames("alertDescription", classes.alertDescription)}>
        <li>
          <strong>
            Sign-In Preference -
          </strong>
          User Sign in preference for this application
          <ul>
            <li>
              <strong>Allow Single Sign-On: </strong>
              User can be implicitly signed in if a valid existing user session is available.
            </li>
            <li>
              <strong>Always Authenticate: </strong>
              User is required to always enter their credentials for the app
              (in other words, Single Sign In is not supported). This is the default value.
            </li>
          </ul>
        </li>
        {signOutPreferenceEnabled && (
          <li>
            <strong>
              Sign-Out Preference -
            </strong>
            User Sign out preference for this application
            <ul>
              <li>
                <strong>Sign Out from Application: </strong>
                User will be signed out of all active sessions of the associated application.
              </li>
              <li>
                <strong>Sign Out Globally: </strong>
                User will be signed out of all sessions (all apps, all session IDs) in the ecosystem
                (primary and all secondary accounts). This is the default value.
              </li>
            </ul>
          </li>
        )}
      </ul>
    </Alert>
  );

  const cancel = React.useCallback(() => {
    setEdit(false);
    reset();
  }, [setEdit, reset]);

  React.useEffect(() => {
    if (showSuccessView) {
      setEdit(false);
    }
  }, [showSuccessView]);

  return (
    <div className={classnames("applicationUserManagementView", classes.container)}>
      <ErrorView
        className={classnames("error", classes.errorView)}
        title={errorTitle}
        message={errorMessage}
        statusCode={configStatusCode}
      />
      {loading && (
        <Typography variant="h3" style={{ margin: "25px 0 0", fontWeight: 300 }}>
          Loading...
        </Typography>
      )}
      {!errorView && !loading && (
        <div className={classnames("userManagementView", classes.summaryContainer)}>
          {header}
          <ErrorView
            className={classnames("updateError", classes.errorView)}
            title={"Failed to Update"}
            message={updateErrorMessage}
            statusCode={updateStatusCode}
          />
          <div className={classnames("signInPreference", classes.summaryItemsContainer)}>
            <label className={classnames("signInName", classes.name)}>Sign-In Preference:</label>
            {!edit && (
              <React.Fragment>
                <label className={classnames("signInValue", classes.value)}>{signInTextValue(signInPreference)}</label>
                <TooltipIcon
                  className={classnames("editSignIn", classes.editIcon)}
                  tooltipText={"Update"}
                  size={Vector.square(20)}
                  icon={EditIcon}
                  onClick={() => setEdit(true)}
                />
              </React.Fragment>
            )}
            {edit && (
              <FormControl className={classnames("formControl", classes.formControl)}>
                <Select
                  className={"signInPreferenceInput"}
                  value={signInPreference}
                  onChange={formEventHandler(updateSignInPreference)}
                >
                  {signInMenuItems}
                </Select>
              </FormControl>
            )}
          </div>
          {signOutPreferenceEnabled && (
            <div className={classnames("signOutPreference", classes.summaryItemsContainer)}>
              <label className={classnames("signOutName", classes.name)}>Sign-Out Preference:</label>
              {!edit && (
                <React.Fragment>
                  <label className={classnames("signOutValue", classes.value)}>
                    {signOutTextValue(signOutPreference)}
                  </label>
                  <TooltipIcon
                    className={classnames("editSignOut", classes.editIcon)}
                    tooltipText={"Update"}
                    size={Vector.square(20)}
                    icon={EditIcon}
                    onClick={() => setEdit(true)}
                  />
                </React.Fragment>
              )}
              {edit && (
                <FormControl className={classnames("formControl", classes.formControl)}>
                  <Select
                    className={"signOutPreferenceInput"}
                    value={signOutPreference}
                    onChange={formEventHandler(updateSignOutPreference)}
                  >
                    {signOutMenuItems}
                  </Select>
                </FormControl>
              )}
            </div>
          )}
          {edit && (
            <div className={classnames("updateControls", classes.controls)}>
              <CancelButton
                className={classnames("cancelButton", classes.cancelButton)}
                label={"Cancel"}
                onClick={cancel}
              />
              <SaveButton
                className={classnames("saveButton", classes.saveButton)}
                color={"primary"}
                label={"Save"}
                save={save}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
});
