import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { PolicyStatement } from "@data";
import { getNameFilter } from "../selectors";
import { setNameFilter } from "../actions";
import {
  PolicyStatementsList,
  PolicyStatementsListColumn,
  PolicyStatementsListActions,
  PolicyStatementsListModel,
} from "@components";

interface ContainerModel extends PolicyStatementsListModel {
  items?: PolicyStatement[];
}

interface ContainerActions extends PolicyStatementsListActions {
}

const StatementsContainer = (props: ContainerModel & ContainerActions) => {

  const {
    items = [],
    ...otherProps
  } = props;

  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(PolicyStatementsListColumn.NONE);

  return (
    <PolicyStatementsList
      {...otherProps}
      items={items}
      showSearch={true}
      showSummary={true}
      showIcon={false}
      showRefreshButton={false}
      sortByColumn={sortByColumn}
      sortOrderAscending={sortOrderAscending}
      setSortByColumn={setSortByColumn}
      setSortOrderAscending={setSortOrderAscending}
    />
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  nameFilter: getNameFilter(state),
});

const mapDispatchToProps = (dispatch: any): ContainerActions => ({
  setNameFilter: (nameFilter: string) => dispatch(setNameFilter(nameFilter)),
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
  mapDispatchToProps,
)(StatementsContainer);
