import { combineReducers, Reducer } from "redux";
import { IdentityType, MaintenanceReminder, MaintenanceReminderAttributes, User, UserAttributes } from "@data";
import { Actions, ActionType, filterAction, Identity, ReduxAction } from "@util";
import { AppSchema } from "@schemas";
import { LoginReducer } from "../login/reducers";
import { SCHEMA_KEY as DASHBOARDS_SCHEMA_KEY } from "../dashboards/constants";
import { DashboardsReducer } from "@modules/dashboards/reducers";
import { SCHEMA_KEY as DASHBOARD_DETAILS_SCHEMA_KEY } from "../dashboardDetails/constants";
import { DashboardDetailsReducer } from "@modules/dashboardDetails/reducers";
import { SCHEMA_KEY as PUBLISH_DASHBOARD_SCHEMA_KEY } from "../publishDashboard/constants";
import { PublishDashboardReducer } from "@modules/publishDashboard/reducers";
import { SCHEMA_KEY as SHARE_DASHBOARD_SCHEMA_KEY } from "../shareDashboard/constants";
import { ShareDashboardReducer } from "@modules/shareDashboard/reducers";
import { SCHEMA_KEY as DATA_SET_DETAILS_SCHEMA_KEY } from "../dataSetDetails/constants";
import { DataSetsReducer } from "../dataSets/reducers";
import { SCHEMA_KEY as DATA_SETS_SCHEMA_KEY } from "../dataSets/constants";
import { DataSetDetailsReducer } from "../dataSetDetails/reducers";
import { SCHEMA_KEY as DATA_CATALOG_SCHEMA_KEY } from "../dataCatalog/constants";
import { DataCatalogReducer } from "../dataCatalog/reducers";
import { NamespacesReducer } from "../namespaces/reducers";
import { NamespaceDetailsReducer } from "@modules/namespaceDetails/reducers";
import { CreateNamespaceReducer } from "../createNamespace/reducers";
import { SchemasReducer } from "../schemas/reducers";
import { SchemaWizardReducer } from "@modules/schemaWizard/reducers";
import { SCHEMA_KEY as SCHEMA_WIZARD_SCHEMA_KEY } from "@modules/schemaWizard/constants";
import { SchemaStateManagerReducer } from "../schemaStateManager/reducers";
import { SCHEMA_KEY as SCHEMA_STATE_MANAGER_SCHEMA_KEY } from "../schemaStateManager/constants";
import { DeviceTypesReducer } from "../deviceTypes/reducers";
import { SCHEMA_KEY as DEVICE_TYPES_SCHEMA_KEY } from "../deviceTypes/constants";
import { DeviceTypeDetailsReducer } from "../deviceTypeDetails/reducers";
import { SCHEMA_KEY as DEVICE_TYPE_DETAILS_SCHEMA_KEY } from "../deviceTypeDetails/constants";
import { DeviceTypeWizardReducer } from "@modules/deviceTypeWizard/reducers";
import { DeviceTypeManagerReducer } from "@modules/deviceTypeManager/reducers";
import { SCHEMA_KEY as DEVICE_TYPE_MANAGER_SCHEMA_KEY } from "@modules/deviceTypeManager/constants";
import { DeviceEnrollmentDetailsReducer } from "@modules/deviceEnrollmentDetails/reducers";
import { SCHEMA_KEY as DEVICE_ENROLLMENT_DETAILS_SCHEMA_KEY } from "@modules/deviceEnrollmentDetails/constants";
import { DeviceValidationDetailsReducer } from "@modules/deviceValidationDetails/reducers";
import { SCHEMA_KEY as DEVICE_VALIDATION_DETAILS_SCHEMA_KEY } from "@modules/deviceValidationDetails/constants";
import { DeviceEnrollmentReducer } from "@modules/deviceEnrollment/reducers";
import { SCHEMA_KEY as DEVICE_ENROLLMENT_SCHEMA_KEY } from "@modules/deviceEnrollment/constants";
import { DeviceEnrollmentWizardReducer } from "@modules/deviceEnrollmentWizard/reducers";
import { SCHEMA_KEY as DEVICE_ENROLLMENT_WIZARD_SCHEMA_KEY } from "@modules/deviceEnrollmentWizard/constants";
import { DevicesReducer } from "@modules/devices/reducers";
import { SCHEMA_KEY as DEVICES_SCHEMA_KEY } from "@modules/devices/constants";
import { DeviceDetailsReducer } from "@modules/deviceDetails/reducers";
import { SCHEMA_KEY as DEVICE_DETAILS_SCHEMA_KEY } from "@modules/deviceDetails/constants";
import { DisenrollDeviceReducer } from "@modules/disenrollDevice/reducers";
import { SCHEMA_KEY as DISENROLL_DEVICE_SCHEMA_KEY } from "@modules/disenrollDevice/constants";
import { UsersReducer } from "../users/reducers";
import { SCHEMA_KEY as USERS_SCHEMA_KEY } from "../users/constants";
import { EmailTemplatesReducer } from "../emailTemplates/reducers";
import { SCHEMA_KEY as EMAIL_TEMPLATE_SCHEMA_KEY } from "../emailTemplates/constants";
import { FederationProvidersReducer } from "../federationProviders/reducers";
import { SCHEMA_KEY as FEDERATION_PROVIDERS_SCHEMA_KEY } from "../federationProviders/constants";
import { DeleteFederationProviderReducer } from "../deleteFederationProvider/reducers";
import { SCHEMA_KEY as DELETE_FEDERATION_PROVIDER_SCHEMA_KEY } from "../deleteFederationProvider/constants";
import { FederationProviderWizardReducer } from "../federationProviderWizard/reducers";
import { SCHEMA_KEY as FEDERATION_PROVIDER_WIZARD_SCHEMA_KEY } from "../federationProviderWizard/constants";
import { ProfileAttributeDefinitionReducer } from "../profileAttributeDefinitions/reducers";
import { SCHEMA_KEY as PROFILE_ATTRIBUTE_DEFINITION_SCHEMA_KEY } from "../profileAttributeDefinitions/constants";
import { CreateUserReducer } from "../createUser/reducers";
import { SCHEMA_KEY as CREATE_USER_SCHEMA_KEY } from "../createUser/constants";
import { LockUserReducer } from "../lockUser/reducers";
import { SCHEMA_KEY as LOCK_USER_SCHEMA_KEY } from "../lockUser/constants";
import { CloseUserReducer } from "../closeUser/reducers";
import { SCHEMA_KEY as CLOSE_USER_SCHEMA_KEY } from "../closeUser/constants";
import { DeleteUserReducer } from "../deleteUser/reducers";
import { SCHEMA_KEY as DELETE_USER_SCHEMA_KEY } from "../deleteUser/constants";
import { ExpireUserPasswordReducer } from "../expireUserPassword/reducers";
import { SCHEMA_KEY as EXPIRE_USER_PASSWORD_SCHEMA_KEY } from "../expireUserPassword/constants";
import { PasswordRecoveryReducer } from "../passwordRecovery/reducers";
import { SCHEMA_KEY as PASSWORD_RECOVERY_SCHEMA_KEY } from "../passwordRecovery/constants";
import { ChangePasswordReducer } from "../changePassword/reducers";
import { SCHEMA_KEY as CHANGE_PASSWORD_SCHEMA_KEY } from "../changePassword/constants";
import { UserReportReducer } from "../userReport/reducers";
import { SCHEMA_KEY as USER_REPORT_SCHEMA_KEY } from "../userReport/constants";
import { LogoutUserReducer } from "../logoutUser/reducers";
import { SCHEMA_KEY as LOGOUT_USER_SCHEMA_KEY } from "../logoutUser/constants";
import { SCHEMA_KEY as SECURITY_GROUPS_SCHEMA_KEY } from "../securityGroups/constants";
import { SecurityGroupsReducer } from "../securityGroups/reducers";
import { SCHEMA_KEY as SECURITY_SERVICE_REGIONAL_DETAILS_SCHEMA } from "../securityServiceRegionalDetails/constants";
import { SecurityServiceRegionalDetailsReducer } from "../securityServiceRegionalDetails/reducers";
import { SCHEMA_KEY as AUDIT_SCHEMA_KEY } from "../auditEvents/constants";
import { AuditReducer } from "../auditEvents/reducers";
import { SCHEMA_KEY as GROUP_MANAGEMENT_WIZARD_SCHEMA_KEY } from "../groupManagementWizard/constants";
import { GroupManagementWizardReducer } from "../groupManagementWizard/reducers";
import { SCHEMA_KEY as POLICY_MANAGEMENT_SCHEMA_KEY } from "../policyManagement/constants";
import { PolicyManagementReducer } from "../policyManagement/reducers";
import { DataAccessRequestsReducer } from "../dataAccessRequests/reducers";
import { SCHEMA_KEY as DATA_ACCESS_REQUEST_SCHEMA_KEY } from "../dataAccessRequests/constants";
import { DeleteDataAccessRequestReducer } from "../deleteDataAccessRequest/reducers";
import { SCHEMA_KEY as DELETE_DATA_ACCESS_REQUEST_SCHEMA_KEY } from "../deleteDataAccessRequest/constants";
import { DataAccessRequestDetailsReducer } from "@modules/dataAccessRequestDetails/reducers";
import { ApplicationRegionalDetailsReducer } from "@modules/applicationRegionalDetails/reducers";
import { SCHEMA_KEY as APPLICATION_REGIONAL_DETAILS_SCHEMA } from "@modules/applicationRegionalDetails/constants";
import { ApplicationWizardReducer } from "@modules/applicationWizardRegional/reducers";
import { SCHEMA_KEY as APPLICATION_WIZARD_SCHEMA } from "@modules/applicationWizardRegional/constants";
import { DataSetRequestsReducer } from "../dataSetRequests/reducers";
import { SCHEMA_KEY as DATA_SET_REQUEST_SCHEMA_KEY } from "../dataSetRequests/constants";
import DataSetRequestWizardReducer from "@modules/dataSetRequestWizard/reducers";
import { SCHEMA_KEY as CREATE_DATA_SET_REQUEST_SCHEMA_KEY } from "../dataSetRequestWizard/constants";
import { DeleteDataSetRequestReducer } from "../deleteDataSetRequest/reducers";
import { SCHEMA_KEY as DELETE_DATA_SET_REQUEST_SCHEMA_KEY } from "../deleteDataSetRequest/constants";
import { DataApprovalRequestsReducer } from "../dataApprovalRequests/reducers";
import { SCHEMA_KEY as DATA_APPROVAL_REQUEST_SCHEMA_KEY } from "../dataApprovalRequests/constants";
import { DataApprovalRequestDetailsReducer } from "@modules/dataApprovalRequestDetails/reducers";
import { ApproveRequestReducer } from "../approveRequest/reducers";
import { SCHEMA_KEY as APPROVE_REQUEST_SCHEMA_KEY } from "../approveRequest/constants";
import { CreatePolicyReducer } from "../createPolicy/reducers";
import { SCHEMA_KEY as CREATE_POLICY_SCHEMA_KEY } from "../createPolicy/constants";
import { EditPolicyReducer } from "../editPolicy/reducers";
import { SCHEMA_KEY as EDIT_POLICY_SCHEMA_KEY } from "../editPolicy/constants";
import { DeletePolicyReducer } from "../deletePolicy/reducers";
import { SCHEMA_KEY as DELETE_POLICY_SCHEMA_KEY } from "../deletePolicy/constants";
import { ManagedPolicyDetailsReducer } from "@modules/managedPolicyDetails/reducers";
import { SCHEMA_KEY as MANAGED_POLICY_DETAILS_SCHEMA_KEY } from "@modules/managedPolicyDetails/constants";
import { DigitalShadowLayoutWizardReducer } from "@modules/digitalShadowLayoutWizard/reducers";
import { SCHEMA_KEY as DIGITAL_SHADOW_LAYOUT_WIZARD_SCHEMA_KEY } from "@modules/digitalShadowLayoutWizard/constants";
import { PolicyDetailsReducer } from "../policyDetails/reducers";
import { SCHEMA_KEY as POLICY_DETAILS_SCHEMA_KEY } from "../policyDetails/constants";
import { UserDetailsReducer } from "../userDetails/reducers";
import { SupersetLoadedContent } from "@modules/portlet/actions";
import MODULES from "./modules";

const VERSION = process.env.REACT_APP_VERSION || "";
const VERSION_CODE = process.env.REACT_APP_VERSION_CODE || "";

const OnToggleShowPortal = filterAction<boolean>(Actions(ActionType.TOGGLE_SHOW_PORTAL));
const OnLogoutTriggered = filterAction<boolean>(Actions(ActionType.LOGOUT_TRIGGERED));
const OnToggleShowFeedbackDialog = filterAction<boolean>(Actions(ActionType.TOGGLE_FEEDBACK_DIALOG));
const OnAccountIdUpdated = filterAction<string>(Actions(ActionType.SET_ACCOUNT_ID));
const OnPrincipalIdUpdated = filterAction<string>(Actions(ActionType.SET_PRINCIPAL_ID));
const OnIdentityTypeUpdated = filterAction<IdentityType>(Actions(ActionType.SET_IDENTITY_TYPE));
const OnCurrentUserUpdated = filterAction<UserAttributes>(Actions(ActionType.UPDATE_CURRENT_USER));
const OnRefreshTokenUpdated = filterAction<string>(Actions(ActionType.UPDATE_REFRESH_TOKEN));
const OnMouseMoved = filterAction<number>(Actions(ActionType.UPDATE_LAST_MOUSE_MOVED));
const OnEnabledModuleIdsUpdated = filterAction<string[]>(Actions(ActionType.UPDATE_ENABLED_MODULE_IDS));
const OnPersistedModuleIdsUpdated = filterAction<string[]>(Actions(ActionType.UPDATE_PERSISTED_MODULE_IDS));
const OnAuthTokenUpdated = filterAction<string>(Actions(ActionType.UPDATE_AUTH_TOKEN));
const OnAuthTokenExpiryTimeUpdated = filterAction<string>(Actions(ActionType.UPDATE_AUTH_TOKEN_EXPIRY_TIME));
const OnSetVersion = filterAction<string>(Actions(ActionType.SET_VERSION));
const OnSetVersionCode = filterAction<string>(Actions(ActionType.SET_VERSION_CODE));
const OnSetShowInformationBanner = filterAction<boolean>(Actions(ActionType.SET_SHOW_INFORMATION_BANNER));
const OnSetUserReportMessage = filterAction<string>(Actions(ActionType.SET_USER_REPORT_MESSAGE));
const OnSupersetPortletRefresh = filterAction<SupersetLoadedContent>(Actions(ActionType.SUPERSET_APP_RELOAD));
const OnFavoritesUpdated = filterAction<string>(Actions(ActionType.SET_USER_FAVORITES));
const OnFavoritesKeyUpdated = filterAction<string>(Actions(ActionType.SET_USER_FAVORITES_KEY));
const OnClosedMaintenanceRemindersUpdated = filterAction<string>
  (Actions(ActionType.UPDATE_CLOSED_MAINTENANCE_REMINDERS));
const OnMaintenanceReminderUpdated = filterAction<MaintenanceReminderAttributes>
  (Actions(ActionType.SET_MAINTENANCE_REMINDER));

const ShowPortalReducer = OnToggleShowPortal(Identity, false);
const LogoutTriggeredReducer = OnLogoutTriggered(Identity, false);
const ShowFeedbackDialogReducer = OnToggleShowFeedbackDialog(Identity, false);
const AccountIdReducer = OnAccountIdUpdated(Identity, "");
const PrincipalIdReducer = OnPrincipalIdUpdated(Identity, "");
const IdentityTypeReducer = OnIdentityTypeUpdated(Identity, IdentityType.USER);
const CurrentUserReducer = OnCurrentUserUpdated(Identity, User.EMPTY.toJS());
const MouseMovedReducer = OnMouseMoved((state: number, action: ReduxAction<ActionType>) => {
  if (action.type === ActionType.UPDATE_LAST_MOUSE_MOVED && state < action.value) {
    return action.value;
  }
  return state;
}, 0);
const EnabledModuleIdsReducer = OnEnabledModuleIdsUpdated(Identity, MODULES
  .filter(({ disabled = false}) => !disabled)
  .map(({ id }) => id));
const PersistedModuleIdsReducer = OnPersistedModuleIdsUpdated(Identity, MODULES
  .filter(({ persist = false}) => persist)
  .map(({ id }) => id));
const RefreshTokenReducer = OnRefreshTokenUpdated(Identity, "");
const AuthTokenReducer = OnAuthTokenUpdated(Identity, "");
const AuthTokenExpiryTimeReducer = OnAuthTokenExpiryTimeUpdated(Identity, "");
const VersionReducer = OnSetVersion(Identity, VERSION);
const VersionCodeReducer = OnSetVersionCode(Identity, VERSION_CODE);
const ShowInformationBannerReducer = OnSetShowInformationBanner(Identity, false);
const UserReportMessageReducer = OnSetUserReportMessage(Identity, "");
const ErrorStateReducer = filterAction(Actions(ActionType.ERROR, ActionType.UPDATE_CURRENT_USER))
        ((t: any, action: ReduxAction<ActionType>) => {
          if (action.type === ActionType.ERROR) {
            return action.value;
          }
          return {};
        }, {});
const QueryParamsReducer = (state: string = window.location.search, action: ReduxAction<ActionType>) => {
  if (action.type === ActionType.UPDATE_QUERY_PARAMS) {
    return action.value;
  } else {
    return state;
  }
};
const SupersetPortletReducer = OnSupersetPortletRefresh(Identity, {time: Date.now(), url: ""});
const FavoritesReducer = OnFavoritesUpdated(Identity, "");
const FavoritesKeyReducer = OnFavoritesKeyUpdated(Identity, "");
const ClosedMaintenanceRemindersReducer = OnClosedMaintenanceRemindersUpdated(Identity, "");
const MaintenanceReminderReducer = OnMaintenanceReminderUpdated(Identity, MaintenanceReminder.EMPTY.toJS());

export const AppReducer: Reducer<AppSchema> = combineReducers<AppSchema>({
  version: VersionReducer,
  versionCode: VersionCodeReducer,
  accountId: AccountIdReducer,
  principalId: PrincipalIdReducer,
  identityType: IdentityTypeReducer,
  currentUser: CurrentUserReducer,
  lastMouseMove: MouseMovedReducer,
  enabledModuleIds: EnabledModuleIdsReducer,
  persistedModuleIds: PersistedModuleIdsReducer,
  showPortal: ShowPortalReducer,
  showFeedbackDialog: ShowFeedbackDialogReducer,
  showInformationBanner: ShowInformationBannerReducer,
  // TODO: The user report message is related to users module only and should not live here
  userReportMessage: UserReportMessageReducer,
  login: LoginReducer,
  [DASHBOARDS_SCHEMA_KEY]: DashboardsReducer,
  [DASHBOARD_DETAILS_SCHEMA_KEY]: DashboardDetailsReducer,
  [PUBLISH_DASHBOARD_SCHEMA_KEY]: PublishDashboardReducer,
  [SHARE_DASHBOARD_SCHEMA_KEY]: ShareDashboardReducer,
  [DATA_SETS_SCHEMA_KEY]: DataSetsReducer,
  [DATA_SET_DETAILS_SCHEMA_KEY]: DataSetDetailsReducer,
  [DATA_CATALOG_SCHEMA_KEY]: DataCatalogReducer,
  namespaces: NamespacesReducer,
  namespaceDetails: NamespaceDetailsReducer,
  createNamespace: CreateNamespaceReducer,
  schemas: SchemasReducer,
  [SCHEMA_WIZARD_SCHEMA_KEY]: SchemaWizardReducer,
  [SCHEMA_STATE_MANAGER_SCHEMA_KEY]: SchemaStateManagerReducer,
  [DEVICE_TYPES_SCHEMA_KEY]: DeviceTypesReducer,
  [DEVICE_TYPE_DETAILS_SCHEMA_KEY]: DeviceTypeDetailsReducer,
  deviceTypeWizard: DeviceTypeWizardReducer,
  [DEVICE_TYPE_MANAGER_SCHEMA_KEY]: DeviceTypeManagerReducer,
  [DEVICE_ENROLLMENT_DETAILS_SCHEMA_KEY]: DeviceEnrollmentDetailsReducer,
  [DEVICE_VALIDATION_DETAILS_SCHEMA_KEY]: DeviceValidationDetailsReducer,
  [DEVICE_ENROLLMENT_SCHEMA_KEY]: DeviceEnrollmentReducer,
  [DEVICE_ENROLLMENT_WIZARD_SCHEMA_KEY]: DeviceEnrollmentWizardReducer,
  [DEVICES_SCHEMA_KEY]: DevicesReducer,
  [DEVICE_DETAILS_SCHEMA_KEY]: DeviceDetailsReducer,
  [DISENROLL_DEVICE_SCHEMA_KEY]: DisenrollDeviceReducer,
  [USERS_SCHEMA_KEY]: UsersReducer,
  [EMAIL_TEMPLATE_SCHEMA_KEY]: EmailTemplatesReducer,
  [FEDERATION_PROVIDERS_SCHEMA_KEY]: FederationProvidersReducer,
  [DELETE_FEDERATION_PROVIDER_SCHEMA_KEY]: DeleteFederationProviderReducer,
  [FEDERATION_PROVIDER_WIZARD_SCHEMA_KEY]: FederationProviderWizardReducer,
  [PROFILE_ATTRIBUTE_DEFINITION_SCHEMA_KEY]: ProfileAttributeDefinitionReducer,
  [CREATE_USER_SCHEMA_KEY]: CreateUserReducer,
  [LOCK_USER_SCHEMA_KEY]: LockUserReducer,
  [CLOSE_USER_SCHEMA_KEY]: CloseUserReducer,
  [DELETE_USER_SCHEMA_KEY]: DeleteUserReducer,
  [EXPIRE_USER_PASSWORD_SCHEMA_KEY]: ExpireUserPasswordReducer,
  [PASSWORD_RECOVERY_SCHEMA_KEY]: PasswordRecoveryReducer,
  [CHANGE_PASSWORD_SCHEMA_KEY]: ChangePasswordReducer,
  [USER_REPORT_SCHEMA_KEY]: UserReportReducer,
  [LOGOUT_USER_SCHEMA_KEY]: LogoutUserReducer,
  [SECURITY_GROUPS_SCHEMA_KEY]: SecurityGroupsReducer,
  [SECURITY_SERVICE_REGIONAL_DETAILS_SCHEMA]: SecurityServiceRegionalDetailsReducer,
  [AUDIT_SCHEMA_KEY]: AuditReducer,
  [GROUP_MANAGEMENT_WIZARD_SCHEMA_KEY]: GroupManagementWizardReducer,
  [POLICY_MANAGEMENT_SCHEMA_KEY]: PolicyManagementReducer,
  [DATA_ACCESS_REQUEST_SCHEMA_KEY]: DataAccessRequestsReducer,
  [DELETE_DATA_ACCESS_REQUEST_SCHEMA_KEY]: DeleteDataAccessRequestReducer,
  dataAccessRequestDetails: DataAccessRequestDetailsReducer,
  [APPLICATION_REGIONAL_DETAILS_SCHEMA]: ApplicationRegionalDetailsReducer,
  [APPLICATION_WIZARD_SCHEMA]: ApplicationWizardReducer,

  [DATA_SET_REQUEST_SCHEMA_KEY]: DataSetRequestsReducer,
  [CREATE_DATA_SET_REQUEST_SCHEMA_KEY]: DataSetRequestWizardReducer,
  [DELETE_DATA_SET_REQUEST_SCHEMA_KEY]: DeleteDataSetRequestReducer,
  [DATA_APPROVAL_REQUEST_SCHEMA_KEY]: DataApprovalRequestsReducer,
  dataApprovalRequestDetails: DataApprovalRequestDetailsReducer,
  [APPROVE_REQUEST_SCHEMA_KEY]: ApproveRequestReducer,

  [CREATE_POLICY_SCHEMA_KEY]: CreatePolicyReducer,
  [EDIT_POLICY_SCHEMA_KEY]: EditPolicyReducer,
  [DELETE_POLICY_SCHEMA_KEY]: DeletePolicyReducer,
  [MANAGED_POLICY_DETAILS_SCHEMA_KEY]: ManagedPolicyDetailsReducer,
  [DIGITAL_SHADOW_LAYOUT_WIZARD_SCHEMA_KEY]: DigitalShadowLayoutWizardReducer,
  [POLICY_DETAILS_SCHEMA_KEY]: PolicyDetailsReducer,
  userDetails: UserDetailsReducer,
  error: ErrorStateReducer,
  queryParams: QueryParamsReducer,
  refreshToken: RefreshTokenReducer,
  authToken: AuthTokenReducer,
  authTokenExpiryTime: AuthTokenExpiryTimeReducer,
  supersetLoadedContent: SupersetPortletReducer,
  favorites: FavoritesReducer,
  favoritesKey: FavoritesKeyReducer,
  maintenanceReminder: MaintenanceReminderReducer,
  closedMaintenanceReminders: ClosedMaintenanceRemindersReducer,
  logoutTriggered: LogoutTriggeredReducer,
});

export default AppReducer;
