import React from "react";
import { createComparator, getSearchResults } from "@components/certificate-authority-list/helpers";
import { AuthTokenContext } from "@components";
import { isEmptyString } from "@util";
import { CertificateAuthorityListColumn } from "@components";
import { GetTrustedCertificateAuthoritiesResponse, CertificateManagementClient, RestClientError } from "@network";
import { CertificateAuthority } from "@data";

export interface UseCertificateAuthoritiesModel {
  certificateAuthorities: CertificateAuthority[];
  nameFilter: string;
  errorMessage: string;
  showLoadMoreButton: boolean;
  sortOrderAscending: boolean;
  showSearch: boolean;
  showLoadingIndicator: boolean;
  totalNumCertificateAuthorities: number;
  showNoResultsView: boolean;
  showAccessDenied: boolean;
  totalNumVisibleCertificateAuthorities: number;
  sortByColumn: CertificateAuthorityListColumn;
  statusCode?: number;
}

export interface UseCertificateAuthoritiesActions {
  refresh: () => void;
  loadMore: () => void;
  setNameFilter: (nameFilter: string) => void;
  setSortByColumn: (column: CertificateAuthorityListColumn) => void;
  setSortOrderAscending: (ascending: boolean) => void;
}

type Model = UseCertificateAuthoritiesModel;
type Actions = UseCertificateAuthoritiesActions;

export const sortAndFilterCertificateAuthorityResult = (props: {
  items: CertificateAuthority[],
  nameFilter: string,
  sortOrderAscending: boolean,
  sortByColumn: CertificateAuthorityListColumn
}) => {

  const {items, nameFilter, sortOrderAscending, sortByColumn } = props;

  const comparator = React.useMemo(() =>
    createComparator(sortByColumn, sortOrderAscending), [sortByColumn, sortOrderAscending]);

  const sortedCertificateAuthorities = React.useMemo(() => items.sort(comparator).slice(), [items, comparator]);

  return React.useMemo(() =>
    getSearchResults(sortedCertificateAuthorities, nameFilter), [sortedCertificateAuthorities, nameFilter]);
};

export const useCertificateAuthorities = (): [Model, Actions] => {

  const authToken = React.useContext(AuthTokenContext);
  const [items, setItems] = React.useState<CertificateAuthority[]>([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [sortOrderAscending, setSortOrderAscending] = React.useState(true);
  const [sortByColumn, setSortByColumn] = React.useState(CertificateAuthorityListColumn.NONE);
  const [showLoadingIndicator, setShowLoadingIndicator] = React.useState(false);
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [showAccessDenied, setShowAccessDenied] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState<number>();

  const fetchCertificateAuthorities = React.useCallback(() =>
      CertificateManagementClient.getTrustedCertificateAuthorities(authToken, nextPageToken),
    [authToken, nextPageToken]);

  React.useEffect(() => {

      let ignore = false;

      setShowLoadingIndicator(true);
      setErrorMessage("");

      fetchCertificateAuthorities()
        .then((response: GetTrustedCertificateAuthoritiesResponse) => {
          if (!ignore) {
            const { items: results = [] , paging: { next = ""} = {} } = response;
            setItems(s => s.concat(results.map(attrs => new CertificateAuthority(attrs))));
            setShowLoadingIndicator(false);
            setNextPageToken(next || "");
          }
        }, (response: RestClientError) => {
          if (!ignore) {
            const { status, error = "Fetch certificate authorities failed" } = response;
            if (status === 404) {
              setShowNotFound(true);
            }
            if (status === 403) {
              setShowAccessDenied(true);
            }
            setErrorMessage(error);
            setShowLoadingIndicator(false);
            setStatusCode(status);
          }
        });
      return () => { ignore = true; };

    },
    [
      lastRefresh,
      setItems,
      setNextPageToken,
      setErrorMessage,
      setShowLoadingIndicator,
      setShowNotFound,
      setShowAccessDenied,
      setStatusCode,
    ]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && isEmptyString(errorMessage) && !showLoadingIndicator,
    [nextPageToken, errorMessage, showLoadingIndicator]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setNextPageToken("");
    setItems([]);
    setLastRefresh(+new Date);
    setSortByColumn(CertificateAuthorityListColumn.NONE);
    setSortOrderAscending(true);
    setShowAccessDenied(false);
    setShowNotFound(false);
  }, [setNextPageToken, setItems, setLastRefresh, setSortByColumn,
    setSortOrderAscending, setShowAccessDenied, setShowNotFound]);

  const certificateAuthorities =
    sortAndFilterCertificateAuthorityResult({items, nameFilter, sortOrderAscending, sortByColumn});

  const totalNumCertificateAuthorities = React.useMemo(() => items.length, [items]);

  const totalNumVisibleCertificateAuthorities = React.useMemo( () =>
    certificateAuthorities.length, [certificateAuthorities]);

  const showSearch = React.useMemo(() => totalNumCertificateAuthorities > 1, [totalNumCertificateAuthorities]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showNoResultsView = React.useMemo(() =>
    totalNumVisibleCertificateAuthorities === 0 && !showErrorView && !showLoadingIndicator,
    [totalNumVisibleCertificateAuthorities, showErrorView, showLoadingIndicator]);

  const model = React.useMemo<Model>(() => ({
    certificateAuthorities,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    showNoResultsView,
    showNotFound,
    showAccessDenied,
    statusCode,
    totalNumCertificateAuthorities,
    totalNumVisibleCertificateAuthorities: totalNumVisibleCertificateAuthorities,
  }), [
    certificateAuthorities,
    nameFilter,
    errorMessage,
    showLoadMoreButton,
    sortOrderAscending,
    sortByColumn,
    showSearch,
    showLoadingIndicator,
    statusCode,
    totalNumCertificateAuthorities,
    showNoResultsView,
    showNotFound,
    showAccessDenied,
    totalNumVisibleCertificateAuthorities,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    refresh,
    loadMore,
    setNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  }) , [
    refresh,
    loadMore,
    setNameFilter,
    setSortByColumn,
    setSortOrderAscending,
  ]);

  return [model, actions];
};

export default useCertificateAuthorities;
