import { Record } from "immutable";
import { getStringValue } from "@util";
import { TriggerAttributes, TriggerType } from "@data";

export type TriggerStorage = {
  bucketName: string;
  key: string;
  size?: number;
};

export enum ExecutionScope {
  NONE = "",
  WORKLOAD = "WORKLOAD",
  WORKFLOW = "WORKFLOW",
}

export class ExecuteWorkloadEvent extends Record({
  eventType: TriggerType.NONE,
  "@type": TriggerType.NONE,
  type: TriggerType.NONE,
  eventDatetime: "",
  scheduleId: "",
  dataSetAlias: "",
  triggerStorage: ({} as TriggerStorage),
  precedingWorkloadName: "",
  accountId: "",
  listFile: [""],
  executionScope: ExecutionScope.NONE,
}) implements TriggerAttributes {

  public static EMPTY: ExecuteWorkloadEvent = new ExecuteWorkloadEvent();

  public readonly eventType: TriggerType;
  public readonly "@type": TriggerType;
  public readonly type: TriggerType;
  public readonly eventDatetime: string;
  public readonly scheduleId: string;
  public readonly triggerStorage: TriggerStorage;
  public readonly precedingWorkloadName: string;
  public readonly accountId: string;
  public readonly dataSetAlias: string;
  public readonly listFile: string[];
  public readonly executionScope: ExecutionScope;

  public static fromTrigger(trigger: TriggerAttributes): ExecuteWorkloadEvent {
    return new ExecuteWorkloadEvent({
      ...trigger,
      type: trigger.type,
      scheduleId: trigger.scheduleId,
      precedingWorkloadName: trigger.precedingWorkloadName,
      dataSetAlias: trigger.dataSetAlias,
    });
  }

  public getAccountId(): string {
    return getStringValue(this.accountId);
  }

  public getBucketName(): string {
    const { bucketName = "" } = this.triggerStorage || {};
    return bucketName;
  }

  public getKey(): string {
    const storage = this.triggerStorage;
    return !this.triggerStorage ? "" : getStringValue(storage.key);
  }

  public getSize(): number {
    const storage = this.triggerStorage;
    return !this.triggerStorage || !storage.size ? 0 : storage.size;
  }

  public getType(): TriggerType {
    return this.type;
  }

  public getPrecedingWorkloadName(): string {
    return getStringValue(this.precedingWorkloadName);
  }

  public getDataSetAlias(): string {
    return getStringValue(this.dataSetAlias);
  }

  public getExecutionScope(): ExecutionScope {
    return this.executionScope;
  }
}

export default ExecuteWorkloadEvent;
