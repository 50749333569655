import React from "react";
import { equalsIgnoreCase, getPluralString, noop } from "@util";
import classnames from "classnames";
import { ActionMenuItem, ActionsMenu } from "@components";
import PaginatedList from "@components/paginated-list";
import { DeviceTypeRequestV3 } from "@modules/deviceTypeWizard/models";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CredentialsEditorDialog from "./CredentialsEditorDialog";
import RemoveCredentialDialog from "./RemoveCredentialDialog";
import CredentialsViewItem from "./CredentialsViewItem";
import styles from "./styles";

enum Action {
  ADD_CREDENTIAL_DEFINITION = "add_credential_definition",
  EDIT_CREDENTIAL_DEFINITION = "edit_credential_definition",
  REMOVE_CREDENTIAL_DEFINITION = "remove_credential_definition",
}

const AddAction = {
  id: Action.ADD_CREDENTIAL_DEFINITION,
  name: "Add Credential Definition",
};

const EditAction = {
  id: Action.EDIT_CREDENTIAL_DEFINITION,
  name: "Edit Credential Definition",
};

const RemoveAction = {
  id: Action.REMOVE_CREDENTIAL_DEFINITION,
  name: "Remove Credential Definition",
};

enum Column {
  NAME = "Name",
  TYPE = "Type",
  ENCODING = "Encoding",
  PERSISTENCE = "Persistence",
  AUTHORITY_IDS = "Authority ID(s)",
  DEVICE_AUTHENTICATION = "Device Authentication Type",
}

export interface Model {
  title?: string;
  deviceType?: DeviceTypeRequestV3;
  cmsAuthorityIds?: string[];
  cmsAuthorityNames?: { [key: string]: string };
  loadingAuthorityIds?: boolean;
  errorMessage?: string;
  statusCode?: number;
}

export interface Actions {
  updateDeviceType?: (updatedDeviceType: DeviceTypeRequestV3) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

type Item = CredentialsViewItem;

export const CredentialsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    title = "Add Credential Definition(s)",
    deviceType = DeviceTypeRequestV3.EMPTY,
    cmsAuthorityIds,
    cmsAuthorityNames,
    loadingAuthorityIds,
    errorMessage,
    statusCode,
    updateDeviceType = noop,
    children,
  } = props;

  const [selectedItem, setSelectedItem] = React.useState<Item>(CredentialsViewItem.EMPTY);

  const [showEditorDialog, setShowEditorDialog] = React.useState(false);

  const [showRemoveDialog, setShowRemoveDialog] = React.useState(false);

  const deviceAuthentications = React.useMemo(() =>
    deviceType.getDeviceAuthentication(), [deviceType]);

  const deviceAuthenticationAlreadyAdded = React.useMemo(() =>
    !selectedItem.hasDeviceAuthentication() && deviceType.hasDeviceAuthentication(),
    [selectedItem, deviceType]);

  const items = React.useMemo<CredentialsViewItem[]>(() => deviceType.getDefinitions()
    .map(definition => new CredentialsViewItem({
      name: definition.getName(),
      type: definition.getType(),
      encoding: definition.getEncoding(),
      persistence: definition.getPersistence(),
      trustedCmsAuthorityIds: definition.getTrustedCmsAuthorityIds(),
      deviceAuthentication: deviceAuthentications
        .filter(({ credentialName }) => equalsIgnoreCase(credentialName, definition.getName()))
        .map(({ type }) => type),
    })), [deviceType, deviceAuthentications]);

  const openEditorDialog = React.useCallback((item: Item = CredentialsViewItem.EMPTY) => {
    setSelectedItem(item);
    setShowEditorDialog(true);
  }, [setSelectedItem, setShowEditorDialog]);

  const closeEditorDialog = React.useCallback(() => {
    setShowEditorDialog(false);
    setSelectedItem(CredentialsViewItem.EMPTY);
  }, [setShowEditorDialog, setSelectedItem]);

  const openRemoveDialog = React.useCallback((item: Item = CredentialsViewItem.EMPTY) => {
    setSelectedItem(item);
    setShowRemoveDialog(true);
  }, [setSelectedItem, setShowRemoveDialog]);

  const closeRemoveDialog = React.useCallback(() => {
    setShowRemoveDialog(false);
    setSelectedItem(CredentialsViewItem.EMPTY);
  }, [setShowRemoveDialog, setSelectedItem]);

  const onClickAction = React.useCallback((action: ActionMenuItem, item?: Item) => {
    if (action.id === Action.ADD_CREDENTIAL_DEFINITION) {
      openEditorDialog();
    } else if (action.id === Action.EDIT_CREDENTIAL_DEFINITION) {
      openEditorDialog(item);
    } else if (action.id === Action.REMOVE_CREDENTIAL_DEFINITION) {
      openRemoveDialog(item);
    }
  }, [openEditorDialog, openRemoveDialog]);

  const updateCredentialDefinitions = React.useCallback((updatedItems: Item[] = []) => {

    const updatedCredentials = CredentialsViewItem.toSecurityCredentials(updatedItems);

    const updatedDeviceType = new DeviceTypeRequestV3({
      ...deviceType.toJS(),
      security: {
        ...deviceType.getSecurity(),
        credentials: {
          ...deviceType.getCredentials().toJS(),
          ...updatedCredentials.toJS(),
          usage: {
            ...deviceType.getUsage(),
            ...updatedCredentials.getUsage(),
          },
        },
      },
    });

    updateDeviceType(updatedDeviceType);

  }, [updateDeviceType, deviceType]);

  const saveCredentialDefinition = React.useCallback((item: Item = CredentialsViewItem.EMPTY) => {
    updateCredentialDefinitions(items
      .filter(entry => !equalsIgnoreCase(item.getName(), entry.getName()))
      .concat(item));
    closeEditorDialog();
  }, [items, updateCredentialDefinitions, closeEditorDialog]);

  const removeCredentialDefinition = React.useCallback((item: Item = CredentialsViewItem.EMPTY) => {
    updateCredentialDefinitions(items.filter(entry => !equalsIgnoreCase(item.getName(), entry.getName())));
    closeRemoveDialog();
  }, [items, updateCredentialDefinitions, closeRemoveDialog]);

  return (
    <div className={classnames("credentialsView", classes.container)}>
      <div className={classnames("header", classes.header)}>
        <label className={classnames("title", classes.title)}>
          {title}
        </label>
        <ActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          buttonVariant="icon"
          actions={[AddAction]}
          buttonClassName={classnames("actionsMenuIconButton", classes.actionsMenuIconButton)}
          onClickAction={onClickAction}
        />
      </div>
      <PaginatedList
        className={classnames("table", classes.table)}
        tableLayoutFixed={false}
        items={items}
        columns={[
          Column.NAME,
          Column.TYPE,
          Column.ENCODING,
          Column.PERSISTENCE,
          Column.AUTHORITY_IDS,
          Column.DEVICE_AUTHENTICATION,
        ]}
        actions={[EditAction, RemoveAction]}
        showLoadMoreButton={true}
        autoLoadMoreEnabled={false}
        loadMoreLabel="Add Credential Definition"
        loadMoreButtonClassName={classnames("addButton", classes.addButton)}
        error={errorMessage}
        statusCode={statusCode}
        onClickLoadMore={openEditorDialog}
        onClickItem={(item: Item) => openEditorDialog(item)}
        onClickAction={(item: Item, action) => onClickAction(action, item)}
        renderItem={(item: Item, column: Column) => {
          switch (column) {
            case Column.NAME:
              return (
                <label className={classnames("name", classes.label, classes.name)}>
                  {item.getName()}
                </label>
              );
            case Column.TYPE:
              return (
                <label className={classnames("type", classes.label, classes.type)}>
                  {item.getType()}
                </label>
              );
            case Column.ENCODING:
              return (
                <label className={classnames("encoding", classes.label, classes.encoding)}>
                  {item.getEncoding()}
                </label>
              );
            case Column.PERSISTENCE:
              return (
                <label className={classnames("persistence", classes.label, classes.persistence)}>
                  {item.getPersistence()}
                </label>
              );
            case Column.AUTHORITY_IDS:
              if (!item.isCertificateCredentialType()) {
                return (
                  <label
                    className={classnames("authorityIds",
                      classes.label,
                      classes.placeholder,
                      classes.authorityIds,
                    )}
                  >
                    N/A
                  </label>
                );
              } else if (!item.hasTrustedCmsAuthorityIds()) {
                return (
                  <label
                    className={classnames("authorityIds",
                      classes.label,
                      classes.placeholder,
                      classes.authorityIds,
                    )}
                  >
                    None
                  </label>
                );
              } else {
                return (
                  <label className={classnames("authorityIds", classes.label, classes.authorityIds)}>
                    {getPluralString(item.getTrustedCmsAuthorityIds().length, {
                      one: "Authority ID Added",
                      other: "Authority IDs Added",
                    })}
                  </label>
                );
              }
            case Column.DEVICE_AUTHENTICATION:
              if (!item.hasDeviceAuthentication()) {
                return (
                  <label
                    className={classnames("deviceAuthentication",
                      classes.label,
                      classes.placeholder,
                      classes.deviceAuthentication,
                    )}
                  >
                    None
                  </label>
                );
              }
              return (
                <div className={classnames("deviceAuthenticationTypes", classes.deviceAuthenticationTypes)}>
                  {item.getDeviceAuthentication().map(deviceAuthentication => (
                    <label
                      key={deviceAuthentication}
                      className={classnames("deviceAuthentication", classes.label, classes.deviceAuthentication)}
                    >
                      {deviceAuthentication}
                    </label>
                  ))}
                </div>
              );
            default:
              return null;
          }
        }}
      />
      {showEditorDialog && (
        <CredentialsEditorDialog
          open={showEditorDialog}
          item={selectedItem}
          cmsAuthorityIds={cmsAuthorityIds}
          cmsAuthorityNames={cmsAuthorityNames}
          loadingAuthorityIds={loadingAuthorityIds}
          deviceAuthenticationAlreadyAdded={deviceAuthenticationAlreadyAdded}
          confirm={saveCredentialDefinition}
          cancel={closeEditorDialog}
        />
      )}
      {showRemoveDialog && (
        <RemoveCredentialDialog
          item={selectedItem}
          open={showRemoveDialog}
          confirm={removeCredentialDefinition}
          cancel={closeRemoveDialog}
        />
      )}
      {children}
    </div>
  );
});

export default CredentialsView;
