import React from "react";
import { Application } from "@data";
import { isEmptyString, noop } from "@util";
import useGetApplications from "@hooks/applicationManagerRegional/use-get-applications-regional";
import SelectApplication, { Actions, Model } from "../components/SelectApplication";

interface ContainerModel extends Model {
  applicationId?: string;
}

interface ContainerActions extends Actions {
  setApplicationId?: (applicationId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const SelectApplicationContainer = (props: Props) => {

  const {
    applicationId = "",
    setApplicationId = noop,
    ...otherProps
  } = props;

  const [{ applications, ...model }, actions] = useGetApplications();

  const selectedItems = React.useMemo(() => {
    if (isEmptyString(applicationId)) {
      return [];
    }
    const selectedApplication = applications.find(item => item.getId() === applicationId);
    return selectedApplication ? [selectedApplication] : [new Application({ id: applicationId })];
  }, [applicationId, applications]);

  const items = React.useMemo(() =>
      selectedItems.length > 0 ? selectedItems : applications,
    [selectedItems, applications]);

  const setSelectedApplication = React.useCallback(
    (application: Application = Application.EMPTY) =>
      setApplicationId(application.getId()), [setApplicationId]);

  return (
    <SelectApplication
      {...otherProps}
      {...model}
      {...actions}
      items={items}
      selectedItems={selectedItems}
      setSelectedApplication={setSelectedApplication}
    />
  );
};

export default SelectApplicationContainer;
