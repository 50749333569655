import LocalStorageClient from "@util/LocalStorageClient";
import {
  KEY_ACCESS_TOKEN_EXPIRY_TIME,
  KEY_ACCOUNT_ID,
  KEY_FAVORITES,
  KEY_IDENTITY_TYPE,
  KEY_LAST_MOUSE_MOVE,
  KEY_PRINCIPAL_ID,
  KEY_SAVED_ACCOUNT,
  KEY_SAVED_SERVICE_ACCOUNT_ID,
  KEY_SAVED_SERVICE_ID,
  KEY_SAVED_USERNAME,
  KEY_VERSION_CODE,
  KEY_CLOSED_MAINTENANCE_REMINDERS,
} from "./constants";
import clearPortalLocalStorage from "@store/clearPortalLocalStorage";

export const cleanLocalStorage = (loggedIn: boolean = false,
                                  canRestoreSession: boolean = false) => {

  if (loggedIn) {
    return false;
  }

  const versionCode = LocalStorageClient.getItem(KEY_VERSION_CODE);
  const savedUsername = LocalStorageClient.getItem(KEY_SAVED_USERNAME);
  const savedUserAccountId = LocalStorageClient.getItem(KEY_SAVED_ACCOUNT);
  const savedServiceId = LocalStorageClient.getItem(KEY_SAVED_SERVICE_ID);
  const savedServiceAccountId = LocalStorageClient.getItem(KEY_SAVED_SERVICE_ACCOUNT_ID);
  const lastMouseMove = LocalStorageClient.getItem(KEY_LAST_MOUSE_MOVE);
  const accountId = LocalStorageClient.getItem(KEY_ACCOUNT_ID);
  const principalId = LocalStorageClient.getItem(KEY_PRINCIPAL_ID);
  const identityType = LocalStorageClient.getItem(KEY_IDENTITY_TYPE);
  const authTokenExpiryTime = LocalStorageClient.getItem(KEY_ACCESS_TOKEN_EXPIRY_TIME);
  const savedFavorites = LocalStorageClient.getItem(KEY_FAVORITES);
  const closedMaintenanceReminders = LocalStorageClient.getItem(KEY_CLOSED_MAINTENANCE_REMINDERS);

  clearPortalLocalStorage();
  LocalStorageClient.setItem(KEY_VERSION_CODE, versionCode);
  LocalStorageClient.setItem(KEY_SAVED_USERNAME, savedUsername);
  LocalStorageClient.setItem(KEY_SAVED_ACCOUNT, savedUserAccountId);
  LocalStorageClient.setItem(KEY_SAVED_SERVICE_ID, savedServiceId);
  LocalStorageClient.setItem(KEY_SAVED_SERVICE_ACCOUNT_ID, savedServiceAccountId);
  LocalStorageClient.setItem(KEY_FAVORITES, savedFavorites);
  LocalStorageClient.setItem(KEY_CLOSED_MAINTENANCE_REMINDERS, closedMaintenanceReminders);

  if (canRestoreSession) {
    LocalStorageClient.setItem(KEY_ACCOUNT_ID, accountId);
    LocalStorageClient.setItem(KEY_PRINCIPAL_ID, principalId);
    LocalStorageClient.setItem(KEY_IDENTITY_TYPE, identityType);
    LocalStorageClient.setItem(KEY_ACCESS_TOKEN_EXPIRY_TIME, authTokenExpiryTime);
    LocalStorageClient.setItem(KEY_LAST_MOUSE_MOVE, lastMouseMove);
  }

  return true;
};

export default cleanLocalStorage;
