import React from "react";
import { instancesListView as styles, WorkloadInstanceIcon } from "./styles";
import { useGetWorkloadInstance, UseGetWorkloadInstancesActions, UseGetWorkloadInstancesModel } from "@hooks";
import { ExecuteWorkloadEvent, TriggerType, WorkloadInstance, WorkloadInstanceState } from "@data";
import { createColumns } from "@components/module-list-view/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import {
  ModuleListView,
  ListViewItem,
  DatePicker,
  DropdownMenu,
  RefreshButton,
  ActionMenuItem,
  WorkloadCancelExecutionDialog,
} from "@components";
import { getPathToWorkloadInstance } from "@modules/dataWorkloadInstanceDetails/helpers";
import StartDateIcon from "@material-ui/icons/DateRange";
import EndDateIcon from "@material-ui/icons/Today";
import { isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled, noop } from "@util";

enum WorkloadInstanceListColumns {
  ID = "ID",
  COMPLETION_AT = "Completed At",
  STATE = "State",
  SUBMISSION_AT = "Started At",
}

const DEFAULT_WORKLOAD_INSTANCE_LIST_COLUMNS: WorkloadInstanceListColumns[] = [
  WorkloadInstanceListColumns.SUBMISSION_AT,
  WorkloadInstanceListColumns.COMPLETION_AT,
  WorkloadInstanceListColumns.STATE,
];

const WORKLOAD_STATES: WorkloadInstanceState[] = [
  WorkloadInstanceState.QUEUED,
  WorkloadInstanceState.RUNNING,
  WorkloadInstanceState.PENDING,
  WorkloadInstanceState.SUCCEEDED,
  WorkloadInstanceState.FAILED,
  WorkloadInstanceState.CANCELLED,
  WorkloadInstanceState.SKIPPED,
];

const DEFAULT_LIMITS: number[] = [10, 25, 50, 75, 100, 200, 500];

export enum ExecutionInstanceAction {
  CANCEL = "Cancel",
  RERUN = "Re-run",
}

export const CANCEL_EXECUTION: ActionMenuItem = Object.freeze({
  id: ExecutionInstanceAction.CANCEL,
  name: "Cancel Execution",
  disabled: false,
  tooltip: "Cancel queued or pending execution",
});

export const RERUN_EXECUTION: ActionMenuItem = Object.freeze({
  id: ExecutionInstanceAction.RERUN,
  name: "Re-run Execution",
  disabled: false,
  tooltip: "Run execution again with the same settings",
});

export interface Model extends UseGetWorkloadInstancesModel {
  name: string;
  version: number;
  reRunEligible: boolean;
}

export interface Actions extends UseGetWorkloadInstancesActions {
  showExecutionInstanceDetails: (workloadInstance: WorkloadInstance) => void;
  setShowTestView: (show: boolean) => void;
  setTestTrigger: (trigger: ExecuteWorkloadEvent) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const ExecutionInstancesView = withStyles(styles)((props: Props) => {

  const {
    classes,
    name,
    version,
    workloadInstances,
    startDate,
    endDate,
    limit,
    instanceState,
    showLoadingIndicator,
    reRunEligible,
    showExecutionInstanceDetails,
    setStartDate,
    setEndDate,
    setInstanceState,
    setLimit,
    setShowTestView,
    setTestTrigger,
    refresh,
    ...otherProps
  } = props;

  const cancelExecutionEnabled = isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled();

  const [nameFilter, setNameFilter] = React.useState("");
  const [executionId, setExecutionId] = React.useState("");
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const onGetWorkloadInstanceSuccess = React.useCallback((instance: WorkloadInstance) => {
    if (instance.event) {
      setTestTrigger(instance.event);
    } else {
      setTestTrigger(new ExecuteWorkloadEvent({ type: TriggerType.MANUAL_EXECUTE }));
    }
    setShowTestView(true);
  }, [setTestTrigger, setShowTestView]);

  const [, { refresh: getWorkloadInstance }] =
    useGetWorkloadInstance({name, version, executionId, onSuccess: onGetWorkloadInstanceSuccess, deferRequest: true});

  const mapActions = React.useCallback((instance: WorkloadInstance, action: ActionMenuItem) => {
    switch (action.id) {
      case ExecutionInstanceAction.CANCEL:
        return {
          ...action,
          disabled: instance.getState() !== WorkloadInstanceState.QUEUED &&
            instance.getState() !== WorkloadInstanceState.PENDING,
        };
      case ExecutionInstanceAction.RERUN:
        return {
          ...action,
          disabled: !instance.isFinished() || !reRunEligible,
        };
      default:
        return action;
    }
  }, [reRunEligible]);

  const onClickCancel = React.useCallback((id: string) => {
    setExecutionId(id);
    setShowCancelDialog(true);
  }, [setExecutionId, setShowCancelDialog]);

  const onClickRerun = React.useCallback((id: string) => {
    setExecutionId(id);
    getWorkloadInstance();
  }, [setExecutionId, getWorkloadInstance]);

  const onClickAction = React.useCallback((instance: WorkloadInstance, action: ActionMenuItem) => {
    switch (action.id) {
      case ExecutionInstanceAction.CANCEL:
        return onClickCancel(instance.getExecutionId());
      case ExecutionInstanceAction.RERUN:
        return onClickRerun(instance.getExecutionId());
      default:
        return noop();
    }
  }, [onClickCancel, onClickRerun]);

  const pathToDetailsView = React.useCallback((item) =>
    getPathToWorkloadInstance(name, version, item.getExecutionId()),
    [name, version, getPathToWorkloadInstance]);

  const moduleListItems: ListViewItem<WorkloadInstance>[] = React.useMemo(() =>
    workloadInstances.map(instance => {
      return {
        item: instance,
        icon: WorkloadInstanceIcon,
        pathToDetailsView: pathToDetailsView(instance),
        columnAttributes: createColumns([
          {
            className: "status",
            value: instance.getState(),
            column: WorkloadInstanceListColumns.STATE,
          },
          {
            className: "startedAt",
            value: instance.getSubmittedAt(),
            column: WorkloadInstanceListColumns.SUBMISSION_AT,
            firstColumn: true,
            isDate: true,
          },
          {
            className: "completedAt",
            value: instance.getCompletedAt(),
            column: WorkloadInstanceListColumns.COMPLETION_AT,
            isDate: true,
          },
        ])
      };
    }), [workloadInstances, pathToDetailsView]);

  const filters = React.useMemo(() => {
    return (
      <div className={classnames("filters", classes.filters)}>
        <DatePicker
          className={classnames("filterByStartDate", classes.datePicker)}
          label={"Start Date"}
          icon={<StartDateIcon/>}
          disabled={showLoadingIndicator}
          selectedDate={startDate}
          setSelectedDate={setStartDate}
          maxDate={endDate}
        />
        <DatePicker
          className={classnames("filterByEndDate", classes.datePicker)}
          label={"End Date"}
          icon={<EndDateIcon/>}
          disabled={showLoadingIndicator}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
          minDate={startDate}
        />
        <DropdownMenu
          className={classnames("state", classes.state)}
          values={WORKLOAD_STATES}
          disabled={showLoadingIndicator}
          selectedValue={instanceState}
          setSelectedValue={(state: WorkloadInstanceState) => setInstanceState(state)}
          dropdownMenuLabel="State"
          emptyValueLabel="All States"
        />
        <DropdownMenu
          className={classnames("limit", classes.limit)}
          values={DEFAULT_LIMITS.map(value => value.toString())}
          disabled={showLoadingIndicator}
          selectedValue={`${limit}`}
          setSelectedValue={(selectedValue: string) => setLimit(Number(selectedValue))}
          dropdownMenuLabel={"Limit"}
          hideEmptyValue={true}
        />
        <RefreshButton
          className={classnames("refresh", classes.refreshButton)}
          loading={showLoadingIndicator}
          refresh={refresh}
        />
      </div>
    );
  }, [
    showLoadingIndicator,
    startDate,
    endDate,
    instanceState,
    limit,
    setStartDate,
    setEndDate,
    setInstanceState,
    setLimit,
    refresh,
  ]);

  return (
    <React.Fragment>
      <ModuleListView
        {...otherProps}
        className={classnames("executionInstancesView", classes.container)}
        showSummary={false}
        showLoadingIndicator={showLoadingIndicator}
        columns={DEFAULT_WORKLOAD_INSTANCE_LIST_COLUMNS}
        listViewItems={moduleListItems}
        nameFilter={nameFilter}
        setNameFilter={setNameFilter}
        onClickItem={showExecutionInstanceDetails}
        showMoreInfoLabel="Workload Instance Details"
        onClickShowMoreInfoInNewTab={pathToDetailsView}
        header={filters}
        actions={cancelExecutionEnabled ? [CANCEL_EXECUTION, RERUN_EXECUTION] : []}
        mapItemToAction={mapActions}
        onClickAction={onClickAction}
      />
      {showCancelDialog && (
        <WorkloadCancelExecutionDialog
          open={showCancelDialog}
          name={name}
          version={version}
          executionId={executionId}
          close={() => setShowCancelDialog(false)}
          onSuccessMessageShown={refresh}
        />
      )}
    </React.Fragment>
  );
});

export default ExecutionInstancesView;
