import React from "react";
import classnames from "classnames";
import Chip from "@components/chip";
import { clickHandler, noop } from "@util";
import TagIcon from "@material-ui/icons/Label";
import DraftIcon from "@material-ui/icons/Edit";
import FailedIcon from "@material-ui/icons/Error";
import ReleasedIcon from "@material-ui/icons/FlashOn";
import InProgressIcon from "@material-ui/icons/Cached";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Workload, { WorkloadState } from "@data/Workload";
import DecommissionedIcon from "@material-ui/icons/NotInterested";
import DeprecatedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ActionsMenu, { ActionMenuItem } from "@components/actions-menu";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  DECOMMISSION_WORKLOAD,
  DELETE_VERSION,
  DEPRECATE_WORKLOAD,
  DRAFT_EXISTING_VERSION,
  DRAFT_LATEST_VERSION,
  DRAFT_NEW_VERSION,
  RELEASE_WORKLOAD,
  SWITCH_PRINCIPAL
} from "@components/workload-actions-menu";
import styles from "./styles";

export interface VersionHistoryListItemData {
  state?: WorkloadState;
  version?: number;
  description?: string;
  tags?: string[];
}

export interface VersionHistoryListItemModel extends VersionHistoryListItemData {
  className?: string;
  workload?: Workload;
  active?: boolean;
  actions?: ActionMenuItem[];
}

export interface VersionHistoryListItemActions {
  onClick?: (workload: Workload) => void;
  onClickAction?: (workload: Workload, action: ActionMenuItem) => void;
}

type Model = VersionHistoryListItemModel;
type Actions = VersionHistoryListItemActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const VersionHistoryListItem = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    workload = Workload.EMPTY,
    active,
    state = workload.getState(),
    version = workload.getVersion(),
    description = workload.getDescription(),
    tags = workload.getTags(),
    onClick = noop,
    onClickAction = noop,
    children,
  } = props;

  const versionActions = [
    {
      ...DRAFT_NEW_VERSION,
      actions: [
        {
          ...DRAFT_EXISTING_VERSION,
          name: `From Version ${version}`
        },
        {
          ...DRAFT_LATEST_VERSION,
        },
      ],
    },
    {
      ...RELEASE_WORKLOAD,
      disabled: state !== WorkloadState.DRAFT &&
        state !== WorkloadState.DEPLOYMENT_FAILED &&
        state !== WorkloadState.RELEASE_FAILED &&
        state !== WorkloadState.DEPRECATED,
    },
    {
      ...DEPRECATE_WORKLOAD,
      disabled: state !== WorkloadState.RELEASED,
    },
    {
      ...DECOMMISSION_WORKLOAD,
      disabled: state !== WorkloadState.RELEASED &&
        state !== WorkloadState.DEPRECATED &&
        state !== WorkloadState.DECOMMISSION_FAILED,
    },
    {
      ...SWITCH_PRINCIPAL,
      disabled: state !== WorkloadState.RELEASED,
    },
    {
      ...DELETE_VERSION,
      disabled: state !== WorkloadState.DRAFT &&
        state !== WorkloadState.DECOMMISSIONED
    },
  ];

  const Icon = React.useMemo<React.ComponentType<SvgIconProps> | null>(() => {
    switch (state) {
      case WorkloadState.DRAFT:
        return DraftIcon;
      case WorkloadState.RELEASED:
        return ReleasedIcon;
      case WorkloadState.DEPRECATED:
        return DeprecatedIcon;
      case WorkloadState.DECOMMISSIONED:
        return DecommissionedIcon;
      case WorkloadState.RELEASING:
      case WorkloadState.DEPLOYING:
      case WorkloadState.DECOMMISSIONING:
        return InProgressIcon;
      case WorkloadState.DEPLOYMENT_FAILED:
      case WorkloadState.RELEASE_FAILED:
      case WorkloadState.DECOMMISSION_FAILED:
        return FailedIcon;
      default:
        return null;
    }
  }, [ state ]);

  const onItemClicked = React.useCallback(clickHandler(() =>
    onClick(workload)), [workload]);

  const onActionClicked = React.useCallback((action: ActionMenuItem) =>
    onClickAction(workload, action), [workload]);

  if (Icon === null || state === WorkloadState.NONE || version <= 0) {
    return null;
  }

  return (
    <div
      className={classnames("listItem", className, classes.container, {
        active,
        [classes.active]: active,
      })}
      onClick={onItemClicked}
    >
      <div className={classnames("listItemContent", classes.listItemContent)}>
        <div className={classnames("stateIconContainer", classes.stateIconContainer)}>
          <Icon
            className={classnames("stateIcon", classes.stateIcon, {
              [classes.draft]: state === WorkloadState.DRAFT,
              [classes.released]: state === WorkloadState.RELEASED,
              [classes.deprecated]: state === WorkloadState.DEPRECATED,
              [classes.decommissioned]: state === WorkloadState.DECOMMISSIONED,
              [classes.inProgressIcon]: state === WorkloadState.RELEASING || state === WorkloadState.DECOMMISSIONING ||
                state === WorkloadState.DEPLOYING,
              [classes.failedIcon]: state === WorkloadState.RELEASE_FAILED ||
                state === WorkloadState.DEPLOYMENT_FAILED || state === WorkloadState.DECOMMISSION_FAILED,
            })}
          />
        </div>
        <label
          className={classnames("stateLabel", classes.stateLabel, {
            [classes.failedLabel]: state === WorkloadState.RELEASE_FAILED ||
            state === WorkloadState.DECOMMISSION_FAILED
          })}
        >
          {`${state.toUpperCase().slice(0, 1)}${state.toLowerCase().slice(1)}`.replace(/_/gi, " ")}
        </label>
        <ActionsMenu
          className={classnames("actionsMenu", classes.actionsMenu)}
          buttonClassName={classnames("actionsMenuIconButton", classes.actionsMenuIconButton)}
          buttonVariant="icon"
          actions={versionActions}
          onClickAction={onActionClicked}
        />
        <label className={classnames("versionLabel", classes.versionLabel)}>
          Version {version}
        </label>
        {description && (
          <label className={classnames("descriptionLabel", classes.descriptionLabel)}>
            {description}
          </label>
        )}
        <div className={classnames("tags", classes.tags)}>
          {tags.length === 0 && (
            <label className={classes.tagsEmptyView}>No Tags Defined</label>
          )}
          {tags.map(tag => (
            <Chip
              key={tag}
              className={classnames("tag", classes.tag)}
              label={(
                <label className={classnames("tagLabel", classes.tagLabel)}>{tag}</label>
              )}
              icon={<TagIcon className={classnames("tagIcon", classes.tagIcon)}/>}
              color="primary"
            />
          ))}
        </div>
      </div>
      {children}
    </div>
  );
});

export default VersionHistoryListItem;
