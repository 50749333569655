import React from "react";
import { noop } from "@util";
import { Workload } from "@data";
import { WorkloadOperations } from "@components";
import DeleteWorkloadDialog from "@components/delete-workload-dialog";
import WorkloadOperationDialog from "@components/workload-operation-dialog";
import WorkloadDraftNewVersionDialog from "@components/workload-draft-new-version-dialog";
import VersionHistory, {
  VersionHistoryContainerActions,
  VersionHistoryContainerModel,
} from "./VersionHistory";
import DataWorkloadDetails, {
  DataWorkloadDetailsContainerActions,
  DataWorkloadDetailsContainerModel,
} from "./DataWorkloadDetails";
import DataWorkloadDetailsLayout, {
  DataWorkloadDetailsLayoutActions,
  DataWorkloadDetailsLayoutModel,
} from "../components/DataWorkloadDetailsLayout";

export interface DataWorkloadDetailsLayoutContainerModel extends DataWorkloadDetailsLayoutModel,
  DataWorkloadDetailsContainerModel,
  VersionHistoryContainerModel {

  name: string;
  version: number;
}

export interface DataWorkloadDetailsLayoutContainerActions extends DataWorkloadDetailsLayoutActions,
  DataWorkloadDetailsContainerActions,
  VersionHistoryContainerActions {

  showLatestVersion?: () => void;
  onDeleteWorkloadSuccess?: () => void;
}

type Model = DataWorkloadDetailsLayoutContainerModel;
type Actions = DataWorkloadDetailsLayoutContainerActions;
type Props = Model & Actions;

const DataWorkloadDetailsLayoutContainer = (props: Props) => {

  const {
    showWorkloadVersionDetails,
    name,
    version,
    deleteWorkload = noop,
    showLatestVersion = noop,
    onDeleteWorkloadSuccess = noop,
    switchPrincipal = noop,
    ...otherProps
  } = props;

  const [reloadPage, setReloadPage] = React.useState(false);
  const [workloadDialogItem, setWorkloadDialogItem] = React.useState<Workload>(Workload.EMPTY);
  const [openOperationDialog, setOpenOperationDialog] = React.useState(false);
  const [operation, setOperation] = React.useState(WorkloadOperations.NONE);
  const [showDeleteWorkloadDialog, setShowDeleteWorkloadDialog] = React.useState(false);
  const [showDeleteWorkloadVersionDialog, setShowDeleteWorkloadVersionDialog] = React.useState(false);

  const refresh = React.useCallback(() =>
    setReloadPage(true), [setReloadPage]);

  const openDeleteWorkloadDialog = React.useCallback(() =>
    setShowDeleteWorkloadDialog(true), [setShowDeleteWorkloadDialog]);

  const closeDeleteWorkloadDialog = React.useCallback(() =>
    setShowDeleteWorkloadDialog(false), [setShowDeleteWorkloadDialog]);

  const openReleaseWorkloadDialog = React.useCallback((workload: Workload) => {
    setWorkloadDialogItem(workload);
    setOperation(WorkloadOperations.RELEASE);
    setOpenOperationDialog(true);
  }, [setWorkloadDialogItem, setOpenOperationDialog]);

  const closeOperationDialog = React.useCallback(() => {
    setOpenOperationDialog(false);
    setWorkloadDialogItem(Workload.EMPTY);
    setOperation(WorkloadOperations.NONE);
  }, [setOpenOperationDialog, setWorkloadDialogItem, setOperation]);

  const openDeprecateWorkloadDialog = React.useCallback((workload: Workload) => {
    setWorkloadDialogItem(workload);
    setOperation(WorkloadOperations.DEPRECATE);
    setOpenOperationDialog(true);
  }, [setWorkloadDialogItem, setOperation, setOpenOperationDialog]);

  const deprecateWorkload = React.useCallback(() =>
      openDeprecateWorkloadDialog(new Workload({ name, version })),
    [openDeprecateWorkloadDialog, name, version]);

  const openDecommissionWorkloadDialog = React.useCallback((workload: Workload) => {
    setWorkloadDialogItem(workload);
    setOperation(WorkloadOperations.DECOMMISSION);
    setOpenOperationDialog(true);
  }, [setWorkloadDialogItem, setOperation, setOpenOperationDialog]);

  const decommissionWorkload = React.useCallback(() =>
      openDecommissionWorkloadDialog(new Workload({ name, version })),
    [openDecommissionWorkloadDialog, name, version]);

  const [ showDraftNewVersionDialog, setShowDraftNewVersionDialog ] = React.useState(false);

  const openDraftNewVersionDialog = React.useCallback((workload: Workload, latest: boolean = false) => {
    setWorkloadDialogItem(new Workload({ ...workload.toJS(), version: latest ? 0 : workload.getVersion() }));
    setShowDraftNewVersionDialog(true);
  }, [ setWorkloadDialogItem, setShowDraftNewVersionDialog]);

  const draftNewVersion = React.useCallback((latest: boolean = false) =>
    openDraftNewVersionDialog(new Workload({ name, version: latest ? 0 : version })),
    [ openDraftNewVersionDialog, name, version ]);

  const closeDraftNewVersionDialog = React.useCallback(() => {
    setShowDraftNewVersionDialog(false);
    setWorkloadDialogItem(Workload.EMPTY);
  }, [ setShowDraftNewVersionDialog, setWorkloadDialogItem ]);

  const draftNewVersionSuccess = React.useCallback(() => {
    closeDraftNewVersionDialog();
    setReloadPage(true);
    showLatestVersion();
  }, [closeDraftNewVersionDialog, showLatestVersion, setReloadPage]);

  const openDeleteWorkloadVersionDialog = React.useCallback((workload: Workload) => {
    setWorkloadDialogItem(workload);
    setShowDeleteWorkloadVersionDialog(true);
  }, [setWorkloadDialogItem, setShowDeleteWorkloadVersionDialog]);

  const closeDeleteWorkloadVersionDialog = React.useCallback(() => {
    setShowDeleteWorkloadVersionDialog(false);
    setWorkloadDialogItem(Workload.EMPTY);
  }, [setWorkloadDialogItem, setShowDeleteWorkloadVersionDialog]);

  const deleteWorkloadVersion = React.useCallback(() =>
    openDeleteWorkloadVersionDialog(new Workload({ name, version })),
    [openDeleteWorkloadVersionDialog, name, version]);

  const deleteWorkloadVersionSuccess = React.useCallback(() => {
    closeDeleteWorkloadVersionDialog();
    setReloadPage(true);
    showLatestVersion();
  }, [closeDeleteWorkloadVersionDialog, showLatestVersion, setReloadPage]);

  const onOperationSuccess = React.useCallback(() => {
    closeOperationDialog();
    setReloadPage(true);
  }, [operation, closeOperationDialog, setReloadPage]);

  const dialogClassName = React.useMemo(() =>
      `${operation.toLowerCase()}WorkloadDialog`,
    [operation]);

  // Simple effect that will ensure both version history and details are re-mounted,
  // which will trigger a refresh of both container components
  React.useEffect(() => {
    if (reloadPage) {
      setReloadPage(false);
    }
  }, [ reloadPage, setReloadPage ]);

  if (reloadPage) {
    return null;
  }

  return (
    <React.Fragment>
      <DataWorkloadDetailsLayout
        title={name}
        refresh={refresh}
        deleteWorkload={openDeleteWorkloadDialog}
        {...otherProps}
      >
        <VersionHistory
          {...otherProps}
          name={name}
          version={version}
          switchWorkloadPrincipal={switchPrincipal}
          draftNewWorkloadVersion={openDraftNewVersionDialog}
          releaseWorkload={openReleaseWorkloadDialog}
          deprecateWorkload={openDeprecateWorkloadDialog}
          decommissionWorkload={openDecommissionWorkloadDialog}
          showWorkloadVersionDetails={showWorkloadVersionDetails}
          deleteWorkloadVersion={openDeleteWorkloadVersionDialog}
        />
        <DataWorkloadDetails
          {...otherProps}
          name={name}
          version={version}
          switchPrincipal={() => switchPrincipal(version)}
          draftNewVersion={draftNewVersion}
          release={openReleaseWorkloadDialog}
          deprecate={deprecateWorkload}
          decommission={decommissionWorkload}
          refreshAllDetails={refresh}
          deleteVersion={deleteWorkloadVersion}
        />
      </DataWorkloadDetailsLayout>
      <DeleteWorkloadDialog
        open={showDeleteWorkloadDialog}
        name={name}
        cancel={closeDeleteWorkloadDialog}
        onSuccessMessageShown={onDeleteWorkloadSuccess}
      />
      <DeleteWorkloadDialog
        open={showDeleteWorkloadVersionDialog}
        name={workloadDialogItem.getName()}
        version={workloadDialogItem.getVersion()}
        cancel={closeDeleteWorkloadVersionDialog}
        onSuccessMessageShown={deleteWorkloadVersionSuccess}
      />
      <WorkloadDraftNewVersionDialog
        open={showDraftNewVersionDialog}
        name={workloadDialogItem.getName()}
        version={workloadDialogItem.getVersionNumOrLatest()}
        onSuccessMessageShown={draftNewVersionSuccess}
        close={closeDraftNewVersionDialog}
      />
      <WorkloadOperationDialog
        dialogClassName={dialogClassName}
        open={openOperationDialog}
        name={workloadDialogItem.getName()}
        version={workloadDialogItem.getVersion()}
        isQueryType={workloadDialogItem.isQueryType()}
        operation={operation}
        onSuccessMessageShown={onOperationSuccess}
        cancel={closeOperationDialog}
      />
    </React.Fragment>
  );
};

export default DataWorkloadDetailsLayoutContainer;
