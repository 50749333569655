import React from "react";
import { noop } from "@util";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EditorViewItem from "../models/EditorViewItem";
import EditorTab from "./EditorTab";
import styles from "./styles";

export interface EditorTabsModel {
  className?: string;
  items?: EditorViewItem[];
}

export interface EditorTabsActions {
  openTab?: (id: string) => void;
  closeTab?: (id: string) => void;
}

type Model = EditorTabsModel;
type Actions = EditorTabsActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const EditorTabs = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    items = [],
    openTab = noop,
    closeTab = noop,
    children,
  } = props;

  return (
    <div className={classnames("editorTabs", className, classes.container)}>
      {items.map(item => {
        const id = item.getId();
        return (
          <EditorTab
            key={id}
            className={classnames("tab", classes.tab)}
            label={item.getLabel()}
            selected={item.isSelected()}
            openTab={() => openTab(id)}
            closeTab={() => closeTab(id)}
          />
        );
      })}
      {children}
    </div>
  );
});

export default EditorTabs;