import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";

export enum ReleaseNoteIssueType {
  CHANGE_REQUEST = "CHANGE_REQUEST",
  NEW_FEATURES = "NEW_FEATURES",
  DEFECT_FIXES = "DEFECT_FIXES",
  OTHER_ENHANCEMENTS = "OTHER_ENHANCEMENTS",
  KNOWN_ISSUES = "KNOWN_ISSUES",
  BREAKING_CHANGES = "BREAKING_CHANGES",
}

export enum ReleaseNoteIssueStatus {
  PLANNED = "PLANNED",
  IN_PROGRESS = "IN_PROGRESS",
  POSTPONED = "POSTPONED",
  COMPLETED = "COMPLETED",
}

export interface ReleaseNoteIssueAttributes {
  affectedService: string;
  compatibility: string;
  summary: string;
  description: string;
  issueType: ReleaseNoteIssueType;
  jiraLink: string;
  otherLink: string;
  issueStatus: ReleaseNoteIssueStatus;
}

export class ReleaseNoteIssue extends Record({
  affectedService: "",
  compatibility: "",
  summary: "",
  description: "",
  issueType: ReleaseNoteIssueType.OTHER_ENHANCEMENTS,
  jiraLink: "",
  otherLink: "",
  issueStatus: ReleaseNoteIssueStatus.COMPLETED,
}) implements ReleaseNoteIssueAttributes {

  public static EMPTY: ReleaseNoteIssue = new ReleaseNoteIssue();

  public readonly affectedService: string;
  public readonly compatibility: string;
  public readonly summary: string;
  public readonly description: string;
  public readonly issueType: ReleaseNoteIssueType;
  public readonly jiraLink: string;
  public readonly otherLink: string;
  public readonly issueStatus: ReleaseNoteIssueStatus;

  public getAffectedService(): string {
    return getStringValue(this.affectedService);
  }

  public getCompatibility(): string {
    return getStringValue(this.compatibility);
  }

  public getSummary(): string {
    return getStringValue(this.summary);
  }

  public getDescription(): string {
    return getStringValue(this.description);
  }

  public getIssueType(): ReleaseNoteIssueType {
    return this.issueType;
  }

  public getJiraLink(): string {
    const jiraLink = getStringValue(this.jiraLink);
    return jiraLink.indexOf("https") < 0 ? "" : jiraLink;
  }

  public getOtherLink(): string {
    const otherLink = getStringValue(this.otherLink);
    return otherLink.indexOf("https") < 0 ? "" : otherLink;
  }

  public hasOtherLink(): boolean {
    const otherLink = this.getOtherLink();
    return !isEmptyString(otherLink);
  }

  public getIssueStatus(): ReleaseNoteIssueStatus {
    return this.issueStatus;
  }
}

export default ReleaseNoteIssue;
