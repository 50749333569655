import React from "react";
import { connect } from "react-redux";
import { isEmptyString } from "@util";
import { AppSchema } from "@main/schemas";
import { getAccountId } from "@main/selectors";
import { EmailTemplateWizardState } from "../models";
import WaitForApiRequestView from "@components/wait-for-api-request-view";
import EmailTemplateWizard, { Actions, Model } from "../components/EmailTemplateWizard";
import TemplateView from "../components/TemplateView";
import BodyView from "../components/BodyView";
import ApplicationView from "./ApplicationView";
import ReviewView from "../components/ReviewView";
import {
  EmailBodyAttributes,
  EmailTemplate,
  EmailTemplateMessageType,
  EmailTemplateRequest,
  EmailTemplateRequestAttributes
} from "@data";
import {
  useCreateEmailTemplate,
  useEmailTemplate,
  useEmailTemplateLanguages,
  useEmailTemplateRequirements,
  useEmailTemplates
} from "@hooks";
import {
  getTemplateIdErrorMessage,
  getDescriptionErrorMessage,
  getEmailSubjectErrorMessage,
  getSteps,
  getErrorTitle,
  EmailTemplateWizardStep,
  getDisabledSteps,
  getCompletedSteps,
} from "../helpers";

interface ContainerModel extends Model {
  editMode?: boolean;
  accountId?: string;
  emailTemplateId?: string;
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const EmailTemplateWizardContainer = (props: Props) => {

  const {
    accountId = "",
    editMode = false,
    emailTemplateId = "",
    ...otherProps
  } = props;

  const [validBody, setValidBody] = React.useState<boolean>(false);
  const [templateId, setTemplateId] = React.useState<string>(emailTemplateId);

  const [{
    emailRequirements,
    showLoadingIndicator: showLoadingIndicatorReq,
    showAccessDenied: showAccessDeniedReq,
    showErrorView: showErrorViewReq,
    errorMessage: errorMessageReq,
    statusCode: statusCodeReq,
  }, {
    refresh: retry,
  }] = useEmailTemplateRequirements();

  const [{
    emailLanguages,
    showLoadingIndicator: showLoadingIndicatorLang,
    showAccessDenied: showAccessDeniedLang,
    showErrorView: showErrorViewLang,
    errorMessage: errorMessageLang,
    statusCode: statusCodeLang,
  }, {
    refresh: retryLang,
  }] = useEmailTemplateLanguages();

  const [{
    emailTemplates: currentEmails = [],
    showLoadingIndicator: loadingCurrentEmails
  }] = useEmailTemplates({ accountId });

  const [{
    emailTemplate: email = new EmailTemplate(),
    showLoadingIndicator: loadingEmail,
  }] = useEmailTemplate({ accountId, templateId, deferRequest: !editMode });

  const defaultTemplateRequest = React.useMemo(() =>
    editMode ? EmailTemplateRequest.from(email) : EmailTemplateRequest.EMPTY,
    [editMode, email]);

  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplateRequestAttributes>(
    defaultTemplateRequest.toJS()
  );

  React.useEffect(() => {
    setEmailTemplate(defaultTemplateRequest.toJS());
  }, [defaultTemplateRequest]);

  const [currentStep, setCurrentStep] = React.useState<EmailTemplateWizardStep>(
    editMode ? EmailTemplateWizardStep.BODY : EmailTemplateWizardStep.TEMPLATE
  );

  const steps = React.useMemo(() => getSteps(editMode), [editMode]);

  const emailTemplateRequest = React.useMemo(() => new EmailTemplateRequest(emailTemplate), [emailTemplate]);

  const currentState = React.useMemo(() =>
    new EmailTemplateWizardState({ templateId, ...emailTemplateRequest.toJS() }),
    [templateId, emailTemplateRequest]);

  const defaultState = React.useMemo(() => !editMode ? EmailTemplateWizardState.EMPTY :
    new EmailTemplateWizardState({ templateId, ...defaultTemplateRequest.toJS() }),
    [templateId, editMode, defaultTemplateRequest]);

  const [createModel, createActions] =
    useCreateEmailTemplate({ templateId, accountId, editMode, emailTemplateRequest });

  const { save, reset } = createActions;
  const { showLoadingIndicator, showSuccessView, errorMessage } = createModel;

  const isCustomTemplate = emailTemplateRequest.getMessageType() ===
    EmailTemplateMessageType.MESSAGE_CUSTOM_TEMPLATE;

  const hasMsgType = (n: EmailTemplate[]) => n.some((e: EmailTemplate) =>
    e.messageType === emailTemplateRequest.getMessageType());

  const appIds = (n: EmailTemplate[]) => n.filter((e: EmailTemplate) =>
    e.applicationId === emailTemplateRequest.getApplicationId());

  const hasDuplicateAppId = !isEmptyString(emailTemplateRequest.getApplicationId()) &&
    hasMsgType(appIds(currentEmails)) && !isCustomTemplate;

  const hasDuplicateNoAppId = isEmptyString(emailTemplateRequest.getApplicationId()) &&
    hasMsgType(currentEmails) && !isCustomTemplate;

  const validApplicationId = React.useMemo(() => emailTemplateRequest.hasApplicationId(), [emailTemplateRequest]);

  const validTemplateId = React.useMemo(() =>
    !isEmptyString(templateId), [templateId]);

  const validSubject = React.useMemo(() =>
    !isEmptyString(emailTemplateRequest.getBody().getEmailSubject()), [emailTemplateRequest]);

  const validDescription = React.useMemo(() =>
    !isEmptyString(emailTemplateRequest.getBody().getDescription()), [emailTemplateRequest]);

  const disabledSteps = React.useMemo(() =>
    getDisabledSteps(steps, validTemplateId, validBody, !editMode && (hasDuplicateAppId || hasDuplicateNoAppId)),
    [steps, validTemplateId, validBody, editMode, hasDuplicateAppId, hasDuplicateNoAppId]);

  const completedSteps = React.useMemo(() => getCompletedSteps(
    steps,
    currentStep,
    validTemplateId,
    validBody,
    validApplicationId,
    showLoadingIndicator,
    showSuccessView
  ), [
    steps,
    currentStep,
    validTemplateId,
    validBody,
    validApplicationId,
    showLoadingIndicator,
    showSuccessView
  ]);

  const errorTitle = React.useMemo(() =>
    getErrorTitle(errorMessage, editMode), [errorMessage, editMode]);

  const emailSubjectError = React.useMemo(() =>
    getEmailSubjectErrorMessage(validSubject, errorMessage), [validSubject, errorMessage]);

  const descriptionError = React.useMemo(() =>
    getDescriptionErrorMessage(validDescription, errorMessage), [validDescription, errorMessage]);

  const templateIdError = React.useMemo(() =>
    getTemplateIdErrorMessage(validTemplateId, errorMessage), [validTemplateId, errorMessage]);

  const updateEmailTemplate = React.useCallback((attrs: Partial<EmailTemplateRequestAttributes>) => {
    setEmailTemplate(new EmailTemplateRequest({
      ...emailTemplateRequest.toJS(),
      ...attrs,
    }));
    if (!isEmptyString(errorMessage)) { reset(); }
  }, [setEmailTemplate, reset, errorMessage, emailTemplateRequest]);

  const updateEmailBody = React.useCallback((attrs: Partial<EmailBodyAttributes>) => {
    return updateEmailTemplate({
      body: {
        ...emailTemplate.body,
        ...attrs,
      }
    });
  }, [updateEmailTemplate, emailTemplate]);

  const templateView = React.useMemo(() => (
    <TemplateView
      templateId={templateId}
      templateIdDisabled={editMode}
      emailLanguages={emailLanguages}
      templateIdError={templateIdError}
      emailRequirements={emailRequirements}
      language={emailTemplateRequest.getLanguage()}
      messageType={emailTemplateRequest.getMessageType()}
      setTemplateId={setTemplateId}
      setLanguage={language => updateEmailTemplate({ language })}
      setMessageType={messageType => updateEmailTemplate({ messageType })}
    />
  ), [
    templateId,
    editMode,
    emailLanguages,
    templateIdError,
    emailRequirements,
    emailTemplateRequest,
    setTemplateId,
    updateEmailTemplate
  ]);

  const applicationView = React.useMemo(() => (
    <ApplicationView
      accountId={accountId}
      currentEmails={currentEmails}
      hasDuplicateAppId={hasDuplicateAppId}
      hasDuplicateNoAppId={hasDuplicateNoAppId}
      messageType={emailTemplateRequest.getMessageType()}
      applicationId={emailTemplateRequest.getApplicationId()}
      setApplicationId={applicationId => updateEmailTemplate({ applicationId })}
    />
  ), [
    accountId,
    currentEmails,
    hasDuplicateAppId,
    hasDuplicateNoAppId,
    emailTemplateRequest,
    updateEmailTemplate
  ]);

  const bodyView = React.useMemo(() => (
    <BodyView
      descriptionError={descriptionError}
      emailSubjectError={emailSubjectError}
      emailRequirements={emailRequirements}
      messageType={emailTemplateRequest.getMessageType()}
      textBody={emailTemplateRequest.getBody().getTextBody()}
      htmlBody={emailTemplateRequest.getBody().getHtmlBody()}
      emailSubject={emailTemplateRequest.getBody().getEmailSubject()}
      description={emailTemplateRequest.getBody().getDescription()}
      setValidateStatus={setValidBody}
      setTextBody={textBody => updateEmailBody({ textBody })}
      setHtmlBody={htmlBody => updateEmailBody({ htmlBody })}
      setDescription={description => updateEmailBody({ description })}
      setEmailSubject={emailSubject => updateEmailBody({ emailSubject })}
    />
  ), [
    descriptionError,
    emailSubjectError,
    emailRequirements,
    emailTemplateRequest,
    setValidBody,
    updateEmailBody,
  ]);

  const reviewView = React.useMemo(() => (
    <ReviewView
      templateId={templateId}
      language={emailTemplateRequest.getLanguage()}
      messageType={emailTemplateRequest.getMessageType()}
      applicationId={emailTemplateRequest.getApplicationId()}
      textBody={emailTemplateRequest.getBody().getTextBody()}
      htmlBody={emailTemplateRequest.getBody().getHtmlBody()}
      description={emailTemplateRequest.getBody().getDescription()}
      emailSubject={emailTemplateRequest.getBody().getEmailSubject()}
    />
  ), [templateId, emailTemplateRequest]);

  const mapStepToView = React.useCallback((step: EmailTemplateWizardStep) => {
    switch (step) {
      case EmailTemplateWizardStep.TEMPLATE:
        return templateView;
      case EmailTemplateWizardStep.APPLICATION:
        return applicationView;
      case EmailTemplateWizardStep.BODY:
        return bodyView;
      case EmailTemplateWizardStep.REVIEW:
        return reviewView;
      default:
        return null;
    }
  }, [
    templateView,
    applicationView,
    bodyView,
    reviewView,
  ]);

  return (
    <WaitForApiRequestView
      className="emailTemplateWizard"
      loadingMessage="Loading Email Template Wizard..."
      errorTitle="Failed to Load Email Template Wizard"
      showLoadingIndicator={
        loadingEmail ||
        loadingCurrentEmails ||
        showLoadingIndicatorReq ||
        showLoadingIndicatorLang
      }
      showAccessDenied={showAccessDeniedReq || showAccessDeniedLang}
      showErrorView={showErrorViewReq || showErrorViewLang}
      errorMessage={errorMessageReq || errorMessageLang}
      retry={retry}
      retryList={[retry, retryLang]}
      statusCode={statusCodeReq || statusCodeLang}
    >
      <EmailTemplateWizard
        {...otherProps}
        {...createModel}
        {...createActions}
        save={save}
        steps={steps}
        errorTitle={errorTitle}
        currentStep={currentStep}
        defaultState={defaultState}
        currentState={currentState}
        mapStepToView={mapStepToView}
        disabledSteps={disabledSteps}
        completedSteps={completedSteps}
        onChangeCurrentStep={step => setCurrentStep(step)}
      />
    </WaitForApiRequestView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  accountId: getAccountId(state),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, ContainerModel & ContainerActions>(
  mapStateToProps,
)(EmailTemplateWizardContainer);
