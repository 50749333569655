import { Set } from "immutable";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI, Reducer } from "redux";
import AppSchema from "../modules/main/schemas";

const DEVELOPMENT_MODE = process.env.NODE_ENV === "development";

export enum ActionType {
  TOGGLE_SHOW_PORTAL = "toggle_show_portal",
  TOGGLE_FEEDBACK_DIALOG = "toggle_feedback_dialog",
  SET_ACCOUNT_ID = "set_account_id",
  SET_PRINCIPAL_ID = "set_principal_id",
  SET_IDENTITY_TYPE = "set_identity_type",
  UPDATE_CURRENT_USER = "update_current_user",
  UPDATE_LAST_MOUSE_MOVED = "update_last_mouse_moved",
  UPDATE_ENABLED_MODULE_IDS = "update_enabled_module_ids",
  UPDATE_PERSISTED_MODULE_IDS = "update_persisted_module_ids",
  UPDATE_AUTH_TOKEN = "update_auth_token",
  UPDATE_AUTH_TOKEN_EXPIRY_TIME = "update_auth_token_expiry_time",
  UPDATE_REFRESH_TOKEN = "update_refresh_token",
  UPDATE_QUERY_PARAMS = "update_query_params",
  FETCH_CURRENT_USER_REQUEST = "fetch_current_user_request",
  FETCH_CURRENT_USER_SUCCESS = "fetch_current_user_success",
  FETCH_CURRENT_USER_FAILURE = "fetch_current_user_failure",
  FETCH_CURRENT_SERVICE_PRINCIPAL_REQUEST = "fetch_current_service_principal_request",
  FETCH_CURRENT_SERVICE_PRINCIPAL_SUCCESS = "fetch_current_service_principal_success",
  FETCH_CURRENT_SERVICE_PRINCIPAL_FAILURE = "fetch_current_service_principal_failure",
  FETCH_MAINTENANCE_REMINDER_REQUEST = "fetch_maintenance_reminder_request",
  FETCH_MAINTENANCE_REMINDER_SUCCESS = "fetch_maintenance_reminder_success",
  FETCH_MAINTENANCE_REMINDER_FAILURE = "fetch_maintenance_reminder_failure",
  APP_RELOAD = "load_state",
  SUPERSET_APP_RELOAD = "superset_force_refresh",
  RESET = "reset",
  ERROR = "error",
  SET_VERSION = "set_version",
  SET_VERSION_CODE = "set_version_code",
  SET_SHOW_INFORMATION_BANNER = "set_show_information_banner",
  SET_USER_REPORT_MESSAGE = "set_user_report_message",
  SET_USER_FAVORITES = "set_user_favorites",
  SET_USER_FAVORITES_KEY = "set_user_favorites_key",
  SET_MAINTENANCE_REMINDER = "set_maintenance_reminder",
  UPDATE_CLOSED_MAINTENANCE_REMINDERS = "update_closed_maintenance_reminders",
  LOGOUT_TRIGGERED = "logout_triggered",
}

export const ErrorDispatcher: Middleware<any, any, any> =
  (api: MiddlewareAPI<any>) =>
    (next: Dispatch<AnyAction>) =>
      (action: any) => {
        let result: any;
        try {
          result = Promise.resolve(next(action));
        } catch (e) {
          result = Promise.reject(e);
        }
        result.catch((e: any) => {
          if (DEVELOPMENT_MODE) {
            console.error("ErrorDispatcher Caught an Error: " + e.message, e);
          }
          api.dispatch({
            type: ActionType.ERROR,
            value: e
          });
        });
        return result;
      };

export interface ReduxAction<T, V = any> extends AnyAction {
  type: T | ActionType;
  value?: V;
}

export type ReduxActionCreator<T = any, V = any, A = V> = (...args: A[]) => ReduxAction<T, V>;

export const Actions = <T>(...action: T[]) => Set(action);

export const Identity = <K>(x: K) => x;

export const filterAction = <T = any>(validActions: Set<string>) =>
  (mapper: (t: any, action: ReduxAction<ActionType>) => T, initialState: T): Reducer<T> =>
    (state: T = initialState, action: ReduxAction<ActionType>) => {
      if (validActions.contains(action.type) || action.type === ActionType.RESET) {
        const value = mapper(action.type === ActionType.RESET ? initialState : action.value, action);
        if (typeof value !== "undefined") {
          return value;
        }
      }
      if (action.type === ActionType.APP_RELOAD) {
        return mapper(state, action);
      }
      return state;
    };

export const emptyAction = <T = string>(type: T) => () => ({ type });

export const identityAction = <T = string, V = any>(type: T, defaultValue: V) =>
  (value: V = defaultValue): ReduxAction<T> => {

    // Handle boolean values independently since the expression below would end up using
    // the default value if the value passed in is actually intended to be false.
    if (typeof value === "boolean") {
      return { type, value };
    }

    return {
      type,
      value: value || defaultValue,
    };
  };

export const analyticAction = <T = string>(type: T, defaultValue: string = "") =>
  identityAction<T, string>(type, defaultValue);

export type AppSelector<T, S extends AppSchema = AppSchema> = (state: S) => T;

export const withModuleState =
  <S = AppSchema>(key: keyof S, mapper: (state: any) => any) =>
    (state: S) => mapper(state[key]);

export const withModuleSelector =
  <S extends AppSchema, K extends keyof S, T = S[K]>(module: keyof S, key: keyof T) =>
    withModuleState(module, (state: T) => state[key]);

export const createModuleSelectorFactory = <K extends keyof AppSchema, S = AppSchema[K]>(module: K) =>
  <T>(mapper: (moduleState: S) => T) => (state: AppSchema) => mapper(state[module]);
