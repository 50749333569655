import React from "react";
import { noop } from "@util";
import styles from "./styles";
import AppSchema from "@schemas";
import { connect } from "react-redux";
import { isPortalLoaded } from "@main/selectors";
import { createPortalSession } from "@main/actions";
import StartSession from "@components/start-session";
import PortalSessionInfo from "@data/PortalSessionInfo";
import useGetPortalSession from "@hooks/session/use-get-portal-session";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Portal from "./Portal";

interface Model {
  isLoggedIn?: boolean;
}

interface Actions {
  initialize?: (session: PortalSessionInfo) => void;
  reload?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

const PortalWebserverClient = withStyles(styles)((props: Props) => {

  const {
    isLoggedIn,
    initialize = noop,
    reload = noop,
  } = props;

  const [reloading, setReloading] = React.useState(false);

  const [initialized, setInitialized] = React.useState(false);

  const retry = React.useCallback(() => {
    setReloading(true);
  }, [setReloading]);

  const [{
    sessionInfo,
    showLoadingIndicator,
    showSuccessView,
    showErrorView,
    errorMessage,
  }] = useGetPortalSession();

  const showErrorMessage = React.useMemo(() =>
    !showLoadingIndicator && !reloading && showErrorView,
    [showLoadingIndicator, reloading, showErrorView]);

  React.useEffect(() => {
    if (showSuccessView) {
      initialize(sessionInfo);
    }
  }, [showSuccessView, sessionInfo, initialize]);

  React.useEffect(() => {
    if (showSuccessView && isLoggedIn) {
      setInitialized(true);
    }
  }, [showSuccessView, isLoggedIn, setInitialized]);

  React.useEffect(() => {
    if (!reloading) {
      return noop;
    }
    const timer = setTimeout(() => {
      reload();
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [reloading, reload]);

  if (!initialized) {
    return (
      <StartSession
        errorMessage={errorMessage}
        showErrorMessage={showErrorMessage}
        retry={retry}
      />
    );
  }

  return <Portal/>;
});

const mapStateToProps = (state: AppSchema, ownProps: Model): Model => ({
  isLoggedIn: isPortalLoaded(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: Actions): Actions => ({
  initialize: (session: PortalSessionInfo) => dispatch(createPortalSession(session)),
  reload: () => {
    window.location.reload();
  },
  ...ownProps,
});

export default connect<Model, Actions, Model & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(PortalWebserverClient);
