import { Module } from "@data";
import { initialize, onMount } from "./actions";
import { createModule } from "@base/createModule";
import { isDeviceManagementEnabled } from "@util";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./DeviceTypesView";

export const deviceTypesModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Device Types",
  title: "Device Type Management",
  className: "deviceTypesModule",
  primaryColor,
  icon,
  view,
  backButton: null,
  initialize,
  onMount,
  disabled: !isDeviceManagementEnabled(),
});

export default deviceTypesModule;
