import { useApiRequestAnalytics } from "@hooks";
import { PortalSessionInfoAttributes } from "@data/PortalSessionInfo";
import PortalSessionManagementClient from "@network/PortalSessionManagementClient";

export type GetPortalSessionInfoApi = () => Promise<PortalSessionInfoAttributes>;

export type RefreshPortalSessionApi = (accessToken: string) => Promise<PortalSessionInfoAttributes>;

export type EndPortalSessionApi = () => Promise<void>;

export interface PortalSessionManagementClientApi {
  getPortalSessionInfo: GetPortalSessionInfoApi;
  refreshPortalSession: RefreshPortalSessionApi;
  endPortalSession: EndPortalSessionApi;
}

enum ActionTypes {
  GET_PORTAL_SESSION_INFO_REQUEST = "portal_session_management_client/get_portal_session_info_request",
  GET_PORTAL_SESSION_INFO_SUCCESS = "portal_session_management_client/get_portal_session_info_success",
  GET_PORTAL_SESSION_INFO_FAILED = "portal_session_management_client/get_portal_session_info_failed",

  REFRESH_PORTAL_SESSION_REQUEST = "portal_session_management_client/refresh_portal_session_request",
  REFRESH_PORTAL_SESSION_SUCCESS = "portal_session_management_client/refresh_portal_session_success",
  REFRESH_PORTAL_SESSION_FAILED = "portal_session_management_client/refresh_portal_session_failed",

  END_PORTAL_SESSION_REQUEST = "portal_session_management_client/end_portal_session_request",
  END_PORTAL_SESSION_SUCCESS = "portal_session_management_client/end_portal_session_success",
  END_PORTAL_SESSION_FAILED = "portal_session_management_client/end_portal_session_failed",
}

const useGetPortalSessionInfoApi = () =>
  useApiRequestAnalytics(PortalSessionManagementClient.getPortalSessionInfo, {
    REQUEST_EVENT: ActionTypes.GET_PORTAL_SESSION_INFO_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_PORTAL_SESSION_INFO_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_PORTAL_SESSION_INFO_FAILED,
  });

const useRefreshPortalSessionApi = () =>
  useApiRequestAnalytics(PortalSessionManagementClient.refreshPortalSession, {
    REQUEST_EVENT: ActionTypes.REFRESH_PORTAL_SESSION_REQUEST,
    SUCCESS_EVENT: ActionTypes.REFRESH_PORTAL_SESSION_SUCCESS,
    ERROR_EVENT: ActionTypes.REFRESH_PORTAL_SESSION_FAILED,
  });

const useEndPortalSessionApi = () =>
  useApiRequestAnalytics(PortalSessionManagementClient.endPortalSession, {
    REQUEST_EVENT: ActionTypes.END_PORTAL_SESSION_REQUEST,
    SUCCESS_EVENT: ActionTypes.END_PORTAL_SESSION_SUCCESS,
    ERROR_EVENT: ActionTypes.END_PORTAL_SESSION_FAILED,
  });

export const usePortalSessionManagementClient = (): PortalSessionManagementClientApi => ({
  getPortalSessionInfo: useGetPortalSessionInfoApi(),
  refreshPortalSession: useRefreshPortalSessionApi(),
  endPortalSession: useEndPortalSessionApi(),
});

export { ActionTypes as PortalSessionManagementClientActionTypes };

export default usePortalSessionManagementClient;
