import React from "react";
import classnames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { ErrorView } from "@components";

export interface CommentsViewModel {
  className?: string;
  errorMessage?: string;
  title?: string;
  statusCode?: number;
}

export interface CommentsViewActions {
}

type Model = CommentsViewModel;
type Actions = CommentsViewActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const CommentsView = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    errorMessage,
    statusCode,
    title = "Comments",
    children,
  } = props;

  return (
    <div className={classnames("comments", className, classes.container)}>
      <h1 className={classnames("title", classes.title)}>
        {title}
      </h1>
      <ErrorView className={classes.errorMessageContainer} message={errorMessage} statusCode={statusCode}/>
      {children}
    </div>
  );
});

export default CommentsView;
