import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./SwitchPrincipalView";

export const switchPrincipalModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Switch Principal",
  title: "Switch Principal",
  className: "switchPrincipalModule",
  primaryColor,
  icon,
  view,
});

export default switchPrincipalModule;
