import { Theme } from "@material-ui/core/styles/createTheme";
import { createStyles, StyleRules } from "@material-ui/core/styles";
import { Color, INFORMATION_BANNER_HEIGHT } from "@components";

export const drawerWidth = 270;

export const drawer: StyleRules = {
  root: {
    width: drawerWidth,
  },
  paper: {
    backgroundColor: "#18202c",
    width: drawerWidth,
  },
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: `0 0 ${drawerWidth}px`,
    },
    maintenanceReminder: {
      top: 65,
    },
    maintenanceReminderFooter: {
      marginBottom: 65,
    },
    informationBannerVisible: {
      marginTop: INFORMATION_BANNER_HEIGHT,
    },
    listItemTitle: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontWeight: 200,
      padding: "16px 4px",
    },
    listItemTitleIcon: {
      width: 48,
      height: 48,
      marginRight: 4,
    },
    listItemSettings: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "28px",
      padding: 0,
      "& .link": {
        padding: "16px 20px",
        margin: 0,
      },
    },
    listItemSettingsIcon: {
      width: 14,
      height: 14,
      marginRight: 14,
    },
    listItemFeedback: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      backgroundColor: Color.BLACK5,
      boxShadow: "0 -1px 0 #404854 inset",
      lineHeight: "16px",
      padding: "16px 20px",
      margin: 0,
    },
    listItemFeedbackIcon: {
      width: 14,
      height: 14,
      marginRight: 14,
    },
    releaseNotes: {
      marginTop: "auto",
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    listItemReleaseNotes: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: "28px",
      padding: 0,
      "& .link": {
        padding: "16px 20px",
        margin: 0,
      },
    },
    listItemReleaseNotesIcon: {
      width: 14,
      height: 14,
      marginRight: 14,
    },
    listItemDivider: {
      backgroundColor: theme.palette.divider,
      marginTop: 12,
    },
    listItemHeader: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 500,
      padding: "8px 12px",
      margin: "8px 0 0 0",
    },
    listItemHeaderIcon: {
      width: 18,
      height: 18,
      marginRight: 10,
    },
    listItemSubHeader: {
      color: Color.WHITE,
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 500,
      padding: "12px 12px 8px",
      margin: 0,
    },
    listItemSubHeaderIcon: {
      width: 18,
      height: 18,
      marginRight: 10,
    },
    listItemLink: {
      color: "rgba(255, 255, 255, 0.75)",
      fontFamily: "Roboto, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      margin: 0,
      padding: 0,
    },
    link: {
      color: "inherit",
      textDecoration: "none",
      flex: "1 0 auto",
      margin: 0,
      padding: "4px 18px 4px 18px",
      display: "inline-flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      "&.active": {
        color: Color.SIGNIFY_GREEN,
      },
    },
    listItemLinkIcon: {
      width: 14,
      height: 14,
      marginRight: 14,
    },
    listItemOutlined: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
    },
    listItemClickable: {
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    listItemActive: {
      color: Color.SIGNIFY_GREEN,
    },
    nestedHeaderListItem: {
    },
    moduleListItem: {
    },
    nestedModuleListItem: {
    },
    headerIcon: {
      width: 18,
      height: 18,
      marginLeft: "auto",
      fill: Color.LIGHT_GREY2,
    },
    subHeaderIcon: {
      padding: "0 2px",
    },
    expandMoreIcon: {
    },
    expandLessIcon: {
    },
    titleListItem: {
      padding: 0,
      "& > a": {
        padding: "16px 4px",
      },
    },
    releaseNotesIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: "rgba(255, 255, 255, 0.75)",
        width: 14,
        "&:hover": {
          color: "rgba(255, 255, 255, 0.95)",
        },
        "&:active": {
          color: Color.WHITE,
        },
      },
    },
    qualityReportsIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: "rgba(255, 255, 255, 0.75)",
        width: 14,
        "&:hover": {
          color: "rgba(255, 255, 255, 0.95)",
        },
        "&:active": {
          color: Color.WHITE,
        },
      },
    },
    costReportsIconButton: {
      marginLeft: 8,
      "& .icon": {
        color: "rgba(255, 255, 255, 0.75)",
        width: 14,
        "&:hover": {
          color: "rgba(255, 255, 255, 0.95)",
        },
        "&:active": {
          color: Color.WHITE,
        },
      },
    },
  });

export default styles;
