import createStyles from "@material-ui/core/styles/createStyles";
import ModuleIcon from "@material-ui/icons/DataUsage";

export const PrimaryIcon = ModuleIcon;

export const styles = () =>
  createStyles({
    container: {
      flex: "1 0 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    ide: {
      flex: "1 0 auto",
      marginTop: 15,
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "stretch",
      minHeight: 500,
    },
    projectView: {
      flex: "0 0 250px",
    },
    editor: {
      flex: "1 0 auto",
    },
    logs: {
    },
  });

export default styles;