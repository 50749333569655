import { createModule } from "@base/createModule";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./components/styles";
import view from "./containers/Files";
import Module from "@data/Module";

export const uiPackageModule: Module = createModule({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: true,
  strictPath: true,
  name: "Hosted UI Management",
  title: "Hosted UI Management",
  className: "uiPackageModule",
  primaryColor,
  icon,
  view,
  backButton: null,
});

export default uiPackageModule;
