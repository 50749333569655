import React from "react";
import classnames from "classnames";
import { equalsIgnoreCase, formEventHandler, isEmptyString, noop } from "@util";
import { workloadInfo as styles } from "./styles";
import { TagEditor, TextField } from "@components";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CreateWorkloadRequest, CreateWorkloadRequestAttributes } from "@data/CreateWorkloadRequest";
import { useValidateWorkloadName } from "@hooks/workloadsManager/use-validate-workload-name";
import { ValidateWorkloadNameResponse } from "@network";

export interface Model {
  data?: CreateWorkloadRequest;
  showLoadingIndicator?: boolean;
  isValidName?: boolean;
}

export interface Actions {
  setWorkloadData?: (data: Partial<CreateWorkloadRequestAttributes>) => void;
  setIsValidName?: (valid: boolean) => void;
}

type Props = WithStyles<typeof styles> & Model & Actions;

export const InfoView = withStyles(styles)((props: Props) => {

  const {
    classes,
    showLoadingIndicator = false,
    data = CreateWorkloadRequest.EMPTY,
    isValidName,
    setWorkloadData = noop,
    setIsValidName = noop,
  } = props;

  const inputLabelProps = {
    shrink: true,
    classes: {
      shrink: classes.inputLabelShrink,
    },
  };

  // Create a ref to the name field, so we can refocus it after validation
  const inputRef = React.useRef<HTMLInputElement>(null);

  const focus = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const onSuccess = React.useCallback((response: ValidateWorkloadNameResponse) => {
    setIsValidName(response.isValid);
    focus();
  }, [setIsValidName, focus]);

  const [pendingName, setPendingName] = React.useState("");

  const [{ message, showLoadingIndicator: validateLoading }, { refresh: validateName }] =
    useValidateWorkloadName({name: pendingName, onSuccess});

  const onChangeName = React.useCallback((name: string) => {
    setPendingName(name);
  }, [setPendingName]);

  const helperText = React.useMemo(() => {
    if (validateLoading) {
      return "Validating workload name...";
    } else if (!isValidName && !validateLoading && !isEmptyString(message)) {
      return message;
    } else {
      return "Workload name cannot include spaces or uppercase letters";
    }
  }, [isValidName, validateLoading, message]);

  const workloadName = React.useMemo(() => data.getName(), [data.getName()]);

  // Whenever the name changes, clear the previous timer, and create a new timer to wait 500ms before
  // validating the name
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!equalsIgnoreCase(workloadName, pendingName)) {
        setWorkloadData({ name: pendingName});
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [pendingName, workloadName]);

  React.useEffect(() => {
    setIsValidName(false);
    validateName();
  }, [workloadName]);

  React.useEffect(() => {
    setPendingName(workloadName);
    setIsValidName(true);
  }, []);

  return (
    <div className={classnames("workloadInfo", classes.container)}>
      <label className={classes.title}>Set Workload Information</label>
      <TextField
        className={classnames("name", classes.marginBottom, classes.textField)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        autoFocus={true}
        required={true}
        label="Workload Name"
        name="workloadName"
        value={pendingName}
        disabled={showLoadingIndicator || validateLoading}
        placeholder="Workload Name"
        helperText={helperText}
        onChange={formEventHandler((name: string) => onChangeName(name.toLowerCase()))}
        InputLabelProps={inputLabelProps}
        disableSpecialChars={true}
        error={!validateLoading && !isValidName && !isEmptyString(pendingName)}
        inputRef={inputRef}
      />
      <TextField
        className={classnames("description", classes.marginBottom, classes.textField)}
        type="text"
        variant="outlined"
        autoComplete="off"
        margin="none"
        fullWidth={true}
        multiline={true}
        minRows={3}
        label="Workload Description"
        name="workloadDescription"
        value={data.description}
        disabled={showLoadingIndicator}
        placeholder="Workload Description"
        onChange={formEventHandler((description: string) => setWorkloadData({ description }))}
        InputLabelProps={inputLabelProps}
      />
      <TagEditor
        tags={data.tags}
        helperText="Tags to associate with this workload"
        setTags={(tags) => setWorkloadData({ tags })}
      />
    </div>
  );
});

export default InfoView;
