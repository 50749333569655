import React from "react";
import { noop } from "@util";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { JsonSchemaMetadata } from "@data";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getPathToSchema } from "@modules/schemaDetails/helpers";
import { MODULE_PATH as DETAILS_PAGE_PATH } from "@modules/schemaDetails/constants";
import { MODULE_PATH as LIST_VIEW_PATH } from "@modules/schemas/constants";
import { usePortalSnackbar } from "@components";
import { SchemaAction } from "../reducers";
import { close, executeSchemaAction } from "../actions";
import {
  getDialogClassName,
  getDialogContinueButtonLabel,
  getDialogTitle,
  getErrorMessage,
  getSchema,
  getSchemaAction,
  getSuccessMessage,
  isAccessDeniedVisible,
  isDialogOpen,
  isProgressIndicatorVisible,
} from "../selectors";
import SchemaStateManager, { Actions, Model } from "../components/SchemaStateManager";

interface ContainerModel extends Model {
}

interface ContainerActions extends Actions {
}

type Props = ContainerModel & ContainerActions;

const SchemaStateManagerDialog = (props: Props) => {

  const history = useHistory();

  const match = useRouteMatch(DETAILS_PAGE_PATH);

  const isDetailsPageLoaded = React.useMemo(() => match, [match]);

  const {
    open,
    errorMessage,
    successMessage,
    item: schema = JsonSchemaMetadata.EMPTY,
    schemaAction = SchemaAction.NONE,
    cancel: closeDialog = noop,
  } = props;

  const wasNewVersionDrafted = React.useMemo(() =>
    schemaAction === SchemaAction.DRAFT, [schemaAction]);

  const wasDeleted = React.useMemo(() =>
    schemaAction === SchemaAction.DELETE, [schemaAction]);

  const showListView = React.useCallback(() => history.push(LIST_VIEW_PATH), [history]);

  const showSchemaDetails = React.useCallback(() => {
    history.push(getPathToSchema(schema.getId()));
  }, [history, schema]);

  const onSuccessMessageShown = React.useCallback(() => {

    if (wasNewVersionDrafted) {
      showSchemaDetails();
    } else if (isDetailsPageLoaded) {
      if (wasDeleted) {
        showListView();
      }
    }

    closeDialog();

  }, [
    isDetailsPageLoaded,
    wasNewVersionDrafted,
    wasDeleted,
    showSchemaDetails,
    showListView,
    closeDialog,
  ]);

  usePortalSnackbar(schemaAction || "schema-state-manager", {
    errorMessage,
    successMessage,
    onSuccessMessageShown,
    successCallbackDelay: 250,
  });

  if (!open) {
    return null;
  }

  return <SchemaStateManager {...props} />;
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  schemaAction: getSchemaAction(state),
  className: getDialogClassName(state),
  title: getDialogTitle(state),
  continueButtonLabel: getDialogContinueButtonLabel(state),
  open: isDialogOpen(state),
  item: getSchema(state),
  loading: isProgressIndicatorVisible(state),
  accessDenied: isAccessDeniedVisible(state),
  errorMessage: getErrorMessage(state),
  successMessage: getSuccessMessage(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  cancel: () => dispatch(close()),
  confirm: (schema: JsonSchemaMetadata) => dispatch(executeSchemaAction(schema)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(SchemaStateManagerDialog);
