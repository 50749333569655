import { AppSchema } from "@schemas";
import { getDeviceTypeAction } from "./selectors";
import { identityAction, ReduxAction } from "@util";
import { getAuthToken } from "@main/selectors";
import { DeviceTypeClient, ETagHeaders, ETagLocationHeaders, RestClientError } from "@network";
import { DeviceTypeListItem, DeviceTypeListItemAttributes, DeviceTypeState } from "@data";
import {
  DEFAULT_STATE,
  DeviceTypeAction,
  DeviceTypeManagerActionType as ActionType,
} from "./reducers";

type Action = ReduxAction<ActionType>;

export const setDeviceType = identityAction<ActionType, DeviceTypeListItemAttributes>(
  ActionType.SET_DEVICE_TYPE, DEFAULT_STATE.deviceType);

export const setDeviceTypeAction = identityAction<ActionType, DeviceTypeAction>(
  ActionType.SET_DEVICE_TYPE_ACTION, DEFAULT_STATE.deviceTypeAction);

export const setErrorMessage = identityAction<ActionType, string>(
  ActionType.SET_ERROR_MESSAGE, DEFAULT_STATE.errorMessage);

export const setSuccessMessage = identityAction<ActionType, string>(
  ActionType.SET_SUCCESS_MESSAGE, DEFAULT_STATE.successMessage);

export const setShowDialog = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_DIALOG, DEFAULT_STATE.showDialog);

export const setShowAccessDenied = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_ACCESS_DENIED, DEFAULT_STATE.showAccessDenied);

export const setShowProgressIndicator = identityAction<ActionType, boolean>(
  ActionType.SET_SHOW_PROGRESS_INDICATOR, DEFAULT_STATE.showProgressIndicator);

export const promoteDeviceTypeRequest = (value: string = ""): Action => ({
  type: ActionType.PROMOTE_DEVICE_TYPE_REQUEST,
  value,
});

export const promoteDeviceTypeRequestSuccess = (value: string = ""): Action => ({
  type: ActionType.PROMOTE_DEVICE_TYPE_REQUEST_SUCCESS,
  value,
});

export const promoteDeviceTypeRequestFailed = (error: string): Action => ({
  type: ActionType.PROMOTE_DEVICE_TYPE_REQUEST_FAILED,
  value: error,
});

export const deprecateDeviceTypeRequest = (value: string = ""): Action => ({
  type: ActionType.DEPRECATE_DEVICE_TYPE_REQUEST,
  value,
});

export const deprecateDeviceTypeRequestSuccess = (value: string = ""): Action => ({
  type: ActionType.DEPRECATE_DEVICE_TYPE_REQUEST_SUCCESS,
  value,
});

export const deprecateDeviceTypeRequestFailed = (error: string): Action => ({
  type: ActionType.DEPRECATE_DEVICE_TYPE_REQUEST_FAILED,
  value: error,
});

export const decommissionDeviceTypeRequest = (value: string = ""): Action => ({
  type: ActionType.DECOMMISSION_DEVICE_TYPE_REQUEST,
  value,
});

export const decommissionDeviceTypeRequestSuccess = (value: string = ""): Action => ({
  type: ActionType.DECOMMISSION_DEVICE_TYPE_REQUEST_SUCCESS,
  value,
});

export const decommissionDeviceTypeRequestFailed = (error: string): Action => ({
  type: ActionType.DECOMMISSION_DEVICE_TYPE_REQUEST_FAILED,
  value: error,
});

export const deleteDeviceTypeRequest = (value: string = ""): Action => ({
  type: ActionType.DELETE_DEVICE_TYPE_REQUEST,
  value,
});

export const deleteDeviceTypeRequestSuccess = (value: string = ""): Action => ({
  type: ActionType.DELETE_DEVICE_TYPE_REQUEST_SUCCESS,
  value,
});

export const deleteDeviceTypeRequestFailed = (error: string): Action => ({
  type: ActionType.DELETE_DEVICE_TYPE_REQUEST_FAILED,
  value: error,
});

export const draftNewDeviceTypeVersionRequest = (value: string = ""): Action => ({
  type: ActionType.DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST,
  value,
});

export const draftNewDeviceTypeVersionRequestSuccess = (value: string = ""): Action => ({
  type: ActionType.DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_SUCCESS,
  value,
});

export const draftNewDeviceTypeVersionRequestFailed = (error: string): Action => ({
  type: ActionType.DRAFT_NEW_DEVICE_TYPE_VERSION_REQUEST_FAILED,
  value: error,
});

export const showDialog = (): Action => setShowDialog(true);
export const hideDialog = (): Action => setShowDialog(false);

export const showAccessDenied = (): Action => setShowAccessDenied(true);
export const hideAccessDenied = (): Action => setShowAccessDenied(false);

export const showProgressIndicator = (): Action => setShowProgressIndicator(true);
export const hideProgressIndicator = (): Action => setShowProgressIndicator(false);

export const promoteDeviceType = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const authToken = getAuthToken(getState());
    const namespace = deviceType.getNameSpace();
    const name = deviceType.getName();
    const version = deviceType.getVersionAsString();
    const etag = deviceType.getEtag();
    const modelVersion = deviceType.getModelVersion();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(setErrorMessage());
    dispatch(promoteDeviceTypeRequest(modelVersion));

    return DeviceTypeClient.promoteDeviceType(authToken, namespace, name, version, etag, modelVersion)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag } = response;

        const updatedDeviceType = new DeviceTypeListItem({
          ...deviceType.toJS(),
          etag: updatedEtag,
          state: DeviceTypeState.RELEASED,
        });

        dispatch(promoteDeviceTypeRequestSuccess(modelVersion));
        dispatch(setDeviceType(updatedDeviceType.toJS()));
        dispatch(setSuccessMessage("Device type promoted"));
        dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to promote device type" } = response;

        dispatch(promoteDeviceTypeRequestFailed(`${modelVersion}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
};

export const deprecateDeviceType = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const authToken = getAuthToken(getState());
    const namespace = deviceType.getNameSpace();
    const name = deviceType.getName();
    const version = deviceType.getVersionAsString();
    const etag = deviceType.getEtag();
    const modelVersion = deviceType.getModelVersion();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(setErrorMessage());
    dispatch(deprecateDeviceTypeRequest(modelVersion));

    return DeviceTypeClient.deprecateDeviceType(authToken, namespace, name, version, etag, modelVersion)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag } = response;

        const updatedDeviceType = new DeviceTypeListItem({
          ...deviceType.toJS(),
          etag: updatedEtag,
          state: DeviceTypeState.DEPRECATED,
        });

        dispatch(deprecateDeviceTypeRequestSuccess(modelVersion));
        dispatch(setDeviceType(updatedDeviceType.toJS()));
        dispatch(setSuccessMessage("Device type deprecated"));
        dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to deprecate device type" } = response;

        dispatch(deprecateDeviceTypeRequestFailed(`${modelVersion}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const decommissionDeviceType = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const authToken = getAuthToken(getState());
    const namespace = deviceType.getNameSpace();
    const name = deviceType.getName();
    const version = deviceType.getVersionAsString();
    const etag = deviceType.getEtag();
    const modelVersion = deviceType.getModelVersion();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(setErrorMessage());
    dispatch(decommissionDeviceTypeRequest(modelVersion));

    return DeviceTypeClient.decommissionDeviceType(authToken, namespace, name, version, etag, modelVersion)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag } = response;

        const updatedDeviceType = new DeviceTypeListItem({
          ...deviceType.toJS(),
          etag: updatedEtag,
          state: DeviceTypeState.DECOMMISSIONED,
        });

        dispatch(decommissionDeviceTypeRequestSuccess(modelVersion));
        dispatch(setDeviceType(updatedDeviceType.toJS()));
        dispatch(setSuccessMessage("Device type decommissioned"));
        dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to decommission device type" } = response;

        dispatch(decommissionDeviceTypeRequestFailed(`${modelVersion}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const deleteDeviceType = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const authToken = getAuthToken(getState());
    const namespace = deviceType.getNameSpace();
    const name = deviceType.getName();
    const version = deviceType.getVersionAsString();
    const etag = deviceType.getEtag();
    const modelVersion = deviceType.getModelVersion();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(setErrorMessage());
    dispatch(deleteDeviceTypeRequest(modelVersion));

    return DeviceTypeClient.deleteDeviceType(authToken, namespace, name, version, etag, modelVersion)
      .then((response: ETagHeaders) => {

        const { etag: updatedEtag = etag } = response;

        const updatedDeviceType = new DeviceTypeListItem({
          ...deviceType.toJS(),
          etag: updatedEtag,
          state: DeviceTypeState.DELETED,
        });

        dispatch(deleteDeviceTypeRequestSuccess(modelVersion));
        dispatch(setDeviceType(updatedDeviceType.toJS()));
        dispatch(setSuccessMessage("Device type deleted"));
        dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to delete device type" } = response;

        dispatch(deleteDeviceTypeRequestFailed(`${modelVersion}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const draftNewDeviceTypeVersion = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    const authToken = getAuthToken(getState());
    const namespace = deviceType.getNameSpace();
    const name = deviceType.getName();
    const modelVersion = deviceType.getModelVersion();

    dispatch(showProgressIndicator());
    dispatch(hideAccessDenied());
    dispatch(setErrorMessage());
    dispatch(draftNewDeviceTypeVersionRequest(modelVersion));

    return DeviceTypeClient.draftNewDeviceTypeVersion(authToken, namespace, name, modelVersion)
      .then((response: ETagLocationHeaders) => {

        const { nameAndVersion, etag: updatedEtag } = response;

        const updatedDeviceType = new DeviceTypeListItem({
          ...deviceType.toJS(),
          etag: updatedEtag,
          state: DeviceTypeState.DRAFT,
          typeIdentity: nameAndVersion,
        });

        dispatch(draftNewDeviceTypeVersionRequestSuccess(modelVersion));
        dispatch(setDeviceType(updatedDeviceType.toJS()));
        dispatch(setSuccessMessage("Device type drafted"));
        dispatch(hideProgressIndicator());

      }, (response: RestClientError) => {

        const { analytic, status, error = "Failed to draft new device" } = response;

        dispatch(draftNewDeviceTypeVersionRequestFailed(`${modelVersion}:${analytic}`));
        dispatch(hideProgressIndicator());

        if (status === 403) {
          dispatch(showAccessDenied());
        }

        return dispatch(setErrorMessage(error));
      });
  };

export const executeDeviceTypeAction = (deviceType: DeviceTypeListItem = DeviceTypeListItem.EMPTY) =>
  (dispatch: any, getState: () => AppSchema) => {

    if (DeviceTypeListItem.EMPTY.equals(deviceType)) {
      return dispatch(setErrorMessage("Invalid Device Type"));
    }

    const state = getState();

    const action = getDeviceTypeAction(state);

    switch (action) {
      case DeviceTypeAction.PROMOTE:
        return dispatch(promoteDeviceType(deviceType));
      case DeviceTypeAction.DEPRECATE:
        return dispatch(deprecateDeviceType(deviceType));
      case DeviceTypeAction.DECOMMISSION:
        return dispatch(decommissionDeviceType(deviceType));
      case DeviceTypeAction.DELETE:
        return dispatch(deleteDeviceType(deviceType));
      case DeviceTypeAction.DRAFT:
        return dispatch(draftNewDeviceTypeVersion(deviceType));
      default:
        return dispatch(setErrorMessage("Invalid Device Type State Transition"));
    }
  };

export const reset = () => (dispatch: any) => {
  dispatch(setDeviceType());
  dispatch(setDeviceTypeAction());
  dispatch(setErrorMessage());
  dispatch(setSuccessMessage());
  dispatch(setShowDialog());
  dispatch(setShowAccessDenied());
  return dispatch(setShowProgressIndicator());
};

export const open = (deviceType: DeviceTypeListItem, action: DeviceTypeAction) => (dispatch: any) => {

  if (!deviceType.hasTypeIdentity() || action === DeviceTypeAction.NONE) {
    return Promise.resolve();
  }

  dispatch(reset());
  dispatch(setDeviceType(deviceType.toJS()));
  dispatch(setDeviceTypeAction(action));
  return dispatch(showDialog());
};

export const close = () => (dispatch: any) => dispatch(reset());

export const initialize = () => (dispatch: any) => dispatch(close());
