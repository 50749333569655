import React from "react";
import { OrderedMap } from "immutable";
import { isEmptyString } from "@util";
import { JsonSchemaMetadata, SearchFilter } from "@data";
import { AuthTokenContext } from "@components/auth-token-provider";
import { GetSchemasResponse, RestClientError, SchemaRegistryClient } from "@network";

export interface UseSchemasProps {
  initialSchemas?: JsonSchemaMetadata[];
}

export interface UseSchemasModel {
  schemas: JsonSchemaMetadata[];
  nameFilter: string;
  searchFilters: SearchFilter[];
  numResults: number;
  showLoadMoreButton: boolean;
  errorMessage: string;
  showErrorView: boolean;
  showProgressIndicator: boolean;
  showNoResultsView: boolean;
  statusCode?: number;
}

export interface UseSchemasActions {
  setNameFilter: (nameFilter: string) => void;
  setSearchFilters: (searchFilters: SearchFilter[]) => void;
  clearList: () => void;
  loadMore: () => void;
  refresh: () => void;
}

export const useSchemas = (props: UseSchemasProps = {}): [UseSchemasModel, UseSchemasActions] => {

  const { initialSchemas = [] } = props;

  const initialSchemasMap = React.useMemo(() => initialSchemas.reduce((data, item) => {
    return !item.hasId() ? data : data.set(item.getId(), item);
  }, OrderedMap<string, JsonSchemaMetadata>({})), [initialSchemas]);

  const authToken = React.useContext(AuthTokenContext);
  const [lastRefresh, setLastRefresh] = React.useState(+new Date);
  const [nameFilter, setNameFilter] = React.useState("");
  const [searchFilters, setSearchFilters] = React.useState<SearchFilter[]>([]);
  const [nextPageToken, setNextPageToken] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showProgressIndicator, setShowProgressIndicator] = React.useState(false);
  const [schemasMap, setSchemasMap] = React.useState<OrderedMap<string, JsonSchemaMetadata>>(
    OrderedMap<string, JsonSchemaMetadata>(initialSchemasMap));
  const [statusCode, setStatusCode] = React.useState<number>();

  const schemas = React.useMemo(() => schemasMap.toArray(), [schemasMap]);

  const numResults = React.useMemo(() => schemas.length, [schemas]);

  const showErrorView = React.useMemo(() => !isEmptyString(errorMessage), [errorMessage]);

  const showLoadMoreButton = React.useMemo(() =>
    !isEmptyString(nextPageToken) && !showErrorView && !showProgressIndicator,
    [nextPageToken, showErrorView, showProgressIndicator]);

  const showNoResultsView = React.useMemo(() =>
    numResults === 0 && !showErrorView && !showProgressIndicator,
    [numResults, showErrorView, showProgressIndicator]);

  const searchQuery = React.useMemo(() =>
    SearchFilter.createSearchQuery(searchFilters),
    [searchFilters]);

  const updateSchemas = React.useCallback((updated: OrderedMap<string, JsonSchemaMetadata>) => {
    setSchemasMap(current => current.merge(updated));
  }, [setSchemasMap]);

  const addSchemas = React.useCallback((updated: JsonSchemaMetadata[]) => {
    updateSchemas(updated.reduce((data, item) => {
      return !item.hasId() ? data : data.set(item.getId(), item);
    }, OrderedMap<string, JsonSchemaMetadata>({})));
  }, [updateSchemas]);

  const getSchemas = React.useCallback(() =>
    SchemaRegistryClient.getSchemas(authToken, nextPageToken, nameFilter, searchQuery),
    [authToken, nextPageToken, nameFilter, searchQuery]);

  const clearList = React.useCallback(() => {
    setSchemasMap(OrderedMap<string, JsonSchemaMetadata>({}));
  }, [setSchemasMap]);

  const loadMore = React.useCallback(() => {
    setLastRefresh(+new Date);
  }, [setLastRefresh]);

  const refresh = React.useCallback(() => {
    setNextPageToken("");
    clearList();
    loadMore();
  }, [setNextPageToken, clearList, loadMore]);

  const model = React.useMemo<UseSchemasModel>(() => ({
    schemas,
    nameFilter,
    searchFilters,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
    statusCode,
  }), [
    schemas,
    nameFilter,
    searchFilters,
    numResults,
    showLoadMoreButton,
    errorMessage,
    showErrorView,
    showProgressIndicator,
    showNoResultsView,
    statusCode,
  ]);

  const actions = React.useMemo<UseSchemasActions>(() => ({
    setNameFilter,
    setSearchFilters,
    loadMore,
    refresh,
    clearList,
  }), [
    setNameFilter,
    setSearchFilters,
    loadMore,
    refresh,
    clearList,
  ]);

  React.useEffect(() => {

    let ignore = false;

    setShowProgressIndicator(true);
    setErrorMessage("");

    getSchemas()
      .then((response: GetSchemasResponse) => {
        if (!ignore) {
          const { items = [], paging: { next: nextPage = "" } = {} } = response;
          addSchemas(items.map(attrs => new JsonSchemaMetadata(attrs)));
          setShowProgressIndicator(false);
          setNextPageToken(nextPage || "");
        }
      }, (response: RestClientError) => {
        if (!ignore) {
          const { error = "Fetch schemas failed" } = response;
          setErrorMessage(error);
          setShowProgressIndicator(false);
          setStatusCode(response.status);
        }
      });

    return () => { ignore = true; };

  }, [
    lastRefresh,
    addSchemas,
    setNextPageToken,
    setErrorMessage,
    setShowProgressIndicator,
    setStatusCode,
  ]);

  return React.useMemo(() => [model, actions], [model, actions]);
};

export default useSchemas;
