import { isEmptyString, isPortalWebserverEnabled } from "@util";
import { PortalSessionInfoAttributes } from "@data/PortalSessionInfo";
import {
  makeApiRequestAndComplete,
  makeJsonApiRequest,
  withRequiredArguments,
} from "@network/helpers";

const PORTAL_SESSION_MANAGEMENT_API = process.env.REACT_APP_PORTAL_SESSION_MANAGEMENT_API || "";

if (isEmptyString(PORTAL_SESSION_MANAGEMENT_API) && isPortalWebserverEnabled()) {
  throw new Error("Missing Environment Variable: REACT_APP_PORTAL_SESSION_MANAGEMENT_API");
}

export interface PortalSessionManagementClientApi {
  getPortalSessionInfo: () => Promise<PortalSessionInfoAttributes>;
  refreshPortalSession: (accessToken: string) => Promise<PortalSessionInfoAttributes>;
  endPortalSession: () => Promise<void>;
}

export const getPortalSessionInfo = (): Promise<PortalSessionInfoAttributes> => {

  const makeRequest = () => {

    const url = `${PORTAL_SESSION_MANAGEMENT_API}/sessions`;

    const settings = {
      method: "GET",
      headers: {
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to get portal session info";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return makeRequest();
};

export const refreshPortalSession = (accessToken: string): Promise<PortalSessionInfoAttributes> => {

  const validate = () => withRequiredArguments([
    ["Updated Access Token", accessToken],
  ]);

  const makeRequest = () => {

    const url = `${PORTAL_SESSION_MANAGEMENT_API}/sessions/refresh`;

    const settings = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to refresh portal session";

    return makeJsonApiRequest(url, settings, defaultErrorMessage);
  };

  return validate().then(makeRequest);
};

export const endPortalSession = (): Promise<void> => {

  const makeRequest = () => {

    const url = `${PORTAL_SESSION_MANAGEMENT_API}/sessions`;

    const settings = {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
      },
    };

    const defaultErrorMessage = "Failed to end portal session";

    return makeApiRequestAndComplete(url, settings, defaultErrorMessage);
  };

  return makeRequest();
};

export const PortalSessionManagementClient: PortalSessionManagementClientApi = {
  getPortalSessionInfo,
  refreshPortalSession,
  endPortalSession,
};

export default PortalSessionManagementClient;
