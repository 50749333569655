import React from "react";
import {
  Model,
  Actions,
  ApplicationUserManagementView
} from "../components/ApplicationUserManagementView";
import {
  useGetApplicationUserManagement,
  useUpdateApplicationUserManagement
} from "@hooks";
import { SignInPreference, SignOutPreference } from "@data";

export interface ContainerModel extends Model {
  applicationId?: string;
}

type Props = ContainerModel & Actions;

const ApplicationUserManagementContainer = (props: Props) => {

  const {
    applicationId = ""
  } = props;

  const [{
    applicationUserManagement,
    showLoadingIndicator: getLoading,
    errorMessage: configErrorMessage,
    showSuccessView: configSuccessView,
    statusCode: configStatusCode,
  }, {
    getApplicationUserManagement
  }] = useGetApplicationUserManagement({applicationId});

  const [signInPreference, setSignInPreference] = React.useState(SignInPreference.ALWAYS_AUTHENTICATE);
  const [signOutPreference, setSignOutPreference] = React.useState(SignOutPreference.SIGN_OUT_GLOBALLY);

  const [{
    errorMessage: updateErrorMessage,
    showLoadingIndicator: updateLoading,
    showSuccessView: updateSuccess,
    statusCode: updateStatusCode,
  } , {
    updateUserManagement
  }] = useUpdateApplicationUserManagement({
    applicationId,
    json: JSON.stringify({ signInPreference, signOutPreference })
  });

  const updateSignInPreference = React.useCallback((value: SignInPreference) => {
    setSignInPreference(value);
  }, [setSignInPreference]);

  const updateSignOutPreference = React.useCallback((value: SignOutPreference) => {
    setSignOutPreference(value);
  }, [setSignInPreference]);

  const reset = React.useCallback(() => {
    setSignInPreference(applicationUserManagement.signInPreference);
    setSignOutPreference(applicationUserManagement.signOutPreference);
  }, [setSignInPreference, setSignOutPreference, applicationUserManagement]);

  const loadingIndicator = React.useMemo(() => updateLoading || getLoading,
    [updateLoading, getLoading]);

  React.useEffect(() => getApplicationUserManagement(), [updateSuccess]);

  React.useEffect(() => {
    setSignInPreference(applicationUserManagement.signInPreference);
    setSignOutPreference(applicationUserManagement.signOutPreference);
  }, [configSuccessView]);

  return (
    <ApplicationUserManagementView
      loading={loadingIndicator}
      errorMessage={configErrorMessage}
      configStatusCode={configStatusCode}
      updateErrorMessage={updateErrorMessage}
      updateStatusCode={updateStatusCode}
      errorTitle="Error"
      reset={reset}
      showSuccessView={updateSuccess}
      signInPreference={signInPreference}
      signOutPreference={signOutPreference}
      updateSignInPreference={updateSignInPreference}
      updateSignOutPreference={updateSignOutPreference}
      save={updateUserManagement}
    />
  );
};

export default ApplicationUserManagementContainer;
