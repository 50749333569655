import React from "react";
import classnames from "classnames";
import { Tooltip } from "@components";
import { ReleaseNotePlatformDomain } from "@data";
import { Link, LinkProps } from "react-router-dom";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { isEmptyString, isReleaseNotesEnabled, stopPropagation } from "@util";
import { PrimaryIcon as ReleaseNotesIcon } from "@modules/releaseNotes/components/styles";
import {
  COMMON_SERVICES_RELEASE_NOTES_PATH,
  DATA_AND_ANALYTICS_RELEASE_NOTES_PATH,
  DEVICE_MANAGEMENT_RELEASE_NOTES_PATH,
  SECURITY_SERVICES_RELEASE_NOTES_PATH,
  SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH,
} from "@modules/releaseNotes/constants";
import styles from "./styles";

const IconButton = React.forwardRef((props: IconButtonProps<Link, LinkProps>, ref: React.Ref<any>) => {
  return (
    <MuiIconButton
      {...props}
      component={Link}
      ref={ref}
    />
  );
});

const mapPlatformDomainToPath = (platformDomain: ReleaseNotePlatformDomain): string => {
  switch (platformDomain) {
    case ReleaseNotePlatformDomain.SELF_SERVICE_TOOLS:
      return SELF_SERVICE_TOOLS_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.DATA_AND_ANALYTICS:
      return DATA_AND_ANALYTICS_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.COMMON_SERVICES:
      return COMMON_SERVICES_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.DEVICE_MANAGEMENT:
      return DEVICE_MANAGEMENT_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.SECURITY_SERVICES:
      return SECURITY_SERVICES_RELEASE_NOTES_PATH;
    case ReleaseNotePlatformDomain.NONE:
    default:
      return "";
  }
};

export interface ReleaseNotesIconButtonProps {
  className?: string;
  tooltip?: string;
  size?: "small" | "medium";
  platformDomain?: ReleaseNotePlatformDomain;
  releaseNotesEnabled?: boolean;
}

type Props = WithStyles<typeof styles> & ReleaseNotesIconButtonProps;

export const ReleaseNotesIconButton = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    tooltip = "Release Notes",
    size = "small",
    platformDomain = ReleaseNotePlatformDomain.NONE,
    releaseNotesEnabled = isReleaseNotesEnabled(),
    ...otherProps
  } = props;

  const path = React.useMemo(() => mapPlatformDomainToPath(platformDomain), [platformDomain]);

  if (!releaseNotesEnabled || isEmptyString(path)) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="right">
      <IconButton
        {...otherProps}
        className={classnames("releaseNotesIconButton", className, classes.container)}
        size={size}
        disableRipple={true}
        color="inherit"
        to={path}
        onClick={stopPropagation}
      >
        <ReleaseNotesIcon className={classnames("icon", classes.icon)}/>
      </IconButton>
    </Tooltip>
  );
});

export default ReleaseNotesIconButton;
