import { Record } from "immutable";
import { getStringValue, isEmptyString } from "@util";
import { WorkloadDataSetsAttributes, WorkloadType } from "@data/Workload";
import ExecuteWorkloadEvent, { ExecutionScope, TriggerStorage } from "@data/ExecuteWorkloadEvent";
import { TriggerType } from "@data/Trigger";

export enum WorkloadInstanceState {
  NONE = "NONE",
  QUEUED = "QUEUED",
  RUNNING = "RUNNING",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  SKIPPED = "SKIPPED",
}

export interface WorkloadInstanceVariableAttributes {
  [key: string]: string;
}

export class WorkloadInstanceVariable extends Record({
  name: "",
  value: "",
}) {

  public static EMPTY: WorkloadInstanceVariable = new WorkloadInstanceVariable();

  public readonly name: string;
  public readonly value: string;

  public getName(): string {
    return getStringValue(this.name);
  }

  public getValue(): string {
    return getStringValue(this.value);
  }
}

export interface WorkloadInstanceAttributes {
  completedAt?: string;
  createdBy?: string;
  dataScanned?: number;
  executedBy?: string;
  executionId?: string;
  executionDuration?: number;
  message?: string;
  type?: WorkloadType;
  state?: WorkloadInstanceState;
  submittedAt?: string;
  createdAt?: string;
  dataOutputs?: WorkloadDataSetsAttributes[];
  dataSources?: WorkloadDataSetsAttributes[];
  name?: string;
  version?: number;
  actions?: string[];
  variables?: WorkloadInstanceVariableAttributes[];
  event?: ExecuteWorkloadEvent;
}

export class WorkloadInstance extends Record({
  completedAt: "",
  executedBy: "",
  executionId: "",
  type: WorkloadType.FUNCTION,
  state: WorkloadInstanceState.NONE,
  submittedAt: "",
  createdBy: "",
  createdAt: "",
  dataScanned: 0,
  executionDuration: 0,
  message: "",
  dataSources: [],
  dataOutputs: [],
  name: "",
  version: -1,
  actions: [],
  variables: [],
  event: ExecuteWorkloadEvent.EMPTY
}) implements WorkloadInstanceAttributes {

  public static EMPTY: WorkloadInstance = new WorkloadInstance();

  public readonly completedAt: string;
  public readonly executedBy: string;
  public readonly executionId: string;
  public readonly type: WorkloadType;
  public readonly state: WorkloadInstanceState;
  public readonly submittedAt: string;
  public readonly createdBy: string;
  public readonly createdAt: string;
  public readonly dataScanned: number;
  public readonly executionDuration: number;
  public readonly message: string;
  public readonly dataOutputs: WorkloadDataSetsAttributes[];
  public readonly dataSources: WorkloadDataSetsAttributes[];
  public readonly name: string;
  public readonly version: number;
  public readonly actions: string[];
  public readonly variables: WorkloadInstanceVariableAttributes[];
  public readonly event: ExecuteWorkloadEvent;

  public getCompletedAt(): string {
    return getStringValue(this.completedAt);
  }

  public getExecutedBy(): string {
    return getStringValue(this.executedBy);
  }

  public getExecutedByServiceId(): string {
    const res = getStringValue(this.executedBy).match("principal:service:(.+)");
    return res ? res[1] : "";
  }

  public getExecutionId(): string {
    return getStringValue(this.executionId);
  }

  public getState(): WorkloadInstanceState {
    return this.state;
  }

  public isFinished(): boolean {
    return this.state === WorkloadInstanceState.SUCCEEDED || this.state === WorkloadInstanceState.FAILED ||
      this.state === WorkloadInstanceState.CANCELLED || this.state === WorkloadInstanceState.SKIPPED;
  }

  public getType(): WorkloadType {
    return this.type;
  }

  public isFunctionType(): boolean {
    return this.type === WorkloadType.FUNCTION;
  }

  public isQueryType(): boolean {
    return this.type === WorkloadType.QUERY;
  }

  public getSubmittedAt(): string {
    return getStringValue(this.submittedAt);
  }

  public getCreatedBy(): string {
    return getStringValue(this.createdBy);
  }

  public getCreatedAt(): string {
    return getStringValue(this.createdAt);
  }

  public getDataScanned(): number {
    return this.dataScanned;
  }

  public getExecutionDuration(): number {
    return this.executionDuration;
  }

  public getMessage(): string {
    return getStringValue(this.message);
  }

  public getDataOutputs(): WorkloadDataSetsAttributes[] {
    return this.dataOutputs;
  }

  public getDataSources(): WorkloadDataSetsAttributes[] {
    return this.dataSources;
  }

  public getName(): string {
    return getStringValue(this.name);
  }

  public getVersion(): number {
    return this.version;
  }

  public getWorkloadCompletionMetadataActions(): string[] {
    return this.actions.map(getStringValue).filter(it => !isEmptyString(it));
  }

  public getWorkloadCompletionMetadataVariables(): WorkloadInstanceVariableAttributes[] {
    return this.variables ? this.variables : [];
  }

  public getEvent(): ExecuteWorkloadEvent {
    return this.event ? this.event : ExecuteWorkloadEvent.EMPTY;
  }

  public getEventType(): TriggerType {
    return this.event.type;
  }

  public getListFiles(): string {
    return this.event.listFile ? this.event.listFile.join(", ") : "";
  }

  public getExecutionScope(): ExecutionScope {
    return this.event.executionScope;
  }

  public getScheduleId(): string {
    return getStringValue(this.event.scheduleId);
  }

  public getEventTriggerStorage(): TriggerStorage {
    return this.event.triggerStorage;
  }

  public getEventBucketName(): string {
    const event = this.getEvent();
    const { bucketName = "" } = event.triggerStorage || {};
    return bucketName;
  }

  public getEventKey(): string {
    const event = this.getEvent();
    const { key = "" } = event.triggerStorage || {};
    return key;
  }

  public getEventAccountId(): string {
    const { accountId = "" } = this.getEvent() || {};
    return accountId;
  }

  public getEventDataSetAlias(): string {
    const { dataSetAlias = "" } = this.getEvent() || {};
    return dataSetAlias;
  }

  public getEventPrecedingWorkload(): string {
    const { precedingWorkloadName = "" } = this.getEvent() || {};
    return precedingWorkloadName;
  }
}

export default WorkloadInstance;
