import React from "react";
import classnames from "classnames";
import Link from "@components/link";
import { isEmptyString } from "@util";
import Typography from "@material-ui/core/Typography";
import KnownIssuesIcon from "@material-ui/icons/Gavel";
import NewFeaturesIcon from "@material-ui/icons/FiberNew";
import DefectFixesIcon from "@material-ui/icons/BugReport";
import ChangeRequestIcon from "@material-ui/icons/Feedback";
import BreakingChangeIcon from "@material-ui/icons/ReportProblem";
import OtherEnhancementsIcon from "@material-ui/icons/NextWeek";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ReleaseNoteIssue, { ReleaseNoteIssueStatus, ReleaseNoteIssueType } from "@data/ReleaseNoteIssue";
import ModuleListView, {
  createColumns,
  ModuleListViewActions,
  ModuleListViewModel,
} from "@components/module-list-view";
import styles from "./styles";
import MarkdownView from "@components/markdown-view";
import startCase from "lodash/startCase";

const mapIssueToIcon = (issue: ReleaseNoteIssue) => {
  switch (issue.issueType) {
    case ReleaseNoteIssueType.NEW_FEATURES:
      return NewFeaturesIcon;
    case ReleaseNoteIssueType.DEFECT_FIXES:
      return DefectFixesIcon;
    case ReleaseNoteIssueType.KNOWN_ISSUES:
      return KnownIssuesIcon;
    case ReleaseNoteIssueType.CHANGE_REQUEST:
      return ChangeRequestIcon;
    case ReleaseNoteIssueType.BREAKING_CHANGES:
      return BreakingChangeIcon;
    case ReleaseNoteIssueType.OTHER_ENHANCEMENTS:
    default:
      return OtherEnhancementsIcon;
  }
};

const mapIssueStatusToColor = (status: ReleaseNoteIssueStatus) => {
  switch (status) {
    case ReleaseNoteIssueStatus.PLANNED:
      return "green";
    case ReleaseNoteIssueStatus.IN_PROGRESS:
      return "yellow";
    case ReleaseNoteIssueStatus.POSTPONED:
      return "gray";
    case ReleaseNoteIssueStatus.COMPLETED:
      return "blue";
    default:
      return "";
  }
};

export enum ReleaseNoteIssuesListColumn {
  ISSUE = "Issue",
  DESCRIPTION = "Description",
  COMPATIBILITY = "Compatibility",
  AFFECTED_SERVICE = "Affected Features",
  OTHER_LINK = "Relevant APIs / Documentation Links",
}

export const DEFAULT_RELEASE_NOTE_ISSUES_LIST_COLUMNS: ReleaseNoteIssuesListColumn[] = [
  ReleaseNoteIssuesListColumn.ISSUE,
  ReleaseNoteIssuesListColumn.DESCRIPTION,
  ReleaseNoteIssuesListColumn.AFFECTED_SERVICE,
  ReleaseNoteIssuesListColumn.COMPATIBILITY,
  ReleaseNoteIssuesListColumn.OTHER_LINK,
];

type Item = ReleaseNoteIssue;
type Column = ReleaseNoteIssuesListColumn;

export interface ReleaseNoteIssuesListModel extends ModuleListViewModel<Item, Column> {
  className?: string;
  title?: string;
  issues?: ReleaseNoteIssue[];
}

export interface ReleaseNoteIssuesListActions extends ModuleListViewActions<Item, Column> {
}

type Model = ReleaseNoteIssuesListModel;
type Actions = ReleaseNoteIssuesListActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const ReleaseNoteIssuesList = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    listClassName,
    title = "Issues",
    placeholder = "N/A",
    noResultsLabel = "No issues found in this release",
    columns = DEFAULT_RELEASE_NOTE_ISSUES_LIST_COLUMNS,
    issues = [],
    items = issues,
    tableLayoutFixed = false,
    listViewItems = React.useMemo(() =>
      items.map(issue => {
        return {
          item: issue,
          pathToDetailsView: issue.getJiraLink(),
          icon: mapIssueToIcon(issue),
          columnAttributes: createColumns([
            {
              className: "issue",
              value: issue.getJiraLink().split("/").pop() || "N/A",
              column: ReleaseNoteIssuesListColumn.ISSUE,
              firstColumn: true,
            },
            {
              className: "description",
              value: issue.getDescription(),
              column: ReleaseNoteIssuesListColumn.DESCRIPTION,
              customEl: (
                <React.Fragment>
                  {!isEmptyString(issue.summary) && (
                    <p>{issue.getSummary()}</p>
                  )}
                  {!isEmptyString(issue.description) && (
                    <MarkdownView markdown={issue.getDescription()} />
                  )}
                </React.Fragment>
              ),
            },
            {
              className: "affectedService",
              value: issue.getAffectedService(),
              column: ReleaseNoteIssuesListColumn.AFFECTED_SERVICE,
            },
            {
              className: "compatibility",
              value: startCase(issue.getCompatibility().toLowerCase()),
              column: ReleaseNoteIssuesListColumn.COMPATIBILITY,
            },
            {
              className: "otherLink",
              value: issue.getOtherLink(),
              column: ReleaseNoteIssuesListColumn.OTHER_LINK,
              ...(!issue.hasOtherLink() ? ({}) : ({
                customEl: (
                  <Link
                    className="otherLink"
                    href={issue.getOtherLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {issue.getOtherLink()}
                  </Link>
                ),
              })),
            },
          ]),
        };
      }), [items]),
    children,
    ...otherProps
  } = props;

  return (
    <ModuleListView
      {...otherProps}
      className={classnames("releaseNoteIssuesList", className, classes.container)}
      listClassName={classnames("list", listClassName, classes.list)}
      columns={columns}
      items={items}
      listViewItems={listViewItems}
      tableLayoutFixed={tableLayoutFixed}
      placeholder={placeholder}
      noResultsLabel={noResultsLabel}
      header={(
        <Typography className={classnames("title", classes.title)} variant="h3">
          {title}
        </Typography>
      )}
      getItemColor={item => mapIssueStatusToColor(item.getIssueStatus())}
    >
      {children}
    </ModuleListView>
  );
});

export default ReleaseNoteIssuesList;
