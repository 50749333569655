import moment from "moment";
import { AppSchema } from "@schemas";
import { createSelector } from "reselect";
import { isEmptyString, memoize, isMaintenanceReminderEnabled } from "@util";
import { isUrlBannerVisible } from "../helpers";
import { MY_PROFILE_PATH } from "@modules/userDetails/constants";
import { MODULE_PATH as SETTINGS_PATH } from "@modules/accountSettings/constants";
import { getPathToService } from "@modules/securityServiceRegionalDetails/helpers";
import { MODULE_ID as DATA_WORKLOADS_MODULE_ID } from "@modules/dataWorkloads/constants";
import { MODULE_ID as RELEASE_NOTES_MODULE_ID } from "@modules/releaseNotes/constants";
import { MODULE_ID as QUALITY_REPORTS_MODULE_ID } from "@modules/qualityReports/constants";
import { MODULE_ID as COST_REPORTS_MODULE_ID } from "@modules/costReports/constants";
import MODULES from "../modules";
import {
  User,
  Module,
  IdentityType,
  UserAttributes,
  MaintenanceReminder,
  MaintenanceReminderAttributes,
  MaintenanceReminderState,
} from "@data";

export const getRefreshToken = (state: AppSchema): string => {
  return state.refreshToken;
};

export const getAuthToken = (state: AppSchema): string => {
  return state.authToken;
};

export const getLastMouseMove = (state: AppSchema): number => {
    return state.lastMouseMove;
};

export const getEnabledModuleIds = (state: AppSchema): string[] => {
    return state.enabledModuleIds;
};

export const getPersistedModuleIds = (state: AppSchema): string[] => {
    return state.persistedModuleIds;
};

export const getAuthTokenExpiryTime = (state: AppSchema): string => {
  return state.authTokenExpiryTime;
};

export const getAuthTokenExpiryMoment = (state: AppSchema): number => {
  return new Date(state.authTokenExpiryTime).getTime();
};

export const isAuthTokenExpired: (state: AppSchema) => boolean = createSelector(
  getAuthTokenExpiryTime, (expiryTime: string) => {

    if (expiryTime == null || expiryTime.trim().length === 0) {
      return true;
    }

    return moment(new Date(expiryTime)).isBefore(moment());
  });

export const isSessionExpiredVisible: (state: AppSchema) => boolean = createSelector(
  [isAuthTokenExpired, getAuthTokenExpiryTime], (expired: boolean, expiryTime: string) =>
    expired && !isEmptyString(expiryTime));

export const getError = (state: AppSchema): any => {
  return state.error;
};

export const getInformationBanner = (state: AppSchema): boolean => {
  return state.showInformationBanner;
};

export const getUserReportMessage = (state: AppSchema): string => {
  return state.userReportMessage;
};

export const isInformationBannerVisible: (state: AppSchema) => boolean = createSelector(
  getInformationBanner, (showInformationBanner: boolean) =>
    showInformationBanner || isUrlBannerVisible());

export const isAccessDenied: (state: AppSchema) => boolean = createSelector(
  getError, (error: any) => {

    if (error && error.status && typeof error.status === "number") {
      return error.status === 401;
    }

    return false;
  });

export const isPortalOpen = (state: AppSchema): boolean => {
  return state.showPortal;
};

export const isFeedbackDialogOpen = (state: AppSchema): boolean => {
  return state.showFeedbackDialog;
};

export const getCurrentAccountId = (state: AppSchema): string => {
  return state.accountId;
};

export { getCurrentAccountId as getAccountId };

export const getAccountEnabledModules: (state: AppSchema) => Module[] = createSelector(
  [getEnabledModuleIds, getCurrentAccountId],
  (enabledModuleIds: string[], currentAccountId: string) => MODULES
    .filter(({ id, whitelistedAccounts = [] }) => {
      if (enabledModuleIds.indexOf(id) < 0) {
        return false;
      }
      return whitelistedAccounts.length === 0 ||
        whitelistedAccounts.indexOf(currentAccountId) >= 0;
    }));

export const getPrincipalId = (state: AppSchema): string =>
  state.principalId;

export const getIdentityType = (state: AppSchema): IdentityType =>
  state.identityType;

export const getCurrentUserAttributes = (state: AppSchema): UserAttributes => {
  return state.currentUser;
};

export const getCurrentUser: (state: AppSchema) => User = createSelector(
  getCurrentUserAttributes, (attrs: UserAttributes) => new User(attrs));

export const getCurrentUserId: (state: AppSchema) => string = createSelector(
  getCurrentUser, (user: User) => user.getUserId());

export const getMaintenanceReminderAttributes = (state: AppSchema): MaintenanceReminderAttributes => {
  return state.maintenanceReminder;
};

export const getMaintenanceReminder: (state: AppSchema) => MaintenanceReminder = createSelector(
  getMaintenanceReminderAttributes, (attrs: MaintenanceReminderAttributes) => new MaintenanceReminder(attrs));

export const getMaintenanceReminderLabel: (state: AppSchema) => string = createSelector(
  getMaintenanceReminder, (maintenanceReminder: MaintenanceReminder) => maintenanceReminder.getDescription());

export const getClosedMaintenanceReminders = (state: AppSchema): string =>
  state.closedMaintenanceReminders;

export const getClosedMaintenanceRemindersData: (state: AppSchema) => string[] =
  createSelector(getClosedMaintenanceReminders, (json) => {
    return JSON.parse(json || "[]");
  });

export const isMaintenanceReminderVisible: (state: AppSchema) => boolean =
  createSelector([getMaintenanceReminder, getClosedMaintenanceRemindersData],
    (maintenanceReminder: MaintenanceReminder, closedMaintenanceReminders: string[]) => {
      const MINUTE_MS = 60000;
      const currDate = new Date();
      const startDate = new Date(maintenanceReminder.startDate);
      const endDate = new Date(maintenanceReminder.endDate);

      return isMaintenanceReminderEnabled() &&
        !closedMaintenanceReminders.includes(maintenanceReminder.getETag()) &&
        maintenanceReminder.getReminderState() === MaintenanceReminderState.PUBLISHED &&
        startDate <= currDate && endDate >= currDate &&
        endDate.getTime() - startDate.getTime() >= MINUTE_MS;
    });

export const getFavorites = (state: AppSchema): string =>
  state.favorites;

export const getFavoritesData: (state: AppSchema) => {} = createSelector(getFavorites, (json: string) => {
  try {
    return JSON.parse(json || "{}");
  } catch {
    return {};
  }
});

export const getFavoritesKey = (state: AppSchema): string => state.favoritesKey;

const getCurrentUserFavoritesValue: (state: AppSchema) => string = createSelector(
  [getFavoritesData, getFavoritesKey],
  (favorites: {}, key: string) => favorites[key] ? favorites[key] : "");

export const getUserFavorites: (state: AppSchema) => string[] = createSelector(
  [getCurrentUserFavoritesValue], (value: string) =>
    value.split(",").filter(moduleName => !isEmptyString(moduleName)));

export const getUserFavoriteModules: (state: AppSchema) => Module[] = createSelector(
  [getAccountEnabledModules, getUserFavorites],
  (modules: Module[], favorites: string[]) =>
    modules.filter(module => favorites.indexOf(module.name) >= 0));

export const isDataWorkloadsModuleEnabled: (state: AppSchema) => boolean = createSelector(
  getAccountEnabledModules, (modules: Module[]) =>
    modules.find(({ id }) => DATA_WORKLOADS_MODULE_ID === id) != null);

export const isReleaseNotesEnabled: (state: AppSchema) => boolean = createSelector(
  getAccountEnabledModules, (modules: Module[]) =>
    modules.find(({ id }) => RELEASE_NOTES_MODULE_ID === id) != null);

export const isServicePrincipal: (state: AppSchema) => boolean = createSelector(
  [getPrincipalId, getIdentityType],
  (principalId: string, identityType: IdentityType) =>
    !isEmptyString(principalId) && (identityType === IdentityType.SERVICE));

export const getCurrentServiceId: (state: AppSchema) => string = createSelector(
  [isServicePrincipal, getPrincipalId], (serviceIdentity: boolean, principalId: string) =>
    !serviceIdentity ? "" : principalId);

export const isUserPrincipal: (state: AppSchema) => boolean = createSelector(
  [getPrincipalId, getIdentityType],
  (principalId: string, identityType: IdentityType) =>
    !isEmptyString(principalId) && (identityType === IdentityType.USER));

export const isQualityReportsEnabled: (state: AppSchema) => boolean = createSelector(
  [getAccountEnabledModules, isUserPrincipal], (modules: Module[], userPrincipalActive: boolean) =>
    userPrincipalActive && modules.find(({ id }) => QUALITY_REPORTS_MODULE_ID === id) != null);

export const isCostReportsEnabled: (state: AppSchema) => boolean = createSelector(
  [getAccountEnabledModules, isUserPrincipal], (modules: Module[], userPrincipalActive: boolean) =>
  userPrincipalActive && modules.find(({ id }) => COST_REPORTS_MODULE_ID === id) != null);

const isAuthTokenPresent: (state: AppSchema) => boolean = createSelector(
  getAuthToken, (authToken: string) => !isEmptyString(authToken));

export const getProfilePath: (state: AppSchema) => string = createSelector(
  [isUserPrincipal, getPrincipalId],
  (userPrincipal: boolean, principalId: string) =>
    userPrincipal ? MY_PROFILE_PATH : getPathToService(principalId));

export const getSettingsPath: (state: AppSchema) => string = createSelector(
  getPrincipalId, (principalId: string) => !isEmptyString(principalId) ? SETTINGS_PATH : "");

const isRefreshTokenPresent: (state: AppSchema) => boolean = createSelector(
  getRefreshToken, (refreshToken: string) => !isEmptyString(refreshToken));

export const isLoggedIn: (state: AppSchema) => boolean = createSelector(
  [isServicePrincipal, isUserPrincipal, isAuthTokenPresent, isRefreshTokenPresent],
  (servicePrincipal: boolean,
   userPrincipal: boolean,
   authTokenPresent: boolean,
   refreshTokenPresent: boolean) => {

    if (servicePrincipal) {
      return authTokenPresent;
    }

    return userPrincipal && authTokenPresent && refreshTokenPresent;
  });

export const canRestoreSession: (state: AppSchema) => boolean = createSelector(
  [isLoggedIn, isAuthTokenExpired, getCurrentAccountId, isUserPrincipal, isServicePrincipal],
  (loggedIn: boolean,
   authTokenExpired: boolean,
   currentAccountId: string,
   userPrincipal: boolean,
   servicePrincipal: boolean) =>
    !loggedIn &&
    !authTokenExpired &&
    !isEmptyString(currentAccountId) &&
    (userPrincipal || servicePrincipal));

export const canRestoreUserSession: (state: AppSchema) => boolean = createSelector(
  [canRestoreSession, isUserPrincipal],
  (sessionRestorable: boolean, userPrincipal: boolean) =>
    sessionRestorable && userPrincipal);

export const canRestoreServiceSession: (state: AppSchema) => boolean = createSelector(
  [canRestoreSession, isServicePrincipal],
  (sessionRestorable: boolean, servicePrincipal: boolean) =>
    sessionRestorable && servicePrincipal);

export const isPortalLoaded: (state: AppSchema) => boolean = createSelector(
  [isLoggedIn, isPortalOpen, isAccessDenied],
  (loggedIn: boolean,
   showPortal: boolean,
   showAccessDenied: boolean) => {

    return loggedIn && showPortal && !showAccessDenied;
  });

export const getModules: (state: AppSchema) => Module[] = createSelector(
  [getAccountEnabledModules, getPersistedModuleIds],
  (modules: Module[], persistedModuleIds: string[]) => modules
    .map(({ id, ...otherProps }) => ({
      ...otherProps,
      id,
      persist: persistedModuleIds.indexOf(id) >= 0,
    })));

export const getLayoutContentModules: (state: AppSchema) => Module[] = createSelector(
  getModules, (portalModules: Module[]) => portalModules
    .filter(module => {

      if (module.disabled) {
        return false;
      }

      if (Array.isArray(module.path)) {
        return module.path.length > 0 && !isEmptyString(module.path.join());
      }

      return !isEmptyString(module.path);
    }));

export const getOpenDialog = memoize((state: AppSchema): Module | undefined => {

  const modules = getModules(state);

  return modules.find((module: Module) => {

    if (!module || !module.dialog) {
      return false;
    }

    const { isDialogOpen = () => false } = module;

    return isDialogOpen(state);
  });
});

export const isLogoutTriggered = (state: AppSchema): boolean => {
  return state.logoutTriggered;
};
