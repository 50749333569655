import React from "react";
import useApiRequest, {
  UseApiRequestActions,
  UseApiRequestModel,
  UseApiRequestProps,
} from "@hooks/use-api-request";
import PortalSessionInfo, { PortalSessionInfoAttributes } from "@data/PortalSessionInfo";
import usePortalSessionManagementClient from "../use-portal-session-management-client";

type SuccessResponse = PortalSessionInfoAttributes;

export interface UseGetPortalSessionInfoProps extends Partial<UseApiRequestProps<SuccessResponse>> {
}

export interface UseGetPortalSessionInfoModel extends UseApiRequestModel<SuccessResponse> {
  sessionInfo: PortalSessionInfo;
}

export interface UseGetPortalSessionInfoActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetPortalSessionInfoProps;
type Model = UseGetPortalSessionInfoModel;
type Actions = UseGetPortalSessionInfoActions;
type Result = [Model, Actions];

export const useGetPortalSession = (props: Props = {}): Result => {

  const {
    defaultErrorMessage = "Failed to get portal session info",
    ...otherProps
  } = props;

  const PortalSessionManagementClient = usePortalSessionManagementClient();

  const makeApiRequest = React.useCallback(() =>
    PortalSessionManagementClient.getPortalSessionInfo(),
    [PortalSessionManagementClient]);

  const [{ successResponse, ...baseModel }, actions] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const sessionInfo = React.useMemo<PortalSessionInfo>(() => {
    return new PortalSessionInfo(successResponse || {});
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    sessionInfo,
  }), [
    baseModel,
    sessionInfo,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetPortalSession;
