import React from "react";
import { isValidInteger } from "@util";
import { RouteParams } from "./constants";
import { useHistory, useParams } from "react-router-dom";
import WorkloadInstanceDetails from "./containers/WorkloadInstanceDetails";
import { getPathToWorkloadExecutionHistory } from "@modules/dataWorkloadDetails/helpers";
import { getPathToWorkloadInstance } from "@modules/dataWorkloadInstanceDetails/helpers";

export function WorkloadInstanceDetailsView() {

  const history = useHistory();

  const { name, version: versionParam, executionId } = useParams<RouteParams>();

  const version = React.useMemo(() => {
    return isValidInteger(versionParam) ? Number(versionParam) : -1;
  }, [ versionParam ]);

  const showExecutionHistory = React.useCallback((workloadName: string,
                                                  workloadVersion: number) =>
      history.push(getPathToWorkloadExecutionHistory(workloadName, workloadVersion)),
    [history]);

  const showWorkloadExecutionDetails = React.useCallback((workloadName: string,
                                                          workloadVersion: number,
                                                          instanceId: string) =>
      history.push(getPathToWorkloadInstance(workloadName, workloadVersion, instanceId)),
    [ history ]);

  return (
    <WorkloadInstanceDetails
      name={name}
      version={version}
      executionId={executionId}
      showExecutionHistory={showExecutionHistory}
      showWorkloadExecutionDetails={showWorkloadExecutionDetails}
    />
  );
}

export default WorkloadInstanceDetailsView;
