import React from "react";
import Files from "../components/Files";
import { useGetUiPackages } from "@hooks";
import FilesList from "../components/FilesList";
import { DeleteUiPackage, UploadUiPackage } from "@components";
import { DELETE_UI_PACKAGE_ACTION, UPLOAD_UI_PACKAGE_ACTION } from "@components/ui-package-management-action-menu";

const FilesContainer = () => {

  const [showUploadUiDialog, setShowUploadUiPackageDialog] = React.useState(false);
  const [showDeleteUiDialog, setShowDeleteUiPackageDialog] = React.useState(false);

  const [model, actions] = useGetUiPackages();

  const [{ files = [], showLoadingIndicator }, { refresh }] = [model, actions];

  const uploadUiPackage = React.useCallback(() => {
    setShowUploadUiPackageDialog(true);
  }, [setShowUploadUiPackageDialog]);

  const deleteUiPackage = React.useCallback(() => {
    setShowDeleteUiPackageDialog(true);
  }, [setShowDeleteUiPackageDialog]);

  const closeDialog = React.useCallback(() => {
    refresh();
    setShowDeleteUiPackageDialog(false);
    setShowUploadUiPackageDialog(false);
  }, [refresh, setShowDeleteUiPackageDialog, setShowUploadUiPackageDialog]);

  return (
    <Files
      {...model}
      {...actions}
      numItems={files.length}
      loading={showLoadingIndicator}
      uploadUiPackage={uploadUiPackage}
      deleteUiPackage={deleteUiPackage}
      actions={[UPLOAD_UI_PACKAGE_ACTION, DELETE_UI_PACKAGE_ACTION]}
    >
      {showUploadUiDialog && (
        <UploadUiPackage
          open={showUploadUiDialog}
          cancel={closeDialog}
        />
      )}
      {showDeleteUiDialog && (
        <DeleteUiPackage
          open={showDeleteUiDialog}
          cancel={closeDialog}
          onSuccessMessageShown={closeDialog}
        />
      )}
      <FilesList items={files} {...model} {...actions} />
    </Files>
  );
};

export default FilesContainer;
