import { Module } from "@data";
import { accountSettingsModule } from "@modules/accountSettings/module";
import {
  QueryData,
  QuerySchedules,
  SupersetDashboards,
  UserDatabase,
} from "@modules/portlet/module";
import { dataAccessRequestDetailsModule } from "@modules/dataAccessRequestDetails/module";
import { dataAccessRequestWizardModule } from "@modules/dataAccessRequestWizard/module";
import { deleteDataAccessRequestModule } from "@modules/deleteDataAccessRequest/module";
import { trainingCertificateModule } from "@modules/trainingCertificate/module";
import { dataSetRequestDetailsModule } from "@modules/dataSetRequestDetails/module";
import {
  cloneDataSetRequestWizardModule,
  createDataSetRequestWizardModule,
  editDataSetRequestWizardModule,
} from "@modules/dataSetRequestWizard/module";
import {
  manageGroupEffectivePoliciesModule,
  manageServiceEffectivePoliciesModule,
  manageUserEffectivePoliciesModule,
} from "@modules/policyManagement/module";
import { deleteDataSetRequestModule } from "@modules/deleteDataSetRequest/module";
import { dataApprovalRequestDetailsModule } from "@modules/dataApprovalRequestDetails/module";
import { certificateAuthoritiesModule } from "@modules/certificateAuthorities/module";
import { approveRequestModule } from "@modules/approveRequest/module";
import { namespacesModule } from "@modules/namespaces/module";
import { namespaceDetailsModule } from "@modules/namespaceDetails/module";
import { createNamespaceModule } from "@modules/createNamespace/module";
import { schemasModule } from "@modules/schemas/module";
import { schemaDetailsModule } from "@modules/schemaDetails/module";
import { schemaStateManagerModule } from "@modules/schemaStateManager/module";
import { createSchemaWizardModule, editSchemaWizardModule } from "@modules/schemaWizard/module";
import { deviceTypeManagerModule } from "@modules/deviceTypeManager/module";
import { deviceTypesModule } from "@modules/deviceTypes/module";
import { deviceTypeDetailsModule } from "@modules/deviceTypeDetails/module";
import { deviceEnrollmentDetailsModule } from "@modules/deviceEnrollmentDetails/module";
import { deviceEnrollmentModule } from "@modules/deviceEnrollment/module";
import { enrollDeviceModule, validateDeviceModule } from "@modules/deviceEnrollmentWizard/module";
import { devicesModule } from "@modules/devices/module";
import { deviceDetailsModule } from "@modules/deviceDetails/module";
import { deviceValidationDetailsModule } from "@modules/deviceValidationDetails/module";
import { disenrollDeviceModule } from "@modules/disenrollDevice/module";
import { managedPoliciesModule } from "@modules/managedPolicies/module";
import { managedPolicyDetailsModule } from "@modules/managedPolicyDetails/module";
import {
  createDeviceTypeWizardModule,
  editDeviceTypeWizardModule,
} from "@modules/deviceTypeWizard/module";
import { usersModule } from "@modules/users/module";
import { emailTemplatesModule } from "@modules/emailTemplates/module";
import { auditEventsModule } from "@modules/auditEvents/module";
import { auditEventDetailsModule } from "@modules/auditEventDetails/module";
import {
  createEmailTemplateWizardModule,
  editEmailTemplateWizardModule,
} from "@modules/emailTemplateWizard/module";
import { emailTemplateDetailsModule } from "@modules/emailTemplateDetails/module";
import {
  createFederationProviderWizardModule,
  editFederationProviderWizardModule,
} from "@modules/federationProviderWizard/module";
import { federationProvidersModule } from "@modules/federationProviders/module";
import { federationProviderDetailsModule } from "@modules/federationProviderDetails/module";
import { deleteFederationProvidersModule } from "@modules/deleteFederationProvider/module";
import { profileAttributeDefinitionsModule } from "@modules/profileAttributeDefinitions/module";
import {
  editProfileAttributeDefinitionModule,
} from "@modules/editProfileAttributeDefinition/module";
import { myProfileModule, userDetailsModule } from "@modules/userDetails/module";
import { createUserModule } from "@modules/createUser/module";
import { deleteUserModule } from "@modules/deleteUser/module";
import { lockUserModule } from "@modules/lockUser/module";
import { closeUserModule } from "@modules/closeUser/module";
import { logoutUserModule } from "@modules/logoutUser/module";
import { inviteUserModule } from "@modules/inviteUser/module";
import { userReportModule } from "@modules/userReport/module";
import { passwordRecoveryModule } from "@modules/passwordRecovery/module";
import { expireUserPasswordModule } from "@modules/expireUserPassword/module";
import { changePasswordModule } from "@modules/changePassword/module";
import { shareDashboardModule } from "@modules/shareDashboard/module";
import { publishDashboardModule } from "@modules/publishDashboard/module";
import { securityGroupsModule } from "@modules/securityGroups/module";
import { createSecurityGroupModule } from "@modules/createSecurityGroup/module";
import { securityGroupDetailsModule } from "@modules/securityGroupDetails/module";
import { securityServicesRegionalModule } from "@modules/securityServicesRegional/module";
import { securityServiceRegionalDetailsModule } from "@modules/securityServiceRegionalDetails/module";
import { createServiceRegionalModule } from "@modules/createServiceRegional/module";
import { applicationsRegionalModule } from "@modules/applicationsRegional/module";
import { applicationRegionalDetailsModule } from "@modules/applicationRegionalDetails/module";
import { createApplicationRegionalModule, editApplicationRegionalModule } from "@modules/applicationWizardRegional/module";
import { policiesModule } from "@modules/policies/module";
import { createPolicyModule } from "@modules/createPolicy/module";
import { editPolicyModule } from "@modules/editPolicy/module";
import { deletePolicyModule } from "@modules/deletePolicy/module";
import { policyDetailsModule } from "@modules/policyDetails/module";
import {
  manageGroupServicesModule,
  manageGroupUsersModule,
  manageServiceGroupsModule,
  manageUserGroupsModule,
} from "@modules/groupManagementWizard/module";
import { welcomePageModule } from "@modules/welcome/module";
import { uiPackageModule } from "@modules/uiPackage/module";
import dataSetsModule from "@modules/dataSets/module";
import dataSetDetailsModule from "@modules/dataSetDetails/module";
import dataAccessRequestsModule from "@modules/dataAccessRequests/module";
import dataSetRequestsModule from "@modules/dataSetRequests/module";
import dataApprovalRequestsModule from "@modules/dataApprovalRequests/module";
import dataLakeBrowserModule from "@modules/dataLakeBrowser/module";
import dataWorkloadsModule from "@modules/dataWorkloads/module";
import { createWorkloadWizardModule, cloneWorkloadWizardModule } from "@modules/dataWorkloadWizard/module";
import dataWorkloadDetailsModule from "@modules/dataWorkloadDetails/module";
import workloadInstanceDetailsModule from "@modules/dataWorkloadInstanceDetails/module";
import addTriggerModule, { editTriggerModule } from "@modules/dataWorkloadTriggerWizard/module";
import DashboardsAccessControlModule from "@modules/dashboards/module";
import DashboardDetailsModule from "@modules/dashboardDetails/module";
import releaseNotesModule from "@modules/releaseNotes/module";
import releaseNoteDetailsModule from "@modules/releaseNoteDetails/module";
import digitalShadowTypesModule from "@modules/digitalShadowTypes/module";
import digitalShadowLayoutsModule from "@modules/digitalShadowLayouts/module";
import digitalShadowLayoutDetailsModule from "@modules/digitalShadowLayoutDetails/module";
import { createDigitalShadowLayoutModule, editDigitalShadowLayoutModule } from "@modules/digitalShadowLayoutWizard/module";
import costReportsModule from "@modules/costReports/module";
import qualityReportsModule from "@modules/qualityReports/module";
import qualityReportDetailsModule from "@modules/qualityReportDetails/module";
import switchPrincipalModule from "@modules/switchPrincipal/module";

const modules: Module[] = Object.freeze([
  accountSettingsModule,

  SupersetDashboards,

  DashboardDetailsModule,
  DashboardsAccessControlModule,

  UserDatabase,
  QueryData,
  QuerySchedules,

  dataSetDetailsModule,
  dataSetsModule,

  trainingCertificateModule,

  dataAccessRequestWizardModule,
  dataAccessRequestDetailsModule,
  dataAccessRequestsModule,

  cloneDataSetRequestWizardModule,
  editDataSetRequestWizardModule,
  createDataSetRequestWizardModule,
  dataSetRequestDetailsModule,
  dataSetRequestsModule,

  approveRequestModule,
  dataApprovalRequestDetailsModule,
  dataApprovalRequestsModule,

  dataLakeBrowserModule,

  editTriggerModule,
  createWorkloadWizardModule,
  cloneWorkloadWizardModule,
  switchPrincipalModule,
  addTriggerModule,
  workloadInstanceDetailsModule,
  dataWorkloadDetailsModule,
  dataWorkloadsModule,

  createUserModule,
  inviteUserModule,
  passwordRecoveryModule,
  manageUserEffectivePoliciesModule,
  manageUserGroupsModule,
  myProfileModule,
  userDetailsModule,
  usersModule,

  createSecurityGroupModule,
  manageGroupUsersModule,
  manageGroupServicesModule,
  manageGroupEffectivePoliciesModule,
  securityGroupDetailsModule,
  securityGroupsModule,

  editPolicyModule,
  createPolicyModule,
  policyDetailsModule,
  policiesModule,

  createServiceRegionalModule,
  manageServiceGroupsModule,
  manageServiceEffectivePoliciesModule,
  securityServiceRegionalDetailsModule,
  securityServicesRegionalModule,

  editApplicationRegionalModule,
  createApplicationRegionalModule,
  applicationRegionalDetailsModule,
  applicationsRegionalModule,

  editEmailTemplateWizardModule,
  createEmailTemplateWizardModule,
  emailTemplateDetailsModule,
  emailTemplatesModule,

  editFederationProviderWizardModule,
  createFederationProviderWizardModule,
  federationProviderDetailsModule,
  federationProvidersModule,

  editProfileAttributeDefinitionModule,
  profileAttributeDefinitionsModule,

  auditEventsModule,
  auditEventDetailsModule,

  createNamespaceModule,
  namespacesModule,
  namespaceDetailsModule,

  createSchemaWizardModule,
  editSchemaWizardModule,
  schemaDetailsModule,
  schemasModule,

  managedPolicyDetailsModule,
  managedPoliciesModule,

  createDeviceTypeWizardModule,
  editDeviceTypeWizardModule,
  deviceTypeDetailsModule,
  deviceTypesModule,

  enrollDeviceModule,
  validateDeviceModule,
  deviceEnrollmentDetailsModule,
  deviceEnrollmentModule,

  deviceValidationDetailsModule,
  deviceDetailsModule,
  devicesModule,

  certificateAuthoritiesModule,

  uiPackageModule,

  welcomePageModule,

  releaseNotesModule,
  releaseNoteDetailsModule,

  digitalShadowTypesModule,

  createDigitalShadowLayoutModule,
  editDigitalShadowLayoutModule,
  digitalShadowLayoutDetailsModule,
  digitalShadowLayoutsModule,

  qualityReportDetailsModule,
  qualityReportsModule,

  costReportsModule,

  // Dialogs
  schemaStateManagerModule,
  deleteUserModule,
  logoutUserModule,
  lockUserModule,
  expireUserPasswordModule,
  changePasswordModule,
  deletePolicyModule,
  deviceTypeManagerModule,
  deleteFederationProvidersModule,
  closeUserModule,
  userReportModule,
  approveRequestModule,
  deleteDataAccessRequestModule,
  deleteDataSetRequestModule,
  disenrollDeviceModule,

  shareDashboardModule,
  publishDashboardModule,

]) as Module[];

export default modules;
