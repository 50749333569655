import React from "react";
import { AppSchema } from "@schemas";
import { connect } from "react-redux";
import { getAccountId, getAuthToken, isSessionExpiredVisible } from "../selectors";
import AppController, { Actions, Model } from "../components/AppController";
import SplashScreen from "./SplashScreen";
import App from "./App";

interface ContainerModel extends Model {
  showSplashScreen?: boolean;
}

type Props = ContainerModel & Actions & {
  children?: React.ReactNode;
};

const AppControllerContainer = (props: Props) => {

  const { showSplashScreen, children, ...remainingProps } = props;

  return (
    <AppController {...remainingProps}>
      {showSplashScreen && <SplashScreen />}
      {!showSplashScreen && <App />}
      {children}
    </AppController>
  );
};

const mapStateToProps = (state: AppSchema): ContainerModel => ({
  authToken: getAuthToken(state),
  accountId: getAccountId(state),
  showSplashScreen: isSessionExpiredVisible(state),
});

const mapDispatchToProps = (dispatch: any): Actions => ({
});

export default connect<ContainerModel, Actions, ContainerModel & Actions>(
  mapStateToProps,
  mapDispatchToProps,
)(AppControllerContainer);
