import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import useEndPortalSession from "@hooks/session/use-end-portal-session";
import useUserLogout from "@hooks/userIdm/use-user-logout";
import LoadingView from "../loading-view";
import styles from "./styles";
import isPortalWebserverEnabled from "@util/isPortalWebserverEnabled";

export interface LogoutListenerModel {
  className?: string;
  logoutTriggered?: boolean;
  loadingViewSize?: number | string;
  loadingViewLabel?: string;
  portalWebserverEnabled?: boolean;
  userId?: string;
}

export interface LogoutListenerActions {
  onLogoutComplete?: () => void;
}

type Model = LogoutListenerModel;
type Actions = LogoutListenerActions;
type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const LogoutListener = withStyles(styles)((props: Props) => {

  const {
    classes,
    className,
    logoutTriggered,
    loadingViewSize = 25,
    loadingViewLabel = "Logging Out...",
    portalWebserverEnabled = isPortalWebserverEnabled(),
    userId,
    onLogoutComplete = noop,
    children,
  } = props;

  const [endPortalSessionModel, { endPortalSession }] = useEndPortalSession();

  const {
    showLoadingIndicator: endPortalSessionLoadingIndicatorVisible,
    showSuccessView: endPortalSessionSuccessViewVisible,
    showErrorView: endPortalSessionErrorViewVisible,
  } = endPortalSessionModel;

  const portalSessionEnded = React.useMemo(() => logoutTriggered &&
    !portalWebserverEnabled || (!endPortalSessionLoadingIndicatorVisible &&
      (endPortalSessionSuccessViewVisible || endPortalSessionErrorViewVisible)),
  [
    logoutTriggered,
    portalWebserverEnabled,
    endPortalSessionLoadingIndicatorVisible,
    endPortalSessionSuccessViewVisible,
    endPortalSessionErrorViewVisible,
  ]);

  React.useEffect(() => {
    if (logoutTriggered && portalWebserverEnabled) {
      endPortalSession();
    }
  }, [logoutTriggered, portalWebserverEnabled, endPortalSession]);

  const userLogoutRequired = React.useMemo(() => !isEmptyString(userId), [userId]);

  const userLogoutReady = React.useMemo(() =>
    userLogoutRequired && portalSessionEnded,
    [userLogoutRequired, portalSessionEnded]);

  const [userLogoutModel, { logout }] = useUserLogout({ userId });

  const {
    showLoadingIndicator: userLogoutLoadingIndicatorVisible,
    showSuccessView: userLogoutSuccessViewVisible,
    showErrorView: userLogoutErrorViewVisible,
  } = userLogoutModel;

  const userLogoutComplete = React.useMemo(() => !userLogoutLoadingIndicatorVisible &&
    (userLogoutSuccessViewVisible || userLogoutErrorViewVisible),
    [userLogoutLoadingIndicatorVisible, userLogoutSuccessViewVisible, userLogoutErrorViewVisible]);

  React.useEffect(() => {
    if (userLogoutReady) {
      logout();
    }
  }, [userLogoutReady, logout]);

  React.useEffect(() => {
    if (portalSessionEnded && (!userLogoutRequired || userLogoutComplete)) {
      onLogoutComplete();
    }
  }, [portalSessionEnded, userLogoutRequired, userLogoutComplete, onLogoutComplete]);

  if (!logoutTriggered) {
    return null;
  }

  return (
    <div className={classnames("logoutListener", className, classes.container)}>
      <div className={classes.blur} />
      <LoadingView
        className={classnames("loadingView", classes.loadingView)}
        size={loadingViewSize}
        label={loadingViewLabel}
      />
      {children}
    </div>
  );
});

export default LogoutListener;
