import React from "react";
import { FederationProvider, FederationProviderAttributes } from "@data";
import { useFederationProvidersClient } from "../use-federation-providers-client";
import { useApiRequest, UseApiRequestActions, UseApiRequestModel, UseApiRequestProps } from "@hooks";

type SuccessResponse = FederationProviderAttributes;

export interface UseGetFederationProviderProps extends Partial<UseApiRequestProps<SuccessResponse>> {
  providerId: string;
}

export interface UseGetFederationProviderModel extends UseApiRequestModel<SuccessResponse> {
  provider: FederationProvider;
}

export interface UseGetFederationProviderActions extends UseApiRequestActions<SuccessResponse> {
}

type Props = UseGetFederationProviderProps;
type Model = UseGetFederationProviderModel;
type Actions = UseGetFederationProviderActions;
type Result = [Model, Actions];

export const useGetFederationProvider = (props: Props): Result => {

  const {
    providerId = "",
    defaultErrorMessage = "Failed to get federation provider",
    ...otherProps
  } = props;

  const FederationProvidersClient = useFederationProvidersClient();

  const makeApiRequest = React.useCallback(() =>
    FederationProvidersClient.getFederationProvider(providerId),
    [FederationProvidersClient, providerId]);

  const [{ successResponse, ...baseModel }, { ...baseActions }] =
    useApiRequest<SuccessResponse>({
      ...otherProps,
      defaultErrorMessage,
      makeApiRequest,
    });

  const provider = React.useMemo(() => {
    const attrs = successResponse || {};
    return new FederationProvider(attrs);
  }, [successResponse]);

  const model = React.useMemo<Model>(() => ({
    ...baseModel,
    provider,
  }), [
    baseModel,
    provider,
  ]);

  const actions = React.useMemo<Actions>(() => ({
    ...baseActions,
  }), [
    baseActions,
  ]);

  return React.useMemo<Result>(() => [model, actions], [model, actions]);
};

export default useGetFederationProvider;
