import React from "react";
import { connect } from "react-redux";
import { AppSchema } from "@schemas";
import { DeviceDataModel } from "@data";
import { isEmptyString, noop } from "@util";
import { DetailsView } from "@components";
import { useDeviceEnrollmentStatus } from "@hooks";
import { getDeviceId, getDeviceRequest } from "../selectors";
import {
  fetchDeviceValidationDetailsFailure,
  fetchDeviceValidationDetailsRequest,
  fetchDeviceValidationDetailsSuccess,
} from "../actions";
import WaitForDeviceValidationDetails from "../components/WaitForDeviceValidationDetails";
import SummaryView from "./SummaryView";
import ModuleIcon from "@material-ui/icons/Smartphone";

interface ContainerModel {
  deviceRequest?: DeviceDataModel;
  title?: string;
  deviceId?: string;
  errorMessage?: string;
  showAccessDenied?: boolean;
  showNotFound?: boolean;
  showLoadingIndicator?: boolean;
}

interface ContainerActions {
  enrollDevice?: () => void;
  trackRequestEvent?: () => void;
  trackSuccessEvent?: () => void;
  trackErrorEvent?: (analytic: string) => void;
  refresh?: () => void;
}

type Props = ContainerModel & ContainerActions;

const DeviceValidationDetailsContainer = (props: Props) => {

  const {
    deviceId,
    deviceRequest = DeviceDataModel.EMPTY,
    enrollDevice,
    trackRequestEvent = noop,
    trackSuccessEvent = noop,
    trackErrorEvent = noop,
  } = props;

  const [model, { refresh }] = useDeviceEnrollmentStatus({
    deviceId,
    trackRequestEvent,
    trackSuccessEvent,
    trackErrorEvent,
    autoRefreshEnabled: true,
  });

  const {
    device,
    errorMessage,
    autoRefreshActive,
    showAccessDenied,
    showNotFound,
    showLoadingIndicator,
    statusCode,
  } = model;

  const waitingForDeviceValidationDetails = React.useMemo(() => {

    // If the module was opened with a device request payload that matches the path id
    // then we can expect that we transitioned from the device validation wizard and are
    // waiting on receiving the device validation details
    if (isEmptyString(deviceId) || deviceId !== deviceRequest.getDeviceId()) {
      return false;
    }

    return autoRefreshActive || showNotFound;

  }, [deviceId, deviceRequest, autoRefreshActive, showNotFound]);

  const showNotFoundError = React.useMemo(() =>
    showNotFound && !waitingForDeviceValidationDetails,
    [showNotFound, waitingForDeviceValidationDetails]);

  React.useEffect(() => {
    refresh();
  }, []);

  return (
      <DetailsView
        {...props}
        title={deviceId}
        icon={ModuleIcon}
        errorMessage={errorMessage}
        showAccessDenied={showAccessDenied}
        showNotFound={showNotFoundError}
        showLoadingIndicator={showLoadingIndicator}
        statusCode={statusCode}
        refresh={refresh}
      >
        {waitingForDeviceValidationDetails && (
          <WaitForDeviceValidationDetails
            showLoadingIndicator={showLoadingIndicator}
            refresh={refresh}
          />
        )}
        {!waitingForDeviceValidationDetails && (
          <SummaryView
            showLoadingIndicator={showLoadingIndicator}
            deviceRequest={deviceRequest}
            device={device}
            enrollDevice={enrollDevice}
          />
        )}
      </DetailsView>
  );
};

const mapStateToProps = (state: AppSchema, ownProps: ContainerModel): ContainerModel => ({
  deviceId: getDeviceId(state),
  deviceRequest: getDeviceRequest(state),
  ...ownProps,
});

const mapDispatchToProps = (dispatch: any, ownProps: ContainerActions): ContainerActions => ({
  trackRequestEvent: () => dispatch(fetchDeviceValidationDetailsRequest()),
  trackSuccessEvent: () => dispatch(fetchDeviceValidationDetailsSuccess()),
  trackErrorEvent: analytic => dispatch(fetchDeviceValidationDetailsFailure(analytic)),
  ...ownProps,
});

export default connect<ContainerModel, ContainerActions, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceValidationDetailsContainer);
