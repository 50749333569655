import { useApiRequestAnalytics } from "@hooks";
import {
  UpdateTriggerRequest,
  WorkloadAttributes,
  WorkloadInstanceAttributes,
  WorkloadQueryType,
  WorkloadState
} from "@data";
import {
  AddTriggerResponse,
  CreateWorkloadResponse,
  GetAllVersionsOfWorkloadResponse,
  GetWorkloadCodeHeadResponse,
  GetWorkloadCodePackagingStatusResponse,
  GetWorkloadCodeUrl,
  GetWorkloadConfigurationResponse,
  GetWorkloadInstanceLogsResponse,
  GetWorkloadInstancesResponse,
  GetWorkloadObjectResponse,
  GetWorkloadTriggersResponse,
  ManuallyExecuteWorkloadResponse,
  SearchInstanceLogParams,
  SearchInstancesParams,
  UpdateWorkloadTriggerResponse,
  UploadWorkloadCodeUrl,
  ValidateWorkloadNameResponse,
  WorkloadConfigurationProperties,
  WorkloadManagerClient,
} from "@network";

export type GetWorkloadsApi = (nameFilter?: string,
                               next?: string,
                               stateFilter?: WorkloadState) => Promise<GetWorkloadObjectResponse>;
export type GetReleasedWorkloadsApi = (nameFilter?: string,
                                       releasedByNameFilter?: string,
                                       next?: string) => Promise<GetWorkloadObjectResponse>;
export type GetAllVersionsOfWorkloadApi = (name: string,
                                           next?: string) => Promise<GetAllVersionsOfWorkloadResponse>;
export type DeleteWorkloadApi = (name: string) => Promise<void>;
export type GetWorkloadApi = (name: string,
                              version?: number | "latest") => Promise<WorkloadAttributes>;
export type GetWorkloadInstancesApi = (name: string,
                                       version: number,
                                       next?: string,
                                       queryParams?: SearchInstancesParams) => Promise<GetWorkloadInstancesResponse>;
export type GetWorkloadInstanceApi = (name: string,
                                      version: number,
                                      executionId: string) => Promise<WorkloadInstanceAttributes>;
export type GetWorkloadInstanceLogsApi =
  (name: string,
   version: number,
   executionId: string,
   next?: string,
   searchParams?: SearchInstanceLogParams) => Promise<GetWorkloadInstanceLogsResponse>;
export type CreateWorkloadApi = (json: string) => Promise<CreateWorkloadResponse>;
export type EditWorkloadDescriptionApi = (name: string, version: number, description: string) => Promise<void>;
export type EditWorkloadEntryPointApi = (name: string,
                                         version: number,
                                         etag: string,
                                         entryPoint: string) => Promise<void>;
export type EditWorkloadTimeoutApi = (name: string, version: number, etag: string, timeout: string) => Promise<void>;
export type EditWorkloadMemoryApi = (name: string, version: number, etag: string, memory: string) => Promise<void>;
export type EditWorkloadTagsApi = (name: string, version: number, tags: string[]) => Promise<void>;
export type EditWorkloadQueryApi = (name: string, version: number, query: string) => Promise<void>;
export type EditWorkloadQueryTypeApi = (name: string, version: number, type: WorkloadQueryType) => Promise<void>;
export type EditWorkloadQueryOverwriteApi = (name: string, version: number, overwrite: boolean) => Promise<void>;
export type EditWorkloadQueryTableNameApi = (name: string, version: number, tableName: string) => Promise<void>;
export type EditWorkloadPolicyApi = (name: string,
                                     version: number,
                                     etag: string,
                                     json: string) => Promise<void>;
export type GetUploadWorkloadCodeUrlApi = (name: string,
                                           version: number,
                                           fileName: string) => Promise<UploadWorkloadCodeUrl>;
export type CopyWorkloadCodeApi = (name: string,
                                   version: number,
                                   sourceName: string,
                                   sourceVersion: number) => Promise<void>;
export type UploadWorkloadCodeApi = (uploadUrl: string, code: File) => Promise<void>;
export type GetWorkloadCodeApi = (name: string, version: number) => Promise<GetWorkloadCodeUrl>;
export type GetWorkloadCodeHeadApi = (name: string, version: number) => Promise<GetWorkloadCodeHeadResponse>;
export type GetWorkloadCodePackagingStatusApi = (name: string, version: number) =>
  Promise<GetWorkloadCodePackagingStatusResponse>;
export type GetWorkloadTriggersApi = (name: string, version: number) => Promise<GetWorkloadTriggersResponse>;
export type UpdateWorkloadTriggerApi = (name: string,
                                        version: number,
                                        body: UpdateTriggerRequest) => Promise<UpdateWorkloadTriggerResponse>;
export type SetWorkloadServiceSecretApi = (name: string,
                                           version: number,
                                           accountId: string,
                                           principal: string,
                                           secret: string) => Promise<void>;
export type GetWorkloadConfigurationApi = (name: string,
                                           version: number) => Promise<GetWorkloadConfigurationResponse>;
export type UpdateWorkloadConfigurationApi = (name: string,
                                              version: number,
                                              properties: WorkloadConfigurationProperties) => Promise<void>;
export type UpdateWorkloadConfigurationByKeyApi = (name: string,
                                                   version: number,
                                                   key: string,
                                                   value: string) => Promise<void>;
export type DeleteWorkloadConfigurationKeysApi = (name: string,
                                                 version: number,
                                                  keys: string[]) => Promise<void>;
export type DeleteWorkloadConfigurationByKeyApi = (name: string,
                                                   version: number,
                                                   key: string) => Promise<void>;
export type AddWorkloadVariablesApi = (name: string,
                                       version: number,
                                       etag: string,
                                       json: string) => Promise<void>;
export type EditWorkloadVariableApi = (name: string,
                                       version: number,
                                       variable: string,
                                       etag: string,
                                       json: string) => Promise<void>;
export type DeleteWorkloadVariableApi = (name: string,
                                         version: number,
                                         etag: string,
                                         variable: string) => Promise<void>;
export type RemoveWorkloadTriggerApi = (name: string,
                                        version: number,
                                        eventBindings: string[]) => Promise<void>;
export type AddWorkloadTriggerApi = (name: string,
                                     version: number,
                                     json: string) => Promise<AddTriggerResponse>;
export type ManuallyExecuteWorkloadApi = (name: string,
                                          version: number,
                                          body: string) => Promise<ManuallyExecuteWorkloadResponse>;
export type CancelWorkloadExecutionApi = (name: string, version: number, executionIds: string[]) => Promise<void>;
export type ReleaseWorkloadApi = (name: string, version: number) => Promise<void>;
export type DeprecateWorkloadApi = (name: string, version: number) => Promise<void>;
export type DecommissionWorkloadApi = (name: string, version: number) => Promise<void>;
export type DraftNewWorkloadVersionFromApi = (name: string,
                                              version: number | "latest") => Promise<CreateWorkloadResponse>;
export type DeleteWorkloadVersionApi = (name: string, version: number) => Promise<void>;
export type SwitchPrincipalApi = (name: string, version: number, accountId: string, email: string) => Promise<void>;
export type AddActionLabelsApi = (name: string,
                                  version: number,
                                  etag: string,
                                  actionLabels: string[]) => Promise<void>;
export type DeleteActionLabelApi = (name: string,
                                  version: number,
                                  etag: string,
                                  actionLabel: string) => Promise<void>;
export type ValidateWorkloadNameApi = (name: string) => Promise<ValidateWorkloadNameResponse>;

export interface WorkloadManagerClientApi {
  getWorkloadApi: GetWorkloadApi;
  getWorkloadsApi: GetWorkloadsApi;
  getReleasedWorkloadsApi: GetReleasedWorkloadsApi;
  getAllVersionsOfWorkload: GetAllVersionsOfWorkloadApi;
  getWorkloadInstancesApi: GetWorkloadInstancesApi;
  getWorkloadInstanceApi: GetWorkloadInstanceApi;
  getWorkloadInstanceLogsApi: GetWorkloadInstanceLogsApi;
  deleteWorkloadApi: DeleteWorkloadApi;
  createWorkloadApi: CreateWorkloadApi;
  editWorkloadDescriptionApi: EditWorkloadDescriptionApi;
  editWorkloadEntryPointApi: EditWorkloadEntryPointApi;
  editWorkloadTimeoutApi: EditWorkloadTimeoutApi;
  editWorkloadMemoryApi: EditWorkloadMemoryApi;
  editWorkloadTagsApi: EditWorkloadTagsApi;
  editWorkloadPolicy: EditWorkloadPolicyApi;
  editWorkloadQuery: EditWorkloadQueryApi;
  editWorkloadQueryType: EditWorkloadQueryTypeApi;
  editWorkloadQueryOverwrite: EditWorkloadQueryOverwriteApi;
  editWorkloadQueryTableName: EditWorkloadQueryTableNameApi;
  getUploadWorkloadCodeUrlApi: GetUploadWorkloadCodeUrlApi;
  uploadWorkloadCodeApi: UploadWorkloadCodeApi;
  copyWorkloadCodeApi: CopyWorkloadCodeApi;
  getWorkloadCodeApi: GetWorkloadCodeApi;
  getWorkloadCodeHeadApi: GetWorkloadCodeHeadApi;
  getWorkloadCodePackagingStatusApi: GetWorkloadCodePackagingStatusApi;
  getWorkloadTriggersApi: GetWorkloadTriggersApi;
  updateWorkloadTriggerApi: UpdateWorkloadTriggerApi;
  setWorkloadServiceSecretApi: SetWorkloadServiceSecretApi;
  removeWorkloadTriggerApi: RemoveWorkloadTriggerApi;
  getWorkloadConfiguration: GetWorkloadConfigurationApi;
  updateWorkloadConfiguration: UpdateWorkloadConfigurationApi;
  updateWorkloadConfigurationByKey: UpdateWorkloadConfigurationByKeyApi;
  deleteWorkloadConfigurationKeys: DeleteWorkloadConfigurationKeysApi;
  deleteWorkloadConfigurationByKey: DeleteWorkloadConfigurationByKeyApi;
  addWorkloadTriggerApi: AddWorkloadTriggerApi;
  addWorkloadVariablesApi: AddWorkloadVariablesApi;
  editWorkloadVariableApi: EditWorkloadVariableApi;
  deleteWorkloadVariableApi: DeleteWorkloadVariableApi;
  manuallyExecuteWorkloadApi: ManuallyExecuteWorkloadApi;
  cancelWorkloadExecutionApi: CancelWorkloadExecutionApi;
  releaseWorkload: ReleaseWorkloadApi;
  deprecateWorkload: DeprecateWorkloadApi;
  decommissionWorkload: DecommissionWorkloadApi;
  draftNewWorkloadVersion: DraftNewWorkloadVersionFromApi;
  deleteWorkloadVersion: DeleteWorkloadVersionApi;
  switchPrincipal: SwitchPrincipalApi;
  addActionLabels: AddActionLabelsApi;
  deleteActionLabel: DeleteActionLabelApi;
  validateWorkloadName: ValidateWorkloadNameApi;
}

enum ActionTypes {
  GET_WORKLOAD_REQUEST = "workload_manager_client/get_workload_request",
  GET_WORKLOAD_SUCCESS = "workload_manager_client/get_workload_success",
  GET_WORKLOAD_FAILED = "workload_manager_client/get_workload_failed",

  GET_WORKLOADS_REQUEST = "workload_manager_client/get_workloads_request",
  GET_WORKLOADS_SUCCESS = "workload_manager_client/get_workloads_success",
  GET_WORKLOADS_FAILED = "workload_manager_client/get_workloads_failed",

  GET_RELEASED_WORKLOADS_REQUEST = "workload_manager_client/get_released_workloads_request",
  GET_RELEASED_WORKLOADS_SUCCESS = "workload_manager_client/get_released_workloads_success",
  GET_RELEASED_WORKLOADS_FAILED = "workload_manager_client/get_released_workloads_failed",

  GET_ALL_VERSIONS_OF_WORKLOAD_REQUEST = "workload_manager_client/get_all_versions_of_workload_request",
  GET_ALL_VERSIONS_OF_WORKLOAD_SUCCESS = "workload_manager_client/get_all_versions_of_workload_success",
  GET_ALL_VERSIONS_OF_WORKLOAD_FAILED = "workload_manager_client/get_all_versions_of_workload_failed",

  GET_WORKLOAD_INSTANCES_REQUEST = "workload_manager_client/get_workload_instances_request",
  GET_WORKLOAD_INSTANCES_SUCCESS = "workload_manager_client/get_workload_instances_success",
  GET_WORKLOAD_INSTANCES_FAILED = "workload_manager_client/get_workload_instances_failed",

  GET_WORKLOAD_INSTANCE_REQUEST = "workload_manager_client/get_workload_instance_request",
  GET_WORKLOAD_INSTANCE_SUCCESS = "workload_manager_client/get_workload_instance_success",
  GET_WORKLOAD_INSTANCE_FAILED = "workload_manager_client/get_workload_instance_failed",

  GET_WORKLOAD_INSTANCE_LOGS_REQUEST = "workload_manager_client/get_workload_instance_logs_request",
  GET_WORKLOAD_INSTANCE_LOGS_SUCCESS = "workload_manager_client/get_workload_instance_logs_success",
  GET_WORKLOAD_INSTANCE_LOGS_FAILED = "workload_manager_client/get_workload_instance_logs_failed",

  DELETE_WORKLOAD_REQUEST = "workload_manager_client/delete_workload_request",
  DELETE_WORKLOAD_SUCCESS = "workload_manager_client/delete_workload_success",
  DELETE_WORKLOAD_FAILED = "workload_manager_client/delete_workload_failed",

  CREATE_WORKLOAD_REQUEST = "workload_manager_client/create_workload_request",
  CREATE_WORKLOAD_SUCCESS = "workload_manager_client/create_workload_success",
  CREATE_WORKLOAD_FAILED = "workload_manager_client/create_workload_failed",

  EDIT_WORKLOAD_DESCRIPTION_REQUEST = "workload_manager_client/edit_workload_description_request",
  EDIT_WORKLOAD_DESCRIPTION_SUCCESS = "workload_manager_client/edit_workload_description_success",
  EDIT_WORKLOAD_DESCRIPTION_FAILED = "workload_manager_client/edit_workload_description_failed",

  EDIT_WORKLOAD_ENTRY_POINT_REQUEST = "workload_manager_client/edit_workload_entry_point_request",
  EDIT_WORKLOAD_ENTRY_POINT_SUCCESS = "workload_manager_client/edit_workload_entry_point_success",
  EDIT_WORKLOAD_ENTRY_POINT_FAILED = "workload_manager_client/edit_workload_entry_point_failed",

  EDIT_WORKLOAD_TIMEOUT_REQUEST = "workload_manager_client/edit_workload_timeout_request",
  EDIT_WORKLOAD_TIMEOUT_SUCCESS = "workload_manager_client/edit_workload_timeout_success",
  EDIT_WORKLOAD_TIMEOUT_FAILED = "workload_manager_client/edit_workload_timeout_failed",

  EDIT_WORKLOAD_MEMORY_REQUEST = "workload_manager_client/edit_workload_memory_request",
  EDIT_WORKLOAD_MEMORY_SUCCESS = "workload_manager_client/edit_workload_memory_success",
  EDIT_WORKLOAD_MEMORY_FAILED = "workload_manager_client/edit_workload_memory_failed",

  EDIT_WORKLOAD_TAGS_REQUEST = "workload_manager_client/edit_workload_tags_request",
  EDIT_WORKLOAD_TAGS_SUCCESS = "workload_manager_client/edit_workload_tags_success",
  EDIT_WORKLOAD_TAGS_FAILED = "workload_manager_client/edit_workload_tags_failed",

  EDIT_WORKLOAD_POLICY_REQUEST = "workload_manager_client/edit_workload_policy_request",
  EDIT_WORKLOAD_POLICY_SUCCESS = "workload_manager_client/edit_workload_policy_success",
  EDIT_WORKLOAD_POLICY_FAILED = "workload_manager_client/edit_workload_policy_failed",

  EDIT_WORKLOAD_QUERY_REQUEST = "workload_manager_client/edit_workload_query_request",
  EDIT_WORKLOAD_QUERY_SUCCESS = "workload_manager_client/edit_workload_query_success",
  EDIT_WORKLOAD_QUERY_FAILED = "workload_manager_client/edit_workload_query_failed",

  EDIT_WORKLOAD_QUERY_TYPE_REQUEST = "workload_manager_client/edit_workload_query_type_request",
  EDIT_WORKLOAD_QUERY_TYPE_SUCCESS = "workload_manager_client/edit_workload_query_type_success",
  EDIT_WORKLOAD_QUERY_TYPE_FAILED = "workload_manager_client/edit_workload_query_type_failed",

  EDIT_WORKLOAD_QUERY_OVERWRITE_REQUEST = "workload_manager_client/edit_workload_query_overwrite_request",
  EDIT_WORKLOAD_QUERY_OVERWRITE_SUCCESS = "workload_manager_client/edit_workload_query_overwrite_success",
  EDIT_WORKLOAD_QUERY_OVERWRITE_FAILED = "workload_manager_client/edit_workload_query_overwrite_failed",

  EDIT_WORKLOAD_QUERY_TABLE_NAME_REQUEST = "workload_manager_client/edit_workload_query_table_name_request",
  EDIT_WORKLOAD_QUERY_TABLE_NAME_SUCCESS = "workload_manager_client/edit_workload_query_table_name_success",
  EDIT_WORKLOAD_QUERY_TABLE_NAME_FAILED = "workload_manager_client/edit_workload_query_table_name_failed",

  GET_UPLOAD_WORKLOAD_CODE_URL_REQUEST = "workload_manager_client/get_upload_workload_code_url_request",
  GET_UPLOAD_WORKLOAD_CODE_URL_SUCCESS = "workload_manager_client/get_upload_workload_code_url_success",
  GET_UPLOAD_WORKLOAD_CODE_URL_FAILED = "workload_manager_client/get_upload_workload_code_url_failed",

  UPLOAD_WORKLOAD_CODE_REQUEST = "workload_manager_client/upload_workload_code_request",
  UPLOAD_WORKLOAD_CODE_SUCCESS = "workload_manager_client/upload_workload_code_success",
  UPLOAD_WORKLOAD_CODE_FAILED = "workload_manager_client/upload_workload_code_failed",

  COPY_WORKLOAD_CODE_REQUEST = "workload_manager_client/copy_workload_code_request",
  COPY_WORKLOAD_CODE_SUCCESS = "workload_manager_client/copy_workload_code_success",
  COPY_WORKLOAD_CODE_FAILED = "workload_manager_client/copy_workload_code_failed",

  GET_WORKLOAD_CODE_REQUEST = "workload_manager_client/get_workload_code_request",
  GET_WORKLOAD_CODE_SUCCESS = "workload_manager_client/get_workload_code_success",
  GET_WORKLOAD_CODE_FAILED = "workload_manager_client/get_workload_code_failed",

  GET_WORKLOAD_CODE_HEAD_REQUEST = "workload_manager_client/get_workload_code_head_request",
  GET_WORKLOAD_CODE_HEAD_SUCCESS = "workload_manager_client/get_workload_code_head_success",
  GET_WORKLOAD_CODE_HEAD_FAILED = "workload_manager_client/get_workload_code_head_failed",

  GET_WORKLOAD_CODE_PACKAGING_STATUS_REQUEST = "workload_manager_client/get_workload_code_packaging_status_request",
  GET_WORKLOAD_CODE_PACKAGING_STATUS_SUCCESS = "workload_manager_client/get_workload_code_packaging_status_success",
  GET_WORKLOAD_CODE_PACKAGING_STATUS_FAILED = "workload_manager_client/get_workload_code_packaging_status_failed",

  GET_WORKLOAD_TRIGGERS_REQUEST = "workload_manager_client/get_workload_triggers_request",
  GET_WORKLOAD_TRIGGERS_SUCCESS = "workload_manager_client/get_workload_triggers_success",
  GET_WORKLOAD_TRIGGERS_FAILED = "workload_manager_client/get_workload_triggers_failed",

  UPDATE_WORKLOAD_TRIGGER_REQUEST = "workload_manager_client/update_workload_trigger_request",
  UPDATE_WORKLOAD_TRIGGER_SUCCESS = "workload_manager_client/update_workload_trigger_success",
  UPDATE_WORKLOAD_TRIGGER_FAILED = "workload_manager_client/update_workload_trigger_failed",

  SET_WORKLOAD_SERVICE_SECRET_REQUEST = "workload_manager_client/set_workload_service_secret_request",
  SET_WORKLOAD_SERVICE_SECRET_SUCCESS = "workload_manager_client/set_workload_service_secret_success",
  SET_WORKLOAD_SERVICE_SECRET_FAILED = "workload_manager_client/set_workload_service_secret_failed",

  REMOVE_WORKLOAD_TRIGGER_REQUEST = "workload_manager_client/remove_workload_trigger_request",
  REMOVE_WORKLOAD_TRIGGER_SUCCESS = "workload_manager_client/remove_workload_trigger_success",
  REMOVE_WORKLOAD_TRIGGER_FAILED = "workload_manager_client/remove_workload_trigger_failed",

  GET_WORKLOAD_CONFIGURATION_REQUEST = "workload_manager_client/get_workload_configuration_request",
  GET_WORKLOAD_CONFIGURATION_SUCCESS = "workload_manager_client/get_workload_configuration_success",
  GET_WORKLOAD_CONFIGURATION_FAILED = "workload_manager_client/get_workload_configuration_failed",

  UPDATE_WORKLOAD_CONFIGURATION_REQUEST = "workload_manager_client/update_workload_configuration_request",
  UPDATE_WORKLOAD_CONFIGURATION_SUCCESS = "workload_manager_client/update_workload_configuration_success",
  UPDATE_WORKLOAD_CONFIGURATION_FAILED = "workload_manager_client/update_workload_configuration_failed",

  UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_REQUEST = "workload_manager_client/update_workload_configuration_by_key_request",
  UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS = "workload_manager_client/update_workload_configuration_by_key_success",
  UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED = "workload_manager_client/update_workload_configuration_by_key_failed",

  DELETE_WORKLOAD_CONFIGURATION_KEYS_REQUEST = "workload_manager_client/delete_workload_configuration_keys_request",
  DELETE_WORKLOAD_CONFIGURATION_KEYS_SUCCESS = "workload_manager_client/delete_workload_configuration_keys_success",
  DELETE_WORKLOAD_CONFIGURATION_KEYS_FAILED = "workload_manager_client/delete_workload_configuration_keys_failed",

  DELETE_WORKLOAD_CONFIGURATION_BY_KEY_REQUEST = "workload_manager_client/delete_workload_configuration_by_key_request",
  DELETE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS = "workload_manager_client/delete_workload_configuration_by_key_success",
  DELETE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED = "workload_manager_client/delete_workload_configuration_by_key_failed",

  ADD_WORKLOAD_VARIABLES_REQUEST = "workload_manager_client/add_workload_variables_request",
  ADD_WORKLOAD_VARIABLES_SUCCESS = "workload_manager_client/add_workload_variables_success",
  ADD_WORKLOAD_VARIABLES_FAILED = "workload_manager_client/add_workload_variables_failed",

  EDIT_WORKLOAD_VARIABLE_REQUEST = "workload_manager_client/edit_workload_variable_request",
  EDIT_WORKLOAD_VARIABLE_SUCCESS = "workload_manager_client/edit_workload_variable_success",
  EDIT_WORKLOAD_VARIABLE_FAILED = "workload_manager_client/edit_workload_variable_failed",

  DELETE_WORKLOAD_VARIABLE_REQUEST = "workload_manager_client/delete_workload_variable_request",
  DELETE_WORKLOAD_VARIABLE_SUCCESS = "workload_manager_client/delete_workload_variable_success",
  DELETE_WORKLOAD_VARIABLE_FAILED = "workload_manager_client/delete_workload_variable_failed",

  ADD_WORKLOAD_TRIGGER_REQUEST = "workload_manager_client/add_workload_trigger_request",
  ADD_WORKLOAD_TRIGGER_SUCCESS = "workload_manager_client/add_workload_trigger_success",
  ADD_WORKLOAD_TRIGGER_FAILED = "workload_manager_client/add_workload_trigger_failed",

  MANUALLY_EXECUTE_WORKLOAD_REQUEST = "workload_manager_client/manually_execute_workload_request",
  MANUALLY_EXECUTE_WORKLOAD_SUCCESS = "workload_manager_client/manually_execute_workload_success",
  MANUALLY_EXECUTE_WORKLOAD_FAILED = "workload_manager_client/manually_execute_workload_failed",

  CANCEL_WORKLOAD_EXECUTION_REQUEST = "workload_manager_client/cancel_workload_execution_request",
  CANCEL_WORKLOAD_EXECUTION_SUCCESS = "workload_manager_client/cancel_workload_execution_success",
  CANCEL_WORKLOAD_EXECUTION_FAILED = "workload_manager_client/cancel_workload_execution_failed",

  RELEASE_WORKLOAD_REQUEST = "workload_manager_client/release_workload_request",
  RELEASE_WORKLOAD_SUCCESS = "workload_manager_client/release_workload_success",
  RELEASE_WORKLOAD_FAILED = "workload_manager_client/release_workload_failed",

  DEPRECATE_WORKLOAD_REQUEST = "workload_manager_client/deprecate_workload_request",
  DEPRECATE_WORKLOAD_SUCCESS = "workload_manager_client/deprecate_workload_success",
  DEPRECATE_WORKLOAD_FAILED = "workload_manager_client/deprecate_workload_failed",

  DECOMMISSION_WORKLOAD_REQUEST = "workload_manager_client/decommission_workload_request",
  DECOMMISSION_WORKLOAD_SUCCESS = "workload_manager_client/decommission_workload_success",
  DECOMMISSION_WORKLOAD_FAILED = "workload_manager_client/decommission_workload_failed",

  DRAFT_NEW_WORKLOAD_VERSION_REQUEST = "workload_manager_client/draft_new_workload_version_request",
  DRAFT_NEW_WORKLOAD_VERSION_SUCCESS = "workload_manager_client/draft_new_workload_version_success",
  DRAFT_NEW_WORKLOAD_VERSION_FAILED = "workload_manager_client/draft_new_workload_version_failed",

  DELETE_WORKLOAD_VERSION_REQUEST = "workload_manager_client/delete_workload_version_request",
  DELETE_WORKLOAD_VERSION_SUCCESS = "workload_manager_client/delete_workload_version_success",
  DELETE_WORKLOAD_VERSION_FAILED = "workload_manager_client/delete_workload_version_failed",

  SWITCH_PRINCIPAL_REQUEST = "workload_manager_client/switch_principal_request",
  SWITCH_PRINCIPAL_SUCCESS = "workload_manager_client/switch_principal_success",
  SWITCH_PRINCIPAL_FAILED = "workload_manager_client/switch_principal_failed",

  ADD_ACTION_LABELS_REQUEST = "workload_manager_client/add_action_labels_request",
  ADD_ACTION_LABELS_SUCCESS = "workload_manager_client/add_action_labels_success",
  ADD_ACTION_LABELS_FAILED = "workload_manager_client/add_action_labels_failed",

  DELETE_ACTION_LABEL_REQUEST = "workload_manager_client/delete_action_label_request",
  DELETE_ACTION_LABEL_SUCCESS = "workload_manager_client/delete_action_label_success",
  DELETE_ACTION_LABEL_FAILED = "workload_manager_client/delete_action_label_failed",

  UPDATE_INTERNET_ACCESS_CONFIGURATION_REQUEST = "workload_manager_client/update_internet_access_configuration_request",
  UPDATE_INTERNET_ACCESS_CONFIGURATION_SUCCESS = "workload_manager_client/update_internet_access_configuration_success",
  UPDATE_INTERNET_ACCESS_CONFIGURATION_FAILED = "workload_manager_client/update_internet_access_configuration_failed",

  VALIDATE_WORKLOAD_NAME_REQUEST = "workload_manager_client/validate_workload_name_request",
  VALIDATE_WORKLOAD_NAME_SUCCESS = "workload_manager_client/validate_workload_name_success",
  VALIDATE_WORKLOAD_NAME_FAILED = "workload_manager_client/validate_workload_name_failed",
}

export const useGetWorkloadApi = (): GetWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkload, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_FAILED,
  });

export const useGetWorkloadsApi = (): GetWorkloadsApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloads, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOADS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOADS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOADS_FAILED,
  });

export const useGetReleasedWorkloadsApi = (): GetReleasedWorkloadsApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getReleasedWorkloads, {
    REQUEST_EVENT: ActionTypes.GET_RELEASED_WORKLOADS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_RELEASED_WORKLOADS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_RELEASED_WORKLOADS_FAILED,
  });

export const useGetAllVersionsOfWorkloadApi = (): GetAllVersionsOfWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getAllVersionsOfWorkload, {
    REQUEST_EVENT: ActionTypes.GET_ALL_VERSIONS_OF_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_ALL_VERSIONS_OF_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_ALL_VERSIONS_OF_WORKLOAD_FAILED,
  });

export const useGetWorkloadInstancesApi = (): GetWorkloadInstancesApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadInstances, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_INSTANCES_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_INSTANCES_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_INSTANCES_FAILED,
  });

export const useGetWorkloadInstanceApi = (): GetWorkloadInstanceApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadInstance, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_FAILED,
  });

export const useGetWorkloadInstanceLogsApi = (): GetWorkloadInstanceLogsApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadInstanceLogs, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_LOGS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_LOGS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_INSTANCE_LOGS_FAILED,
  });

export const useDeleteWorkloadApi = (): DeleteWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadApi, {
    REQUEST_EVENT: ActionTypes.DELETE_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_WORKLOAD_FAILED,
  });

export const useCreateWorkloadApi = (): CreateWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.createWorkload, {
    REQUEST_EVENT: ActionTypes.CREATE_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.CREATE_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.CREATE_WORKLOAD_FAILED,
  });

export const useEditWorkloadDescriptionApi = (): EditWorkloadDescriptionApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadDescription, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_DESCRIPTION_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_DESCRIPTION_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_DESCRIPTION_FAILED,
  });

export const useEditWorkloadEntryPointApi = (): EditWorkloadEntryPointApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadEntryPoint, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_ENTRY_POINT_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_ENTRY_POINT_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_ENTRY_POINT_FAILED,
  });

export const useEditWorkloadTimeoutApi = (): EditWorkloadTimeoutApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadTimeout, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_TIMEOUT_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_TIMEOUT_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_TIMEOUT_FAILED,
  });

export const useEditMemoryApi = (): EditWorkloadMemoryApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadMemory, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_MEMORY_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_MEMORY_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_MEMORY_FAILED,
  });

export const useEditWorkloadTagsApi = (): EditWorkloadTagsApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadTags, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_TAGS_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_TAGS_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_TAGS_FAILED,
  });

export const useEditWorkloadPolicyApi = (): EditWorkloadPolicyApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadPolicy, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_POLICY_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_POLICY_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_POLICY_FAILED,
  });

export const useEditWorkloadQueryApi = (): EditWorkloadQueryApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadQuery, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_FAILED,
  });

export const useEditWorkloadQueryTypeApi = (): EditWorkloadQueryTypeApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadQueryType, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TYPE_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TYPE_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TYPE_FAILED,
  });

export const useEditWorkloadQueryOverwriteApi = (): EditWorkloadQueryOverwriteApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadQueryOverwrite, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_OVERWRITE_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_OVERWRITE_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_OVERWRITE_FAILED,
  });

export const useEditWorkloadQueryTableNameApi = (): EditWorkloadQueryTableNameApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadQueryTableName, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TABLE_NAME_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TABLE_NAME_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_QUERY_TABLE_NAME_FAILED,
  });

export const useGetUploadWorkloadCodeUrlApi = (): GetUploadWorkloadCodeUrlApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getUploadCodeUrl, {
    REQUEST_EVENT: ActionTypes.GET_UPLOAD_WORKLOAD_CODE_URL_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_UPLOAD_WORKLOAD_CODE_URL_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_UPLOAD_WORKLOAD_CODE_URL_FAILED,
  });

export const useUploadWorkloadCodeApi = (): UploadWorkloadCodeApi =>
  useApiRequestAnalytics(WorkloadManagerClient.uploadCode, {
    REQUEST_EVENT: ActionTypes.UPLOAD_WORKLOAD_CODE_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPLOAD_WORKLOAD_CODE_SUCCESS,
    ERROR_EVENT: ActionTypes.UPLOAD_WORKLOAD_CODE_FAILED,
  });

export const useCopyWorkloadCodeApi = (): CopyWorkloadCodeApi =>
  useApiRequestAnalytics(WorkloadManagerClient.copyCode, {
    REQUEST_EVENT: ActionTypes.COPY_WORKLOAD_CODE_REQUEST,
    SUCCESS_EVENT: ActionTypes.COPY_WORKLOAD_CODE_SUCCESS,
    ERROR_EVENT: ActionTypes.COPY_WORKLOAD_CODE_FAILED,
  });

export const useGetWorkloadCodeApi = (): GetWorkloadCodeApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadCode, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_CODE_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_CODE_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_CODE_FAILED,
  });

export const useGetWorkloadCodeHeadApi = (): GetWorkloadCodeHeadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadCodeHead, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_CODE_HEAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_CODE_HEAD_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_CODE_HEAD_FAILED,
  });

export const useGetWorkloadCodePackagingStatusApi = (): GetWorkloadCodePackagingStatusApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadCodePackagingStatus, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_CODE_PACKAGING_STATUS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_CODE_PACKAGING_STATUS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_CODE_PACKAGING_STATUS_FAILED,
  });

export const useGetWorkloadTriggersApi = (): GetWorkloadTriggersApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadTriggers, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_TRIGGERS_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_TRIGGERS_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_TRIGGERS_FAILED,
  });

export const useUpdateWorkloadTriggerApi = (): UpdateWorkloadTriggerApi =>
  useApiRequestAnalytics(WorkloadManagerClient.updateWorkloadTrigger, {
    REQUEST_EVENT: ActionTypes.UPDATE_WORKLOAD_TRIGGER_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_WORKLOAD_TRIGGER_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_WORKLOAD_TRIGGER_FAILED,
});

export const useSetWorkloadServiceSecretApi = (): SetWorkloadServiceSecretApi =>
  useApiRequestAnalytics(WorkloadManagerClient.setWorkloadServiceSecret, {
    REQUEST_EVENT: ActionTypes.SET_WORKLOAD_SERVICE_SECRET_REQUEST,
    SUCCESS_EVENT: ActionTypes.SET_WORKLOAD_SERVICE_SECRET_SUCCESS,
    ERROR_EVENT: ActionTypes.SET_WORKLOAD_SERVICE_SECRET_FAILED,
  });

export const useRemoveWorkloadTriggerApi = (): RemoveWorkloadTriggerApi =>
  useApiRequestAnalytics(WorkloadManagerClient.removeWorkloadTrigger, {
    REQUEST_EVENT: ActionTypes.REMOVE_WORKLOAD_TRIGGER_REQUEST,
    SUCCESS_EVENT: ActionTypes.REMOVE_WORKLOAD_TRIGGER_SUCCESS,
    ERROR_EVENT: ActionTypes.REMOVE_WORKLOAD_TRIGGER_FAILED,
  });

export const useGetWorkloadConfigurationApi = (): GetWorkloadConfigurationApi =>
  useApiRequestAnalytics(WorkloadManagerClient.getWorkloadConfiguration, {
    REQUEST_EVENT: ActionTypes.GET_WORKLOAD_CONFIGURATION_REQUEST,
    SUCCESS_EVENT: ActionTypes.GET_WORKLOAD_CONFIGURATION_SUCCESS,
    ERROR_EVENT: ActionTypes.GET_WORKLOAD_CONFIGURATION_FAILED,
  });

export const useUpdateWorkloadConfigurationApi = (): UpdateWorkloadConfigurationApi =>
  useApiRequestAnalytics(WorkloadManagerClient.updateWorkloadConfiguration, {
    REQUEST_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_FAILED,
  });

export const useUpdateWorkloadConfigurationByKeyApi = (): UpdateWorkloadConfigurationByKeyApi =>
  useApiRequestAnalytics(WorkloadManagerClient.updateWorkloadConfigurationByKey, {
    REQUEST_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_REQUEST,
    SUCCESS_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS,
    ERROR_EVENT: ActionTypes.UPDATE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED,
  });

export const useDeleteWorkloadConfigurationKeysApi = (): DeleteWorkloadConfigurationKeysApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadConfigurationKeys, {
    REQUEST_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_KEYS_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_KEYS_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_KEYS_FAILED,
  });

export const useDeleteWorkloadConfigurationByKeyApi = (): DeleteWorkloadConfigurationByKeyApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadConfigurationByKey, {
    REQUEST_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_BY_KEY_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_BY_KEY_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_WORKLOAD_CONFIGURATION_BY_KEY_FAILED,
  });

export const useAddWorkloadTriggerApi = (): AddWorkloadTriggerApi =>
  useApiRequestAnalytics(WorkloadManagerClient.addWorkloadTrigger, {
    REQUEST_EVENT: ActionTypes.ADD_WORKLOAD_TRIGGER_REQUEST,
    SUCCESS_EVENT: ActionTypes.ADD_WORKLOAD_TRIGGER_SUCCESS,
    ERROR_EVENT: ActionTypes.ADD_WORKLOAD_TRIGGER_FAILED,
  });

export const useAddWorkloadVariablesApi = (): AddWorkloadVariablesApi =>
  useApiRequestAnalytics(WorkloadManagerClient.addWorkloadVariables, {
    REQUEST_EVENT: ActionTypes.ADD_WORKLOAD_VARIABLES_REQUEST,
    SUCCESS_EVENT: ActionTypes.ADD_WORKLOAD_VARIABLES_SUCCESS,
    ERROR_EVENT: ActionTypes.ADD_WORKLOAD_VARIABLES_FAILED,
  });

export const useEditWorkloadVariableApi = (): EditWorkloadVariableApi =>
  useApiRequestAnalytics(WorkloadManagerClient.editWorkloadVariable, {
    REQUEST_EVENT: ActionTypes.EDIT_WORKLOAD_VARIABLE_REQUEST,
    SUCCESS_EVENT: ActionTypes.EDIT_WORKLOAD_VARIABLE_SUCCESS,
    ERROR_EVENT: ActionTypes.EDIT_WORKLOAD_VARIABLE_FAILED,
  });

export const useDeleteWorkloadVariableApi = (): DeleteWorkloadVariableApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadVariable, {
    REQUEST_EVENT: ActionTypes.DELETE_WORKLOAD_VARIABLE_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_WORKLOAD_VARIABLE_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_WORKLOAD_VARIABLE_FAILED,
  });

export const useManuallyExecuteWorkloadApi = (): ManuallyExecuteWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.manuallyExecuteWorkload, {
    REQUEST_EVENT: ActionTypes.MANUALLY_EXECUTE_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.MANUALLY_EXECUTE_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.MANUALLY_EXECUTE_WORKLOAD_FAILED,
  });

export const useCancelWorkloadExecutionApi = (): CancelWorkloadExecutionApi =>
  useApiRequestAnalytics(WorkloadManagerClient.cancelWorkloadExecution, {
    REQUEST_EVENT: ActionTypes.CANCEL_WORKLOAD_EXECUTION_REQUEST,
    SUCCESS_EVENT: ActionTypes.CANCEL_WORKLOAD_EXECUTION_SUCCESS,
    ERROR_EVENT: ActionTypes.CANCEL_WORKLOAD_EXECUTION_FAILED,
  });

export const useReleaseWorkloadApi = (): ReleaseWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.releaseWorkload, {
    REQUEST_EVENT: ActionTypes.RELEASE_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.RELEASE_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.RELEASE_WORKLOAD_FAILED,
  });

export const useDeprecateWorkloadApi = (): DeprecateWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deprecateWorkload, {
    REQUEST_EVENT: ActionTypes.DEPRECATE_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.DEPRECATE_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.DEPRECATE_WORKLOAD_FAILED,
  });

export const useDecommissionWorkloadApi = (): DecommissionWorkloadApi =>
  useApiRequestAnalytics(WorkloadManagerClient.decommissionWorkload, {
    REQUEST_EVENT: ActionTypes.DECOMMISSION_WORKLOAD_REQUEST,
    SUCCESS_EVENT: ActionTypes.DECOMMISSION_WORKLOAD_SUCCESS,
    ERROR_EVENT: ActionTypes.DECOMMISSION_WORKLOAD_FAILED,
  });

export const useDraftNewWorkloadVersionApi = (): DraftNewWorkloadVersionFromApi =>
  useApiRequestAnalytics(WorkloadManagerClient.draftNewWorkloadVersion, {
    REQUEST_EVENT: ActionTypes.DRAFT_NEW_WORKLOAD_VERSION_REQUEST,
    SUCCESS_EVENT: ActionTypes.DRAFT_NEW_WORKLOAD_VERSION_SUCCESS,
    ERROR_EVENT: ActionTypes.DRAFT_NEW_WORKLOAD_VERSION_FAILED,
  });

export const useDeleteWorkloadVersionApi = (): DeleteWorkloadVersionApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadVersion, {
    REQUEST_EVENT: ActionTypes.DELETE_WORKLOAD_VERSION_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_WORKLOAD_VERSION_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_WORKLOAD_VERSION_FAILED,
  });

export const useSwitchPrincipalApi = (): SwitchPrincipalApi =>
  useApiRequestAnalytics(WorkloadManagerClient.switchPrincipal, {
    REQUEST_EVENT: ActionTypes.SWITCH_PRINCIPAL_REQUEST,
    SUCCESS_EVENT: ActionTypes.SWITCH_PRINCIPAL_SUCCESS,
    ERROR_EVENT: ActionTypes.SWITCH_PRINCIPAL_FAILED,
  });

export const useAddActionLabelsApi = (): AddActionLabelsApi =>
  useApiRequestAnalytics(WorkloadManagerClient.addWorkloadActionLabels, {
    REQUEST_EVENT: ActionTypes.ADD_ACTION_LABELS_REQUEST,
    SUCCESS_EVENT: ActionTypes.ADD_ACTION_LABELS_SUCCESS,
    ERROR_EVENT: ActionTypes.ADD_ACTION_LABELS_FAILED,
  });

export const useDeleteActionLabelApi = (): DeleteActionLabelApi =>
  useApiRequestAnalytics(WorkloadManagerClient.deleteWorkloadActionLabel, {
    REQUEST_EVENT: ActionTypes.DELETE_ACTION_LABEL_REQUEST,
    SUCCESS_EVENT: ActionTypes.DELETE_ACTION_LABEL_SUCCESS,
    ERROR_EVENT: ActionTypes.DELETE_ACTION_LABEL_FAILED,
  });

export const useValidateWorkloadNameApi = (): ValidateWorkloadNameApi =>
  useApiRequestAnalytics(WorkloadManagerClient.validateWorkloadName, {
    REQUEST_EVENT: ActionTypes.VALIDATE_WORKLOAD_NAME_REQUEST,
    SUCCESS_EVENT: ActionTypes.VALIDATE_WORKLOAD_NAME_SUCCESS,
    ERROR_EVENT: ActionTypes.VALIDATE_WORKLOAD_NAME_FAILED,
  });

export const useWorkloadManagerClient = (): WorkloadManagerClientApi => ({
  getWorkloadApi: useGetWorkloadApi(),
  getWorkloadsApi: useGetWorkloadsApi(),
  getReleasedWorkloadsApi: useGetReleasedWorkloadsApi(),
  getAllVersionsOfWorkload: useGetAllVersionsOfWorkloadApi(),
  getWorkloadInstancesApi: useGetWorkloadInstancesApi(),
  getWorkloadInstanceApi: useGetWorkloadInstanceApi(),
  getWorkloadInstanceLogsApi: useGetWorkloadInstanceLogsApi(),
  deleteWorkloadApi: useDeleteWorkloadApi(),
  createWorkloadApi: useCreateWorkloadApi(),
  editWorkloadDescriptionApi: useEditWorkloadDescriptionApi(),
  editWorkloadEntryPointApi: useEditWorkloadEntryPointApi(),
  editWorkloadTimeoutApi: useEditWorkloadTimeoutApi(),
  editWorkloadMemoryApi: useEditMemoryApi(),
  editWorkloadTagsApi: useEditWorkloadTagsApi(),
  editWorkloadPolicy: useEditWorkloadPolicyApi(),
  editWorkloadQuery: useEditWorkloadQueryApi(),
  editWorkloadQueryType: useEditWorkloadQueryTypeApi(),
  editWorkloadQueryOverwrite: useEditWorkloadQueryOverwriteApi(),
  editWorkloadQueryTableName: useEditWorkloadQueryTableNameApi(),
  getUploadWorkloadCodeUrlApi: useGetUploadWorkloadCodeUrlApi(),
  uploadWorkloadCodeApi: useUploadWorkloadCodeApi(),
  copyWorkloadCodeApi: useCopyWorkloadCodeApi(),
  getWorkloadCodeApi: useGetWorkloadCodeApi(),
  getWorkloadCodeHeadApi: useGetWorkloadCodeHeadApi(),
  getWorkloadCodePackagingStatusApi: useGetWorkloadCodePackagingStatusApi(),
  getWorkloadTriggersApi: useGetWorkloadTriggersApi(),
  updateWorkloadTriggerApi: useUpdateWorkloadTriggerApi(),
  setWorkloadServiceSecretApi: useSetWorkloadServiceSecretApi(),
  removeWorkloadTriggerApi: useRemoveWorkloadTriggerApi(),
  getWorkloadConfiguration: useGetWorkloadConfigurationApi(),
  updateWorkloadConfiguration: useUpdateWorkloadConfigurationApi(),
  updateWorkloadConfigurationByKey: useUpdateWorkloadConfigurationByKeyApi(),
  deleteWorkloadConfigurationKeys: useDeleteWorkloadConfigurationKeysApi(),
  deleteWorkloadConfigurationByKey: useDeleteWorkloadConfigurationByKeyApi(),
  addWorkloadTriggerApi: useAddWorkloadTriggerApi(),
  addWorkloadVariablesApi: useAddWorkloadVariablesApi(),
  editWorkloadVariableApi: useEditWorkloadVariableApi(),
  deleteWorkloadVariableApi: useDeleteWorkloadVariableApi(),
  manuallyExecuteWorkloadApi: useManuallyExecuteWorkloadApi(),
  cancelWorkloadExecutionApi: useCancelWorkloadExecutionApi(),
  releaseWorkload: useReleaseWorkloadApi(),
  deprecateWorkload: useDeprecateWorkloadApi(),
  decommissionWorkload: useDecommissionWorkloadApi(),
  draftNewWorkloadVersion: useDraftNewWorkloadVersionApi(),
  deleteWorkloadVersion: useDeleteWorkloadVersionApi(),
  switchPrincipal: useSwitchPrincipalApi(),
  addActionLabels: useAddActionLabelsApi(),
  deleteActionLabel: useDeleteActionLabelApi(),
  validateWorkloadName: useValidateWorkloadNameApi(),
});

export { ActionTypes as WorkloadManagerClientActionType };

export default useWorkloadManagerClient;
