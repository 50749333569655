import React from "react";
import { Tooltip } from "./tooltip";

export const withHoveredState = (Component: React.ComponentType<{hovered?: boolean}>) =>
  // TODO: Convert to pure function component
  class HoveredComponent extends React.Component<any, {hovered?: boolean}> {
    constructor(props: any) {
      super(props);
      this.state = {hovered: false};
    }

    public render() {
      return (
        <div
          onMouseEnter={() => this.setState({hovered: true})}
          onMouseLeave={() => this.setState({hovered: false})}
        >
          <Component {...this.props} hovered={this.state.hovered} />
        </div>
      );
    }
  };

export const withTooltip = (Component: React.ComponentType<any>) => (props: any & {
  tooltip?: string,
}) => {

    const {
      tooltip = "",
      ...remainingProps
    } = props;

    if (tooltip && tooltip.trim().length > 0) {
      return (
        <Tooltip title={tooltip}>
          <Component {...remainingProps} />
        </Tooltip>
      );
    }

    return <Component {...remainingProps} />;
};
