import { createStyles } from "@material-ui/core/styles";
import ModuleIcon from "@material-ui/icons/Assignment";
import Color from "@Color";

export const PrimaryIcon = ModuleIcon;

export const primaryColor = Color.MODULES;

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    title: {
      color: Color.TEXT,
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "28px",
      margin: 0,
      padding: 0,
    },
    name: {
      marginBottom: 10,
    },
    path: {
      marginTop: 15,
    },
    textField: {
      color: Color.TEXT,
      fontSize: 16,
      height: "auto",
    },
    formHelperText: {
      color: Color.TEXT,
      fontSize: 13,
    },
    formHelperTextError: {
      marginBottom: 15,
    },
    inputLabelShrink: {
      lineHeight: "1 !important",
    },
  });

export default styles;
