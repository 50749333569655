import React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import PortalModuleDialog, {
  PortalModuleDialogActions,
  PortalModuleDialogModel,
} from "@components/portal-module-dialog";
import { AlertSeverity, MissingAlertView, WaitForApiRequestView, WorkloadOperations } from "@components";
import { isEmptyString, noop } from "@util";
import classnames from "classnames";
import { WorkloadCodePackagingStatus } from "@data";
import {
  useGetWorkloadCodeHead,
  useGetWorkloadCodePackagingStatus,
  useWorkloadActionManagement
} from "@hooks";

export interface WorkloadOperationDialogModel extends PortalModuleDialogModel<string> {
  open: boolean;
  name: string;
  version?: number;
  isQueryType?: boolean;
  operation?: WorkloadOperations;
  dialogClassName?: string;
}

export interface WorkloadOperationDialogActions extends PortalModuleDialogActions<string> {
}

type Props = WithStyles< typeof styles> & WorkloadOperationDialogModel & WorkloadOperationDialogActions;

export const WorkloadOperationDialog = withStyles(styles)((props: Props) => {

  const {
    classes,
    open,
    name,
    version = 1,
    isQueryType = false,
    operation = WorkloadOperations.NONE,
    dialogClassName,
    ...otherProps
  } = props;

  const title = React.useMemo(() => `${operation} Workload`, [operation]);

  const isRelease = React.useMemo(() => operation === WorkloadOperations.RELEASE, [operation]);

  const [ model, actions ] = useWorkloadActionManagement({ name, version, operation });

  const { showLoadingIndicator, ...otherModel } = model;
  const { performOperation, ...otherActions } = actions;

  const [{ errorResponse, showLoadingIndicator: loadingCodeHead }, { refresh: getCodeHead }] =
    useGetWorkloadCodeHead({ name, version, deferRequest: true });

  const [{
    status,
    showLoadingIndicator: loadingPackagingStatus,
    errorMessage: packagingError
  }, { refresh: getPackagingStatus }] = useGetWorkloadCodePackagingStatus({ name, version, deferRequest: true });

  const missingCode = React.useMemo(() => errorResponse?.status === 404, [errorResponse]);
  const codePackaged = React.useMemo(() => status && status === WorkloadCodePackagingStatus.SUCCESS, [status]);

  const isLoading = React.useMemo(() => {
    return showLoadingIndicator || loadingCodeHead || loadingPackagingStatus;
  }, [showLoadingIndicator, loadingCodeHead, loadingPackagingStatus]);

  const continueButtonDisabled = React.useMemo(() => {
    return isLoading || (isRelease && !isQueryType && (missingCode || !codePackaged));
  }, [isLoading, isRelease, isQueryType, missingCode, codePackaged]);

  const retry = React.useCallback(() => {
    if (isRelease && !isQueryType) {
      getCodeHead();
      getPackagingStatus();
    }
  }, [isRelease, isQueryType, getCodeHead, getPackagingStatus]);

  React.useEffect(() => open ? retry() : noop(), [open]);

  const confirm = React.useCallback(() => {
    performOperation();
  }, [performOperation]);

  if (isEmptyString(name) || operation === WorkloadOperations.NONE) {
    return null;
  }

  return (
    <PortalModuleDialog
      {...otherProps}
      {...otherModel}
      {...otherActions}
      className={classnames("workloadOperationsDialog", dialogClassName)}
      open={open}
      title={title}
      loading={showLoadingIndicator}
      continueButtonLabel={operation}
      continueButtonDisabled={continueButtonDisabled}
      confirm={confirm}
    >
      <WaitForApiRequestView
        className="workloadOperationsView"
        loadingMessage="Loading..."
        errorTitle="Failed to Load Workload Operation Dialog"
        showLoadingIndicator={isLoading}
        retry={retry}
      >
        <div className={classnames("workloadOperation", classes.container)}>
          {isRelease && continueButtonDisabled ? (
            <MissingAlertView
              className={classnames("codeNotUploadedAlert")}
              severity={AlertSeverity.WARNING}
              showAction={false}
              message={(
                <React.Fragment>
                  <h3>{operation} operation is disabled due to the missing requirements:</h3>
                  <ul>
                    {missingCode && (
                      <li className={classnames("warnItems", classes.warnItems)}>
                        Code must be uploaded to version {version} of {name}
                      </li>
                    )}
                    {!codePackaged && (
                      <li className={classnames("warnItems", classes.warnItems)}>
                        Code must be packaged to version {version} of {name}
                        <ul>
                          <li className={classnames("warnItems", classes.warnItems)}>
                            Code will be packaged a few seconds after being uploaded
                          </li>
                          {!isEmptyString(packagingError) && (
                            <li className={classnames("warnItems", classes.warnItems)}>
                              Code packaging error: {packagingError}
                            </li>
                            )}
                        </ul>
                      </li>
                    )}
                  </ul>
                </React.Fragment>
              )}
            />
          ) : (
              <React.Fragment>
                <label className={classnames("title", classes.title)}>
                  Are you sure you want to {operation} this workload?
                </label>
                {isRelease && (
                  <label className={classnames("subtitle", classes.subtitle)}>
                    NOTE: If there is a released version of this workload,
                    it will be decommissioned when this version is released.
                  </label>
                )}
                <label className={classnames("name", classes.name)}>
                  <b>Name:</b> {name}
                  {version && (
                    <span className={classnames("version", classes.version)}>
                      <b>Version:</b> {version}
                    </span>
                  )}
                </label>
            </React.Fragment>
          )}
        </div>
      </WaitForApiRequestView>
    </PortalModuleDialog>
  );
});

export default WorkloadOperationDialog;
