import createStyles from "@material-ui/core/styles/createStyles";
import Color from "@Color";

export const markdownStyle = () =>
  createStyles({
    container: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "center",
    },
    blur: {
      backdropFilter: "blur(5px)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9998,
    },
    loadingView: {
      padding: "25px 50px",
      border: `1px solid ${Color.BLACK}`,
      alignSelf: "center",
      borderRadius: 6,
      backgroundColor: Color.WHITE,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      color: Color.SIGNIFY_GREEN,
      zIndex: 9999,
      "& > .circularProgress": {
        color: Color.SIGNIFY_GREEN,
        width: 25,
        height: 25,
      },
      "& > .label": {
        color: Color.TEXT,
        lineHeight: "25px",
        margin: "0 14px",
        fontSize: 16,
        fontWeight: 400,
        fontStyle: "normal",
      },
    },
    loadingIndicatorLabel: {
      flex: "0 0 auto",
      margin: 8,
      fontSize: 18,
      lineHeight: "21px",
      fontWeight: 400,
    },
  });

export default markdownStyle;
