import React from "react";
import { isDataWorkloadsOneDotThreeFeaturesEnabled, noop } from "@util";
import classnames from "classnames";
import TagsIcon from "@material-ui/icons/Label";
import { summaryView as styles } from "./styles";
import { getPathToDataSet } from "@modules/dataSetDetails/helpers";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ValidationView, { ValidationViewActions, ValidationViewModel } from "./ValidationView";
import { WorkloadConfiguration as WorkloadParameters, WorkloadDataSetsAttributes, WorkloadInternetConfig } from "@data";
import {
  ChipView,
  DataSetsList,
  DataSetsListColumn,
  DataSetsListItemData,
  SummaryViewActionItems,
  SummaryViewWithActions,
  SummaryViewWithActionsModel,
} from "@components";
import DnsNameIcon from "@material-ui/icons/CheckCircle";

export interface Model extends SummaryViewWithActionsModel, ValidationViewModel {
  summaryViewItems?: SummaryViewActionItems[];
  tags?: string[];
  parameters?: WorkloadParameters[];
  dataInputs?: WorkloadDataSetsAttributes[];
  dataOutputs?: WorkloadDataSetsAttributes[];
  loading?: boolean;
  hideValidationView?: boolean;
  showCodeError?: boolean;
  missingCode?: boolean;
  showEditTags?: boolean;
  workloadFailure?: boolean;
  failureReason?: string;
  queryWorkload?: boolean;
  internetConfiguration: WorkloadInternetConfig;
  showInternetAccess?: boolean;
}

export interface Actions extends ValidationViewActions {
  showDataSetDetails: (dataSet: DataSetsListItemData) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
  editTags?: () => void;
  uploadCode?: () => void;
}

type Props = WithStyles<typeof styles> & Model & Actions & {
  children?: React.ReactNode;
};

export const SummaryView = withStyles(styles)((props: Props) => {

  const {
    classes,
    summaryViewItems,
    tags = [],
    dataInputs = [],
    dataOutputs = [],
    loading,
    hideValidationView,
    showCodeError,
    missingCode,
    showDataSetDetails,
    showDataSetDetailsInNewTab,
    showEditTags,
    workloadFailure,
    queryWorkload,
    failureReason = "",
    editTags = noop,
    uploadCode,
    internetConfiguration,
    showInternetAccess = isDataWorkloadsOneDotThreeFeaturesEnabled(),
    children,
  } = props;

  const dataSetItem = React.useCallback((name, accountId) =>
      new DataSetsListItemData({
        name, href: getPathToDataSet(
          `lrn:iot:dmg::${accountId}:dataset:alias:${name}`),
      }),
    [ getPathToDataSet ]);

  return (
    <div className={classnames("summaryView", classes.container)}>
      {!hideValidationView && (
        <ValidationView
          showCodeError={showCodeError}
          missingCode={missingCode}
          uploadCode={uploadCode}
          workloadFailure={workloadFailure}
          queryWorkload={queryWorkload}
          failureReason={failureReason}
        />
      )}
      <SummaryViewWithActions
        className={classnames("summaryView", classes.summaryContainer)}
        items={summaryViewItems}
      >
        {!loading && (
          <React.Fragment>
            <div className={classnames("dataSetContainer", classes.dataSetContainer)}>
              <div className={classnames("dataSetInputContainer", classes.dataSetInputContainer)}>
                <div className={classnames("dataSetControls", classes.dataSetControls)}>
                  <label className={classnames("title", classes.dataSetTitle)}>
                    Data Set Inputs
                  </label>
                </div>
                <DataSetsList
                  className={classnames("dataSetInputList", classes.dataListView)}
                  noResultsLabel="No Data Set inputs selected for this workload"
                  selectable={false}
                  showSearch={false}
                  selectAllDisabled={true}
                  tableLayoutFixed={false}
                  columns={[DataSetsListColumn.NAME]}
                  items={dataInputs.map(({dataSetAlias, accountId}) => dataSetItem(dataSetAlias, accountId))}
                  onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
                  showMoreInfoLabel="Data Set Details"
                  onClickItem={React.useCallback((item: DataSetsListItemData) =>
                    showDataSetDetails(item), [showDataSetDetails])}
                />
              </div>
              <div className={classnames("dataSetOutputContainer", classes.dataSetOutputContainer)}>
                <div className={classnames("dataSetControls", classes.dataSetControls)}>
                  <label className={classnames("title", classes.dataSetTitle)}>
                    Data Set Outputs
                  </label>
                </div>
                <DataSetsList
                  className={classnames("dataSetOutputList", classes.dataListView)}
                  noResultsLabel="No Data Set outputs selected for this workload"
                  selectable={false}
                  showSearch={false}
                  selectAllDisabled={true}
                  tableLayoutFixed={false}
                  columns={[DataSetsListColumn.NAME]}
                  items={dataOutputs.map(({dataSetAlias, accountId}) => dataSetItem(dataSetAlias, accountId))}
                  onClickShowMoreInfoInNewTab={showDataSetDetailsInNewTab}
                  showMoreInfoLabel="Data Set Details"
                  onClickItem={React.useCallback((item: DataSetsListItemData) =>
                    showDataSetDetails(item), [showDataSetDetails])}
                />
              </div>
            </div>
            {showInternetAccess && (
              <ChipView
                title="Accessible DNS Names"
                items={internetConfiguration.accessibleDns}
                className={classnames("dnsNames")}
                icon={DnsNameIcon}
                showEmptyView={true}
                emptyViewLabel="No DNS names provided"
                marginTop={true}
              />
            )}
            <ChipView
              title={"Tags"}
              items={tags}
              className={"tag"}
              icon={TagsIcon}
              showEmptyView={true}
              emptyViewLabel={"No tags are defined for this workload"}
              onClickEditIcon={editTags}
              showEditIcon={showEditTags}
              editTooltipText={"Update Tags"}
              editIconClassName={"editWorkloadTags"}
            />
          </React.Fragment>
        )}
      </SummaryViewWithActions>
      {children}
    </div>
  );
});

export default SummaryView;
