import { Module } from "@data";
import { createModule } from "@modules/base";
import { MODULE_ID, MODULE_PATH } from "./constants";
import { primaryColor, PrimaryIcon as icon } from "./styles";
import view from "./FederationProviderDetailsView";

type RouteParams = { id?: string };

export const federationProviderDetailsModule: Module = createModule<RouteParams>({
  id: MODULE_ID,
  path: MODULE_PATH,
  exactPath: false,
  strictPath: true,
  name: "Federation Provider Details",
  title: "Federation Provider Details",
  className: "federationProviderDetailsModule",
  primaryColor,
  icon,
  view,
});

export default federationProviderDetailsModule;
